import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Address } from '@activia/cm-api';
import { LocationViewMode } from '../location-view-mode.constant';
import { CountryService } from '@activia/geo';
import { Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { PlacementTypes } from '@activia/ngx-components';
import { getApiAddressDisplayFormat } from '@amp/site-monitoring-shared';

@Component({
  selector: 'amp-address-viewer',
  templateUrl: './address-viewer.component.html',
  styleUrls: ['./address-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressViewerComponent implements OnChanges {
  @Input() address: Address;
  @Input() showEditIcon = true;
  @Output() displayModeChanged = new EventEmitter<LocationViewMode>();

  PlacementTypes = PlacementTypes;

  formattedAddress: string;

  constructor(private _countryService: CountryService, private _translocoService: TranslocoService) {}

  ngOnChanges({ address }: SimpleChanges) {
    if (address?.currentValue) {
      this.formattedAddress = getApiAddressDisplayFormat(this.address, this._countryService.getCountryByCountryNameOrCode(this.address.country), true, true);
    }
  }

  getCountryTranslation(countryNameOrCode: string): Observable<string> {
    const country = this._countryService.getCountryByCountryNameOrCode(countryNameOrCode);
    return country?.shortCountryCode ? this._translocoService.selectTranslate(country.shortCountryCode, {}, { scope: 'countries' }) : of('');
  }
}
