import { getNextCharNotEmpty, getPreviousCharNotEmpty, trimWrapperChar } from '@activia/ngx-components';
import {
  IExpressionInfo,
  IExpressionSuggestionsResult,
  IFieldExpression,
  INlpDatasourceService,
  INlpEnum18nInfo,
  IParsedExpressionInfo,
  IPartialValueInfo,
  ITokenSuggestion,
  NlpExpressionParser,
} from '@activia/ngx-components/nlp';
import { CstNode, ILexingError, ILexingResult, IToken, tokenMatcher, TokenType } from 'chevrotain';
import { last as _last, dropRight as _dropRight } from 'lodash/array';

import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceFilterApiExpressionVisitor } from './device-filter-api-expression.visitor';
import { DeviceFilterExpressionListVisitor } from './device-filter-expression-list.visitor';
// prettier-ignore
import {
  And,
  BoardTagField,
  Contains,
  DateField, DateOperator,
  DateValue,
  DefinedStringField,
  DurationField,
  EndsWith,
  EqualityNumericField,
  EqualityStringField,
  Equals,
  Field,
  GreaterThan,
  GreaterThanEquals,
  IsDefined,
  IsNotDefined,
  LessThan,
  LessThanEquals,
  LogicalOperator,
  LParenthesis,
  NotContains,
  NotEndsWith,
  NotEquals,
  NotStartsWith,
  NumericField,
  NumericValue,
  Operator,
  Or,
  RelationalOperator,
  RParenthesis,
  SelfEndOperator,
  SiteTagField,
  StartsWith,
  StringField,
  StringOperator,
  StringValue,
  TagField,
  TimeUnit,
  Value,
  WhiteSpace
} from './device-filter.tokens';
import { ISuggestionsInfo } from './device-filter.utils';

const createTokens = (fieldTokens: TokenType[]): TokenType[] => [
  WhiteSpace,
  LParenthesis,
  RParenthesis,
  And,
  Or,
  LogicalOperator,
  NotStartsWith,
  StartsWith,
  NotEndsWith,
  EndsWith,
  NotContains,
  Contains,
  GreaterThanEquals,
  GreaterThan,
  LessThanEquals,
  LessThan,
  RelationalOperator,
  StringOperator,
  DateOperator,
  Operator,
  SelfEndOperator,
  IsDefined,
  IsNotDefined,
  NotEquals,
  Equals,
  /** START fields **/
  ...fieldTokens,
  /** END fields **/
  EqualityStringField,
  DefinedStringField,
  StringField,
  EqualityNumericField,
  NumericField,
  Field,
  DateValue,
  NumericValue,
  StringValue,
  Value,
  SiteTagField,
  BoardTagField,
  TagField, // must be a the end
];

/**
 * The NLP parser for device filters
 */
export class DeviceFilterNlpParser extends NlpExpressionParser {
  /** Visitor of the expression to retrieve the current expression **/
  private _expressionListVisitor: DeviceFilterExpressionListVisitor;

  /** Visitor of the expression to convert to the api expression **/
  private _expressionApiVisitor: DeviceFilterApiExpressionVisitor;

  /** Visitor of the expression to retrieve the current expression **/
  private _tokenGroups = new Map<TokenType, string>();

  /** @ignore **/
  constructor(
    fieldTokens: TokenType[],
    private datasourceService: INlpDatasourceService,
    useDisplayLanguage = true,
    private enableTags = true,
  ) {
    // init the parser and lexer and verify the validity of the rules

    super(createTokens(fieldTokens), useDisplayLanguage);
    this.performSelfAnalysis();

    const BaseCstVisitorWithDefaults = this.getBaseCstVisitorConstructorWithDefaults();
    this._expressionListVisitor = new DeviceFilterExpressionListVisitor(BaseCstVisitorWithDefaults);
    this._expressionApiVisitor = new DeviceFilterApiExpressionVisitor(BaseCstVisitorWithDefaults);

    // init i18n depending on tokens used
    this.datasourceService.initParserI18n(this);

    // resets datasource cache in case there are new tags created without refreshing tha page after.
    this.datasourceService.resetCache();
  }

  /** @ignore **/
  private filter = this.RULE('filter', () => {
    this.SUBRULE(this.filterExpression);
  });

  /** @ignore **/
  private filterExpression = this.RULE('filterExpression', () => {
    this.SUBRULE(this.fieldExpression, { LABEL: 'lhs' });
    this.MANY(() => {
      // consuming 'LogicalOperator' will consume either AND or OR as they are subclasses of
      // LogicalOperator
      this.CONSUME(LogicalOperator);
      //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during
      //  runtime
      this.SUBRULE2(this.fieldExpression, { LABEL: 'rhs' });
    });
  });

  /** @ignore **/
  private fieldExpression = this.RULE('fieldExpression', () => {
    // parenthesisExpression has the highest precedence and thus it appears
    // in the "lowest" leaf in the expression ParseTree.
    const or = [
      { ALT: () => this.SUBRULE(this.parenthesisExpression) },
      { ALT: () => this.SUBRULE(this.stringExpression) },
      { ALT: () => this.SUBRULE(this.numericExpression) },
      { ALT: () => this.SUBRULE(this.dateExpression) },
      { ALT: () => this.SUBRULE(this.durationExpression) },
      { ALT: () => this.SUBRULE(this.selfEndExpression) },
      { ALT: () => this.SUBRULE(this.siteTagFieldExpression) },
      { ALT: () => this.SUBRULE(this.boardTagFieldExpression) },
    ];
    if (this.enableTags) {
      or.push({ ALT: () => this.SUBRULE(this.tagFieldExpression) });
    }
    this.OR(or);
  });

  /** @ignore **/
  private stringExpression = this.RULE('stringExpression', () => {
    this.OR([{ ALT: () => this.SUBRULE(this.regularStringExpression) }, { ALT: () => this.SUBRULE(this.equalityStringExpression) }, { ALT: () => this.SUBRULE(this.definedStringExpression) }]);
  });

  /** @ignore **/
  private regularStringExpression = this.RULE('regularStringExpression', () => {
    this.CONSUME(StringField);
    this.CONSUME(StringOperator);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private equalityStringExpression = this.RULE('equalityStringExpression', () => {
    this.CONSUME(EqualityStringField);
    this.OR([{ ALT: () => this.CONSUME(Equals) }, { ALT: () => this.CONSUME(NotEquals) }]);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private definedStringExpression = this.RULE('definedStringExpression', () => {
    this.CONSUME(DefinedStringField);
    this.CONSUME(StringOperator);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private selfEndExpression = this.RULE('selfEndExpression', () => {
    this.OR([{ ALT: () => this.SUBRULE(this.isDefinedOrUndefinedExpression) }, { ALT: () => this.SUBRULE(this.dateIsDefinedOrUndefinedExpression) }]);
  });

  private isDefinedOrUndefinedExpression = this.RULE('isDefinedOrUndefinedExpression', () => {
    this.CONSUME(StringField);
    this.OR([{ ALT: () => this.CONSUME(IsDefined) }, { ALT: () => this.CONSUME(IsNotDefined) }]);
  });

  /** @ignore **/
  private dateExpression = this.RULE('dateExpression', () => {
    this.CONSUME(DateField);
    this.CONSUME(DateOperator);
    this.OR([
      {
        ALT: () => {
          this.CONSUME(NumericValue);
          this.CONSUME(TimeUnit);
        },
      },
      { ALT: () => this.CONSUME(DateValue) },
    ]);
  });

  private dateIsDefinedOrUndefinedExpression = this.RULE('dateIsDefinedOrUndefinedExpression', () => {
    this.CONSUME(DateField);
    this.OR([{ ALT: () => this.CONSUME(IsDefined) }, { ALT: () => this.CONSUME(IsNotDefined) }]);
  });

  /** @ignore **/
  private durationExpression = this.RULE('durationExpression', () => {
    this.CONSUME(DurationField);
    this.CONSUME(RelationalOperator);
    this.OR([
      {
        ALT: () => {
          this.CONSUME(NumericValue);
          this.CONSUME(TimeUnit);
        },
      },
    ]);
  });

  /** @ignore **/
  private tagFieldExpression = this.RULE('tagFieldExpression', () => {
    this.CONSUME(TagField);
    this.CONSUME(StringOperator);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private siteTagFieldExpression = this.RULE('siteTagFieldExpression', () => {
    this.CONSUME(SiteTagField);
    this.CONSUME(StringOperator);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private boardTagFieldExpression = this.RULE('boardTagFieldExpression', () => {
    this.CONSUME(BoardTagField);
    this.CONSUME(StringOperator);
    this.CONSUME(StringValue);
  });

  /** @ignore **/
  private numericExpression = this.RULE('numericExpression', () => {
    this.OR([{ ALT: () => this.SUBRULE(this.regularNumericExpression) }, { ALT: () => this.SUBRULE(this.equalityNumericExpression) }]);
  });

  /** @ignore **/
  private regularNumericExpression = this.RULE('regularNumericExpression', () => {
    this.CONSUME(NumericField);
    this.CONSUME(RelationalOperator);
    this.CONSUME(NumericValue);
  });

  /** @ignore **/
  private equalityNumericExpression = this.RULE('equalityNumericExpression', () => {
    this.CONSUME(EqualityNumericField);
    this.OR([{ ALT: () => this.CONSUME(Equals) }, { ALT: () => this.CONSUME(NotEquals) }]);
    this.CONSUME(NumericValue);
  });

  /** @ignore **/
  private parenthesisExpression = this.RULE('parenthesisExpression', () => {
    this.CONSUME(LParenthesis);
    this.SUBRULE(this.filterExpression);
    this.CONSUME(RParenthesis);
  });

  /** Validates the current expression and returns a CST result **/
  validateExpression(): CstNode {
    // validates the whole expression
    return this.filter();
  }

  /**
   * Returns extra text to be appended after the suggested token
   *  e.g. auto-append double quotes after a string operator (=) has been selected
   **/
  private _getAutoAppendAfterToken(ruleName: string): string {
    const wrapperChar = this.getValueWrapperChar(ruleName);
    if (!wrapperChar) {
      return '';
    }
    return wrapperChar.repeat(2);
  }

  /**
   * Returns extra text to be appended after the suggested token
   *  e.g. auto-append double quotes after a string operator (=) has been selected
   **/
  getValueWrapperChar(ruleName: string): string {
    // we want to auto insert wrapping quotes for string values (only if no value has been entered
    // yet)
    if (['definedStringExpression', 'equalityStringExpression', 'regularStringExpression', 'tagFieldExpression', 'dateFieldExpression'].includes(ruleName)) {
      return '"';
    }
    return '';
  }

  /**
   * When using custom label for field display to make the expression more readable, and replacing
   * the display label with the actual native field value, there are case where we don't want to
   * replace the value, for instance if its a string value matching a field name. Example: Display
   * expression:  Name = "Test" AND model starts with "AND" Native expression: Name = "Test" & model
   * *~ "AND"
   *
   *  In the example above, the AND between double quotes is a value and not an operator so we dont
   * wanna replace it with the native value.
   * *
   */
  getValueWrapperCharMap(): Map<TokenType, string> {
    return new Map([[StringValue, '"']]);
  }

  /** Indicates if the specified token is a field token (ie. neither an operator nor a value) * */
  isFieldToken(token: TokenType): boolean {
    return this.isTokenTypeOf(token, Field);
  }

  /** Called before the expression is tokenized / lexed **/
  onBeforeTokenizeExpression(expression: string): IExpressionInfo {
    // init the expressions (convert display to native if necessary)
    const nativeExpressionInfo = { expression: this._useDisplayLanguage ? this.displayToNativeExpression(expression) : expression };
    return nativeExpressionInfo;
  }

  /** Called after the expression is tokenized / lexed **/
  onAfterTokenizeExpression(expressionInfo: IExpressionInfo, lexResult: ILexingResult): IExpressionInfo {
    // combine tags tokens together if needed
    expressionInfo.tokens = this.enableTags ? this._combineTagTokens(lexResult.tokens) : lexResult.tokens;
    return expressionInfo;
  }

  /** @ignore combine tags tokens together **/
  private _combineTagTokens(tokens: IToken[]): IToken[] {
    if (!tokens || tokens.length === 0) {
      return tokens;
    }
    // get all operators tokens indexes
    const operatorsIndexes = tokens.reduce((p, c, i) => (tokenMatcher(c, Operator) ? p.concat(i) : p), []);

    const allowMergeToken = (token: IToken) => ![LParenthesis, RParenthesis, StringValue].some((t) => tokenMatcher(token, t));

    const mergeTokens = (tokenArray: IToken[]): void => {
      while (tokenArray.length > 1) {
        const token = tokenArray[tokenArray.length - 1];
        const previousToken = tokenArray[tokenArray.length - 2];
        const hasSpaceBetweenTokens = token.startOffset > previousToken.endOffset + 1;

        if (hasSpaceBetweenTokens || !allowMergeToken(token) || !allowMergeToken(previousToken)) {
          break;
        }

        previousToken.image = `${previousToken.image}${token.image}`;
        previousToken.endOffset = token.endOffset;
        previousToken.endColumn = token.endColumn;
        previousToken.tokenType = TagField;
        previousToken.tokenTypeIdx = TagField.tokenTypeIdx;
        tokenArray.length = tokenArray.length - 1;
      }
    };

    let res: IToken[] = [];
    for (let i = operatorsIndexes.length - 1; i >= 0; i--) {
      const min = i > 0 ? operatorsIndexes[i - 1] + 1 : 0;
      const max = operatorsIndexes[i] - 1;
      const precedingTokens = tokens.reduce((p, c, k) => (k >= min && k <= max ? p.concat(c) : p), []);
      // going backward, combine all preceding tokens that:
      // * are not separated by a space
      // * are not string values, parenthesis
      mergeTokens(precedingTokens);
      res = [...precedingTokens, tokens[operatorsIndexes[i]], ...res];
    }
    const lastTokens = tokens.filter((_, i) => operatorsIndexes.length === 0 || i > operatorsIndexes[operatorsIndexes.length - 1]);
    mergeTokens(lastTokens);
    res.push(...lastTokens);
    return res;
  }

  /**
   * @ignore
   * Update the display expression info from the native expression info.
   * create a display token for each native token and:
   *  - update the native value with the display value.
   *  - update the offsets of the text within the expression.
   */
  private _updateNativeExpressionEnumLabels(nativeExpressionInfo: IExpressionInfo) {
    let currentTokenEnumField: TokenType;

    const enumValueTokens = this._enumI18nValues.map((v) => v.nativeValueTokenType).filter((token, i, a) => a.indexOf(token) === i);

    for (let i = 0; i < nativeExpressionInfo.tokens.length; i++) {
      const nativeToken = nativeExpressionInfo.tokens[i];
      currentTokenEnumField = this.isFieldToken(nativeToken.tokenType) ? nativeToken.tokenType : currentTokenEnumField;

      // if the current field is a enum value field
      if (enumValueTokens.includes(nativeToken.tokenType)) {
        // the current token enum field indicates which field we are processing for this value
        // get the native enum value using that info
        let enumI18nValue = nativeToken.image;
        const wrapperChar = this._getValueWrapperChars().includes(enumI18nValue.charAt(0)) ? nativeToken.image.charAt(0) : '';
        enumI18nValue = enumI18nValue.substring(1);
        enumI18nValue = enumI18nValue.charAt(enumI18nValue.length - 1) === wrapperChar ? enumI18nValue.substring(0, enumI18nValue.length - 1) : enumI18nValue;
        const enumValue = this._enumI18nValues.find((v) => v.fieldToken === currentTokenEnumField && v.enumI18nValue === enumI18nValue);

        if (enumValue) {
          const isNumericNativeValue = this.isTokenTypeOf(enumValue.nativeValueTokenType, NumericValue);
          const newWrapperChar = isNumericNativeValue ? '' : wrapperChar;
          const nativeEnumValue = newWrapperChar + enumValue.enumValue + (nativeToken.image.charAt(nativeToken.image.length - 1) === wrapperChar ? newWrapperChar : '');
          const tokenOffset = nativeEnumValue.length - nativeToken.image.length;

          // replace in the expression (do it before updating the native token offset)
          nativeExpressionInfo.expression =
            nativeExpressionInfo.expression.substring(0, nativeToken.startOffset) + nativeEnumValue + nativeExpressionInfo.expression.substring(nativeToken.endOffset + 1);
          // update the native token value and offset
          nativeToken.image = nativeEnumValue;
          nativeToken.endOffset = nativeToken.endOffset + tokenOffset;
          // also update the token type (we may be display a string but the native value is an
          // intger)
          nativeToken.tokenType = enumValue.nativeValueTokenType;
          nativeToken.tokenTypeIdx = enumValue.nativeValueTokenType.tokenTypeIdx;

          // update all following tokens offsets
          for (let j = i + 1; j < nativeExpressionInfo.tokens.length; j++) {
            const nextToken = nativeExpressionInfo.tokens[j];
            nextToken.startOffset += tokenOffset;
            nextToken.startColumn += tokenOffset;
            nextToken.endOffset += tokenOffset;
            nextToken.endColumn += tokenOffset;
          }

          // also update the lex errors offset
          for (let j = 0; j < this.lexResult.errors.length; j++) {
            const lexError = this.lexResult.errors[j];
            if (lexError.offset > nativeToken.startOffset) {
              lexError.offset += tokenOffset;
            }
          }
        }
      }
    }
  }

  /** Called before the tokenized expression is validated against the grammar rules **/
  onBeforeValidateExpression(nativeExpressionInfo: IExpressionInfo): IExpressionInfo {
    // replace enum values if needed
    if (this._useDisplayLanguage) {
      this._updateNativeExpressionEnumLabels(nativeExpressionInfo);
    }
    return nativeExpressionInfo;
  }

  /** Called after the tokenized expression is validated against the grammar rules **/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onAfterValidateExpression(nativeExpressionInfo: IExpressionInfo, cst: CstNode): IExpressionInfo {
    // translate tags with the real api filter
    nativeExpressionInfo.apiExpression = nativeExpressionInfo.expression;
    return nativeExpressionInfo;
  }

  /** @ignore get enum field value suggestions **/
  private _getEnumFieldValueSuggestions({ enumData, currentFieldExpression, currentFullFieldExpression, partialValueInfo, isNextToken }: ISuggestionsInfo) {
    const enumFieldValueSuggestions: ITokenSuggestion[] = enumData.map((enumItem) => {
      const wrapperChar = this.getValueWrapperCharMap().get(enumItem.displayValueTokenType);
      return {
        value: `${wrapperChar}${enumItem.enumI18nValue}${wrapperChar}`,
        label: enumItem.enumI18nValue,
        showSuggestion: true,
        isNextToken,
        group: this._tokenI18nValuesMap.get(enumItem.fieldToken.LABEL),
      };
    });
    const enumFieldValueSuggestionsResult$ = of({
      tokenSuggestions: enumFieldValueSuggestions,
      currentFieldExpression,
      currentFullFieldExpression,
      partialValueInfo,
      autocompleteSuggestionFiltered: isNextToken,
    });
    return enumFieldValueSuggestionsResult$;
  }

  /** @ignore get field value suggestions **/
  private _getFieldValueSuggestions({ currentFieldExpression, currentFullFieldExpression, partialValueInfo, isNextToken }: ISuggestionsInfo) {
    const partialValue = partialValueInfo.partialValue;
    // its not one of the enum values
    // specify a value for the field for which we want to retrieve data (e.g. for Tags, field =
    // TagField and the fieldValue = 0_Content will indicate that we want to retrieve data for the
    // tag named 0_Content)
    const fieldValue = tokenMatcher(currentFieldExpression.field, TagField) ? currentFieldExpression.field.image : null;
    const valueWrapperChar = this.getValueWrapperChar(currentFieldExpression.ruleName);
    return this.datasourceService.datasourceFn.apply(this.datasourceService, [currentFieldExpression.field.tokenType, partialValue, fieldValue]).pipe(
      map((suggestions: ITokenSuggestion[]) =>
        suggestions.map(
          (suggestion) =>
            ({
              ...suggestion,
              value: `${valueWrapperChar}${suggestion.value}${valueWrapperChar}`,
              isNextToken,
            }) as ITokenSuggestion,
        ),
      ),
      map((suggestions: ITokenSuggestion[]) => ({
        tokenSuggestions: suggestions,
        currentFieldExpression,
        currentFullFieldExpression,
        partialValueInfo,
        autocompleteSuggestionFiltered: partialValue.length > 0,
      })),
    );
  }

  /** @ignore get tag field suggestions **/
  private _getTagSuggestions({ expressionInfo, currentFieldExpression, currentFullFieldExpression, partialValueInfo, isTokenTypeTag }: ISuggestionsInfo) {
    const partialValue = partialValueInfo.partialValue;
    // get all the next operator suggestions for tags
    const suggestions = this._getSuggestions(expressionInfo.native.tokens, true);
    // remove last tag tokens (there can be more than one for space-separated tokens, eg. `IP Address`)
    let tokensForSuggestions = _dropRight(expressionInfo.native.tokens);
    while (tokensForSuggestions.length > 0 && tokenMatcher(_last(tokensForSuggestions), TagField)) {
      tokensForSuggestions = _dropRight(tokensForSuggestions);
    }
    // tags act like a partially value entered suggestions, so include previous token suggestion
    const previousSuggestions = this._getSuggestions(tokensForSuggestions).filter((s) => s.label.toLowerCase().indexOf(partialValue.toLowerCase()) > -1 && s.label.length !== partialValue.length);

    // also include the matching tags when no field exist yet
    const includeAllTags = currentFieldExpression && currentFieldExpression.field && isTokenTypeTag(currentFieldExpression.field);
    const tagsRequest$: Observable<ITokenSuggestion[]> = includeAllTags ? this.datasourceService.datasourceFn.apply(this.datasourceService, [TagField, partialValue]) : of([]);
    const siteTagsRequest$: Observable<ITokenSuggestion[]> = includeAllTags ? this.datasourceService.datasourceFn.apply(this.datasourceService, [SiteTagField, partialValue]) : of([]);
    const boardTagsRequest$: Observable<ITokenSuggestion[]> = includeAllTags ? this.datasourceService.datasourceFn.apply(this.datasourceService, [BoardTagField, partialValue]) : of([]);
    const tagSuggestionsResult$ = forkJoin([of(previousSuggestions), tagsRequest$, siteTagsRequest$, boardTagsRequest$, of(suggestions)]).pipe(
      map(([prev, tags, siteTags, boardTags, curr]) => {
        const tagSuggestions: ITokenSuggestion[] = [...prev, ...tags, ...siteTags, ...boardTags, ...curr];
        return {
          tokenSuggestions: tagSuggestions,
          currentFieldExpression,
          currentFullFieldExpression,
          partialValueInfo: {
            ...partialValueInfo,
            fromLexError: false,
          },
          autocompleteSuggestionFiltered: true,
        };
      }),
    );
    return tagSuggestionsResult$;
  }

  /** @ignore get default suggestions **/
  private _getDefaultSuggestions = ({ expressionInfo, currentFieldExpression, currentFullFieldExpression, partialValueInfo }: ISuggestionsInfo) => {
    const partialValue = partialValueInfo.partialValue;
    const tokenSuggestions = this._getSuggestions(expressionInfo.native.tokens, partialValue.length === 0);
    const defaultSuggestions = tokenSuggestions.filter((s) => s.label.toLowerCase().indexOf(partialValue.toLowerCase()) > -1 && s.label.length !== partialValue.length);
    const defaultSuggestionsResult$ = of({ tokenSuggestions: defaultSuggestions, currentFieldExpression, currentFullFieldExpression, partialValueInfo, autocompleteSuggestionFiltered: false });
    return defaultSuggestionsResult$;
  };

  /**
   * Returns suggestions for the current expression up to the cursor position
   */
  public getFilterSuggestions(fullExpressionInfo: IParsedExpressionInfo, partialExpressionInfo: IParsedExpressionInfo): Observable<IExpressionSuggestionsResult> {
    const expressionInfo: IParsedExpressionInfo = partialExpressionInfo || fullExpressionInfo;

    // visit the current expression to retrieve all field expressions and get the current one being
    // processed in the expression
    const fieldExpressionList: Array<IFieldExpression> = this._expressionListVisitor.getFieldExpressionList(expressionInfo.cst);
    const currentFieldExpression: IFieldExpression = _last(fieldExpressionList);
    const currentFieldExpressionIdxInsideFullList: number = fieldExpressionList.length - 1;

    const fullFieldExpressionList: Array<IFieldExpression> = this._expressionListVisitor.getFieldExpressionList(fullExpressionInfo.cst);
    const currentFullFieldExpression: IFieldExpression = fullFieldExpressionList[currentFieldExpressionIdxInsideFullList];

    // check the last token to figure out if we need to add more suggestions
    const lastToken: IToken = _last(expressionInfo.native.tokens);
    const partialValueInfo: IPartialValueInfo = this._getPartialValue(expressionInfo, currentFieldExpression, currentFullFieldExpression, lastToken);
    const partialValue: string = partialValueInfo.partialValue;
    const isNextToken: boolean = partialValueInfo.fromLexError ? false : partialValue.length === 0;
    const isDateField: boolean = currentFieldExpression && currentFieldExpression.ruleName === 'dateExpression';
    const showValueSuggestions: boolean = currentFieldExpression && currentFieldExpression.field && currentFieldExpression.operator && !currentFieldExpression.values && !isDateField;
    const isTokenTypeTag: (token: IToken) => boolean = (token) => tokenMatcher(token, TagField) || tokenMatcher(token, SiteTagField) || tokenMatcher(token, BoardTagField);
    const isLastTokenExists: boolean = lastToken !== undefined;
    const showTagSuggestions: boolean = isLastTokenExists && isTokenTypeTag(lastToken);

    const currentSuggestionsInfo: ISuggestionsInfo = {
      expressionInfo,
      currentFieldExpression,
      currentFullFieldExpression,
      partialValueInfo,
      isNextToken,
      isTokenTypeTag,
    };

    if (showValueSuggestions) {
      // check if its one of our localized enum
      const enumData: INlpEnum18nInfo[] = this._enumI18nValues.filter(
        (e) => e.fieldToken === currentFieldExpression.field.tokenType && e.enumI18nValue.toLowerCase().indexOf(partialValue.toLowerCase()) > -1,
      );
      const enumDataExists: boolean = enumData.length > 0;
      if (enumDataExists) {
        currentSuggestionsInfo.enumData = enumData;
        return this._getEnumFieldValueSuggestions(currentSuggestionsInfo);
      }
      return this._getFieldValueSuggestions(currentSuggestionsInfo);
    }
    if (showTagSuggestions) {
      return this._getTagSuggestions(currentSuggestionsInfo);
    }
    return this._getDefaultSuggestions(currentSuggestionsInfo);
  }

  /** @ignore get the partial value already entered **/
  private _getPartialValue(expressionInfo: IParsedExpressionInfo, currentField: IFieldExpression, currentFullFieldExpression: IFieldExpression, lastToken: IToken): IPartialValueInfo {
    const isTagField = lastToken && (tokenMatcher(lastToken, TagField) || tokenMatcher(lastToken, SiteTagField) || tokenMatcher(lastToken, BoardTagField));
    const hasExpressionLexErrors = expressionInfo.errors.lex?.length > 0;
    const isValueNumeric =
      currentField && ['regularStringExpression', 'equalityStringExpression', 'definedStringExpression', 'tagFieldExpression'].includes(currentField.ruleName) && tokenMatcher(lastToken, NumericValue);

    if (isTagField) {
      // combine consecutive tags tokens to allow for more complex partial values separated with spaces (e.g. `IP Address`)
      let partialValue = lastToken.image;
      for (let i = expressionInfo.display.tokens.length - 2; i >= 0; i--) {
        const previousToken = expressionInfo.display.tokens[i];
        const currentToken = expressionInfo.display.tokens[i + 1];

        if (!tokenMatcher(previousToken, TagField)) {
          break;
        }
        const separatorSpaceCount = currentToken.startOffset - previousToken.endOffset - 1;
        partialValue = `${previousToken.image}${' '.repeat(separatorSpaceCount)}${partialValue}`;
      }

      return {
        partialValue,
        fromLexError: false,
      };
    }
    if (isValueNumeric) {
      return {
        partialValue: lastToken.image,
        fromLexError: false,
      };
    }
    if (hasExpressionLexErrors) {
      const lexErrorIndex = expressionInfo.errors.lex[0].offset;
      // check the full expression to see if the value starts at this index
      const fullExpressionToken = currentFullFieldExpression && currentFullFieldExpression.values ? currentFullFieldExpression.values[0] : null;
      if (fullExpressionToken?.startOffset === lexErrorIndex) {
        return {
          partialValue: this._trimValue(expressionInfo.native.expression.substring(lexErrorIndex), currentField),
          fromLexError: true,
        };
      } else {
        // combine siblings lex errors to retrieve the full unexpected value
        let siblingLexErrors: ILexingError[] = [];
        for (let i = expressionInfo.errors.lex.length - 1; i >= 0; i--) {
          let addError = false;
          if (i === expressionInfo.errors.lex.length - 1) {
            addError = true;
          } else {
            const lexError = expressionInfo.errors.lex[i];
            const nextLexError = expressionInfo.errors.lex[i + 1];
            // if there are only spaces between the errors, combine them
            const start = nextLexError.offset;
            const end = lexError.offset + lexError.length;
            addError = expressionInfo.native.expression.substring(start, end).trim().length === 0;
          }
          if (addError) {
            siblingLexErrors = [expressionInfo.errors.lex[i], ...siblingLexErrors];
          }
        }

        let partialValue = siblingLexErrors.map((lexError) => expressionInfo.native.expression.substring(lexError.offset, lexError.offset + lexError.length)).join(' ');
        if (currentField) {
          partialValue = this._trimValue(partialValue, currentField);
        }
        return {
          partialValue,
          fromLexError: true,
        };
      }
    }

    return {
      partialValue: '',
      fromLexError: false,
    };
  }

  /** @ignore remove wrapper chars from the specified value **/
  private _trimValue(value: string, currentField: IFieldExpression): string {
    // remove wrapper chars
    const wrapperChar = currentField ? this.getValueWrapperChar(currentField.ruleName) : '';
    return trimWrapperChar(value, wrapperChar);
  }

  /** @ignore gets suggestions for the specified tokens **/
  private _getSuggestions(tokens: IToken[], isNextToken = false): ITokenSuggestion[] {
    // get the field suggestions
    const syntacticSuggestions = this.computeContentAssist('filter', tokens);

    // get the field suggestions
    const suggestions: ITokenSuggestion[] = [];

    for (let i = 0; i < syntacticSuggestions.length; i++) {
      const currSyntaxSuggestion = syntacticSuggestions[i];
      const currTokenType = currSyntaxSuggestion.nextTokenType;
      const currRuleStack = currSyntaxSuggestion.ruleStack;
      const lastRuleName = _last(currRuleStack);

      // auto append empty string depending on the token
      const autoAppendAfter = this.isTokenTypeOf(currTokenType, Operator) && !this.isTokenTypeOf(currTokenType, SelfEndOperator) ? this._getAutoAppendAfterToken(lastRuleName) : '';
      // it the next possible field is a category, includes all sub elements of that category
      if (currTokenType.categoryMatches.length > 0) {
        this.tokens
          .filter((t) => currTokenType.categoryMatches.includes(t.tokenTypeIdx))
          .forEach((t) => {
            this.addToSuggestions(suggestions, this.createTokenSuggestion(t, autoAppendAfter, isNextToken, this._tokenGroups.get(t)));
          });
      } else {
        this.addToSuggestions(suggestions, this.createTokenSuggestion(currTokenType, autoAppendAfter, isNextToken, this._tokenGroups.get(currTokenType)));
      }
    }

    return suggestions;
  }

  /** Converts a native expression into a display expression **/
  nativeToDisplayExpression(expression: string): string {
    if (!this._useDisplayLanguage || (expression || '').length === 0) {
      return expression;
    }

    // first we need to format the tags correctly to match our grammar
    if (this.enableTags) {
      expression = this._removeTagIdentifier(expression);
    }

    // tokenize the nativeExpression
    const lexResult = this.lexer.tokenize(expression);
    const tokens = this.enableTags ? this._combineTagTokens(lexResult.tokens) : lexResult.tokens;
    // get unique value token types for enum values
    const enumValueTokens = this._enumI18nValues.map((v) => v.nativeValueTokenType).filter((token, i, a) => a.indexOf(token) === i);

    let currentTokenEnumField: TokenType;
    let displayExpression = '';
    for (let i = 0; i < tokens.length; i++) {
      displayExpression += displayExpression.length > 0 ? ' ' : '';

      const nativeToken = tokens[i];
      currentTokenEnumField = this.isFieldToken(nativeToken.tokenType) ? nativeToken.tokenType : currentTokenEnumField;

      // if the current field is a enum value field
      if (enumValueTokens.includes(nativeToken.tokenType)) {
        // the current token enum field indicates which field we are processing for this value
        // get the native enum value using that info
        let enumNativeValue = nativeToken.image;
        // remove any wrapper char
        if (this.getValueWrapperCharMap().has(nativeToken.tokenType)) {
          const wrapperChar = this.getValueWrapperCharMap().get(nativeToken.tokenType);
          enumNativeValue = enumNativeValue.charAt(0) === wrapperChar ? enumNativeValue.substring(1) : enumNativeValue;
          enumNativeValue = enumNativeValue.charAt(enumNativeValue.length - 1) === wrapperChar ? enumNativeValue.substring(0, enumNativeValue.length - 1) : enumNativeValue;
        }
        // check if the native value can be found in the enum value list
        const enumValue = this._enumI18nValues.find((v) => v.fieldToken === currentTokenEnumField && `${v.enumValue}` === `${enumNativeValue}`);
        if (enumValue) {
          const wrapperChar = this.getValueWrapperCharMap().get(enumValue.displayValueTokenType);
          const displayEnumValue = wrapperChar + enumValue.enumI18nValue + wrapperChar;
          displayExpression += displayEnumValue;
        } else {
          displayExpression += nativeToken.image;
        }
      } else {
        displayExpression += this._tokenI18nValuesMap.get(nativeToken.tokenType.LABEL) || nativeToken.tokenType.LABEL || nativeToken.image;
      }
    }
    return displayExpression;
  }

  /** @ignore replaces tag identifiers in the native expression **/
  private _removeTagIdentifier(expression: string): string {
    const tagIdentifier = 'custom.field';

    // we need to make sure to ignore values with the tag identifier as much as possible
    let tagIdentifierIndex;
    let fromIndex = 0;
    while ((tagIdentifierIndex = expression.indexOf(tagIdentifier, fromIndex)) > -1) {
      // make sure the next non blank char is a semi colon
      const indexOfSemiColon = expression.indexOf(':', tagIdentifierIndex + tagIdentifier.length);
      if (indexOfSemiColon === -1 || expression.substring(tagIdentifierIndex + tagIdentifier.length, indexOfSemiColon).trim().length > 0) {
        fromIndex = tagIdentifierIndex + tagIdentifier.length;
        continue;
      }
      // make sure the next non blank char after semi colon is a double quote
      const indexOfOpeningDoubleQuote = expression.indexOf('"', indexOfSemiColon + 1);
      if (indexOfOpeningDoubleQuote === -1 || expression.substring(indexOfSemiColon + 1, indexOfOpeningDoubleQuote).trim().length > 0) {
        fromIndex = tagIdentifierIndex + tagIdentifier.length;
        continue;
      }

      // extract the tag value, ie. the next value between double quotes
      const tagNameStartIndex = indexOfOpeningDoubleQuote + 1;
      const tagNameEndIndex = expression.indexOf('"', tagNameStartIndex);
      if (tagNameEndIndex === -1) {
        fromIndex = tagIdentifierIndex + tagIdentifier.length;
        continue;
      }

      // extract the tag name
      const tagName = expression.substring(tagNameStartIndex, tagNameEndIndex);

      // update the expression
      expression = expression.substring(0, tagIdentifierIndex) + tagName + expression.substring(tagNameEndIndex + 1);
      const totalCharRemoved = tagNameStartIndex - tagIdentifierIndex - 1; // 1 for ending quote
      fromIndex = tagNameEndIndex + 1 - totalCharRemoved;
    }
    return expression;
  }

  /**
   * Returns the indexes of the tokens to replace with a new value selected from a list of
   * suggestions or custom overlay *
   */
  getIndexesOfTokensToReplace(fullExpressionInfo: IParsedExpressionInfo, partialExpressionInfo: IParsedExpressionInfo, suggestionsResult: IExpressionSuggestionsResult): number[] {
    const expressionInfo = partialExpressionInfo ? partialExpressionInfo : fullExpressionInfo;
    const lastToken = _last(expressionInfo.native.tokens);
    const currentFieldExpression = suggestionsResult.currentFieldExpression;
    const currentFullFieldExpression = suggestionsResult.currentFullFieldExpression;

    // if the current expression exist, check the full expression to determine whats needed to be replaced
    if (!!currentFieldExpression && !currentFieldExpression.completed) {
      const isNextTokenReplaceable = (t: IToken) => {
        // if the next token is NOT a logical separator or a right parenthesis, replace it (e.g. `IP Address = 10.` => replace `10.`)
        const nextTokenIndex = expressionInfo.native.tokens.indexOf(t) + 1;
        const nextToken = expressionInfo.native.tokens[nextTokenIndex];
        if (nextToken) {
          if (!tokenMatcher(nextToken, LogicalOperator) && !tokenMatcher(nextToken, RParenthesis)) {
            return true;
          }
        }
        return false;
      };

      const isRegularValueReplacement = !!currentFieldExpression.operator && !!currentFullFieldExpression.values;
      if (isRegularValueReplacement) {
        return currentFullFieldExpression.values.map((_, i) => fullExpressionInfo.native.tokens.indexOf(currentFullFieldExpression.values[i]));
      }
      const isInvalidValueReplacement = !!currentFieldExpression.operator && isNextTokenReplaceable(currentFullFieldExpression.operator);
      if (isInvalidValueReplacement) {
        return [fullExpressionInfo.native.tokens.indexOf(currentFullFieldExpression.operator) + 1];
      }

      const matchingToken = (t: IToken, t2: IToken) => tokenMatcher(t, t2.tokenType) && t.image === t2.image;
      const isOperatorReplacement = !!currentFieldExpression.field && !!currentFullFieldExpression.operator && matchingToken(currentFieldExpression.field, currentFullFieldExpression.field);
      if (isOperatorReplacement) {
        return [fullExpressionInfo.native.tokens.indexOf(currentFullFieldExpression.operator)];
      }

      const isFieldReplacement = !!currentFullFieldExpression.operator;
      if (isFieldReplacement) {
        return [fullExpressionInfo.native.tokens.indexOf(currentFullFieldExpression.field)];
      }
    }

    if (expressionInfo.native.tokens.length > 0) {
      const lastTokenIndex = expressionInfo.native.tokens.indexOf(lastToken);
      // if the partial value was retrieved from a lex error, we dont want to replace the last
      // token but the following one
      const offset = suggestionsResult.partialValueInfo.fromLexError ? 1 : 0;
      return [lastTokenIndex + offset];
    }
    return [];
  }

  /**
   * Converts a display expression into a native expression
   * Overrides the parser one cuz we need to be more specific because of tags
   **/
  displayToNativeExpression(expression: string): string {
    if ((expression || '').length === 0) {
      return expression;
    }
    const sortFn = (a: [string, string], b: [string, string]) =>
      // sort by field size, if equal by alphabet
      a[1].length !== b[1].length ? b[1].length - a[1].length : -a[1].localeCompare(b[1]);
    // sort the display values by length (longer to smaller). We need to start replacing the longest
    // values first.
    const sortedEntries = Array.from(this._tokenI18nValuesMap.entries()).sort(sortFn);

    // create a regex with all the keywords
    let regex = '';
    for (const entry of sortedEntries) {
      regex += regex.length > 0 ? '|' : '';
      regex += this._escapeRegExp(entry[1]);
    }

    // grammar keywords concatenated with another grammar keyword are considered as a tag name (e.g `daymonth`)
    // except for some keywords (eg. `(day` or `day>`)
    const excludeConcatTextTags = [RParenthesis, LParenthesis, GreaterThan, GreaterThanEquals, LessThanEquals, LessThan, Equals, NotEquals].map(
      (t) => this.getTokenI18nValuesMap().get(t.LABEL) || t.LABEL,
    );

    const regexText = new RegExp(regex, 'g');
    const wrapperCharList = this._getValueWrapperChars() || [];

    // replace all keywords with display values
    const res = expression.replace(regexText, (match, index) => {
      const precedingChar = getPreviousCharNotEmpty(expression, index - 1);
      const followingChar = getNextCharNotEmpty(expression, index + match.length);
      // dont replace keywords in values
      if (wrapperCharList.includes(precedingChar) && wrapperCharList.includes(followingChar) && precedingChar === followingChar) {
        return match;
      }
      // for tags we want to avoid converting tokens within a tag name (eg. `outdoor-day-breakfast` where day is a reserved keyword)
      if (this.enableTags) {
        // get the text until the first blank space
        let text = this._getPrecedingTextBeforeSpace(expression, index - 1);
        if (!!text && !this._hasSiblingText(text, excludeConcatTextTags, false)) {
          return match;
        }
        // get the text until the first blank space
        text = this._getFollowingTextBeforeSpace(expression, index + match.length);
        if (!!text && !this._hasSiblingText(text, excludeConcatTextTags, true)) {
          return match;
        }
      }

      for (const entry of sortedEntries) {
        if (entry[1] === match) {
          return this._labelToPatternMap.get(entry[0]) || match;
        }
      }
      return match;
    });

    return res;
  }

  /** @ignore returns the sub-text from the specified index until a whitespace is matched (or end of string), going backwards */
  private _getPrecedingTextBeforeSpace(value: string, fromIndex: number) {
    const res: string[] = [];
    for (let i = fromIndex; i >= 0; i--) {
      if (value.charAt(i) === ' ') {
        break;
      }
      res.push(value.charAt(i));
    }
    return res.reverse().join('');
  }

  /** @ignore returns the sub-text from the specified index until a whitespace is matched (or end of string), going forward */
  private _getFollowingTextBeforeSpace(value: string, fromIndex: number) {
    const res: string[] = [];
    for (let i = fromIndex; i < value.length; i++) {
      if (value.charAt(i) === ' ') {
        break;
      }
      res.push(value.charAt(i));
    }
    return res.join('');
  }

  /**
   *@ignore Indicates if the specified text starts with one of the specified keywords
   * @param forward  Indicates if the search should start from the start or the end of the text
   **/
  private _hasSiblingText(text: string, keywords: string[], forward = true) {
    if (forward) {
      for (const keyword of keywords) {
        if (text.substring(0, keyword.length) === keyword) {
          return true;
        }
      }
    } else {
      for (const keyword of keywords) {
        if (text.substring(text.length - 1 - keyword.length, text.length - 1) === keyword) {
          return true;
        }
      }
    }
    return false;
  }

  /** Sets the group for a token **/
  addTokenGroup(tokenType: TokenType, group: string) {
    this._tokenGroups.set(tokenType, group);
  }

  /** Converts the native expression in the api readable expression **/
  public getApiExpression(expressionInfo: IParsedExpressionInfo): string {
    return this._expressionApiVisitor.getApiExpression(expressionInfo.cst);
  }
}
