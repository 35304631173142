import { IFieldExpression, INlpEnum18nInfo, IParsedExpressionInfo, IPartialValueInfo } from '@activia/ngx-components/nlp';
import { TranslocoService } from '@ngneat/transloco';
import { IRecognitionException, IToken, NoViableAltException, TokenType } from 'chevrotain';

import { DeviceFilterNlpParser } from './device-filter-nlp-parser';
import { Day, DEVICE_TOKEN_FEATURE_PERMISSION, DEVICE_TOKEN_GROUPS, Hour, Minute, Month, Second, Week, Year } from './device-filter.tokens';
import { DeviceType } from '../../model/device-type.enum';
import { FeatureToggleService } from '@amp/feature-toggle';

export interface ISuggestionsInfo {
  expressionInfo: IParsedExpressionInfo;
  currentFieldExpression: IFieldExpression;
  currentFullFieldExpression: IFieldExpression;
  partialValueInfo: IPartialValueInfo;
  enumData?: INlpEnum18nInfo[];
  isTokenTypeTag?: (token: IToken) => boolean;
  isNextToken?: boolean;
}

export const NLP_INTERNAL_DATE_FORMAT = 'yyyy-MM-dd';
export const NLP_INTERNAL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const NLP_API_DATE_FORMAT = `ddMMyyyy't'HH:mm:ss+0000`;

export const LUXON_DURATION_MAP = {
  [Second.PATTERN.toString()]: 'seconds',
  [Minute.PATTERN.toString()]: 'minutes',
  [Hour.PATTERN.toString()]: 'hours',
  [Day.PATTERN.toString()]: 'days',
  [Week.PATTERN.toString()]: 'weeks',
  [Month.PATTERN.toString()]: 'months',
  [Year.PATTERN.toString()]: 'years',
};

/**
 * Filter out the tokens that are hidden by the feature flag
 */
export const getVisibleTokens = (tokens: Array<TokenType>, featureToggleService: FeatureToggleService) =>
  tokens.filter((token) => {
    const permission = DEVICE_TOKEN_FEATURE_PERMISSION.get(token.name);
    return !permission || featureToggleService.isOn(permission);
  });

/**
 * Adds groups for the device nlp tokens.
 * Groups allow to categorize the tokens when the suggestion list is displayed
 */
export const addDeviceNlpTokenGroups = (parser: DeviceFilterNlpParser, translate: TranslocoService, featureToggleService: FeatureToggleService): void => {
  const groups = Array.from(DEVICE_TOKEN_GROUPS.keys());
  translate.selectTranslate(groups).subscribe((labels) => {
    groups.forEach((group, i) => {
      const visibleTokens = getVisibleTokens(DEVICE_TOKEN_GROUPS.get(group), featureToggleService);
      if (visibleTokens.length > 0) {
        visibleTokens.forEach((tokenType) => parser.addTokenGroup(tokenType, labels[i]));
      }
    });
  });
};

/**
 * Creates a function that allows to display a custom tag error message in devices nlp component
 * For example, if the user tries to use a tag name that is also a reserved keyword then the error message
 * will suggest the user to prefix the keyword with the optional tag prefix.
 */
export const createDeviceNlpCustomTagError =
  (parser: DeviceFilterNlpParser, translate: TranslocoService): ((exceptions: IRecognitionException[], currentFieldExpression: IFieldExpression) => string) =>
  (exceptions: IRecognitionException[], currentFieldExpression: IFieldExpression) => {
    // check for reserved keywords only if a field name is being entered (in that case there will be no current field)
    const check = !currentFieldExpression || !currentFieldExpression.field || currentFieldExpression.completed;
    if (check) {
      const tokens = exceptions
        .filter((e) => e instanceof NoViableAltException)
        .map((e) => e.token.image)
        .filter((tokenValue) => !!tokenValue && parser.isGrammarKeyword(tokenValue))
        .map((tokenValue) => `'${tokenValue}'`);
      if (tokens.length > 0) {
        return translate.translate(`NLP.ERROR.TAG_PREFIX_MESSAGE.${tokens.length > 1 ? 'PLURAL' : 'SINGULAR'}`, { tokens: tokens.join('') });
      }
    }

    return null;
  };

/** adds the device types to the specified nlp device filter **/
export const addDeviceTypesToNlpFilter = (filter: string, deviceTypes: DeviceType[]): string => {
  if ((deviceTypes || []).length === 0) {
    return filter;
  }
  const deviceTypeFilter = deviceTypes.map((type) => ` type.type = "${type}"`).join(' | ');
  if ((filter || '').trim().length === 0) {
    return deviceTypeFilter;
  }
  return `(${filter}) & (${deviceTypeFilter})`;
};
