<ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS'">
  <ng-container *ngIf="!!address">
    <div class="address" [attr.data-e2e]="'address'" [innerHTML]="formattedAddress">
    </div>
    <div *ngIf="showEditIcon" class="actions">
      <avn-icon
        [attr.data-e2e]="'create-icon'"
        [icon]="'content:create'"
        [fontSize]="16"
        [avnTooltip]="t('EDIT_MANUALLY_MESSAGE_80')"
        [tooltipCloseOnMouseLeave]="true"
        [tooltipPlacement]="PlacementTypes.bottom"
        (click)="displayModeChanged.emit('edit')">
      </avn-icon>
    </div>
  </ng-container>
</ng-container>
