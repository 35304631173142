import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Address } from '@activia/cm-api';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, share, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, map, Observable, of, ReplaySubject, Subject, switchMap } from 'rxjs';
import { FormStatus } from '../../../models/form-status.constant';
import { isSitePropertyUnique } from '../../../utils/site-uniqueness-validator.utils';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { dataOnceReady, IOptionData } from '@activia/ngx-components';
import { siteManagementEntities } from '../../../store/site-management.selectors';
import { CountryService } from '@activia/geo';
import { TranslocoService } from '@ngneat/transloco';
import { SiteMonitoringFacade, SiteProperties } from '@amp/site-monitoring-shared';

@Component({
  selector: 'amp-address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() siteId: number;
  @Input() address: Address;
  @Input() isGeoAddressValid: boolean;
  @Input() validateFormOnInitialLoad: boolean;

  /** (Optional) Connect the basic info editor form to this parent form */
  @Input() parentForm?: UntypedFormGroup;

  /** Emit whenever the form values have changed and form is valid */
  @Output() addressChanged = new EventEmitter<Address>();

  /** Emit whenever the form values changed. Initial values and invalid values are also emitted. */
  @Output() valuesChanged = new EventEmitter<Address>();

  form: UntypedFormGroup;

  addressLine1ControlStatusSub: Subject<FormStatus> = new Subject<FormStatus>();
  addressLine1ControlStatus$ = this.addressLine1ControlStatusSub.asObservable();

  countries$: Observable<IOptionData<void>[]> = this._translocoService.langChanges$.pipe(
    switchMap((lang) => this._translocoService.selectTranslation(`countries/${lang}`)),
    map((names) =>
      Object.keys(names).reduce(
        (acc, countryCode) => [
          ...acc,
          {
            value: countryCode,
            label: names[countryCode],
          },
        ],
        []
      )
    )
  );

  SiteProperties = SiteProperties;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _store: Store<ISiteManagementState>,
    private _countryService: CountryService,
    private _translocoService: TranslocoService,
    public siteMonitoringFacade: SiteMonitoringFacade
  ) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      addressLine1: [this.address?.addressLine1, { validators: [Validators.required] }],
      addressLine2: new UntypedFormControl(this.address?.addressLine2),
      city: new UntypedFormControl(this.address?.city),
      state: new UntypedFormControl(this.address?.state),
      postalCode: new UntypedFormControl(this.address?.zip),
      country: new UntypedFormControl(this._countryService.getCountryByCountryNameOrCode(this.address?.country)?.shortCountryCode, [Validators.required]),
    });

    // If parent form exist then connect basic info form to it
    if (this.parentForm) {
      this.parentForm.addControl('address', this.form);
      this.form.setParent(this.parentForm);
    }

    const formValuesChanged = this.form.valueChanges.pipe(
      debounceTime(300),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    formValuesChanged
      .pipe(
        tap((values) => {
          this.valuesChanged.emit(values);
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    formValuesChanged
      .pipe(
        filter(() => this.form.valid),
        distinctUntilChanged(
          (prev, curr) =>
            prev.addressLine1 === curr.addressLine1 &&
            prev.addressLine2 === curr.addressLine2 &&
            prev.city === curr.city &&
            prev.state === curr.state &&
            prev.postalCode === curr.postalCode &&
            prev.country === curr.country
        ),
        tap(() => {
          this.addressChanged.emit(this._getAddress());
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    combineLatest([this.form.controls['addressLine1'].statusChanges, this.form.controls['addressLine1'].valueChanges])
      .pipe(
        distinctUntilChanged(([prevStatus, prevValue], [currStatus, currValue]) => prevStatus === currStatus && prevValue === currValue),
        switchMap(([status, value]) => {
          if (status === 'VALID') {
            this.addressLine1ControlStatusSub.next('PENDING');
            const allSites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1));
            return isSitePropertyUnique(allSites$, this.siteId, value, 'address', 'addressLine1').pipe(map((duplicateFound) => (duplicateFound ? 'WARNING' : 'VALID')));
          } else {
            return of(status as FormStatus);
          }
        }),
        tap((status) => {
          this.addressLine1ControlStatusSub.next(status);
        })
      )
      .subscribe();

    if (this.validateFormOnInitialLoad) {
      this.form.markAllAsTouched();
    }
  }

  ngOnChanges({ address }: SimpleChanges) {
    if (address?.currentValue && !address.firstChange) {
      this.form.patchValue({
        addressLine1: this.address?.addressLine1,
        addressLine2: this.address?.addressLine2,
        city: this.address?.city,
        state: this.address?.state,
        postalCode: this.address?.zip,
        country: this._countryService.getCountryByCountryNameOrCode(this.address?.country)?.shortCountryCode,
      });
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    // This component does not always exist in the parent component
    // Hence needs to remove the address form from the parent form on destroy
    if (this.parentForm) {
      this.parentForm.removeControl('address');
    }
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private _getAddress(): Address {
    return {
      addressLine1: this.form.controls.addressLine1.value,
      addressLine2: this.form.controls.addressLine2.value,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      zip: this.form.controls.postalCode.value,
      country: this.form.controls.country.value,
    };
  }
}
