import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { HealthStatusAPINames, HealthStatusCode, HealthStatusThemes } from '@amp/devices';
import { IPieDataItem, themeTypeToCssVar } from '@activia/dataviz';
import { TranslocoService } from '@ngneat/transloco';

export interface ISiteHealthPieDatum extends IPieDataItem {
  id: number;
}

/**
 * Convert the site(s) health status to pie data items.
 * Used by DMB only
 */
export const convertSiteHealthToPieData = (
  statusMap: CountAggregationData<HealthStatusCode, number>,
  translocoService: TranslocoService,
): ISiteHealthPieDatum[] => {
  const pieDataItems = Object.keys(statusMap).reduce((res, status) => {
    const datum = {
      id: +status,
      name: translocoService.translate(`siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.${HealthStatusAPINames[+status]}.TITLE_20`),
      value: statusMap[status],
      color: +status !== HealthStatusCode.OK ? themeTypeToCssVar(HealthStatusThemes[+status]) : null,
    };
    return [ ...res, datum ];
  }, []);

  // Sort in the order of Error - Warning - OK
  return pieDataItems.sort((a, b) => b.id - a.id);
};
