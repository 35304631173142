import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  CreateQueryResultDTO,
  DeviceDTO,
  DeviceQueryResultService,
  DeviceService, SiteDTO,
  SitesService,
} from '@activia/cm-api';
import { SiteWithTagsDTO } from '../utils/device-list.utils';
import { DeviceColumnType } from '../model/device-column-type';
import { DeviceFieldTemplateService } from '../services/device-field-template.service';
import { DeviceFilterApiExpressionService } from '../nlp/device-filter/device-filter-api-expression.service';
import { IMonitoringListDatum, IMonitoringListDTO, IMonitoringSharedListDTO } from '../model/monitoring-list.interface';
import { SiteManager } from '../nlp/device-filter/device-filter.tokens';
import {
  DEFAULT_PAPAPARSE_CONFIG,
  exportAsCsv,
  extractIdFromHttpLocationHeaders,
  extractTotalRecordsFromHttpHeaders,
  Papa
} from '@amp/utils/common';
import { catchError, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { AMP_DMB_INFO_TOKEN, AmpDmbInfoProvider } from '@amp/analytics';
import { HttpResponse } from '@angular/common/http';
import {
  addLogicalPlayerMonitoredValues, aggregateRelatedMonitoringValue,
  getMonitoringValue,
  parsePlayerVersion
} from '../utils/monitored-values-util';
import { MonitoredValue } from '../model/monitored-value.enum';
import { FeatureToggleService } from '@amp/feature-toggle';

/** This service handles fetching devices of a device monitoring list */
@Injectable({ providedIn: 'root' })
export class DeviceListService implements OnDestroy {
  serviceDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(AMP_DMB_INFO_TOKEN) private _ampDmbInfoProvider: AmpDmbInfoProvider,
    private _papaParser: Papa,
    private _deviceService: DeviceService,
    private _deviceQueryResultService: DeviceQueryResultService,
    private _deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
    private _deviceFieldTemplateService: DeviceFieldTemplateService,
    private _sitesService: SitesService,
    private _featureToggleService: FeatureToggleService,
  ) {}

  ngOnDestroy() {
    this.serviceDestroyed$.next();
    this.serviceDestroyed$.complete();
  }

  /**
   * Used by device monitoring list table. Get paginated devices of a device monitoring list.
   * ATTENTION: Not to be used for export to CSV feature.
   */
  getPaginatedDeviceList(
    list: IMonitoringListDTO,
    tagsColumns: string[],
    amount: number,
    offset: number,
    sort: string,
    sortAsc: boolean,
  ): Observable<{ total: number; devices: DeviceDTO[]; queryResultId?: number; sites?: SiteWithTagsDTO[] }> {
    const hasSitePropertyColumn = list.columns.some((column) => column.type === DeviceColumnType.SiteProperty);
    const hasSiteTagColumn = list.columns.some((column) => column.type === DeviceColumnType.SiteTag);

    const monitoredValues = this._getMonitoringListMonitoredValues(list);

    return this._deviceFilterApiExpressionService.getAvailableDeviceFilter(list.filter, list.deviceTypes, true).pipe(
      switchMap((_filter) => this._deviceService.getDevices(amount, offset, sort, sortAsc, _filter, list.deviceGroupId, monitoredValues, tagsColumns, null, null, 'response')),
      // Fetch site information related with the devices
      switchMap((response) => {
        const deviceListDTO: DeviceDTO[] = response.body;

        return this._fetchSitesByDevices(hasSitePropertyColumn, hasSiteTagColumn, deviceListDTO).pipe(
          map((mergedSites) => {
            return {
              response,
              sites: mergedSites,
            };
          })
        );
      }),
      map(({ response, sites }) => {
        const dataSource = {
          total: extractTotalRecordsFromHttpHeaders<DeviceDTO>(response),
          devices: response.body || [],
          sites,
        };
        return dataSource;
      })
    );
  }

  /**
   * Used by Export to CSV feature. Getting a cached list of devices of a device monitoring list.
   * A cached device monitoring list is created by first creating a query result request so that the backend creates cache according
   * to the filter defined in a device monitoring list. Then the completed list of devices is fetched from this cache.
   */
  getCachedDeviceList(list: IMonitoringListDTO, managerId?: number, withMonitoredValues?: boolean, withTags?: boolean): Observable<IMonitoringListDatum[]> {
    const paginationLimit = 1000;
    const cacheTimeInSec = 600;

    const monitoredValues = withMonitoredValues ? this._getMonitoringListMonitoredValues(list) : null;
    const tagsColumns = withTags ? list.columns.filter((col) => col.type === DeviceColumnType.Tag).map((item) => item.value) : null;
    const hasSitePropertyColumn = list.columns.some((column) => column.type === DeviceColumnType.SiteProperty);
    const hasSiteTagColumn = list.columns.some((column) => column.type === DeviceColumnType.SiteTag);
    const optionalFilter = managerId ? (list.filter?.length > 0 ? `& ${SiteManager.PATTERN}=${managerId}` : `${SiteManager.PATTERN}=${managerId}`) : null;

    return this._deviceFilterApiExpressionService.getAvailableDeviceFilter(list.filter, list.deviceTypes, true, optionalFilter).pipe(
      switchMap((_filter) => {
        const queryResult: CreateQueryResultDTO = { filter: _filter, deviceGroupId: list.deviceGroupId };

        // First create a cached list of this monitoring list in the backend by creating a query result
        return this._deviceService.createQueryResultObject(cacheTimeInSec, queryResult, 'response').pipe(
          map((response: HttpResponse<any>) => extractIdFromHttpLocationHeaders(response.headers)),
          switchMap((queryResultId: number) => {
            // With the current limitation, to find out the total count of devices in this cached list, the only way is to query the 1st page
            // and get the total count in the http response header
            return queryResultId ?
              this._getPaginatedCachedDeviceList(queryResultId, paginationLimit, 0, monitoredValues, tagsColumns, hasSitePropertyColumn, hasSiteTagColumn, managerId).pipe(
                switchMap(({ devices, sites, totalCount }) => {
                  if (totalCount <= paginationLimit) {
                    // If the list contains less or equal to 1000 devices, fetch is done
                    return of({ devices, sites });
                  } else {
                    // Else calculate how many more calls are still needed
                    // E.g. if total is 3245, each call is limited to 1000 devices only, total 4 calls required
                    // First call is already done, so 3 more calls needed
                    // Subsequent calls should also be done through query result to get the cached data
                    const numOfSubsequentCalls = Math.ceil(totalCount / paginationLimit) - 1;

                    return from([...Array(numOfSubsequentCalls).keys()].map((key) => key + 1)).pipe(
                      mergeMap((idx) => {
                        return this._getPaginatedCachedDeviceList(
                          queryResultId,
                          idx === numOfSubsequentCalls ? totalCount % paginationLimit : paginationLimit,
                          paginationLimit * idx,
                          monitoredValues,
                          tagsColumns,
                          hasSitePropertyColumn,
                          hasSiteTagColumn,
                          managerId
                        )
                      }, 5),
                      toArray(),
                      map((remainingDeviceSubsets) => {
                        const allDevices = devices;
                        const allSites = sites;
                        remainingDeviceSubsets.forEach((subsetData) => {
                          allDevices.push(...subsetData.devices);
                          allSites.push(...subsetData.sites);
                        });
                        return { devices: allDevices, sites: allSites };
                      })
                    );
                  }
                }),
                map(({ devices, sites }) => {
                  const rows = this.toMonitoringListData(devices, tagsColumns, sites);
                  rows.forEach(this.addExtraDeviceData);
                  return rows;
                }),
              ) :
              throwError([])
          }),
          catchError((_) => throwError([]))
        );
      })
    );
  }

  /** Used by Export to CSV feature. Get exportable devices data in CSV string format */
  getExportableDevicesCsvData(list: IMonitoringSharedListDTO, withManagerId: boolean, devices?: IMonitoringListDatum[]): Observable<string> {
    const managerId$ = withManagerId ? this._ampDmbInfoProvider.managerId$ : of(null);
    return managerId$.pipe(
      take(1),
      switchMap((managerId) => (devices ? of(devices) : this.getCachedDeviceList(list, managerId, true, true))),
      map((ds) => {
        // Convert device data to the formats defined in the column definitions of the list
        const { columns, data } = this._deviceFieldTemplateService.toExportableDevices(list, ds);
        // export data to csv format and download it
        const csvFormattedData = exportAsCsv(this._papaParser, columns, data, DEFAULT_PAPAPARSE_CONFIG);
        return csvFormattedData;
      }),
      catchError((_) => throwError(null))
    );
  }

  /**
   * Combine devices with their device tags values and the sites they associate to together
   *
   * @param devices Array of DeviceDTO
   * @param tagColumns Columns of device tags in the device monitoring list
   * @param sites Array of SiteWithTagsDTO associated with devices
   */
  toMonitoringListData(devices: DeviceDTO[], tagColumns: string[], sites?: SiteWithTagsDTO[]): IMonitoringListDatum[] {
    const sitesMap = this._convertToSitesMap(sites);

    const dataRows: IMonitoringListDatum[] = (devices || []).map((device) => {
      // get the monitoring data
      let monitoringData = {};
      if (device.deviceInfo.monitoringData?.sample?.monitoredValues) {
        for (const monitoredValue of device.deviceInfo.monitoringData.sample.monitoredValues) {
          if (monitoredValue.name === MonitoredValue.PlayerVersion) {
            monitoringData[monitoredValue.name] = parsePlayerVersion(getMonitoringValue(monitoredValue));
          } else {
            monitoringData[monitoredValue.name] = getMonitoringValue(monitoredValue);
          }
        }
        monitoringData = aggregateRelatedMonitoringValue(monitoringData);
      }

      const tagsData = {};
      tagColumns?.forEach((col) => {
        const tag = device.deviceInfo.keyValuePairs && device.deviceInfo.keyValuePairs.find((kv) => kv.key === col);
        tagsData[col] = tag ? tag.value : null;
      });

      return {
        device,
        monitoringData,
        tagsData,
        siteData: this._hasDeviceLinkWithSite(device) && sitesMap ? sitesMap[device.siteId] : null,
      };
    });

    return dataRows;
  }

  /** Adds extra device data to the monitoring data of the row */
  addExtraDeviceData(row: IMonitoringListDatum) {
    // add last attempt
    row.monitoringData[MonitoredValue.LastAttempt] = row.device.deviceInfo.monitoringData?.lastAttempt;
    // add last update
    row.monitoringData[MonitoredValue.LastUpdate] = row.device.deviceInfo.monitoringData?.lastUpdate;
    // add device response time
    row.monitoringData[MonitoredValue.CcpRespTime] = row.device.deviceInfo.monitoringData?.responseTime;
    // add hostnameIp value
    row.device.deviceInfo['hostnameIp'] = { line1: row.device.deviceInfo.hostname, line2: row.device.deviceInfo.ipAddress };
  }

  private _convertToSitesMap(sites: SiteWithTagsDTO[]): Record<number, SiteWithTagsDTO> {
    return sites?.length ? sites.reduce((result, site) => {
      if (result[site.id]) {
        return result;
      } else {
        return { ...result, [site.id]: site };
      }
    }, {} as Record<number, SiteDTO>) : null;
  }

  private _hasDeviceLinkWithSite(device: DeviceDTO): boolean {
    return device.siteId !== -1;
  }

  private _getMonitoringListMonitoredValues(list: IMonitoringListDTO): string[] {
    // fetch the monitored values required for the columns
    let monitoredValues = list.columns
      .filter((col) => col.type === DeviceColumnType.MonitoredValue)
      .map((col) => col.value);
    // add logical player values
    monitoredValues = addLogicalPlayerMonitoredValues(monitoredValues);
    return monitoredValues;
  }

  /**
   * ATTENTION: Used by Export to CSV feature only!
   * Get paginated devices from cached query result.
   */
  private _getPaginatedCachedDeviceList(
    queryResultId: number,
    limit: number,
    offset: number,
    monitoredValues: string[],
    tagsColumns: string[],
    hasSitePropertyColumn: boolean,
    hasSiteTagColumn: boolean,
    managerId?: number,
  ) {
    return this._deviceQueryResultService.getQueryResult(queryResultId, limit, offset, monitoredValues, tagsColumns, 'response').pipe(
      switchMap((response) => {
        const total = extractTotalRecordsFromHttpHeaders<DeviceDTO>(response);
        const devices = response.body || [];

        return hasSitePropertyColumn ?
          this._fetchSitesByDevices(hasSitePropertyColumn, hasSiteTagColumn, devices, managerId).pipe(
            map((sites) => {
              return { devices, sites, totalCount: total };
            }),
          ) :
          of({ devices, sites: [] as SiteWithTagsDTO[], totalCount: total });
      }),
    );
  }

  private _fetchSitesByDevices(hasSitePropertyColumn: boolean, hasSiteTagColumn: boolean, devices: DeviceDTO[], managerId?: number): Observable<SiteWithTagsDTO[]> {
    const siteIds = devices.filter((device) => this._hasDeviceLinkWithSite(device)).map((device) => device.siteId);
    const uniqueSiteIds = Array.from(new Set(siteIds));

    let sites$: Observable<SiteWithTagsDTO[]>;

    if (hasSitePropertyColumn) {
      sites$ = uniqueSiteIds.length > 0 ? this._sitesService.findSiteSummary(uniqueSiteIds, null, managerId).pipe(
        map((sites) => sites.map((site) => site.site)),
        catchError((_) => of([] as SiteWithTagsDTO[])),
      ) : of([] as SiteWithTagsDTO[]);
    } else {
      sites$ = of([] as SiteWithTagsDTO[]);
    }

    if (this._featureToggleService.isOn('monitoring.v2.list.columns.siteTags') && hasSiteTagColumn) {
      // TODO: CMUI-5771 - implement proper fix and fetch site tags when site tags related columns existed in the list
    }

    return sites$;
  }
}
