import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, share, Subject, take } from 'rxjs';
import { BoardTagsService, SiteDTO } from '@activia/cm-api';
import { defaultIfEmpty, distinctUntilChanged, filter, first, map, skip, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SiteMonitoringDetailRoutingService } from './site-monitoring-detail-routing.service';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { IBoardsInfo, SiteMonitoringDetailStore } from './store/site-monitoring-detail.store';
import { ICombinedDeviceInfo, ISiteSection } from './store/site-monitoring-detail.model';
import { TimerService } from '@amp/messenger';
import { AsyncDataState, dataOnceReady, dataWhenReady } from '@activia/ngx-components';
import { parseAlarmEvents } from '../../utils/alarm-event.utils';
import { IBoardAlarm } from '../../model/alarm-event.interface';
import { OrganizationLevelHealthStatus } from '../../model/organization-level-health';
import { CMRole } from '@amp/auth';
import { RouterFacade } from '@amp/router-store';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { hasVisibleKeyMetrics } from '../../utils/key-metrics.utils';
import { Store } from '@ngrx/store';
import { getBoardOrgPathFromTags, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState } from '@amp/tag-operation';
import { KeyMetricsViewerService } from '../keymetrics/key-metrics-viewer/key-metrics-viewer.service';

/**
 * This component is mostly a presentational component.
 * It is used as a standalone component to display a site's information.
 * It does fetch the data for the site devices (device info, monitored values, screenshots).
 * It should not contain routing information (use Outputs to execute any routing in parent component).
 * It does NOT manage the loading state of the site.
 *
 * Not meant to be used by Angular Element directly (Angular element should be using site-monitoring-detail-container)
 * by just providing the site id
 */
@Component({
  selector: 'amp-site-monitoring-detail',
  templateUrl: './site-monitoring-detail.component.html',
  styleUrls: ['./site-monitoring-detail.component.scss'],
  providers: [SiteMonitoringDetailRoutingService, SiteMonitoringDetailStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringDetailComponent implements OnInit, OnDestroy {
  /** The site for which to display the detail **/
  @Input() set site(site: SiteDTO) {
    this._siteMonitoringDetailStore.setSite(site);
  }

  /** Force toggle state change from Router change*/
  @Input()
  set routerQueryParamChange(state: { [key: string]: string }) {
    this._siteMonitoringDetailRoutingService.routerParamChange(state);
  }

  /** Site title */
  @Input() siteTitle;

  /** Emit device id if go to device detail is clicked */
  @Output() navigateToDeviceDetail: EventEmitter<number> = new EventEmitter();

  /** Emit board name that has been toggled to full screen */
  @Output() widgetToggled: EventEmitter<{
    queryParam: { key: string; value: string };
    state: boolean;
    extra?: any;
  }> = new EventEmitter();

  site$ = this._siteMonitoringDetailStore.site$.pipe(filter((site) => !!site));

  sections$ = dataOnceReady(this._siteMonitoringDetailStore.structureData$, this._siteMonitoringDetailStore.structureDataState$).pipe(map((structure) => structure.sections));

  siteAlarms$: Observable<Map<string, { hasBoardInError: boolean; boards: Array<IBoardAlarm> }>>;
  siteAlarmsState$: Observable<AsyncDataState>;

  /** Indicate if site detail map is enabled */
  enableSiteDetailMap$: Observable<boolean>;

  /** Indicate if keyMetrics is enabled */
  enableSiteDetailKeyMetrics$: Observable<boolean>;

  /** Indicate if alarm event widget is enabled */
  enableSiteDetailAlarmEventsSummary$: Observable<boolean>;

  i18nMapping: any = {
    '=1': 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.SITE_COUNT.SINGULAR',
    other: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.SITE_COUNT.PLURAL',
  };

  rCols = {
    web: 8,
    tablet: 1,
    handset: 1,
  };

  CmRoles = CMRole;

  private _componentDestroyed$: Subject<void> = new Subject<void>();
  sectionTrackFn = (_: number, section: ISiteSection) => section.name;

  constructor(
    public _siteMonitoringDetailRoutingService: SiteMonitoringDetailRoutingService,
    private _elementRef: ElementRef,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _siteMonitoringDetailStore: SiteMonitoringDetailStore,
    private _timerService: TimerService,
    private _boardTagsService: BoardTagsService,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _routerFacade: RouterFacade,
    private _store: Store,
    private _keyMetricsViewerService: KeyMetricsViewerService
  ) {
    const hasVisibleKeyMetrics$ = this.site$.pipe(
      withLatestFrom(this._siteMonitoringDetailStore.monitoredData$),
      switchMap(([site, monitoringData]) => {
        const { keyMetrics$ } = this._keyMetricsViewerService.getSiteKeyMetrics(site.id, monitoringData);
        return keyMetrics$;
      }),
      map((monitoredData) => hasVisibleKeyMetrics(monitoredData))
    );

    this.enableSiteDetailKeyMetrics$ = combineLatest([
      this._siteMonitoringFacade.userPreferences$.pipe(
        filter((preference) => preference !== null),
        map((preference) => preference.enableSiteDetailKeyMetrics)
      ),
      hasVisibleKeyMetrics$,
    ]).pipe(map(([enableSiteDetailKeyMetrics, hasKeyMetrics]) => enableSiteDetailKeyMetrics && hasKeyMetrics));
  }

  ngOnInit(): void {
    this._siteMonitoringDetailRoutingService.setDetailContainer(this._elementRef.nativeElement);

    this._siteMonitoringDetailRoutingService.navigateToDeviceDetail$.pipe(takeUntil(this._componentDestroyed$)).subscribe((deviceid) => this.navigateToDeviceDetail.emit(deviceid));

    this._siteMonitoringDetailRoutingService.toggleStateChanged$
      .pipe(distinctUntilChanged(), takeUntil(this._componentDestroyed$))
      .subscribe((res: { queryParam: { key: string; value: string }; state: boolean; extra?: any }) => this.widgetToggled.emit(res));

    this.enableSiteDetailMap$ = this._siteMonitoringFacade.userPreferences$.pipe(
      filter((preference) => preference !== null),
      map((preference) => preference.showSiteDetailMap)
    );

    this.enableSiteDetailAlarmEventsSummary$ = this._siteMonitoringFacade.userPreferences$.pipe(
      filter((preference) => preference !== null),
      map((preference) => preference.enableSiteDetailAlarmEventsSummary)
    );

    // Refresh the devices and the players info of the site periodically
    this._siteMonitoringFacade.dataRefreshIntervalMs$
      .pipe(
        switchMap((refreshInterval) => this._timerService.timer$(refreshInterval, refreshInterval)),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(() => {
        this._siteMonitoringDetailStore.refreshDevices();
      });

    this.siteAlarms$ = combineLatest([
      dataWhenReady(this._siteMonitoringDetailStore.boardsInfoData$, this._siteMonitoringDetailStore.boardsInfoDataState$),
      dataOnceReady(this._siteMonitoringDetailStore.devicesData$, this._siteMonitoringDetailStore.devicesDataState$),
      dataWhenReady(this._siteMonitoringFacade.preference$, this._siteMonitoringFacade.preferenceDataState$).pipe(map((pref) => pref.defaultToOptimisticView)),
      dataWhenReady(this._siteMonitoringFacade.userPreferences$, this._siteMonitoringFacade.userPreferencesDataState$).pipe(map((userPref) => userPref.showOnlyAlarmErrors)),
    ]).pipe(
      switchMap(([boardsInfo, devices, defaultToOptimisticView, showOnlyAlarmErrors]) => this.groupAlarmsByBoards(boardsInfo, devices, defaultToOptimisticView, showOnlyAlarmErrors)),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.siteAlarmsState$ = this._siteMonitoringDetailStore.alarmsDataState$;

    dataWhenReady(this._siteMonitoringFacade.userPreferences$, this._siteMonitoringFacade.userPreferencesDataState$)
      .pipe(
        map((userPref) => userPref.showOnlyAlarmErrors),
        distinctUntilChanged(),
        skip(1), // No need to refresh alarms on initial load
        tap(() => {
          this._siteMonitoringDetailStore.refreshAlarms();
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();
  }

  /** close widget */
  onCloseWidget(preference: string) {
    this._siteMonitoringFacade.updateUserPreferences({ [`${preference}`]: false });
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  navigateToSiteManagement() {
    this.site$.pipe(take(1)).subscribe((site) => {
      this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, site.id] });
    });
  }

  private groupAlarmsByBoards(
    boardsInfo: IBoardsInfo,
    devices: Array<ICombinedDeviceInfo>,
    defaultToOptimisticView: boolean,
    showOnlyAlarmErrors: boolean
  ): Observable<Map<string, { hasBoardInError: boolean; boards: Array<IBoardAlarm> }>> {
    // If no data or this site does not have at least one board with one device, return null
    if (!boardsInfo || (boardsInfo.deviceIds || []).length === 0) {
      return of(null);
    }

    const boardsAlarms = parseAlarmEvents(
      // eslint-disable-next-line prefer-spread
      [].concat.apply(
        [],
        devices.map((d) => d.alarmEvents)
      ),
      boardsInfo.boards,
      devices,
      defaultToOptimisticView,
      showOnlyAlarmErrors
    );

    // Key: the organization path of the section, e.g. "indoor", "outdoor.lane1"

    return dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState)).pipe(
      first(),
      switchMap((boardOrgPathDef) =>
        forkJoin(
          boardsAlarms.map((board) =>
            this._boardTagsService.findTagsForEntity(board.id).pipe(
              map((tags) => ({
                ...board,
                organizationPath: getBoardOrgPathFromTags(boardOrgPathDef, tags, board.name),
              }))
            )
          )
        ).pipe(defaultIfEmpty([]))
      ),
      map((newBoardAlarms) => {
        const boardAlarmsBySection: Map<
          string,
          {
            hasBoardInError: boolean;
            boards: Array<IBoardAlarm>;
          }
        > = new Map<string, { hasBoardInError: boolean; boards: Array<IBoardAlarm> }>();
        newBoardAlarms.forEach((newBoardAlarm) => {
          const orgPath = newBoardAlarm.organizationPath.split('.');
          const orgPathWithoutName = orgPath.slice(0, orgPath.length - 1);

          const section = orgPathWithoutName.join(' / ');
          const sectionInfo = boardAlarmsBySection.get(section);
          if (sectionInfo) {
            sectionInfo.boards.push({ ...newBoardAlarm, organizationPath: orgPathWithoutName });
          } else {
            boardAlarmsBySection.set(section, {
              hasBoardInError: false,
              boards: [{ ...newBoardAlarm, organizationPath: orgPathWithoutName }],
            });
          }
        });
        boardAlarmsBySection.forEach((value: { hasBoardInError: boolean; boards: Array<IBoardAlarm> }) => {
          value.hasBoardInError = value.boards.some((board) => board.boardStatus.status === OrganizationLevelHealthStatus.ERROR);
        });
        return boardAlarmsBySection;
      })
    );
  }
}
