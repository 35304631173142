import { IBoardAlarm } from '../model/alarm-event.interface';
import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { IOrganizationLevelHealthStatus, OrganizationLevelHealthStatus, OrganizationLevelHealthStatusThemes } from '../model/organization-level-health';
import { AlarmEventLevel, getHighestSeverityLevelInfo, IAlarmEventLevelInfo } from '@amp/devices';
import { getBoardDeviceIds, getBoardsDeviceIds } from './site-boards.utils';
import { ThemeType } from '@activia/ngx-components';
import { IBoardWithOrgPath } from '../model/board-with-orgpath.interface';
import { ICombinedDeviceInfo } from '../components/site-monitoring-detail/store/site-monitoring-detail.model';

/** Get health status of a board based on AlarmEventDTO in devices within this board */
export const getBoardHealthStatus = (boardAlarms: IBoardAlarm, defaultToOptimisticView: boolean): IOrganizationLevelHealthStatus => {
  const getDeviceStatus = (alarms: Array<MonitoringAlarmEventDTO>) => {
    let status: OrganizationLevelHealthStatus;
    if (alarms.some((alarm) => hasAlarmEventError(alarm))) {
      status = OrganizationLevelHealthStatus.ERROR;
    } else {
      if (!defaultToOptimisticView && alarms.some((alarm) => +alarm.level === AlarmEventLevel.Warning || +alarm.level === AlarmEventLevel.Notice)) {
        status = OrganizationLevelHealthStatus.WARNING;
      } else {
        status = OrganizationLevelHealthStatus.OK;
      }
    }
    return status;
  };

  const boardStatus = Math.max(...boardAlarms.combinedDevicesInfo.map((dev) => Math.max(getDeviceStatus(dev.alarmEvents ?? []))));
  // TODO: still need tooltip, count and total??
  return {
    status: boardStatus,
    ...OrganizationLevelHealthStatusThemes[boardStatus],
    tooltip: null,
    count: 0,
    total: 0,
  };
};

export const getDeviceHealthStatus = (alarms: Array<MonitoringAlarmEventDTO>): IAlarmEventLevelInfo => {
  const status = getHighestSeverityLevelInfo(alarms);
  if (status.theme === ThemeType.INFO) {
    status.theme = ThemeType.SUCCESS;
  }
  return status;
};

/**
 * Parse alarms events that are displayed in the alarms widget in site monitoring
 * detail page and in the alarms section in site preview
 */
export const parseAlarmEvents = (
  alarms: Array<MonitoringAlarmEventDTO>,
  boards: Array<IBoardWithOrgPath>,
  devices: Array<ICombinedDeviceInfo>,
  defaultToOptimisticView: boolean,
  showOnlyAlarmErrors: boolean
): Array<IBoardAlarm> => {
  const deviceIds = getBoardsDeviceIds(boards).sort((a, b) => a - b);
  let deviceAlarms: Array<ICombinedDeviceInfo> = deviceIds.map((deviceId) => {
    const alarmsParsed = alarms
      .filter((alarm) => alarm.deviceId === deviceId)
      .map((alarm) => {
        if (defaultToOptimisticView && (alarm.level === AlarmEventLevel.Warning || alarm.level === AlarmEventLevel.Notice)) {
          alarm.level = AlarmEventLevel.Info;
        }
        return alarm;
      })
      .sort((a, b) => a.level - b.level);

    const device = devices.find((d) => d.device.deviceId === deviceId);

    return {
      ...device,
      deviceStatus: getDeviceHealthStatus(alarmsParsed),
      alarmEvents: alarmsParsed,
    };
  });
  // If show errors only is on, devices without error+ alarms will
  // have empty alarms, filter them out as they will not be shown
  if (showOnlyAlarmErrors) {
    deviceAlarms = deviceAlarms.filter((devAlarm) => devAlarm.alarmEvents.length > 0);
  }

  const boardsAlarms: Array<IBoardAlarm> = boards
    .map((board) => {
      const devIds = getBoardDeviceIds(board).sort((a, b) => a - b);
      return {
        id: board.id,
        name: board.name,
        organizationPath: board.organizationPath.split('.'),
        boardStatus: undefined,
        combinedDevicesInfo: deviceAlarms.filter((da) => devIds.includes(da.device.deviceId)),
      };
    })
    .filter(
      (boardAlarms) =>
        // Filter out boards without device (this can happen when board only contains devices with error- alarms
        // or no alarm, and showOnlyAlarmErrors is on)
        boardAlarms.combinedDevicesInfo.length > 0
    );

  return boardsAlarms.map((ba) => ({ ...ba, boardStatus: getBoardHealthStatus(ba, defaultToOptimisticView) }));
};

export const hasAlarmEventError = (alarmEvent: MonitoringAlarmEventDTO): boolean => +alarmEvent.level <= AlarmEventLevel.Error;
