<section>
  <div [innerHTML]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.MESSAGE_100' | transloco"></div>

  <div class="options-wrapper">
    <avn-option-list
      #experiencesSelect
      [attr.data-e2e]="'experiences-select'"
      [selectionMode]="'multiple'"
      [enableGroups]="true"
      [options]="experienceTemplateOptions$ | async"
      [virtualization]="false"
      [showCheckbox]="true"
      [maxOptionsVisibleInViewport]="6"
      (selectionsChanged)="onExperienceSelectionChanged($event)"
    >
    </avn-option-list>
  </div>
</section>

<ng-container *ngIf="experiencesSelect?.selectedOptions?.length > 0">
  <section>
    <span>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.SELECTION_MESSAGE_100' | transloco }}</span>

    <div class="experience-template-device-action">
      <div class="option provision" [class.selected]="selection.deviceAction === 'provision'" (click)="selectExperienceDeviceAction('provision')">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.PROVISION_NEW_PLAYER.TITLE_60' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.PROVISION_NEW_PLAYER.TEXT_100' | transloco }}</span>
      </div>

      <div class="option connect" [class.selected]="selection.deviceAction === 'connect-existing'" (click)="selectExperienceDeviceAction('connect-existing')">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.CONNECT_EXISTING_PLAYERS.TITLE_60' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.CONNECT_EXISTING_PLAYERS.TEXT_100' | transloco }}</span>
      </div>
    </div>
  </section>
</ng-container>
