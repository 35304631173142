import { BoardTagsService } from '@activia/cm-api';
import { APT_OWNER, EngineTagLevel, FeaturesSharedTagOperationModule, IAPITagValue, IEngineTagValue, TagAdapterService } from '@amp/tag-operation';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentSiteId, selectedCurrentBoard } from '../../../../store/board/board.selectors';
import { Observable, first, map, switchMap, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreModule, EmptyDataMessageModule } from '@activia/ngx-components';
import { SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { NavigationExtras, Router } from '@angular/router';
import { BoardSelectorComponent } from '../board-selector/board-selector.component';

@Component({
  selector: 'amp-board-apt-info',
  templateUrl: './board-apt-info.component.html',
  styleUrl: './board-apt-info.component.scss',
  standalone: true,
  imports: [CommonModule, CoreModule, FeaturesSharedTagOperationModule, TranslocoModule, EmptyDataMessageModule, BoardSelectorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardAptInfoComponent {
  boardStore = inject(Store);
  boardTagsService = inject(BoardTagsService);
  tagAdapterService = inject(TagAdapterService);
  siteManagementConfig = inject(SITE_MANAGEMENT_MODULE_CONFIG);
  router = inject(Router);

  currentSelectedBoardId$ = this.boardStore.select(selectedCurrentBoard).pipe(map((e) => e?.id));

  tagValues$ = this._getAptTags$();

  selectBoard(boardId: number): void {
    this.boardStore
      .select(selectCurrentSiteId)
      .pipe(
        first(),
        tap((siteId) => {
          const path = [...this.siteManagementConfig.moduleBasePath, siteId, 'boards', 'apt', `${boardId}`];
          const extras = { queryParamsHandling: 'preserve' } as NavigationExtras;
          this.router.navigate(path, extras);
        }),
      )
      .subscribe();
  }

  /** Flatten an nested object */
  flattenObject(value: unknown, ref = {}): object {
    if (typeof value === 'object') {
      Object.entries(value).forEach(([currKey, currValue]) => {
        if (typeof currValue === 'object') {
          this.flattenObject(currValue, ref);
        } else {
          ref[currKey] = currValue;
        }
      });
    }

    return ref;
  }

  /** Format all APT json tag in key value pair, and merge all non-json apt tag into an map of key value  */
  formatAptTags(tags: { value: IEngineTagValue }[]) {
    return tags
      .map((e) => e.value)
      .reduce(
        (acc, curr) => {
          if (curr.keyDescription?.schema?.type === 'object') {
            acc.json.push({ key: curr.key, value: curr.values });
          } else {
            acc.standard[curr.key] = curr.values;
          }
          return acc;
        },
        { json: [], standard: {} } as { json: { key: string; value }[]; standard: object },
      );
  }

  /** Get APT tags values and definitions  */
  private _getAptTags$(): Observable<Record<string, IEngineTagValue>> {
    return this.boardTagsService.findAllTagKeys(APT_OWNER).pipe(
      switchMap((tagDefs) =>
        this.currentSelectedBoardId$.pipe(
          switchMap((id) => this.boardTagsService.findTagsForEntity(id, Object.keys(tagDefs))),
          // transform all tags to IAPITagValue
          map((tags) => {
            return Object.keys(tags)
              .filter((tagKey) => !!tagDefs[tagKey]) // Only "apt" tag with a definition
              .reduce(
                (acc, curr) => {
                  acc[curr] = {
                    key: curr,
                    propertyType: EngineTagLevel.BOARD,
                    values: tags[curr],
                    keyDescription: tagDefs[curr],
                  } as IAPITagValue;
                  return acc;
                },
                {} as Record<string, IAPITagValue>,
              );
          }),
          // Normalize tag values coming from API
          map((tagValues) => this.tagAdapterService.normalizeIncomingTagValues(tagValues)),
        ),
      ),
    );
  }
}
