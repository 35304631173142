<avn-page>
  <avn-ribbon
    [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.TITLE_50' | transloco"
    [fabIcons]="(isAdmin$ | async) ? 'action:settings' : null"
    (fabActionClicked)="openSettingsDrawer()">
    <avn-breadcrumbs>
      <avn-breadcrumb [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.TITLE.TITLE_50' | transloco"
                      [routerLink]="['..']"></avn-breadcrumb>
    </avn-breadcrumbs>

    <avn-ribbon-facets>
      <ng-container *ngIf="hasSyncJob$ | async">
        <ng-container *ngIf="counts$ | async as counts">
          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.ALL_20' | transloco"
                  [icon]="'content:select_all'"
                  [toggled]="siteFilter === 'all'"
                  (actioned)="updateFilter('all')"
          ></avn-ribbon-card>
          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.IN_PROGRESS_20' | transloco"
                  [icon]="SiteSyncStatusIcons.processing"
                  [count]="counts['in-progress']"
                  [countTheme]="SiteSyncStatusThemes.processing"
                  [toggled]="siteFilter === 'in-progress'"
                  (actioned)="updateFilter('in-progress')"
          ></avn-ribbon-card>
          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.COMPLETED_20' | transloco"
                  [icon]="SiteSyncStatusIcons.processed"
                  [count]="counts.completed"
                  [countTheme]="SiteSyncStatusThemes.processed"
                  [toggled]="siteFilter === 'completed'"
                  (actioned)="updateFilter('completed')"
          ></avn-ribbon-card>
          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.WARNING_20' | transloco"
                  [icon]="SiteSyncStatusIcons.warning"
                  [count]="counts.warning"
                  [countTheme]="SiteSyncStatusThemes.warning"
                  [toggled]="siteFilter === 'warning'"
                  (actioned)="updateFilter('warning')"
          ></avn-ribbon-card>
          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.ERROR_20' | transloco"
                  [icon]="SiteSyncStatusIcons.error"
                  [count]="counts.error"
                  [countTheme]="SiteSyncStatusThemes.error"
                  [toggled]="siteFilter === 'error'"
                  (actioned)="updateFilter('error')"
          ></avn-ribbon-card>

          <avn-ribbon-card
                  [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.RIBBON.FILTERS.SKIPPED_20' | transloco"
                  [icon]="SiteSyncStatusIcons.unchanged"
                  [count]="counts.skipped"
                  [countTheme]="SiteSyncStatusThemes.unchanged"
                  [toggled]="siteFilter === 'skipped'"
                  (actioned)="updateFilter('skipped')"
          ></avn-ribbon-card>
        </ng-container>
      </ng-container>
    </avn-ribbon-facets>
  </avn-ribbon>

  <avn-main avnScroll>
    <ng-container>
      <avn-data-table
              [columns]="columns$ | async"
              [dataSource]="dataSource$ | async"
              [dataSourceConfig]="importDataSourceConfig"
              [templateCacheSize]="0"
              [rowHeight]="80"
              [keyboardNavigationEnabled]="true"
              [headerTooltipsEnabled]="true"
              [infiniteScrollEnabled]="false"
              [forceLoadingState]="false"
              [serverSorting]="false"
              [footerHeight]="50"
              [footerTemplate]="footerTemplate"
              (selectionChanged)="onSelectImportJobs($event)"
              (columnSorted)="updateSiteListSort($event)"
      >
        <avn-empty-data-message
                *ngIf="(hasSyncJob$ | async) === false || siteFilter !== 'all' && !(counts$ | async)[siteFilter]"
                icon="notification:sync_disabled"
                [text]="
            (hasSyncJob$ | async) ?
            ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.NO_FILTERED_SYNC_JOB_MESSAGE.TEXT_100' | transloco) :
            'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.NO_SYNC_JOB_MESSAGE.TEXT_100' | transloco"
                [subText]="
            (hasSyncJob$ | async) ?
            ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.NO_FILTERED_SYNC_JOB_MESSAGE.SUBTEXT_100' | transloco) :
            'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.NO_SYNC_JOB_MESSAGE.SUBTEXT_100' | transloco"
        >
          <avn-button
                  *ngIf="(hasSyncJob$ | async) === false"
                  style="margin-top: 16px"
                  [attr.data-e2e]="'import-from-csv-btn'"
                  [label]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.BUTTON_UPLOAD_CSV_FILE_30' | transloco"
                  avnTheme="primary"
                  (actioned)="openSiteSyncModal()"
          ></avn-button>
        </avn-empty-data-message>

      </avn-data-table>

      <ng-template #footerTemplate let-totalRowCount="totalRowCount">
        <div class="site-list-footer">
          {{ 'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.FOOTER.SITE_COUNT' | transloco: { count: totalRowCount } }}
        </div>
      </ng-template>

      <ng-template #operationTemplate let-row="row">
        <ng-container *ngIf="['add', 'update', 'unchanged'].includes(row.validationStatus)">
          {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.OPERATION.' + (row.validationStatus | uppercase) + '_20' | transloco }}
        </ng-container>
      </ng-template>

      <ng-template #progressTemplate let-row="row">
        <ng-container *ngIf="row.syncProgress as syncProgress">
          <amp-site-sync-progress [syncProgress]="syncProgress"></amp-site-sync-progress>
        </ng-container>
      </ng-template>

      <ng-template #statusTemplate let-row="row">
        <ng-container *ngIf="row.validationStatus === 'invalid-id'">
          {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.INVALID_ID_50' | transloco }}
        </ng-container>
        <ng-container *ngIf="row.syncProgress as syncProgress">
          <amp-site-sync-status [syncProgress]="syncProgress"></amp-site-sync-status>
        </ng-container>
      </ng-template>

      <ng-template #actionsTemplate let-row="row">
        <amp-site-sync-action [siteImportState]="row"></amp-site-sync-action>
      </ng-template>
    </ng-container>
    <amp-site-templates [prefix]="'site'"></amp-site-templates>
  </avn-main>
</avn-page>
