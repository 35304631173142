import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ITaskPanelItemComponent, ModalService, ModalType } from '@activia/ngx-components';
import { BehaviorSubject, map, mergeMap, Observable, of, pairwise, ReplaySubject, scan, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { SiteDTO, SitesService } from '@activia/cm-api';
import { Store } from '@ngrx/store';
import { differenceBy } from 'lodash';
import { catchError, startWith, tap } from 'rxjs/operators';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';
import * as GeoFixerAction from '../../store/geo-fixer/geo-fixer.actions';
import { geoFixerEntities } from '../../store/geo-fixer/geo-fixer.selectors';
import { findSiteCoordinates$, updateSite$ } from '../../utils/site.utils';
import { CountryService, GeoTimezoneService, GoogleMapsService, IGeoPoint } from '@activia/geo';
import { IGeoAutoFixSite, IGeoFixerSite } from '../../models/geo-fixer-site.interface';
import { GeoFixSiteSummaryModalComponent } from '../geo-fix-site-summary-modal/geo-fix-site-summary-modal.component';
import { findGeoLocation, IGeoLocationValidationResult } from '../../utils/geo-location-validator.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';

const MAX_CONCURRENT_FIX_SITE_REQUESTS = 10;

@Component({
  selector: 'amp-geo-fix-site-task-panel-item',
  templateUrl: './geo-fix-site-task-panel-item.component.html',
  styleUrls: ['./geo-fix-site-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoFixSiteTaskPanelItemComponent implements ITaskPanelItemComponent, OnDestroy {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);
  model$: Observable<{
    errorCount: number;
    successCount: number;
    totalCount: number;
    completePercentage: number;
    taskCompleted: boolean;
  }>;

  SiteSyncStatusIcons = SiteSyncStatusIcons;
  SiteSyncStatusThemes = SiteSyncStatusThemes;

  private _fixSiteSubject = new ReplaySubject<IGeoAutoFixSite>();
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _googleMapsService: GoogleMapsService,
    private _geoTimezoneService: GeoTimezoneService,
    private _countryService: CountryService,
    private _sitesService: SitesService,
    private _store: Store,
    private _modalService: ModalService,
    private _translocoService: TranslocoService,
  ) {
    // total site will increase as more sites are fixed
    let totalSites = 0;

    this._store
      .pipe(geoFixerEntities.sitesToAutoFix$)
      .pipe(
        startWith([]),
        pairwise(),
        tap(([previousFixSites, currentFixSites]) => {
          const newSitesToFix = differenceBy(currentFixSites, previousFixSites, 'id');
          newSitesToFix.forEach((site) => {
            totalSites++;
            this._fixSiteSubject.next(site);
          });
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    this.model$ = this._fixSiteSubject.asObservable().pipe(
      mergeMap((site) => {
        this._store.dispatch(GeoFixerAction.UpdateAutoFixGeoProblematicSiteStatus({ siteId: site.id, status: 'PROCESSING' }));

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { autoFixStatus, error, ...siteDTO } = site;
        return this._fixSite$(siteDTO).pipe(
          map((_) => {
            // If there is value in this field, the address is invalid and cannot be used to auto-fix coordinates or timezone
            if (_.validationSubStatus) {
              const translatedStatus = this._translocoService.translate(`siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.${_.validationStatus}.WARNING_MESSAGE_100`);
              const payload = {
                site: _,
                error: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.AMBIGUOUS_ADDRESS.WARNING_TOOLTIP_150', { validationStatus: translatedStatus.toLowerCase() })
              };
              this._store.dispatch(GeoFixerAction.AutoFixGeoProblematicSiteFail(payload));
              return { success: false };
            } else {
              this._store.dispatch(GeoFixerAction.AutoFixGeoProblematicSiteSuccess({ site: _ }));
              return { success: true };
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this._store.dispatch(GeoFixerAction.AutoFixGeoProblematicSiteFail({ site: siteDTO, error: err?.error ? JSON.stringify(err.error) : '' }));
            return of({ success: false });
          })
        );
      }, MAX_CONCURRENT_FIX_SITE_REQUESTS),
      // accumulate counts as the sites are fixed ('scan' is the observable equivalent to 'reduce')
      scan(
        (counts, { success }) => {
          if (success) {
            counts.successCount++;
          } else {
            counts.errorCount++;
          }
          counts.totalCount = totalSites;
          counts.completePercentage = ((counts.successCount + counts.errorCount) / counts.totalCount) * 100;
          counts.taskCompleted = counts.completePercentage === 100;
          return counts;
        },
        {
          errorCount: 0,
          successCount: 0,
          totalCount: totalSites,
          completePercentage: 0,
          taskCompleted: false,
        }
      ),
      tap(({ taskCompleted }) => {
        this.completed$.next(taskCompleted);
      }),
      startWith({
        errorCount: 0,
        successCount: 0,
        totalCount: 0,
        completePercentage: 0,
        taskCompleted: true,
      })
    );
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._store.dispatch(GeoFixerAction.ClearAutoFixedGeoProblematicSites());
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  showSummary() {
    this._modalService.open<GeoFixSiteSummaryModalComponent, void>(
      GeoFixSiteSummaryModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        width: '90%',
        minWidth: '500px',
        maxWidth: '1400px',
        height: '50%',
        minHeight: '500px',
        maxHeight: '800px',
      },
      ModalType.Dialog
    );
  }

  /**
   * Auto-fix missing timezone from address.
   * Note: USE THIS FUNCTION ONLY WHEN THE ADDRESS IS VALID FOR AUTO-FIX
   */
  private _fixMissingTimezone$(siteDTO: SiteDTO): Observable<SiteDTO> {
    return updateSite$(this._geoTimezoneService, this._countryService, this._sitesService, siteDTO, true).pipe(
      map((updatedSite) => updatedSite.siteResponse.site)
    );
  }

  /**
   * Auto-fix missing coordinates from address.
   * Note: USE THIS FUNCTION ONLY WHEN THE ADDRESS IS VALID FOR AUTO-FIX
   */
  private _fixMissingCoordinates$(ste: SiteDTO): Observable<SiteDTO> {
    return findSiteCoordinates$(this._countryService, this._googleMapsService, ste).pipe(
      switchMap((result: IGeoPoint) => (result ? this._fixMissingTimezone$({ ...ste, geodeticCoordinates: result }) : throwError('Coordinates not found')))
    );
  }

  /** Validate if address can be used to auto-fix missing coordinates or timezone */
  private _validateSite$(site: IGeoFixerSite): Observable<IGeoFixerSite> {
    const location = { address: site.address, geodeticCoordinates: site.geodeticCoordinates };
    return findGeoLocation(this._countryService, this._googleMapsService, location, true).pipe(
      map((result: IGeoLocationValidationResult) => result.valid
        ? site
        : ({
          ...site,
          validationSubStatus: 'AMBIGUOUS_ADDRESS',
        })
      )
    );
  }

  /**
   * Return the fixed site if the missing coordinates or timezone can be resolved from the address
   * Otherwise return with AMBIGUOUS_ADDRESS status
   */
  private _fixSite$(site: IGeoFixerSite): Observable<IGeoFixerSite> {
    const { validationStatus, ...siteDTO } = site;

    return this._validateSite$(site).pipe(
      switchMap((ste) => {
        if (ste.validationSubStatus) {
          // If there is value in this field, missing coordinates or timezone cannot be resolved from address
          // Simply return as it is
          return of(ste);
        } else {
          // Otherwise the missing coordinates or timezone can be resolved from the address
          // Perform auto-fix
          if (ste.validationStatus === 'INCOMPLETE_GEODETIC_COORDINATES') {
            return this._fixMissingCoordinates$(siteDTO).pipe(
              map((s) => ({ ...s, validationStatus }))
            );
          } else if (ste.validationStatus === 'MISSING_TIMEZONE') {
            return this._fixMissingTimezone$(siteDTO).pipe(
              map((s) => ({ ...s, validationStatus }))
            );
          }
        }
      })
    );
  }
}
