import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOrgPathDefNode } from '@amp/tag-operation';
import { IJsonSchema, JsonSchemaControlFieldComponent } from '@activia/json-schema-forms';
import { AsyncValidatorFn, ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { CoreModule } from '@activia/ngx-components';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'amp-orgpath-form-group',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, JsonSchemaControlFieldComponent, CoreModule],
  templateUrl: './orgpath-form-group.component.html',
  styleUrls: ['./orgpath-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgpathFormGroupComponent implements OnInit, OnDestroy {
  /** The dependent json schema to build the form */
  @Input() nodeDefinition: IOrgPathDefNode;

  /** Dictionnary of all Tags definition (json-schema) */
  @Input() tagDefinition: Record<string, IJsonSchema>;

  @Input() controlAsyncValidatorMap: Map<string, AsyncValidatorFn>;

  @Input() editable: boolean;

  @Output() formChanged: EventEmitter<void> = new EventEmitter();

  /** Json-schema used by the current node */
  schema: IJsonSchema;

  /** Parent formGroup. This component needs to be encapsulated by a formGroup to work */
  form: FormGroup;

  /** Form control of the current node */
  formControl = new FormControl();

  /** Property name of the current node */
  propertyName: string;

  private _componentDestroyed$: Subject<void> = new Subject();

  // Take parent form group
  constructor(private _transloco: TranslocoService, @SkipSelf() @Optional() public controlContainer: ControlContainer) {
    // At initialization, add the control of the current node to the parent form
    this.form = this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {
    if (!this.nodeDefinition || !this.tagDefinition) {
      throw new Error('Input [nodeDefinition] and [tagDefinition] are required by <amp-orgpath-form-group>');
    }

    if (!this.controlContainer?.control) {
      throw new Error('The component <amp-orgpath-form-group> need to be encapsulated by a form group');
    }

    this.schema = this.nodeDefinition.tag ? this.tagDefinition[this.nodeDefinition.tag] : this.nodeDefinition.schema;

    this.propertyName = this.nodeDefinition.tag || this.nodeDefinition.property;

    const control = this.form.get(this.propertyName);
    // If a previous form control already exist (for example by switching value on a dependent parent)
    if (control) {
      const oldValue = control.value; // Keep the old value
      this.formControl.patchValue(oldValue);
    }

    // Add new control to formGroup. If a previous form control already exist (for example by switching value on a dependent parent)
    // it will replace the existing one
    if (control) {
      this.form.setControl(this.propertyName, this.formControl);
    } else {
      this.form.addControl(this.propertyName, this.formControl);
    }

    if (this.editable) {
      this.form.enable();
    } else {
      this.form.disable();
    }

    this.updateValidators();
  }

  ngOnDestroy(): void {
    // If the control is still in the form group when destroying the component, remove it
    // (It is possible the control doesn't exist anymore if another node already replace to a new one)
    if (this.form.get(this.propertyName) === this.formControl) {
      this.form.removeControl(this.propertyName);
    }

    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  getNameCustomError(propertyName: string): object {
    if (propertyName === 'name') {
      return {
        required: this._transloco.translate(`siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NAME_VALIDATION_ERROR`),
      };
    }
    return {};
  }

  /** Will find the first node with condition that meet requirement */
  getFirstDependencyChild(nodes: IOrgPathDefNode[], value: unknown) {
    // We don't know the type of the value. "1" in the schema and 1 in the value should met the condition
    // Following '==' instead of '===' is on purpose.
    return nodes.find((e) => !e.dependentItem || e.dependentItem == value);
  }

  /**
   * Update the async validators for the form controls based on the controlAsyncValidatorMap.
   */
  private updateValidators() {
    if (this.controlAsyncValidatorMap?.size > 0) {
      Object(this.controlAsyncValidatorMap).forEach((asyncValidators: AsyncValidatorFn, key: string) => {
        const control = this.form.get(key);
        if (control && asyncValidators) {
          control.setAsyncValidators(asyncValidators);
          control.updateValueAndValidity();
        }
      });
    }
  }
}
