import { getCurrentRoute } from '@amp/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBoard } from '../../models/board-config.interface';
import * as fromBoard from './board.reducer';

export const selectBoardState = createFeatureSelector<fromBoard.BoardState>(fromBoard.BOARD_FEATURE_KEY);

const { selectIds, selectEntities, selectAll, selectTotal } = fromBoard.adapter.getSelectors();

export const selectBoardIds = createSelector(selectBoardState, selectIds);
export const selectBoardEntities = createSelector(selectBoardState, selectEntities);
export const selectAllBoards = createSelector(selectBoardState, selectAll);
export const selectBoardTotal = createSelector(selectBoardState, selectTotal);

export const selectBoardStatus = createSelector(selectBoardState, (state) => state.status);

export const selectedCurrentBoard = createSelector(getCurrentRoute, selectAllBoards, (router, boards): IBoard => {
  if (router.data.module === 'site-management') {
    return boards.find((e) => e.organizationPath === router.params.boardOrgPath) || boards.find((e) => e.id === +router.params.boardId);
  }
});

export const selectCurrentSiteId = createSelector(selectBoardState, (state) => state.currentSiteId);

// Board group by org path
export const selectBoardByOrgPath = createSelector(selectAllBoards, (boards) =>
  boards.reduce(
    (acc, curr) => {
      const partialOrgPath = curr.organizationPath.split('.').slice(0, -1).join('.'); // Remove board name from org path

      // Group board per orgPath
      if (acc[partialOrgPath]) {
        acc[partialOrgPath].push(curr);
      } else {
        acc[partialOrgPath] = [curr];
      }
      return acc;
    },
    {} as Record<string, IBoard[]>,
  ),
);

export const selectTemporaryDeletedBoards = createSelector(selectBoardState, (state: fromBoard.BoardState) => state.temporaryDeletedBoards);

export const selectBoardOrgPath = createSelector(selectAllBoards, (boards) => boards.map((board) => board.organizationPath));
