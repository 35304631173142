import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { dataOnceReady, IOptionData, SelectModule } from '@activia/ngx-components';
import { IBoard } from '../../../../models/board-config.interface';
import { RouterFacade } from '@amp/router-store';
import { Store } from '@ngrx/store';
import { BoardState } from '../../../../store/board/board.reducer';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { TranslocoModule } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'amp-board-selector',
  templateUrl: './board-selector.component.html',
  styleUrls: ['./board-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SelectModule, TranslocoModule, AsyncPipe],
})
export class BoardSelectorComponent {
  @Output() boardChange: EventEmitter<number> = new EventEmitter();
  /** Enable filter board options to display only boards with displays **/
  @Input() filterByDisplay: boolean;

  siteId$: Observable<number>;
  boards$: Observable<IOptionData<IBoard>[]>;
  selectedBoard$: Observable<number>;

  constructor(
    private _routerFacade: RouterFacade,
    private _store: Store<ISiteManagementState>,
    private _boardStore: Store<BoardState>,
  ) {
    this.siteId$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$)).pipe(
      filter((site) => !!site),
      map((site) => site.id),
    );

    this.boards$ = this._boardStore.select(BoardSelectors.selectAllBoards).pipe(
      map((allBoards) =>
        this.filterBoardsByDisplay(allBoards, this.filterByDisplay)
          .map((board) => ({
            value: board.id,
            label: board.organizationPath,
            data: board,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
    );

    this.selectedBoard$ = this._routerFacade.currentRoute$.pipe(map((route) => +route.params.boardId));
  }

  onBoardChange(boardId: number) {
    this.boardChange.emit(boardId);
  }

  filterBoardsByDisplay(boards: IBoard[], filterByDisplay: boolean) {
    return boards && filterByDisplay ? boards.filter((board) => board.displays.length > 0) : boards;
  }
}
