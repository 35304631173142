import { SiteDTO, SitesService } from '@activia/cm-api';
import { Observable, of, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { CountryService, GeoTimezoneService, GoogleMapsService } from '@activia/geo';
import { ISiteGeoResponse, validateCsvSiteGeoLocation$ } from './site-sync-geo.utils';
import { createSite$ } from '../../../utils/site.utils';
import {
  CustomError,
  RequestChain,
  RequestChainFunction,
} from '../request-chain';
import { take } from 'rxjs/operators';
import { validateRequiredValuesFilled } from './site-sync-validation.utils';

export const createSiteRequestChain = (
  siteToAdd$: Observable<SiteDTO>,
  googleMapsService: GoogleMapsService,
  geoTimezoneService: GeoTimezoneService,
  countryService: CountryService,
  sitesService: SitesService,
  isMissingRequiredValueFixedForSite$: Observable<boolean>,
  isGeoFixedForSite$: Observable<boolean>
): RequestChain<SiteDTO> => {
  const requestChain = new RequestChain<SiteDTO>();

  // 1 verify all required fields are provided
  const validateRequiredValuesStep$: RequestChainFunction = () => isMissingRequiredValueFixedForSite$.pipe(
      take(1),
      withLatestFrom(siteToAdd$),
      switchMap(([isMissingRequiredValueFixedForSite, siteToAdd]) => {
        if (isMissingRequiredValueFixedForSite) {
          return of({ dataValidationResponse: { response: 'ok' } });
        }
        const invalidFields = validateRequiredValuesFilled(siteToAdd);
        return invalidFields.length === 0 ?
          of({ dataValidationResponse: { response: 'ok' } }) :
          throwError(new CustomError(`missing required value`, 'missing-required-value', { dataValidationResponse: { response: 'warning', fields: invalidFields } }));
      })
    );
  requestChain.registerRequest({
    id: 'validate-required-values',
    request$: validateRequiredValuesStep$,
  });

  // 2 check geo
  const validateGeoStep$: RequestChainFunction = () =>
    isGeoFixedForSite$.pipe(
      take(1),
      withLatestFrom(siteToAdd$),
      switchMap(([isGeoFixed, siteToAdd]) =>
        isGeoFixed
          ? of({ response: 'ok' } as ISiteGeoResponse)
          : validateCsvSiteGeoLocation$(siteToAdd, googleMapsService, countryService).pipe(
              tap((geoResponse) => {
                // Throw an error to stop the request chain. Keep geo result data using a custom error, so we can use it in the UI (regular Error can only save a string message)
                if (geoResponse.response === 'error' || geoResponse.response === 'warning') {
                  // stop the request sequence
                  const errorCode = geoResponse.status;
                  const errorData = { geoResponse };
                  throw new CustomError(`geo ${geoResponse.response}`, errorCode, errorData);
                }
              })
            )
      )
    );
  requestChain.registerRequest({
    id: 'validate-geo',
    request$: validateGeoStep$,
  });

  // 3 create site
  const createSiteStep$: RequestChainFunction<SiteDTO> = () => siteToAdd$.pipe(
      take(1),
      switchMap((siteToAdd) => createSite$(geoTimezoneService, countryService, sitesService, siteToAdd))
    );
  requestChain.registerRequest({ id: 'create-site', request$: createSiteStep$ });

  return requestChain;
};
