import { PERMISSIONS } from '@amp/auth';
import { DeviceActionCommandEnum } from './model/device-command.enum';
import { DeviceCommandCategoryEnum } from './model/device-command-category.enum';
import { DeviceCommandTypeEnum } from './model/device-command-type.enum';
import { CommandControlDTOControlEnum, CommandDevicePowerDTOControlEnum, CommandPlayerAssetsDTOControlEnum, CommandPlayerPlaybackDTOControlEnum } from '@activia/cm-api';

/** Represents a command that can be executed on a device **/
export interface IDeviceCommandInfo {
  /** Command action */
  action: DeviceActionCommandEnum;

  /** Category where this command would be shown */
  category: DeviceCommandCategoryEnum;

  /** Type of the command */
  type: DeviceCommandTypeEnum;

  /**
   * Action control associates with the command type
   * e.g. type: mute + action control: on => turn on mute
   */
  actionControl?: CommandPlayerAssetsDTOControlEnum | CommandControlDTOControlEnum | CommandPlayerPlaybackDTOControlEnum | CommandDevicePowerDTOControlEnum;

  /** i18n label for the command */
  label: string;

  /** Icon of the command */
  icon: string;

  /** Message to show when the command starts */
  startingMsg: string;

  /** Message to show when the command fails */
  failingMsg?: string;

  /** Message to show when the command completes */
  endingMsg?: string;

  /** True if the command is only executable on Digital Signage Network Player */
  dspDeviceOnly: boolean;

  /** True if user's confirmation is required to start the command */
  requiredConfirmation: boolean;

  /** Title to display in the confirmation dialog */
  confirmationTitle?: string;

  /** Message to display in the confirmation dialog */
  confirmationMsg?: string;

  /** Label of the ok action to display in the confirmation dialog */
  confirmationOk?: string;

  /** Label of the cancel action to display in the confirmation dialog */
  confirmationCancel?: string;

  /** Permission required to execute the command */
  permission?: PERMISSIONS;

  /** Throttling time in milliseconds which the command cannot executed again after the previous one is succesfully finished */
  throttlingTimeInMs?: number;
}

// TODO there are more commands to add since the new endpoint
export const DEVICE_COMMANDS: Partial<Record<DeviceActionCommandEnum, IDeviceCommandInfo>> = {
  [DeviceActionCommandEnum.StartPlayback]: {
    action: DeviceActionCommandEnum.StartPlayback,
    category: DeviceCommandCategoryEnum.Player,
    type: DeviceCommandTypeEnum.Playback,
    actionControl: CommandPlayerPlaybackDTOControlEnum.Start,
    label: 'commands.DEVICE_COMMANDS.START_PLAYER.LABEL',
    icon: 'av:play_arrow',
    startingMsg: 'commands.DEVICE_COMMANDS.START_PLAYER.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.START_PLAYER.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: false,
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.StopPlayback]: {
    action: DeviceActionCommandEnum.StopPlayback,
    category: DeviceCommandCategoryEnum.Player,
    type: DeviceCommandTypeEnum.Playback,
    actionControl: CommandPlayerPlaybackDTOControlEnum.Stop,
    label: 'commands.DEVICE_COMMANDS.STOP_PLAYER.LABEL',
    icon: 'av:stop',
    startingMsg: 'commands.DEVICE_COMMANDS.STOP_PLAYER.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.STOP_PLAYER.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: true,
    confirmationTitle: 'commands.DEVICE_COMMANDS.STOP_PLAYER.CONFIRM_TITLE',
    confirmationMsg: 'commands.DEVICE_COMMANDS.STOP_PLAYER.CONFIRM_MSG',
    confirmationOk: 'commands.DEVICE_COMMANDS.STOP_PLAYER.CONFIRM_OK',
    confirmationCancel: 'button.cancel',
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.RestartPlayback]: {
    action: DeviceActionCommandEnum.RestartPlayback,
    category: DeviceCommandCategoryEnum.Player,
    type: DeviceCommandTypeEnum.Playback,
    actionControl: CommandPlayerPlaybackDTOControlEnum.Restart,
    label: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.LABEL',
    icon: 'av:replay',
    startingMsg: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: true,
    confirmationTitle: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.CONFIRM_TITLE',
    confirmationMsg: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.CONFIRM_MSG',
    confirmationOk: 'commands.DEVICE_COMMANDS.RESTART_PLAYER.CONFIRM_OK',
    confirmationCancel: 'button.cancel',
    permission: PERMISSIONS.SERVICE_ACTION,
    throttlingTimeInMs: 60 * 1000,
  },
  [DeviceActionCommandEnum.FlushAssets]: {
    action: DeviceActionCommandEnum.FlushAssets,
    category: DeviceCommandCategoryEnum.Player,
    type: DeviceCommandTypeEnum.Assets,
    actionControl: CommandPlayerAssetsDTOControlEnum.Flush,
    label: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.LABEL',
    icon: 'image:flash_on',
    startingMsg: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: true,
    confirmationTitle: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.CONFIRM_TITLE',
    confirmationMsg: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.CONFIRM_MSG',
    confirmationOk: 'commands.DEVICE_COMMANDS.FLUSH_PLAYER.CONFIRM_OK',
    confirmationCancel: 'button.cancel',
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.DisplayOff]: {
    action: DeviceActionCommandEnum.DisplayOff,
    category: DeviceCommandCategoryEnum.Utilities,
    type: DeviceCommandTypeEnum.Display,
    actionControl: CommandControlDTOControlEnum.Off,
    label: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.LABEL',
    icon: 'hardware:phonelink_off',
    startingMsg: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: true,
    confirmationTitle: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.CONFIRM_TITLE',
    confirmationMsg: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.CONFIRM_MSG',
    confirmationOk: 'commands.DEVICE_COMMANDS.DISPLAY_OFF.CONFIRM_OK',
    confirmationCancel: 'button.cancel',
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.DisplayOn]: {
    action: DeviceActionCommandEnum.DisplayOn,
    category: DeviceCommandCategoryEnum.Utilities,
    type: DeviceCommandTypeEnum.Display,
    actionControl: CommandControlDTOControlEnum.On,
    label: 'commands.DEVICE_COMMANDS.DISPLAY_ON.LABEL',
    icon: 'hardware:phonelink',
    startingMsg: 'commands.DEVICE_COMMANDS.DISPLAY_ON.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.DISPLAY_ON.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: false,
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.Mute]: {
    action: DeviceActionCommandEnum.Mute,
    category: DeviceCommandCategoryEnum.Utilities,
    type: DeviceCommandTypeEnum.Mute,
    actionControl: CommandControlDTOControlEnum.On,
    label: 'commands.DEVICE_COMMANDS.MUTE.LABEL',
    icon: 'av:volume_off',
    startingMsg: 'commands.DEVICE_COMMANDS.MUTE.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.MUTE.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: false,
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.Unmute]: {
    action: DeviceActionCommandEnum.Unmute,
    category: DeviceCommandCategoryEnum.Utilities,
    type: DeviceCommandTypeEnum.Mute,
    actionControl: CommandControlDTOControlEnum.Off,
    label: 'commands.DEVICE_COMMANDS.UNMUTE.LABEL',
    icon: 'av:volume_up',
    startingMsg: 'commands.DEVICE_COMMANDS.UNMUTE.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.UNMUTE.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: false,
    permission: PERMISSIONS.SERVICE_ACTION,
  },
  [DeviceActionCommandEnum.RebootDevice]: {
    action: DeviceActionCommandEnum.RebootDevice,
    category: DeviceCommandCategoryEnum.Miscellaneous,
    type: DeviceCommandTypeEnum.SystemPower,
    actionControl: CommandDevicePowerDTOControlEnum.Reboot,
    label: 'commands.DEVICE_COMMANDS.REBOOT.LABEL',
    icon: 'av:loop',
    startingMsg: 'commands.DEVICE_COMMANDS.REBOOT.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.REBOOT.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: true,
    confirmationTitle: 'commands.DEVICE_COMMANDS.REBOOT.CONFIRM_TITLE',
    confirmationMsg: 'commands.DEVICE_COMMANDS.REBOOT.CONFIRM_MSG',
    confirmationOk: 'commands.DEVICE_COMMANDS.REBOOT.CONFIRM_OK',
    confirmationCancel: 'button.cancel',
    permission: PERMISSIONS.SYSTEM_ACTION,
  },
  [DeviceActionCommandEnum.UpdateDeviceProperties]: {
    action: DeviceActionCommandEnum.UpdateDeviceProperties,
    category: DeviceCommandCategoryEnum.Miscellaneous,
    type: DeviceCommandTypeEnum.UpdateProperties,
    label: 'commands.DEVICE_COMMANDS.UPDATE_DEVICE_PROPERTIES.LABEL',
    icon: 'action:update',
    startingMsg: 'commands.DEVICE_COMMANDS.UPDATE_DEVICE_PROPERTIES.STARTING_MSG',
    endingMsg: 'commands.DEVICE_COMMANDS.UPDATE_DEVICE_PROPERTIES.STOP_MSG',
    dspDeviceOnly: true,
    requiredConfirmation: false,
    permission: PERMISSIONS.GET_DEVICE,
    throttlingTimeInMs: 30 * 1000,
  },
};

// From the endpoint /api/command/codes
export const DEVICE_COMMAND_CATEGORY: Map<number, any> = new Map();
DEVICE_COMMAND_CATEGORY.set(0, { name: 'UPLOAD_CONFIGURATION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(1, { name: 'BACKUP_CONFIGURATION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(2, { name: 'RESTORE_CONFIGURATION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(3, { name: 'CANCEL_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(4, { name: 'COMMAND_STATUS' });
DEVICE_COMMAND_CATEGORY.set(5, { name: 'WEB_DISTRIBUTION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(6, { name: 'UPLOAD_LOGS_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(7, { name: 'GENERATE_LOGS_REPORT_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(10, { name: 'PURGE_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(11, { name: 'CHANNEL_UPDATE_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(23, { name: 'SERVICE_ACTION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(28, { name: 'SYSTEM_ACTION_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(40, { name: 'UPDATE_SOFTWARE_COMMAND' });
DEVICE_COMMAND_CATEGORY.set(42, { name: 'SCRIPT_COMMAND' }); // TODO: need to confirm, duplicate code
DEVICE_COMMAND_CATEGORY.set(42, { name: 'PLAYER_ACTION_COMMAND' }); // TODO: need to confirm, duplicate code
DEVICE_COMMAND_CATEGORY.set(52, { name: 'SET_DEVICE_PROPERTIES' });
DEVICE_COMMAND_CATEGORY.set(53, { name: 'CANCEL_RECOVERY_COMMAND' });
