import {
  CommandControlDTO,
  CommandDevicePowerDTO,
  CommandPlayerAssetsDTO,
  CommandPlayerPlaybackDTO,
  CommandService,
  CommandStatusDTO,
  CommandStatusService,
  CreateDeviceGroupDTO,
  CreateDeviceGroupDTOTypeEnum,
  DeviceCommandService,
  DeviceGroupService,
  PlayerActionCommandDTO,
} from '@activia/cm-api';
import { AnalyticsService, IAnalyticsEvent, TaskPanelService } from '@activia/ngx-components';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  EMPTY,
  exhaustMap,
  forkJoin,
  mapTo,
  merge,
  mergeMapTo,
  Observable,
  retry,
  switchMap,
  take,
  takeUntil,
  timer
} from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import * as CommandsAction from './commands.action';
import { DeviceCommandCompleted } from './commands.action';
import { ErrorHandlingService } from '@amp/error';
import { ScannedActionsSubject, Store } from '@ngrx/store';
import { commandsQuery } from './commands.selectors';
import { CommandsTaskPanelItemComponent } from '../components/commands-task-panel-item/commands-task-panel-item.component';
import { CommandEntityType, IRunningCommand } from '../model/running-command.interface';
import { DeviceActionCommandEnum } from '../model/device-command.enum';
import { DEVICE_COMMANDS } from '../device-commands.constant';
import { HttpResponse } from '@angular/common/http';
import { IDeviceGroupCommand } from '../model/device-group-command.interface';
import { extractIdFromHttpLocationHeaders } from '@amp/utils/common';

/** Interval at which commands are refreshed (i.e. polled from the server for their status) **/
export const COMMAND_REFRESH_INTERVAL_MS = 1000 * 30;

@Injectable()
export class CommandsEffects {
  runDeviceCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommandsAction.RunDeviceCommand),
      mergeMap(({ deviceId, deviceInfo, command, gaCategory }) => {
        const runCommand$ = this._runDeviceCommand$(command.action, deviceId);
        return runCommand$.pipe(
          map(({ commandId, commandStatusId}) => {
            const gaData: IAnalyticsEvent = {
              eventCategory: gaCategory,
              eventAction: command.action,
              eventLabel: `Device ID: ${deviceId}`,
            };
            this.analyticsService.trackEvent(gaData);

            // return the success action
            const runningCommand: IRunningCommand = {
              action: command.action,
              commandId,
              commandStatusId,
              entityId: deviceId,
              entity: deviceInfo,
              entityType: CommandEntityType.DEVICE,
            };

            return CommandsAction.RunDeviceCommandSuccess({ runningCommand });
          }),
          catchError((e) => {
            this.errorHandlingService.catchError(e, undefined, 'commands.DEVICE_COMMANDS.ERROR.RUN_COMMAND');
            return EMPTY;
          })
        );
      })
    )
  );

  runDeviceGroupCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommandsAction.RunDeviceGroupCommand),
      switchMap(({ command, deviceGroup, devices, gaCategory }) => {
        const dg: CreateDeviceGroupDTO = {
          name: deviceGroup.name,
          parentGroupId: deviceGroup.parentGroupId,
          filter: deviceGroup.filter,
          type: deviceGroup.type as any as CreateDeviceGroupDTOTypeEnum,
        };
        const deviceGroup$ = this.deviceGroupService.createDeviceGroup(dg, 'response').pipe(
          map((resp) => extractIdFromHttpLocationHeaders(resp.headers)),
          switchMap((deviceGrpId) => this.deviceGroupService.getDeviceGroupById(deviceGrpId)),
        );
        return deviceGroup$.pipe(
          switchMap((deviceGrp) => {
            const createdDeviceGroup = { ...deviceGroup, ...deviceGrp };
            return this._runDeviceGroupCommand$(command, createdDeviceGroup.id).pipe(
              map((response) => {
                const gaData: IAnalyticsEvent = {
                  eventCategory: gaCategory,
                  eventAction: command,
                  eventLabel: `Device Group ID: ${createdDeviceGroup.id}`,
                };
                this.analyticsService.trackEvent(gaData);

                // TODO: CMUI-4405 - need new endpoint that returns list of device command statuses
                const runningCommand: IRunningCommand = {
                  action: command,
                  commandId: response.commandId,
                  commandStatusId: response.commandStatusId,
                  entityId: createdDeviceGroup.id,
                  entity: createdDeviceGroup,
                  entityType: CommandEntityType.DEVICE_GROUP,
                  devices,
                };

                return CommandsAction.RunDeviceCommandSuccess({ runningCommand });
              }),
            );
          }),
          catchError((e) => {
            this.errorHandlingService.catchError(e, undefined, 'commands.DEVICE_COMMANDS.ERROR.RUN_COMMAND');
            return EMPTY;
          })
        );
      })
    )
  );

  /**
   * Once a command is run successfully:
   * - we immediately retrieve its command status using the resource links provided by the command creation
   * - we add it to the task panel
   */
  fetchCommandStatusWhenSuccessfullyRun$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommandsAction.RunDeviceCommandSuccess),
      tap(() => this.taskPanelService.addTaskComponent(CommandsTaskPanelItemComponent)),
      mergeMap(({ runningCommand }) => forkJoin([this.commandStatusService.getCommandStatusById(runningCommand.commandStatusId), this.commandService.getCommandById(runningCommand.commandId)]).pipe(
          map(([commandStatus, deviceCommand]) => {
            const isCommandCompleted = !!commandStatus.endDate;
            if (isCommandCompleted) {
              return CommandsAction.DeviceCommandCompleted({ commandStatus, runningCommand });
            } else {
              return CommandsAction.DeviceCommandUpdateStatus({ commandStatus, deviceCommand });
            }
          }),
          catchError((err) => {
            this.errorHandlingService.catchError(err, undefined, 'commands.DEVICE_COMMANDS.ERROR.FETCH_COMMAND_STATUS');
            return EMPTY;
          })
        ))
    )
  );

  /**
   * Once a command is run successfully, it cannot be ran again until its finished.
   * Also there may be a throttling time once completed
   */
  throttleCommands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommandsAction.RunDeviceCommandSuccess),
      mergeMap(({ runningCommand }) => {
        // throttle the command immediately
        this.store.dispatch(CommandsAction.DeviceCommandThrottle({ runningCommand }));
        const commandThrottleTimeMs = Object.values(DEVICE_COMMANDS).find((c) => c.action === runningCommand.action)?.throttlingTimeInMs || 0;

        const commandCompleted$ = this._actionsSubject.pipe(
          filter((action) => action.type === DeviceCommandCompleted.type),
          filter((action: ReturnType<typeof DeviceCommandCompleted>) => action.commandStatus.commandId === runningCommand.commandId)
        );
        return commandCompleted$.pipe(take(1), mergeMapTo(timer(commandThrottleTimeMs).pipe(map(() => CommandsAction.DeviceCommandUnthrottle({ runningCommand })))));
      })
    )
  );

  deviceCommandCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommandsAction.DeviceCommandCompleted),
      switchMap(({ runningCommand }) => runningCommand.entityType === CommandEntityType.DEVICE_GROUP && (runningCommand.entity as IDeviceGroupCommand).isTemporary ?
          this.deviceGroupService.deleteDeviceGroupById(runningCommand.entityId) : EMPTY),
    ),
    { dispatch: false }
  );

  /**
   * Periodically refresh the running commands statuses by polling the server at given refresh interval.
   * The polling for a command will terminate once the command is completed (could be completed by a incoming push notification)
   */
  refreshRunningCommands$ = createEffect(
    () =>
      timer(0, COMMAND_REFRESH_INTERVAL_MS).pipe(
        exhaustMap(() =>
          this.store.select<IRunningCommand[]>(commandsQuery.getRunningCommands).pipe(
            take(1),
            filter((runningCommands) => runningCommands.length > 0),
            mergeMap((runningCommands) => {
              const pollRunningCommands$ = runningCommands.map((runningCommand) => this._pollRunningCommand$(runningCommand));
              return merge(...pollRunningCommands$);
            })
          )
        ),
        catchError(() => EMPTY)
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private analyticsService: AnalyticsService,
    private commandService: CommandService,
    private commandStatusService: CommandStatusService,
    private deviceCommandService: DeviceCommandService,
    private deviceGroupService: DeviceGroupService,
    private errorHandlingService: ErrorHandlingService,
    private taskPanelService: TaskPanelService,
    private _actionsSubject: ScannedActionsSubject
  ) {
    // clear the completed tasks from the store when the task panel is closed
    this.taskPanelService.taskPanelClosed.subscribe(() => this.store.dispatch(CommandsAction.DeviceCommandClearCompleted()));
  }

  /** @ignore Fetches the command status of a running command **/
  private _pollRunningCommand$(runningCommand: IRunningCommand): Observable<CommandStatusDTO> {
    return this.commandStatusService.getCommandStatusById(runningCommand.commandStatusId).pipe(
      tap((commandStatus) => {
        const isCommandCompleted = !!commandStatus.endDate;

        if (isCommandCompleted) {
          this.store.dispatch(CommandsAction.DeviceCommandCompleted({ commandStatus, runningCommand }));
        } else {
          this.store.dispatch(CommandsAction.DeviceCommandUpdateStatus({ commandStatus }));
        }
      }),
      retry(3),
      // just do nothing when there is an error
      catchError((err) => {
        this.errorHandlingService.catchError(err, undefined, 'commands.DEVICE_COMMANDS.ERROR.FETCH_COMMAND_STATUS');
        return EMPTY;
      }),
      // if command ran successfully before the api call is completed (via incoming notifications in the web socket), just cancel to avoid toast dups
      takeUntil(this._commandNotRunningAnymore(runningCommand))
    );
  }

  /** Emits only when the specified command is not running anymore **/
  private _commandNotRunningAnymore(runningCommand: IRunningCommand): Observable<true> {
    return this.store.select(commandsQuery.getRunningCommands).pipe(
      filter((runningCommands) => {
        const isCommandRunning = runningCommands.some((command) => command.commandStatusId === runningCommand.commandStatusId);
        return !isCommandRunning;
      }),
      mapTo(true)
    );
  }

  private _runDeviceCommand$(action: DeviceActionCommandEnum, deviceId: number): Observable<{ commandId: number; commandStatusId: number; response: PlayerActionCommandDTO }> {
      const payload = { schedule: (new Date()).toISOString() };
      let command$: Observable<HttpResponse<PlayerActionCommandDTO>>;

    switch (action) {
      case DeviceActionCommandEnum.Mute: {
        command$ = this.deviceCommandService.runDeviceMuteCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.Unmute: {
        command$ = this.deviceCommandService.runDeviceMuteCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.DisplayOff: {
        command$ = this.deviceCommandService.runDeviceDisplayCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.DisplayOn: {
        command$ = this.deviceCommandService.runDeviceDisplayCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.StartPlayback: {
        command$ = this.deviceCommandService.runDevicePlaybackCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.StopPlayback: {
        command$ = this.deviceCommandService.runDevicePlaybackCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.RestartPlayback: {
        command$ = this.deviceCommandService.runDevicePlaybackCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.FlushAssets: {
        command$ = this.deviceCommandService.runDeviceAssetsCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerAssetsDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.RebootDevice: {
        command$ = this.deviceCommandService.runDevicePowerCommand(deviceId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandDevicePowerDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.UpdateDeviceProperties: {
        command$ = this.deviceCommandService.runDevicePropertiesUpdateCommand(deviceId, { ...payload, override: false }, 'response');
        break;
      }
    }

    if (command$) {
      return command$.pipe(
        map((response) => {
          const commandId = extractIdFromHttpLocationHeaders(response.headers);
          const resp = response.body as PlayerActionCommandDTO;
          return { commandId, commandStatusId: resp.id, response: resp };
        })
      );
    } else {
      throw new Error(`Command / Action not supported: ${action}`);
    }
  }

  private _runDeviceGroupCommand$(action: DeviceActionCommandEnum, deviceGroupId: number): Observable<{ commandId: number; commandStatusId: number; response: PlayerActionCommandDTO }> {
    const payload = { schedule: (new Date()).toISOString() };
    let command$: Observable<HttpResponse<PlayerActionCommandDTO>>;

    switch (action) {
      case DeviceActionCommandEnum.Mute: {
        command$ = this.deviceCommandService.runDeviceGroupMuteCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.Unmute: {
        command$ = this.deviceCommandService.runDeviceGroupMuteCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.DisplayOn: {
        command$ = this.deviceCommandService.runDeviceGroupDisplayCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.DisplayOff: {
        command$ = this.deviceCommandService.runDeviceGroupDisplayCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandControlDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.StartPlayback: {
        command$ = this.deviceCommandService.runDeviceGroupPlayerCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.StopPlayback: {
        command$ = this.deviceCommandService.runDeviceGroupPlayerCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.RestartPlayback: {
        command$ = this.deviceCommandService.runDeviceGroupPlayerCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerPlaybackDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.FlushAssets: {
        command$ = this.deviceCommandService.runDeviceGroupAssetsCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandPlayerAssetsDTO, 'response');
        break;
      }
      case DeviceActionCommandEnum.RebootDevice: {
        command$ = this.deviceCommandService.runDeviceGroupPowerCommand(deviceGroupId, { ...payload, control: DEVICE_COMMANDS[action].actionControl } as CommandDevicePowerDTO, 'response');
        break;
      }
    }
    if (command$) {
      return command$.pipe(
        map((response) => {
          const commandId = extractIdFromHttpLocationHeaders(response.headers);
          const resp = response.body as PlayerActionCommandDTO;
          return { commandId, commandStatusId: resp.id, response: resp };
        })
      );
    } else {
      throw new Error(`Command / Action not supported: ${action}`);
    }
  }
}
