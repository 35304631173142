import { ThemeType } from '@activia/ngx-components';

/**
 * See https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values for more info
 */
export enum ContentStatus {
  OK = 0,
  WARNING = 1,
  ERROR = 2,
}

export const ContentStatuses: Record<ContentStatus, { label: string, theme: ThemeType }> = {
  [ContentStatus.ERROR]: {
    label: 'ERROR',
    theme: ThemeType.DANGER,
  },
  [ContentStatus.WARNING]: {
    label: 'WARNING',
    theme: ThemeType.WARNING,
  },
  [ContentStatus.OK]: {
    label: 'OK',
    theme: ThemeType.SUCCESS,
  },
};

/** The statuses ordered by severity (high to low) **/
export const ContentStatusSeverity = [ContentStatus.ERROR, ContentStatus.WARNING, ContentStatus.OK];
