/**
 * util function to prepare site count for pie chart
 * - health status pie chart
 * - content status pie chart
 */

import { IPieDataItem, themeTypeToCssVar } from '@activia/dataviz';
import { ContentStatus, ContentStatuses, HealthStatusCode, HealthStatusThemes } from '@amp/devices';
import { TranslocoService } from '@ngneat/transloco';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { combineOptimisticData } from './site-monitoring-optimistic-view.utils';
import { CONTENT_STATUS_OPTIMISTIC_COMBINATIONS, HEALTH_STATUS_OPTIMISTIC_COMBINATIONS } from '../model/optimistic-view-status-combinations';
import { enumToMap } from '@activia/ngx-components';

/** Cauculate site count for each content status, pie data item*/
export const parseContentStatus = (data: CountAggregationData<ContentStatus>, optimistic: boolean, translate: TranslocoService): IPieDataItem[] => {
  if (optimistic) {
    data = combineOptimisticData(data, CONTENT_STATUS_OPTIMISTIC_COMBINATIONS);
  }
  const contentStatusDataSource = [];

  const ok: IPieDataItem = {
    value: data[ContentStatus.OK.toString()] || 0,
    name: translate.translate('siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.CONTENT_STATUS.OK_20'),
    color: themeTypeToCssVar(ContentStatuses[ContentStatus.OK].theme),
    data: { statusCode: ContentStatus.OK },
  };

  const error: IPieDataItem = {
    value: data[ContentStatus.ERROR.toString()] || 0,
    name: translate.translate('siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.CONTENT_STATUS.ERROR_20'),
    color: themeTypeToCssVar(ContentStatuses[ContentStatus.ERROR].theme),
    data: { statusCode: ContentStatus.ERROR },
  };

  const warning: IPieDataItem = {
    value: data[ContentStatus.WARNING.toString()] || 0,
    name: translate.translate('siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.CONTENT_STATUS.WARNING_20'),
    color: themeTypeToCssVar(ContentStatuses[ContentStatus.WARNING].theme),
    data: { statusCode: ContentStatus.WARNING },
  };

  [ok, error, warning]
    .filter((status) => status.value > 0)
    .forEach((status) => {
      contentStatusDataSource.push(status);
    });

  return contentStatusDataSource;
};

/**
 * calculate site count for each health status, pie data item
 *
 * @param data Site health status count
 * @param optimistic Optimistic view
 * @param translate Transloco service
 * @param removeZeroCount Set to true to remove status with 0 count
 * @param sort Sort the returned result in the order specified. Must provide order index for each health status.
 */
export const parseHealthStatus = (
  data: CountAggregationData<HealthStatusCode>,
  optimistic: boolean,
  translate: TranslocoService,
  removeZeroCount = true,
  sort?: Map<number, number>
): IPieDataItem[] => {
  if (optimistic) {
    data = combineOptimisticData(data, HEALTH_STATUS_OPTIMISTIC_COMBINATIONS);
  }

  let healthStatusDataSource = convertHealthStatus(optimistic, translate, sort);
  healthStatusDataSource.forEach((status) => {
    status.value = data[status.data.statusCode.toString()] || 0;
  });
  if (removeZeroCount) {
    healthStatusDataSource = healthStatusDataSource.filter((status) => status.value > 0);
  }

  return healthStatusDataSource;
};

/**
 * Convert each health status to IPieDataItem.
 *
 * @param optimistic Optimistic view
 * @param translate Transloco service
 * @param sort Sort the returned result in the order specified. Must provide order index for each health status.
 */
export const convertHealthStatus = (optimistic: boolean, translate: TranslocoService, sort?: Map<number, number>): IPieDataItem[] => {
  const healthStatusDataSource = [];
  enumToMap<number>(HealthStatusCode).forEach((value, key) => {
    if (!HEALTH_STATUS_OPTIMISTIC_COMBINATIONS.has(value) || !optimistic) {
      healthStatusDataSource.push({
        value: 0,
        name: translate.translate(`siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.${key}.TITLE_20`),
        color: themeTypeToCssVar(HealthStatusThemes[`${value}`], HealthStatusCode[key] === HealthStatusCode.UNREACHABLE ? 'variant' : undefined),
        data: { statusName: key, statusCode: value },
      });
    }
  });
  if (sort) {
    healthStatusDataSource.sort((a: IPieDataItem, b: IPieDataItem) => sort.get(a.data.statusCode) - sort.get(b.data.statusCode));
  }
  return healthStatusDataSource;
};
