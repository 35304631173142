<avn-data-table
  [attr.data-e2e]="'shared-devices-table'"
  [columns]="columnsSource$ | async"
  [dataSource]="dataSource$ | async"
  [dataSourceConfig]="dataSourceConfig"
  [rowHeight]="80"
  [keyboardNavigationEnabled]="false"
  [isStaticDataSource]="true"
  [forceLoadingState]="sharedListsLoading$ | async"
  [showHeader]="(dataSource$ | async)?.totalRowCount > 0"
  [selectionMode]="selectionMode"
  [selectedItemIds]="selections || []"
  [themeIndicatorWidthPx]="3"
  (rowClicked)="onListSelected($event)"
  (selectionChanged)="onSelectionChanged($event)"
>

  <ng-template #descriptionTemplate let-row="row" let-rowIndex="rowIndex">
    <ng-container *ngIf="row.description || row.filter">
      <span class="description"
            [attr.data-e2e]="'description'"
            *ngIf="row.description; else nlpDescription">{{ row.description }}</span><br />
    </ng-container>
    <ng-template #nlpDescription>
      <span class="description"
            [attr.data-e2e]="'description-nlp'"
            *ngIf="nlpDisplayExpressionMap.get(row.id)" [title]="nlpDisplayExpressionMap.get(row.id)">
        {{ nlpDisplayExpressionMap.get(row.id) }}
      </span>
    </ng-template>
  </ng-template>

  <ng-template #nameDescriptionTemplate let-row="row" let-rowIndex="rowIndex">
    <p class="list-description">
      <span class="name" [attr.data-e2e]="'list-description-name'">{{ row.name }}</span><br />
      <ng-container *ngTemplateOutlet="descriptionTemplate; context: { row: row }"></ng-container>
    </p>
  </ng-template>

  <ng-template #deviceCountTemplate let-row="row" let-rowIndex="rowIndex">
    <ng-container *ngIf="listDeviceCount.get(row.id) | async as countInfo">
      <div *ngIf="countInfo.loaded; else deviceCountNotLoaded" class="count-container">
        <avn-icon
          class="trend-icon"
          [attr.data-e2e]="'trend-icon'"
          *ngIf="countInfo.countDifference !== null && countInfo.countDifference !== 0"
          icon="action:trending_flat"
          [class.up]="countInfo.countDifference > 0"
          [class.down]="countInfo.countDifference < 0"
          avnTheme="secondary"
        ></avn-icon>
        <div class="count" [attr.data-e2e]="'count'">
          {{ countInfo.count }}
          <span *ngIf="countInfo.countDifference !== null && countInfo.countDifference !== 0" class="difference"
          >({{ countInfo.countDifference > 0 ? '+' : '' }}{{ countInfo.countDifference }})</span
          >
        </div>
      </div>
      <ng-template #deviceCountNotLoaded>
        <ng-container *ngIf="countInfo.error; else deviceCountLoading">
          <avn-button
            [attr.data-e2e]="'retry-btn'"
            [label]="'deviceFields.DEVICE.SHARED_DEVICES.RETRY_50' | transloco"
            avnTheme="warning"
            icon="navigation:refresh"
            iconPosition="right"
            (click)="$event.stopPropagation(); onRetryListCount(countInfo.listId)"
          ></avn-button>
        </ng-container>
      </ng-template>
      <ng-template #deviceCountLoading>
        <avn-icon icon="notification:sync"
                  [attr.data-e2e]="'sync-icon'"
                  [rotate]="true"
                  [fontSize]="16"></avn-icon>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #columnCountTemplate let-row="row">
    <div class="count" [attr.data-e2e]="'count'">
      {{ row.columns.length }}
    </div>
  </ng-template>

  <ng-template #activeTemplate let-row="row">
    <avn-switch [avnTheme]="'primary'"
                [attr.data-e2e]="'switch-btn'"
                [status]="row.active" (statusChanged)="updateStatus($event, row)"
                (click)="$event.stopPropagation()"></avn-switch>
  </ng-template>

  <ng-template #ownerTemplate let-row="row">
    <strong>{{ row?.owner }}</strong>
  </ng-template>

  <ng-template #actionsTemplate let-row="row">
    <ng-container *ngTemplateOutlet="tableActions; context: { row: row }"></ng-container>
  </ng-template>

  <avn-empty-data-message
    icon="image:grid_off"
    [attr.data-e2e]="'empty-data-message'"
    [text]="'deviceFields.DEVICE.SHARED_DEVICES.EMPTY.EMPTY_TITLE_100' | transloco"
    [subText]="emptyMessage"
  >
    <ng-container *ngTemplateOutlet="emptyMessageActions"></ng-container>
  </avn-empty-data-message>
</avn-data-table>
