// prettier-ignore
import {
  Day,
  EncFanCount,
  EncFantime,
  EncFanType,
  EncHumid0,
  EncHumid1,
  EncMode,
  EncPower0,
  EncPower1,
  EncPressure,
  EncPwm0,
  EncPwm1,
  EncTemperatureAim,
  EncTemperatureEx,
  EncTemperatureIn,
  EncTemperatureMax,
  EncTemperatureMin,
  EncType,
  EncVersion,
  Hostname,
  Hour,
  InterfaceMac,
  IpAddress,
  Minute,
  Month,
  MvAgentVersion,
  MvHealthStatus,
  MvLastAttempt,
  MvLastUpdate,
  CodLastUpdate,
  CodState,
  PosLastUpdate,
  PosLastModified,
  PosState,
  MvPl,
  MvLastContentUpdate,
  MvLastContentFailedUpdate,
  MvLastContentUpTodate,
  MvPlayerCsMsg,
  MvPlayerSt,
  MvPlayerStMsg,
  MvPlayerSvc,
  MvPlayerSvcMsg,
  MvPlayerVersion,
  MvSpls,
  MvSysCpu,
  MvSysDisk,
  MvSysMem,
  NlpOperationalState,
  Second,
  Serial,
  SiteManager,
  TypeBrand,
  TypeModel,
  TypeSeries,
  Week,
  Year,
  MvPlayerContentStatus,
  SiteAddressCity,
  SiteAddressState,
  SiteAddressZip,
  SiteAddressCountry,
  SiteExternalId,
  Version
} from './device-filter/device-filter.tokens';

export const NLP_DEVICE_PROPERTIES_FIELDS = [
  Hostname,
  IpAddress,
  NlpOperationalState,
  Serial,
  SiteAddressCity,
  SiteAddressState,
  SiteAddressZip,
  SiteAddressCountry,
  SiteExternalId,
  TypeBrand,
  TypeModel,
  TypeSeries,
  InterfaceMac,
  Version,
];

/** Those fields should be match with availables monitoring fields from backend */
export const NLP_DEVICE_MONITORING_FIELDS = [
  MvPlayerVersion,
  MvAgentVersion,
  MvSpls,
  MvPlayerStMsg,
  MvPlayerCsMsg,
  MvPlayerSvcMsg,
  MvSysCpu,
  MvSysMem,
  MvSysDisk,
  MvPlayerSvc,
  MvPlayerContentStatus,
  MvLastContentUpdate,
  MvLastContentFailedUpdate,
  MvLastContentUpTodate,
  MvPlayerSt,
  MvPl, // put here because the pattern matches the start of other tokens
  MvHealthStatus,
  MvLastUpdate,
  MvLastAttempt,
];

export const NLP_DEVICE_SYSTEM_PLUGINS_FIELDS = [CodLastUpdate, CodState, PosLastUpdate, PosLastModified, PosState];

export const NLP_DEVICE_ENCLOSURE_FIELDS = [
  EncFanCount,
  EncFanType,
  EncVersion,
  EncType,
  EncFantime,
  EncHumid0,
  EncHumid1,
  EncMode,
  EncPower0,
  EncPower1,
  EncPressure,
  EncPwm0,
  EncPwm1,
  EncTemperatureMin,
  EncTemperatureMax,
  EncTemperatureAim,
  EncTemperatureEx,
  EncTemperatureIn,
];

export const NLP_DEVICE_OTHER_FIELDS = [SiteManager];

export const NLP_TIME_UNITS = [Second, Minute, Hour, Day, Week, Month, Year];
