<ng-container *transloco="let t">
  <avn-page>
    <avn-main avnResponsiveElement>
      <avn-loader *ngIf="loading$ | async; else configForm" avnTheme="primary" [isLoading]="loading$ | async"></avn-loader>

      <ng-template #configForm>
        <form *ngIf="selectedProfile$ | async as selectedProfile" [formGroup]="applicationProfiles[selectedProfile]?.form">
          <div class="side-menu">
            <avn-grid [cols]="menuCols" [gridRowHeight]="30" [gap]="10">
              <ng-container *avnShowForDevices="['tablet', 'web', 'huge', 'yuge', 'gigantic']">
                <ng-container *ngTemplateOutlet="profile"></ng-container>
                <ng-container *ngTemplateOutlet="categories"></ng-container>
                <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
              </ng-container>
              <ng-container *avnShowForDevices="'handset'">
                <ng-container *ngTemplateOutlet="categories; context: { isHandset: true }"></ng-container>
                <div>
                  <ng-container *ngTemplateOutlet="profile; context: { isHandset: true }"></ng-container>
                  <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
                </div>
              </ng-container>
            </avn-grid>
          </div>

          <div class="content" avnScroll>
            <avn-grid [cols]="gridColumns" [gridRowHeight]="100">
              <div
                *ngFor="let category of siteColumnsCategories"
                avnGridElement
                [id]="category.id"
                [class.disabled]="(isAdmin$ | async) === false"
                class="category">

                <avn-widget
                  [headingTitle]="t('siteMonitoringSharedScope.' + category.label)"
                  [showCloseButton]="false"
                >
                  <div class="title">
                    <h6>{{ t('siteMonitoringSharedScope.SITE_MONITORING.SITE_SETTINGS.COLUMN_NAME_20') }}</h6>
                    <h6>{{ t('siteMonitoringSharedScope.SITE_MONITORING.SITE_SETTINGS.DISPLAY_NAME_20') }}</h6>
                  </div>
                  
                  <ng-container *ngFor="let column of category.children">
                    <div class="field" *ngIf="column.allowRename">
                      <label>{{ t('siteMonitoringSharedScope.' + column.name + (column.type === DeviceColumnType.MonitoredValue ? '.TITLE_35' : '')) }}</label>
                      <avn-form-field>
                        <avn-input [formControlName]="column.id" [type]="'text'"></avn-input>
                      </avn-form-field>
                    </div>
                  </ng-container>
                </avn-widget>
              </div>
            </avn-grid>
          </div>

          <ng-template #categories let-isHandset="isHandset">
            <div class="section categories" [class.isHandset]="isHandset">
              <h5>{{ t('siteMonitoringSharedScope.SITE_MONITORING.CATEGORIES.TITLE_50') }}</h5>
              <div *ngFor="let category of siteColumnsCategories" (click)="navigateToSection(category.id)">
                {{ t('siteMonitoringSharedScope.' + category.label) }}
              </div>
            </div>
          </ng-template>

          <ng-template #profile let-isHandset="isHandset">
            <div class="section profile" [class.isHandset]="isHandset">
              <h5>{{ t('siteMonitoringSharedScope.SITE_MONITORING.PROFILES.TITLE_20') }}</h5>
              <avn-select
                [options]="profiles | toSelectOption"
                [value]="selectedProfile"
                (change)="onProfileChange($event.value)">
              </avn-select>
              <div class="active-profile" [class.disabled]="(isAdminOrNetworkAdmin$ | async) === false">
                <avn-switch
                  [avnTheme]="'primary'"
                  [status]="(selectedProfile$ | async) === activeProfileSelected"
                  [disabled]="(isAdminOrNetworkAdmin$ | async) === false"
                  (statusChanged)="onActiveProfileChanged($event.status)">
                </avn-switch>
                <label>{{ t('siteMonitoringSharedScope.SITE_MONITORING.PROFILES.ACTIVATE_PROFILE_60',  { profile: selectedProfile }) | titlecase }}</label>
              </div>
            </div>
          </ng-template>

          <ng-template #actionButtons>
            <div class="action-buttons" *ngIf="isAdminOrNetworkAdmin$ | async">
              <avn-button
                [attr.data-e2e]="'reset'"
                [label]="t('button.reset')"
                [disabled]="!(saveEnabled)"
                (actioned)="cancel()">
              </avn-button>
              <avn-button
                [attr.data-e2e]="'save'"
                avnTheme="primary"
                [label]="t('button.save')"
                [disabled]="!(saveEnabled)"
                (actioned)="save()">
              </avn-button>
            </div>
          </ng-template>
        </form>
      </ng-template>
    </avn-main>
  </avn-page>
</ng-container>
