import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { EngineTagLevel } from 'libs/features/shared/tag-operation/src/lib/model/operation-scope.interface';
import { ButtonModule } from '@activia/ngx-components';
import { TranslocoModule } from '@ngneat/transloco';
import { TagValueItemComponent } from 'libs/features/shared/tag-operation/src/lib/components/engine-tag-value-detail/tag-value-item/tag-value-item.component';
import { IEngineTagKeyDesc, IEngineTagValue } from '@amp/tag-operation';
import { IExpressionSuggestionsResult, NlpOverlayComponent, NlpOverlayService } from '@activia/ngx-components/nlp';
import { EMPTY, catchError } from 'rxjs';
import { TagKeyDescDTO } from '@activia/cm-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'amp-nlp-tag-picker',
  templateUrl: './nlp-tag-picker.component.html',
  styleUrls: ['./nlp-tag-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, TranslocoModule, TagValueItemComponent],
})
export class NlpTagPickerComponent extends NlpOverlayComponent<string> implements OnInit {
  @Input() tagKeyPrefix: string;
  @Input() tagLevel: EngineTagLevel;
  @Input() tagKeyRawObject: { [key: string]: TagKeyDescDTO };

  private _destroyRef = inject(DestroyRef);
  private _changeDetector = inject(ChangeDetectorRef);
  tag: IEngineTagValue;
  value: string;

  constructor(private _nlpOverlayService: NlpOverlayService) {
    super(_nlpOverlayService);
    this._nlpOverlayService.setAllowCloseOnOutsideClick(false);
  }

  handleChange(schema) {
    this.value = schema.values;
  }

  applyChanges() {
    this._nlpOverlayService.setAllowCloseOnOutsideClick(true);
    this._nlpOverlayService.setValueSelected(`"${this.value}"`, undefined, false);
  }

  ngOnInit(): void {
    this.activated$
      .pipe(
        catchError((error) => {
          console.error('Error in subscription:', error);
          return EMPTY;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((data) => {
        const tagKey = data?.currentFieldExpression?.field?.image;
        const tagKeyWithoutPrefixTrimmed = tagKey.replace(this.tagKeyPrefix, '').trim();
        const tagKeyDesc = this.tagKeyRawObject[tagKeyWithoutPrefixTrimmed];
        this.tag = this._convertToTagValue(tagKeyWithoutPrefixTrimmed, tagKeyDesc);
        this._changeDetector.detectChanges();
      });
  }

  private _convertToTagValue(tagKey: string, tagKeyDesc: TagKeyDescDTO): IEngineTagValue {
    return {
      key: tagKey,
      propertyType: this.tagLevel,
      values: undefined,
      keyDescription: tagKeyDesc as IEngineTagKeyDesc,
    };
  }

  protected parseValue(res: IExpressionSuggestionsResult): string {
    if (!res.currentFullFieldExpression.values || res.currentFullFieldExpression.values.length === 0) {
      return '';
    }
    const currentValue = res.currentFullFieldExpression.values[0].image;
    return currentValue;
  }
}
