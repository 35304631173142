import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiteMonitoringDetailRoutingService } from '../site-monitoring-detail-routing.service';
import { IBoardDeviceAlarmInfo, ICombinedDeviceInfo, IDeviceToDisplayConnection, ILogicalPlayerInfo } from '../store/site-monitoring-detail.model';
import {
  AlarmLevelsInfo,
  DeviceMonitoringData,
  getDeviceStatusWithTheme,
  getHighestSeverityLevelInfo,
  IAlarmEventDisplayConfig,
  IAlarmEventLevelInfo,
  MonitoredValue,
  AlarmEventNamespaceRegex,
  AlarmEventNamespace,
} from '@amp/devices';
import { BoardDTO } from '@activia/cm-api';
import { ConnectorLineDirectiveAnchor, ConnectorLineService } from '@activia/dataviz';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { Observable } from 'rxjs';
import { ThemeType } from '@activia/ngx-components';
import { map } from 'rxjs/operators';

@Component({
  selector: 'amp-site-monitoring-device',
  templateUrl: './site-monitoring-device.component.html',
  styleUrls: ['./site-monitoring-device.component.scss'],
})
export class SiteMonitoringDeviceComponent implements OnChanges, AfterViewInit {
  /** Board the device is currently displayed on **/
  @Input() board: BoardDTO;

  /** Info about the current device **/
  @Input() deviceInfo: ICombinedDeviceInfo;

  /** Indicates if the view device button should be displayed or not **/
  @Input() viewDeviceEnabled: boolean;

  deviceHealthData: { label: string; theme: string; healthErrorIds: string };
  networkIPs: string;

  /** Indicates the player for which the alerts are currently shown in connected modal **/
  playerWithAlertsModalOpened: ILogicalPlayerInfo;

  alarmDisplayConfig$: Observable<IAlarmEventDisplayConfig>;
  showHealthErrorAlarms$: Observable<boolean>;
  showIpAddress$: Observable<boolean>;
  showNetworkIpAddresses$: Observable<boolean>;

  constructor(
    public elementRef: ElementRef,
    private _innerService: SiteMonitoringDetailRoutingService,
    private _connectorLineService: ConnectorLineService,
    private _siteMonitoringFacade: SiteMonitoringFacade
  ) {
    this.alarmDisplayConfig$ = this._siteMonitoringFacade.alarmDisplayConfig$;
    this.showHealthErrorAlarms$ = this._siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.showHealthErrorAlarms));
    this.showIpAddress$ = this._siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.showIpAddress));
    this.showNetworkIpAddresses$ = this._siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.showNetworkIpAddresses));
  }

  ngAfterViewInit() {
    // hack to compensate for the full screen fade in animation. Redraw the connector lines.
    setTimeout(() => {
      this._connectorLineService.updateLinesPosition();
    }, 350);
  }

  ngOnChanges({ deviceInfo }: SimpleChanges): void {
    if (deviceInfo && deviceInfo.currentValue) {
      const deviceMonitoringData: DeviceMonitoringData = deviceInfo.currentValue.monitoringData;
      const healthStatus = deviceMonitoringData[MonitoredValue.HealthStatus];
      const status = getDeviceStatusWithTheme(healthStatus);
      this.deviceHealthData = {
        label: status.label,
        theme: status.theme,
        healthErrorIds: deviceMonitoringData.HEALTH_ERROR_IDS,
      };
      this.networkIPs = deviceMonitoringData[MonitoredValue.ConfSystemNetwork];
    }
  }

  goToDeviceDetail(id) {
    this._innerService.navigate(id);
  }

  showPlayerStatus($event: MouseEvent, player: ILogicalPlayerInfo) {
    // when the player is unreachable, we dont show the extra player errors as they may not be valid anymore
    if (player.alarms.length > 0) {
      $event.preventDefault();
      $event.stopPropagation();
      this.playerWithAlertsModalOpened = player;
    }
  }

  onToggle(opened: boolean) {
    if (!opened) {
      this.playerWithAlertsModalOpened = null;
    }
  }

  getConnectorLinesAnchors(connections: IDeviceToDisplayConnection[]): ConnectorLineDirectiveAnchor[] {
    // there can be multiple connections from the same player, so create multiple anchors
    // also the device can feed displays on several boards, so make sure to show connector for current board only
    return connections
      .filter((connection) => connection.boardId === this.board.id)
      .map((connection) => ({
        id: connection.boardId + '-' + connection.deviceId + '-' + connection.devicePlayerId + '-' + connection.displayId + '-' + connection.displayInput,
        type: 'start',
        group: `${connection.deviceId}`,
        lineOptions: { startSocket: 'bottom', endSocket: 'top', zIndex: 501 },
      }));
  }

  getPlayerAlertIconInfo(player: ILogicalPlayerInfo): IAlarmEventLevelInfo {
    if (player.alarms.length === 0) {
      return { icon: 'action:check_circle', theme: ThemeType.SUCCESS, shade: 500 };
    }
    // get the icon of the highest alarm level reported
    const allLevels = player.alarms.map((alarm) => alarm.data.level).sort();
    return AlarmLevelsInfo[allLevels[0]] || {};
  }

  logicalPlayerTrackBy(_, player: ILogicalPlayerInfo) {
    return player.id;
  }

  getPlayersLayout(playerCount: number) {
    if (playerCount <= 1) {
      return 'calc(var(--player-width) * 2)';
    }
    return 'repeat(' + playerCount + ', var(--player-width))';
  }

  getLevelIconThemeInfo(alarms: IBoardDeviceAlarmInfo[]) {
    const alarmInfo = getHighestSeverityLevelInfo(alarms.map((alarm) => alarm.data));
    if (!alarmInfo) {
      return null;
    }

    return {
      color: {
        theme: alarmInfo?.theme,
        shade: alarmInfo?.shade,
      },
    };
  }

  showPlayerAlarmNamespace(alarm: IBoardDeviceAlarmInfo): boolean {
    const isPlayerNamespace = alarm.data?.namespace?.match(AlarmEventNamespaceRegex[AlarmEventNamespace.Player]);
    return !isPlayerNamespace;
  }

  filterHealthErrorAlarms(alarms: IBoardDeviceAlarmInfo[], showHealthErrors): IBoardDeviceAlarmInfo[] {
    if (showHealthErrors) {
      return alarms;
    }
    return alarms.filter(({ alarm }) => alarm !== 'device-health-error');
  }
}
