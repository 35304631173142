<div class="description">
  <avn-icon icon="file:download"></avn-icon>
  <div>
    <p>
      {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.UPDATE_DESCRIPTION_TEXT_1_200' | transloco }}
    </p>
    <p>
      <a
        (click)="downloadCsvSites.emit()"
        [class.disabled]="!downloadCsvSitesEnabled"
        [avnTooltip]="downloadCsvSitesEnabled ? '' : ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TOOLTIP_UPLOAD_CSV_FILE_DISABLED_50' | transloco)"
        >{{
          'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.UPDATE_DESCRIPTION_LINK_1_200'
            | transloco
              : {
                  selectedSites: hasSiteSelection
                    ? ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.YOUR_SELECTED_SITES_20' | transloco)
                    : ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.ALL_SITES_10' | transloco)
                }
        }}</a
      >
      {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.UPDATE_DESCRIPTION_TEXT_2_200' | transloco }}
      <a (click)="openFieldDescription.emit()">{{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.UPDATE_DESCRIPTION_LINK_2_200' | transloco }}</a>
      {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.UPDATE_DESCRIPTION_TEXT_3_200' | transloco }}
    </p>
  </div>
</div>
<div class="dnd-csv">
  <div class="file-uploader-dnd-zone" [attr.data-e2e]="'dnd-files-zone'" avnFilePicker [multipleSelection]="false" [mimeTypesAccepted]="allowedTypes" [triggerMode]="'dragDrop'" (filesChanged)="onSelectedFile($event)">
    <avn-icon class="upload-icon" [fontSize]="80" icon="file:system_update_alt"></avn-icon>
    <h4>{{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.DND_LABEL_100' | transloco }}</h4>
  </div>
  <avn-button
    [attr.data-e2e]="'browse-files-btn'"
    [label]="'Browse Files'"
    [avnTheme]="'primary'"
    avnFilePicker
    [multipleSelection]="false"
    [mimeTypesAccepted]="allowedTypes"
    [triggerMode]="'click'"
    (filesChanged)="onSelectedFile($event)"
  >
  </avn-button>
</div>
