export enum PlayerColumn {
  HOSTNAME_IP = 'HOSTNAME_IP',
  HOSTNAME_IP_WITH_LINK = 'HOSTNAME_IP_WITH_LINK',
  MODEL = 'MODEL',
  OPERATIONAL_STATE = 'OPERATIONAL_STATE',
  SERIAL_MAC = 'SERIAL_MAC',
  LINKED_SITE_ID = 'LINKED_SITE_ID',
}

export const STANDARD_PLAYER_COLS = [
  PlayerColumn.HOSTNAME_IP,
  PlayerColumn.MODEL,
  PlayerColumn.OPERATIONAL_STATE,
];

export const LINKED_PLAYER_COLS = [
  PlayerColumn.HOSTNAME_IP,
  PlayerColumn.OPERATIONAL_STATE,
];

export const LINKED_PLAYER_COLS_WITH_LINK = [
  PlayerColumn.HOSTNAME_IP_WITH_LINK,
  PlayerColumn.OPERATIONAL_STATE,
];

export const UNLINKED_PLAYER_COLS = [
  PlayerColumn.HOSTNAME_IP,
  PlayerColumn.SERIAL_MAC,
  PlayerColumn.LINKED_SITE_ID,
];
