<ng-container *avnAsyncData="site$ | async; state: siteApiRequestState.dataState$ | async; errorTemplate: error; emptyTemplate: emptyData; loaderType: 'loader'">
  <ng-container *ngIf="site$ | async as siteData">
    <div class="section site-title">
      <span>
        {{ (siteTitle$ | async).name }} <b>{{ (siteTitle$ | async).id }}</b>
      </span>
      <br />
      <span>{{ siteData.site.address | applyFunction: formatAddress }}</span>
    </div>

    <div class="section site-alarms" *ngIf="hasOneDevice$ | async">
      <div class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.ALARMS_10' | transloco }}</div>
      <amp-site-alarms [siteId]="site.id" [siteTitle]="(siteTitle$ | async).name + ' ' + (siteTitle$ | async).id" [boardAlarms]="boardAlarms$ | async"></amp-site-alarms>
    </div>

    <div class="section key-metrics" *ngIf="hasVisibleKeyMetrics$ | async">
      <div class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.KEY_METRICS_100' | transloco }}</div>
      <amp-key-metrics-viewer
        [siteId]="site.id"
        [showCompactView]="showCompactView"
        [carouselEnabled]="!showCompactView"
        [height]="!showCompactView ? 200 : null"
      ></amp-key-metrics-viewer>
    </div>
  </ng-container>
</ng-container>

<ng-template #error>
  <avn-empty-data-message
    icon="alert:error"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_ERROR_TITLE_50' | transloco"
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_ERROR_DESCRIPTION_200' | transloco"
  >
  </avn-empty-data-message>
</ng-template>

<ng-template #emptyData>
  <div class="empty-message">
    <avn-empty-data-message
      icon="image:grid_off"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_LIST.SITE_LIST_DETAIL.EMPTY_KEY_METRICS_TITLE_50' | transloco"
      [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_LIST.SITE_LIST_DETAIL.EMPTY_KEY_METRICS_DESCRIPTION_200' | transloco"
    >
    </avn-empty-data-message>
  </div>
</ng-template>
