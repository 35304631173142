import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DeviceFilterApiExpressionService, DeviceProperty, DeviceType, getApiSortExpression } from '@amp/devices';
import { AsyncDataState, IBadgeSize, IDataTableColumnConfig, IDataTableDataFetchEvent, IDataTableDataSource, LoadingState } from '@activia/ngx-components';
import { BehaviorSubject, combineLatest, Observable, of, Subject, tap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { DeviceDTO, DeviceService } from '@activia/cm-api';
import { extractTotalRecordsFromHttpHeaders } from '@amp/utils/common';
import { HttpErrorResponse } from '@angular/common/http';
import { PlayerColumnService } from '../../players/services/player-column.service';
import { STANDARD_PLAYER_COLS } from '../../players/models/player-column';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'amp-site-management-search-devices',
  templateUrl: './site-management-search-devices.component.html',
  styleUrls: ['./site-management-search-devices.component.scss'],
})
export class SiteManagementSearchDevicesComponent implements OnInit, OnDestroy {
  @Output() selectedDevices: EventEmitter<DeviceDTO[]> = new EventEmitter<DeviceDTO[]>();

  amount = 20;
  DEFAULT_PARAMS: IDataTableDataFetchEvent = { offset: 0, amount: this.amount, sort: undefined };

  DEFAULT_FILTER = 'site.id.defined="false"';

  DEFAULT_DATASOURCE: IDataTableDataSource<DeviceDTO> = { rows: [], totalRowCount: 0 };
  dataSource$: BehaviorSubject<IDataTableDataSource<DeviceDTO>> = new BehaviorSubject<IDataTableDataSource<DeviceDTO>>(this.DEFAULT_DATASOURCE);

  fetchDataSub: Subject<IDataTableDataFetchEvent> = new Subject<IDataTableDataFetchEvent>();
  filterSub: Subject<string> = new Subject<string>();

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  loadingStateSub: BehaviorSubject<AsyncDataState> = new BehaviorSubject<AsyncDataState>(LoadingState.INIT);
  loadingState$: Observable<AsyncDataState> = this.loadingStateSub.asObservable();

  LoadingState = LoadingState;
  DeviceProperty = DeviceProperty;

  badgeSize: IBadgeSize = IBadgeSize.EXTRA_SMALL;

  private _dataSource: IDataTableDataSource<DeviceDTO> = this.DEFAULT_DATASOURCE;

  /** @ignore Pattern used to close all subscriptions*/
  private componentDestroyed$: Subject<void> = new Subject<void>();

  get queryParamsChanged$(): Observable<IDataTableDataFetchEvent> {
    return this.fetchDataSub.asObservable().pipe(
      startWith(this.DEFAULT_PARAMS),
      filter((params) => !!params)
    );
  }

  get filterChanged$(): Observable<string> {
    // Force search on init to show all devices that are not attached to any site
    return this.filterSub.asObservable().pipe(startWith(''));
  }

  constructor(private deviceFilterApiExpressionService: DeviceFilterApiExpressionService, private deviceService: DeviceService, private playerColumnService: PlayerColumnService) {
    combineLatest([this.filterChanged$, this.queryParamsChanged$])
      .pipe(
        distinctUntilChanged((prev, curr) => {
          const [prevFilterExpression, prevParams] = prev;
          const [currFilterExpression, currParams] = curr;
          return prevFilterExpression === currFilterExpression && prevParams.offset === currParams.offset && prevParams.amount === currParams.amount;
        }),
        tap(() => {
          this.loadingStateSub.next(LoadingState.LOADING);
        }),
        // TODO: UIC-505 set devices that are already added
        switchMap(([filterExpression, params]) => {
          // get the sort direction (defaults to asc if not set)
          const sortAsc = params.sort ? params.sort.direction === 'asc' : true;
          return this.deviceFilterApiExpressionService
            .getAvailableDeviceFilter(filterExpression, [DeviceType.DigitalSignageNetworkPlayer], true, filterExpression.length === 0 ? `${this.DEFAULT_FILTER}` : `& ${this.DEFAULT_FILTER}`)
            .pipe(
              switchMap((filters) =>
                this.deviceService.getDevices(params.amount, params.offset, getApiSortExpression(params.sort), sortAsc, filters, null, null, ['*'], null, null,'response').pipe(
                  map((response) => {
                    const deviceList = response.body || [];
                    const totalRows = extractTotalRecordsFromHttpHeaders<DeviceDTO>(response);
                    const appendData = params.offset > 0;
                    return [totalRows, deviceList, appendData] as [number, DeviceDTO[], boolean];
                  })
                )
              )
            );
        }),
        map(([totalRows, newData, appendData]) => {
          const newDataSource: IDataTableDataSource<DeviceDTO> = {
            rows: appendData ? [...this._dataSource.rows, ...newData] : newData,
            totalRowCount: +totalRows,
          };
          this.loadingStateSub.next(LoadingState.LOADED);
          this._dataSource = newDataSource;
          this.dataSource$.next(this._dataSource);
          return newDataSource;
        }),
        catchError((err: HttpErrorResponse) => {
          this.loadingStateSub.next({ errorMsg: err.message });
          return of(this.DEFAULT_DATASOURCE);
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.setColumns();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  setColumns(): void {
    this.columns$ = this.playerColumnService.getFormattedPlayerColumns$(STANDARD_PLAYER_COLS);
  }

  /** When datatable emits a data fetch event **/
  onFetchData(event: IDataTableDataFetchEvent) {
    this.fetchDataSub.next(event);
  }

  searchQueryChanged(filtr: string) {
    this.filterSub.next((filtr ?? '').trim());
    this.dataSource$.next(this.DEFAULT_DATASOURCE);
  }

  onSelectionChanged(devices: DeviceDTO[]) {
    this.selectedDevices.emit(devices);
  }
}
