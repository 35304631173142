import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { EngineTagValueDetailComponent, TagValueAssignmentScope } from '@amp/tag-operation';
import { IBoard } from '../../../../models/board-config.interface';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { combineLatest, filter, map, Observable } from 'rxjs';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { BoardState } from '../../../../store/board/board.reducer';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { RouterFacade } from '@amp/router-store';
import { take, tap } from 'rxjs/operators';
import { mapBoards } from '../../../../utils/iboard.utils';
import { NavigationExtras } from '@angular/router';
import { SiteManagementService } from '../../../../services/site-management.service';

@Component({
  selector: 'amp-board-tags',
  templateUrl: './board-tags.component.html',
  styleUrls: ['./board-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardTagsComponent implements ISaveBeforeExit {
  /** Component  */
  @ViewChild(EngineTagValueDetailComponent) detailComponent: EngineTagValueDetailComponent;

  boardsCount$: Observable<number> = this._boardStore.select(BoardSelectors.selectBoardTotal);
  board$: Observable<IBoard> = this._boardStore.select(BoardSelectors.selectedCurrentBoard);
  site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));

  /** Tag assignment scope */
  scope$: Observable<TagValueAssignmentScope>;

  /** If there is unsaved changes in the tag editor */
  changeDetails: { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } = { hasUnsavedChanges: false, hasInvalidChanges: false };

  editable$: Observable<boolean>;

  constructor(
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _routerFacade: RouterFacade,
    private _store: Store<ISiteManagementState>,
    private _boardStore: Store<BoardState>,
    private _siteManagementService: SiteManagementService,
  ) {
    this.editable$ = this._siteManagementService.hasAuthority$('tag');

    this.scope$ = combineLatest([this.site$, this.board$]).pipe(
      filter(([site, board]) => !!site && !!board),
      map(([site, board]) => mapBoards(board, site.externalId)),
    );
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return this.changeDetails;
  }

  save(): Observable<boolean> {
    return this.detailComponent.onPushChanges();
  }

  selectBoard(boardId: number): void {
    this.site$
      .pipe(
        take(1),
        map((site) => site.id),
        tap((siteId) => {
          const path = [...this._siteManagementConfig.moduleBasePath, siteId, 'boards', 'tags', `${boardId}`];
          const extras = { queryParamsHandling: 'preserve' } as NavigationExtras;
          this._routerFacade.navigate({ path, extras });
        }),
      )
      .subscribe();
  }
}
