<ng-container *avnAsyncData="monitoringData; state: monitoringDataDataState; emptyTemplate: showCompactView ? null : emptyTemplate; loadingTemplate: loadingTemplate; emptyFn: emptyMonitoringDataFn.bind(this)">
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <div class="chart-wrapper">
      <avn-percent-donut
        [color]="chartData.theme"
        [value]="chartData.diskPercent"
        [valueSuffix]="'%'"
        [name]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.SYSTEM_DISK.TITLE_20' | transloco"
      ></avn-percent-donut>
    </div>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
      class="key-metrics-container"
      [keyMetricsAccordionBorderColor]="dataDisplayTypeCode()"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SYSTEM_DISK.TITLE_35' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>
  </ng-template>
</ng-container>

<ng-template #accordionTemplate>
  <div class="accordion-content">
    <amp-site-field-templates
      [showEmptyPlaceholder]="true"
      [field]="dataInfo.data.field"
      [displayType]="dataInfo.data.displayType"
      [aggregationType]="dataInfo.data.aggregationType"
      [value]="monitoringData"
    ></amp-site-field-templates>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <avn-skeleton
    [attr.data-e2e]="'empty'"
    [animate]="false"
    [animateDirection]="'VERTICAL'"
    [text]="
      ('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_EMPTY_MSG1_50' | transloco) + ('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.' + config.id + '.TITLE_35' | transloco)
    "
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_EMPTY_MSG2_50' | transloco"
    [skeletonType]="'BAR_CHART'"
  >
  </avn-skeleton>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SYSTEM_DISK.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      [attr.data-e2e]="'loading'"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'BAR_CHART'"
    >
    </avn-skeleton>
  </div>
</ng-template>
