<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="false"
                     [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ADD_SITE_50' | transloco"
                     [attr.data-e2e]="'site-creation-header'"
                     (closeClicked)="onCancel()"></avn-dialog-header>
  <avn-dialog-content avnScroll>
    <avn-stepper [linear]="true" [showHeader]="false" [avnTheme]="'secondary'">
      <avn-step [stepControl]="form.controls['basicInfo']"
                [headerType]="'number'"
                [attr.data-e2e]="'basic-info'">
        <ng-template avnStepLabel>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.GENERAL_35' | transloco }}</ng-template>

        <amp-site-management-site-basic-info-editor [parentForm]="form"></amp-site-management-site-basic-info-editor>

        <div class="button-container general" [attr.data-e2e]="'general'">
          <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
          <div>
            <avn-button [label]="'button.next' | transloco" avnStepperNext avnTheme="primary" class="btn-next" [attr.data-e2e]="'btn-next'"></avn-button>
          </div>
        </div>
      </avn-step>

      <avn-step [stepControl]="form.controls['geoSearch']" [headerType]="'number'">
        <ng-template avnStepLabel>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.LOCATION_35' | transloco }}</ng-template>
        <amp-location-editor [parentForm]="form"></amp-location-editor>

        <div class="button-container general" [attr.data-e2e]="'general'">
          <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
          <div>
            <avn-button [label]="'button.prev' | transloco" avnStepperPrevious class="btn-prev" [attr.data-e2e]="'btn-prev'"></avn-button>
            <avn-button [label]="'button.next' | transloco" avnStepperNext avnTheme="primary" class="btn-next" [attr.data-e2e]="'btn-next'"></avn-button>
          </div>
        </div>
      </avn-step>

      <avn-step>
        <amp-site-sync-experience-selection (experiencesSelected)="onExperienceSelected($event)"></amp-site-sync-experience-selection>

        <div class="button-container location" [attr.data-e2e]="'location'">
          <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
          <div>
            <avn-button
              [label]="'button.prev' | transloco"
              [attr.data-e2e]="'prev-step'"
              avnStepperPrevious
              class="btn-prev"
              [attr.data-e2e]="'btn-prev'">
            </avn-button>
            <avn-button
              [label]="'button.create' | transloco"
              [attr.data-e2e]="'create-btn'"
              avnStepperComplete
              [disabled]="!!experiencesSelected && experiencesSelected.experiences.length > 0 && experiencesSelected.deviceAction === null"
              [avnTheme]="'primary'"
              (actioned)="onCreate()"
            >
            </avn-button>
          </div>
        </div>
      </avn-step>
    </avn-stepper>
  </avn-dialog-content>

  <ng-template #cancelButton>
    <div>
      <avn-button
        [attr.data-e2e]="'cancel-btn'"
        [label]="'button.cancel' | transloco"
        (actioned)="onCancel()" class="btn-cancel"
        [attr.data-e2e]="'btn-cancel'"></avn-button>
    </div>
  </ng-template>
</avn-dialog-layout>
