import { DeviceMonitoringData, IAlarmEventLevelInfo } from '@amp/devices';
import { LogicalPlayerDTO, ScreenShotDTO } from '@activia/device-screenshot-api';
import { DeviceInfoDTO, DisplayDTO, DisplayInputDTO, MonitoringAlarmEventDTO } from '@activia/cm-api';
import { ScreenshotOrientation } from '../../../model/site-screenshot.enum';
import { ITagStructure } from '@amp/tag-operation';

/**
 * A section is the first level of a site (based on organization path)
 *
 * For the sake of UI display, we create an empty 'dummy' section with no name when there is no intermediate section
 */
export interface ISiteSection {
  tagsStructure: ITagStructure[];
  name: string | null;
  boards: ISiteBoard[];
}
export interface ISiteBoard {
  id: number;
  name: string;
  order: number;
  /** full name of the board including the site name, zone name and section name as an array **/
  fullName: string[];
}
export interface ISiteStructure {
  sections: ISiteSection[];
  boards: { [boarId: number]: ISiteBoard };
}

export interface IScreenshotDisplayInfo {
  /** ID of the display */
  id: number;

  /** screen orientation */
  orientation: ScreenshotOrientation;

  /** IDs of the devices connected to this display */
  deviceIds?: number[];

  /** the current playlist */
  playList?: string;

  /** image urls */
  screenshot?: ScreenShotDTO;
}

/**
 * Contains info for a logical player
 * Built from CM info + live info retrieved from the device itself (CGI endpoint)
 * **/
export interface ILogicalPlayerInfo {
  id: number; // logical player id
  status: boolean; // the last known status of the player 1=running 0=error
  unreachable: boolean; // indicates if the player's device is unreachable
  playlist: string; // the last known playlist of the player 1=running 0=error
  player?: LogicalPlayerDTO; // the dto retrieved from the cgi call
  connections: IDeviceToDisplayConnection[]; // connections to displays for this player
  alarms: IBoardDeviceAlarmInfo[]; // alarms for this logical player
}

/**
 * Information on a device
 * Combined from data returned by CM engine + the device CGI endpoint to have the most up-to-date data
 * **/
export interface ICombinedDeviceInfo {
  device: DeviceInfoDTO;
  monitoringData?: DeviceMonitoringData;
  logicalPlayers?: ILogicalPlayerInfo[];
  deviceLoading?: boolean; // indicates if the device connection is pending
  connectivity?: IDeviceToDisplayConnection[]; // constructed from board info: all connections to displays from this device
  /**
   * If data is from cm, this is the last updated date, otherwise this is the time when this object is created.
   * Value is in UTC ISO string format, e.g. 2021-03-31T15:34:40.806Z
   */
  time?: string;
  /* Last update timestamp in UTC string from the monitored data of this device */
  lastUpdate?: string;
  /* Alarm level of this device */
  deviceStatus?: IAlarmEventLevelInfo;
  /** board alarms for the device */
  alarms?: IBoardDeviceAlarmInfo[];
  /** alarms for the device */
  alarmEvents?: MonitoringAlarmEventDTO[];
}

/**
 * All possible alarms for the device of a board.
 * Player events are returned under 'event' alarm code
 */
export type BoardDeviceAlarm =
  // The device could not be reached on the CGI endpoint
  | 'unreachable'
  // CM monitoring data reports the device as unreachable but a direct connection to the device could be established
  | 'device-reachable-mismatch'
  // CM monitoring data reports the device as reachable but a direct connection to the device could not be established
  | 'device-unreachable-mismatch'
  // device health errors
  | 'device-health-error'
  // players with versions < 4.3.1.11 do not return monitoring data and failover info
  | 'player-version-outdated'
  // Some players on the board cannot be found in monitoring data returned by CM or the device
  | 'misconfigured'
  // Players events retrieved from the player
  | 'event'
  // The player from the board cannot be found in the monitoring data returned by cm.
  | 'misconfigured-cm'
  // The player from the board cannot be found in the monitoring data returned by the device.
  | 'misconfigured-player'
  // The player state is missing from the monitoring data returned by the device.
  | 'unknown-state';

export const BoardDeviceAlarmsLabels: Partial<Record<BoardDeviceAlarm, string>> = {
  unreachable: 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.DEVICE.UNREACHABLE_100',
  'device-reachable-mismatch': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.DEVICE.DEVICE_REACHABLE_MISMATCH_100',
  'device-unreachable-mismatch': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.DEVICE.DEVICE_UNREACHABLE_MISMATCH_100',
  'player-version-outdated': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.DEVICE.PLAYER_VERSION_OUTDATED_100',
  misconfigured: 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.DEVICE.MISCONFIGURED_100',
  'misconfigured-cm': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.MISCONFIGURED_100',
  'misconfigured-player': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.MISCONFIGURED_100',
  'unknown-state': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.UNKNOWN_STATE_100',
};

/** Represents a possible alarm that can occur for a device on a display board:
 * - custom errors created manually
 * - device errors returned via player events
 * **/
export interface IBoardDeviceAlarmInfo {
  alarm: BoardDeviceAlarm;
  data: MonitoringAlarmEventDTO;
}

export interface IDeviceToDisplayConnection {
  boardId: number;
  displayId: number;
  displayInput: number;
  deviceId: number;
  devicePlayerId: number;
  deviceOutput: number;
  displayIndex: number;
}

export interface IDisplayInputInfo {
  device: ICombinedDeviceInfo;
  displayInput: DisplayInputDTO;
  logicalPlayer: ILogicalPlayerInfo;
  failover: 'primary' | 'backup' | null;
  show: boolean;
  on: boolean;
  screenOff: number | null;
  screenshot: ScreenShotDTO;
}

/** Represents a row of displays **/
export interface IBoardDisplayRow {
  rowIndex: number;
  displays: DisplayDTO[];
}
