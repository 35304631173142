import { DeviceHealthCountDTO, DeviceService } from '@activia/cm-api';
import { GlobalFacade } from '@amp/global';
import { TimerService } from '@amp/messenger';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  Observable,
  ReplaySubject,
  share,
  take,
  takeWhile,
  tap
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorHandlingService } from '@amp/error';
import { AsyncDataState, hasAsyncDataError, LoadingState } from '@activia/ngx-components';
import { HttpErrorResponse } from '@angular/common/http';

export type IDeviceHealthCount = DeviceHealthCountDTO & { total: number };
export interface IDeviceHealthStatus {
  deviceGroupId: number;
  moduleName: string;
  statusSubject: BehaviorSubject<{ data: IDeviceHealthCount; state: AsyncDataState }>;
}

@Injectable({ providedIn: 'root' })
export class DevicesStatusService {
  devicesStatusState$: BehaviorSubject<AsyncDataState> = new BehaviorSubject<AsyncDataState>(LoadingState.INIT);

  /** Refresh counts of all device groups in ms */
  private _refreshDelay = 5 * 60 * 1000;

  /** Indicate if error occurs while fetching the data */
  hasError$: Observable<boolean> = this.devicesStatusState$.pipe(map((state: AsyncDataState) => hasAsyncDataError(state)));

  private _timer$: Observable<number> = this.timerService.timer$(this._refreshDelay).pipe(
    takeWhile(() => !hasAsyncDataError(this.devicesStatusState$.value)),
  );

  devicesStatus$: Observable<IDeviceHealthCount>;

  constructor(
    private devicesService: DeviceService,
    private globalFacade: GlobalFacade,
    private errorHandlingService: ErrorHandlingService,
    private timerService: TimerService,
  ) {
    const timerTicked$ = combineLatest([
      this._timer$,
      this.globalFacade.defaultDeviceGroup$.pipe(filter((defaultDeviceGroup) => !!defaultDeviceGroup)),
    ]);

    timerTicked$.pipe(
      take(1),
      tap(() => {
        this.devicesStatusState$.next(LoadingState.LOADING);
      }),
    ).subscribe();

    this.devicesStatus$ = timerTicked$.pipe(
      switchMap(([_, defaultDeviceGroup]) => this.devicesService.getHealthStatusCountForDeviceGroup(defaultDeviceGroup.id).pipe(
        map((_count) => {
          if(this.devicesStatusState$.value !== LoadingState.LOADED) {
            this.devicesStatusState$.next(LoadingState.LOADED);
          }
          return { ..._count, total: _count.ok + _count.warning + _count.error + _count.unreachable + _count.notMonitored };
        }),
        catchError((err: HttpErrorResponse) => {
          // Possible error:
          // 404: invalid device-group
          // 204: no content in device-group
          if (err.status !== 0) {
            this.errorHandlingService.catchError(err, undefined, 'global.error.fetch-device-group-health-status-fail', { key: defaultDeviceGroup.id });
          }
          this.devicesStatusState$.next({ errorMsg: err.message });
          return EMPTY;
        })
      )),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );
  }
}
