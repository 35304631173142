import { IBadgeSize, ThemeType } from '@activia/ngx-components';
import { AggregationType, DisplayType } from '@amp/column-picker';
import { ContentState, ContentStatus, ContentStatuses, DevicePropertiesStatus, HealthErrorIds, HealthStatusCode, ServiceStatusCode } from '@amp/devices';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SiteMonitoredValue } from '../../model/site-monitored-value.enum';
import { CountAggregationData, MinMaxAggregationData } from '../../model/site-monitoring-data.interface';
import { SiteProperties } from '../../model/site-properties.enum';
import { getSiteMonitoringColumnDefinition } from '../../utils/site-monitoring-columns.utils';
import { SiteMonitoringAggregationTypeMappings } from '../../utils/site-monitored-values.utils';

@Component({
  selector: 'amp-site-field-templates',
  templateUrl: './site-field-templates.component.html',
  styleUrls: ['./site-field-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteFieldTemplatesComponent implements OnChanges {
  /** Site field, either a site property or a site monitoring value **/
  @Input() field: any; // SiteProperties | SiteMonitoredValue

  /** monitoring data value for the site property / monitoring value **/
  @Input() value: any; // primitive type | CountAggregationData | MinMaxAggregationData

  /** field display type **/
  @Input() displayType: DisplayType;

  /** field aggregation type **/
  @Input() aggregationType: AggregationType;

  /** Indicates if the view should be optimistic
   * Applies mostly to status field (health, content..)
   * Will combine warnings with ok statuses when enable **/
  @Input() optimisticViewEnabled: boolean;

  /** Indicates if a placeholder text should display when the data is not available (monitoring values only ) **/
  @Input() showEmptyPlaceholder = false;

  /** Opacity of the placeholder text **/
  @Input() emptyPlaceholderOpacity = 0.7;

  /** @ignore to use in template **/
  SiteMonitoredValue = SiteMonitoredValue;

  /** @ignore to use in template **/
  HealthStatusCode = HealthStatusCode;

  /** @ignore to use in template **/
  HealthErrorIds = HealthErrorIds;

  /** @ignore to use in template **/
  ContentStatuses = ContentStatuses;

  /** @ignore to use in template **/
  DevicePropertiesStatus = DevicePropertiesStatus;

  /** @ignore to use in template **/
  ServiceStatusCode = ServiceStatusCode;

  /** @ignore to use in template **/
  BadgeSize = IBadgeSize;

  /** @ignore to use in template **/
  DisplayType = DisplayType;

  /** @ignore to use in template **/
  ThemeType = ThemeType;

  /** When the display type is Percentage Pie or Count Pie Chart, we need to specify what are the OK / Error statuses **/
  pieConfig: { okStatus: string; errorStatus: string };

  /** Indicates if the data is available (not empty) */
  isDataAvailable: boolean;

  constructor(public translate: TranslocoService) {}

  /** for some displayTypes we want the template to span 100% of its parent **/
  @HostBinding('class.full-width')
  get isFullWidth() {
    return [DisplayType.PercentChart].includes(this.displayType);
  }

  ngOnChanges(): void {
    // if no display/aggregation types are set, use the default one for the column
    if (!this.displayType) {
      this.displayType = getSiteMonitoringColumnDefinition(this.field).defaultDisplayType;
    }
    if (!this.aggregationType) {
      this.aggregationType = getSiteMonitoringColumnDefinition(this.field).defaultAggregationType;
    }

    // check if the data is available
    this.isDataAvailable = this._isDataAvailable(this.value, this.field, this.aggregationType);

    if (this.isDataAvailable) {
      // templates are reused (caching) so make sure to refresh the charts data whenever there is a change
      if (this.displayType === DisplayType.CountPieChart || this.displayType === DisplayType.PercentPieChart) {
        this._refreshPieConfig(this.field);
      }
    }
  }

  private _refreshPieConfig(field: any): void {
    if (field === SiteMonitoredValue.HealthStatus) {
      this.pieConfig = { okStatus: `${HealthStatusCode.OK}`, errorStatus: `${HealthStatusCode.ERROR}` };
    } else if (field === SiteMonitoredValue.ContentState) {
      this.pieConfig = { okStatus: `${ContentState.OK}`, errorStatus: `${ContentState.MissingContent}` };
    } else if (field === SiteMonitoredValue.ContentStatus) {
      this.pieConfig = { okStatus: `${ContentStatus.OK}`, errorStatus: `${ContentStatus.ERROR}` };
    } else if (field === SiteMonitoredValue.DevicePropertiesStatus) {
      this.pieConfig = { okStatus: `${DevicePropertiesStatus.OK}`, errorStatus: `${DevicePropertiesStatus.ErrorALL}` };
    } else {
      this.pieConfig = { okStatus: `${ServiceStatusCode.OK}`, errorStatus: `${ServiceStatusCode.ERROR}` };
    }
  }

  /**
   * @ignore
   * Indicates if the data is available. pure function to make it easier to unit test.
   */
  private _isDataAvailable(value: any | CountAggregationData | MinMaxAggregationData, field: SiteProperties | SiteMonitoredValue, aggregationType: AggregationType): boolean {
    // data availability check is only for monitoring data
    const isSiteProperty = Object.values(SiteProperties).includes(field as SiteProperties);
    if (isSiteProperty) {
      return true;
    }

    // at this stage, the value is aggregated data. let's ignore other data
    if (typeof value !== 'object') {
      return false;
    }

    if (value === undefined || value === null) {
      return false;
    }

    // the aggregated data must contain at least one key
    const dataKeys = Object.keys(value);
    if (dataKeys.length === 0) {
      return false;
    }

    // for aggregated data we need at least one value set and not equal to 0 (backend sometimes return 0 for all values when data is not available...)
    const minMaxKeys = Object.values(SiteMonitoringAggregationTypeMappings);
    const isMinMaxAggregatedData = Object.keys(value).some((key) => minMaxKeys.includes(key));
    if (isMinMaxAggregatedData) {
      // for some unknown reason, min/max/avg aggregated data is always returned (all with a 0 value or empty string when not available)
      const hasAggregatedCountData = Object.values(value).some((count) => !!count && count !== 0);
      if (!hasAggregatedCountData) {
        return false;
      }
      const hasAggregatedDataRequested = `${value[SiteMonitoringAggregationTypeMappings[aggregationType]] ?? ''}`.trim().length > 0;
      return hasAggregatedDataRequested;
    }
    // if we are here, its aggregated count data (at least one status is required)
    return Object.keys(value).length > 0;
  }
}
