<avn-accordion [multiExpansionEnabled]="true">
  <avn-accordion-panel
    *ngFor="let board of boards"
    [class.no-device]="!board.combinedDevicesInfo?.length"
    [avnBackground]="{ cssProp: '--surface-background', shade: 700 }"
    [expand]="expand || board.boardStatus.status === OrganizationLevelHealthStatus.ERROR"
    [toggleIconPosition]="'none'"
    [headerTemplate]="boardHeader"
    [headerTemplateContext]="{ boardData: board }"
    [disableExpansion]="disableExpansion"
    (toggled)="toggleExpanded($event, board.id)"
  >
    <div class="board-info">
      <div *ngFor="let deviceAlarm of board.combinedDevicesInfo" class="devices">
        <div class="device-info" [attr.data-e2e]="'device-info'" [avnTheming]="{ 'border-left-color': { theme: deviceAlarm.deviceStatus.theme } }">
          <div class="device-information" [attr.data-e2e]="'device-information'">
            <div class="device-header" [attr.data-e2e]="'device-info-header'">
              <div>
                <span>{{ deviceAlarm.device.hostname || deviceAlarm.device.deviceId }}</span>
              </div>
              <amp-site-device-actions [deviceInfo]="deviceAlarm" [iconSize]="20"></amp-site-device-actions>
            </div>
            <ul class="alarms">
              <li *ngFor="let alarm of deviceAlarm.alarmEvents">
                <amp-device-alarm [alarm]="alarm" [displayConfig]="alarmDisplayConfig$ | async"></amp-device-alarm>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </avn-accordion-panel>

  <ng-template #boardHeader let-boardData="boardData">
    <div
      class="board-header"
      [ngClass]="{
        ok: boardData.boardStatus.status === OrganizationLevelHealthStatus.OK,
        warning: boardData.boardStatus.status === OrganizationLevelHealthStatus.WARNING,
        error: boardData.boardStatus.status === OrganizationLevelHealthStatus.ERROR
      }"
    >
      <ng-container *ngIf="boardHeaderStyle === 'fullOrgPath'; else nameHeader">
        <avn-section-breadcrumb [sections]="boardData.organizationPath | applyFunction: getBreadcrumbSections"></avn-section-breadcrumb>
      </ng-container>
      <ng-template #nameHeader>
        <avn-section-breadcrumb [sections]="[{ value: boardData.name }]"></avn-section-breadcrumb>
      </ng-template>
      <div class="board-header-action">
        <avn-button
          *ngIf="showDetailsButton && showBoardPlayerMapping$ | async"
          [label]="'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.BUTTON.DETAILS_15' | transloco"
          (click)="onDetailsClicked($event, boardData.id)"
          [size]="'small'"
          [avnTheme]="'primary'"
          [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.ACTIONS.SHOW_BOARD_DETAIL_100' | transloco"
          [tooltipMouseEnterOpenDelay]="1000"
        >
        </avn-button>
        <avn-icon *ngIf="!disableExpansion" icon="navigation:chevron_left" [fontSize]="17" [ngClass]="{ expanded: boardHeaderOpenMap.get(boardData.id) }"></avn-icon>
      </div>
    </div>
  </ng-template>
</avn-accordion>
