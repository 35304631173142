import { CreateSiteDTO, SiteDTO, SitesService, SiteTagsService, UpdateSiteDTO } from '@activia/cm-api';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { extractLastModifiedInfoFromHttpHeaders } from '@amp/utils/common';
import { catchError, combineLatest, map, Observable, of, switchMap, throwError } from 'rxjs';
import { CountryService, GeoResultTypes, GeoTimezoneService, GoogleMapsService, IGeoPoint, IGeoResult } from '@activia/geo';
import { areGeodeticCoordinatesComplete, isAddressComplete } from './geo-location-validator.utils';
import { IGeoFixerSite } from '../models/geo-fixer-site.interface';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IEngineTagValue } from '@amp/tag-operation';
import { getApiAddressDisplayFormat } from '@amp/site-monitoring-shared';

export const convertToCreateSiteDTO = (site: SiteDTO): CreateSiteDTO => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, managers, deviceCount, connectedDeviceCount, boardCount, displayCount, creationTime, modificationTime, lastModifiedBy, ...siteDTO } = site;
  return siteDTO;
};

export const convertToUpdateSiteDTO = (site: SiteDTO): UpdateSiteDTO => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, managers, deviceCount, connectedDeviceCount, boardCount, displayCount, creationTime, modificationTime, lastModifiedBy, ...siteDTO } = site;
  return siteDTO;
};

/**
 * Find geodetic coordinates of a site. The site must have complete address to call this function.
 * If fails to reach Maps api, or api does not return exactly 1 result, null is returned.
 */
export const findSiteCoordinates$ = (countryService: CountryService, googleMapsService: GoogleMapsService, site: SiteDTO): Observable<IGeoPoint> => {
  const formattedAddress = getApiAddressDisplayFormat(site.address, countryService.getCountryByCountryNameOrCode(site.address.country), false, false);

  const geoSearch$ = googleMapsService.fetch(formattedAddress, GeoResultTypes.ADDRESS).pipe(catchError((_) => of(null)));

  return geoSearch$.pipe(
    map((geoResults: IGeoResult[]) => {
      let result = null;
      if ((geoResults || []).length === 1) {
        result = geoResults[0].location;
      }
      return result;
    })
  );
};

/** Find and set timezone of a site */
export const findSiteTimezone$ = (geoTimezoneService: GeoTimezoneService, site: SiteDTO): Observable<SiteDTO> =>
  // Not need to catch error, GeoTimezoneService will return null as timezoneId when fail to reach Google API
  geoTimezoneService.fetch(site.geodeticCoordinates as IGeoPoint).pipe(
    map((timezoneId) => ({
      ...site,
      timezone: timezoneId,
    }))
  );

/**
 * Find and set country code of a site.
 * If the country code is not found, will set the country to whatever it is in the address
 */
export const fixSiteCountry = (countryService: CountryService, site: SiteDTO): SiteDTO => {
  const country = countryService.getCountryByCountryNameOrCode(site.address.country);
  return {
    ...site,
    address: {
      ...site.address,
      country: country?.shortCountryCode || site.address.country,
    },
  };
};

/**
 * Find timezone of a new site then send create request to the backend.
 * This function does not handle exception.
 */
export const createSite$ = (
  geoTimezoneService: GeoTimezoneService,
  countryService: CountryService,
  siteService: SitesService,
  site: SiteDTO,
  tags?: Record<string, IEngineTagValue>,
  siteTagsService?: SiteTagsService
): Observable<SiteDTO> => {
  const siteCreated$ = findSiteTimezone$(geoTimezoneService, site).pipe(
    switchMap((siteWithTimezone: SiteDTO) => {
      return siteService.createSite(convertToCreateSiteDTO(fixSiteCountry(countryService, siteWithTimezone)));
    })
  );
  const tagsCreated$ = updateSiteTags$(siteTagsService, tags, site.id, false);
  return combineLatest([siteCreated$, tagsCreated$]).pipe(
    map(([createdSite, _]) => {
      return createdSite;
    })
  );
};

export type IUpdateSiteStatus =
  /** Update and refresh successfully */
  | 'UPDATED'

  /** Update fail */
  | 'UPDATE_FAIL';

export type IUpdateSiteTagsStatus =
  /** Update successfully */
  | 'UPDATED'

  /** Update fail */
  | 'UPDATE_FAIL';

/**
 * Find timezone of the site then update it.
 * If tags are provided, tags will be updated as well.
 */
export const updateSite$ = (
  geoTimezoneService: GeoTimezoneService,
  countryService: CountryService,
  siteService: SitesService,
  site: SiteDTO,
  reThrowError: boolean,
  tags?: Record<string, IEngineTagValue>,
  siteTagsService?: SiteTagsService
): Observable<{
  siteResponse: { site: SiteDTO; state: { status: IUpdateSiteStatus; error?: HttpErrorResponse } };
  tagsResponse: { state: { status: IUpdateSiteTagsStatus; error?: HttpErrorResponse } };
}> => {
  const siteUpdate$ = findSiteTimezone$(geoTimezoneService, site).pipe(
    switchMap((siteWithTimezone: SiteDTO) => {
      const siteToUpdate = fixSiteCountry(countryService, siteWithTimezone);

      return siteService.updateSite(siteToUpdate.id, convertToUpdateSiteDTO(siteToUpdate), 'response', true).pipe(
        map((response) => {
          return { site: getUpdatedSite(response, siteToUpdate), state: { status: 'UPDATED' as any } };
        }),
        catchError((err) => {
          return reThrowError
            ? throwError(err)
            : of({
                site: siteWithTimezone,
                state: { status: 'UPDATE_FAIL' as any, error: err },
              });
        })
      );
    })
  );
  const tagsUpdate$ = updateSiteTags$(siteTagsService, tags, site.id, reThrowError);
  return combineLatest([siteUpdate$, tagsUpdate$]).pipe(
    map(([siteRes, tagsResp]) => {
      return { siteResponse: siteRes, tagsResponse: tagsResp };
    })
  );
};

/**
 * Update site tags
 *
 * @return null if no update occurs, otherwise the update status and error if applicable
 */
export const updateSiteTags$ = (
  siteTagsService: SiteTagsService,
  tags: Record<string, unknown>,
  siteId: number,
  reThrowError: boolean
): Observable<{ state: { status: IUpdateSiteTagsStatus; error?: HttpErrorResponse } }> => {
  return siteTagsService && !!Object.keys(tags || {}).length && !!siteId
    ? siteTagsService.findTagsForEntity2(siteId).pipe(
        switchMap((siteTags) => {
          const operations = [];

          if (tags && Object.keys(tags).length > 0) {
            for (const [key, value] of Object.entries(tags)) {
              const op = Object.keys(siteTags)?.includes(key) ? 'replace' : 'add';
              operations.push({ op, key, newValues: [value?.toString()] });
            }
          }
          return operations.length > 0
            ? siteTagsService.patchTagsForEntity2(siteId, operations).pipe(
                map(() => {
                  return { state: { status: 'UPDATED' as IUpdateSiteTagsStatus } };
                })
              )
            : of(null);
        }),
        catchError((err: HttpErrorResponse) => {
          return reThrowError ? throwError(err) : of({ state: { status: 'UPDATE_FAIL' as IUpdateSiteTagsStatus, error: err } });
        })
      )
    : of(null);
};

/** Get updated site with last modified date and last modified by values extracted from the HTTP response header */
export const getUpdatedSite = (response: HttpResponse<any>, site: SiteDTO): SiteDTO => {
  const lastModifiedInfo = extractLastModifiedInfoFromHttpHeaders(response.headers);
  const updatedSite: SiteDTO = {
    ...site,
    lastModifiedBy: lastModifiedInfo.lastModifiedBy || site.lastModifiedBy,
    // The format used in the header is e.g. Wed, 25 May 2022 12:47:17 GMT.
    // Need to convert to the format e.g. 2022-04-14T19:09:50.350Z
    modificationTime: lastModifiedInfo.modificationTime ? new Date(lastModifiedInfo.modificationTime).toISOString() : site.modificationTime,
  };
  return updatedSite;
};

/**
 * When user searches with a single character, search in external id ONLY with exact match.
 * When user searches with 2 characters, look for exact match then search in state and country for an exact match that is case-insensitive.
 * When user searches with 3 characters or more, look for exact match then search stays as it is.
 */
export const externalIdSearch = (sites: SiteDTO[], query: string): SiteDTO | false => {
  const lowerCaseQuery = query.toLowerCase();

  // First, check for an exact match on site.externalId
  const exactMatch = sites.find((site) => (site.externalId || '').toLowerCase() === lowerCaseQuery);
  if (exactMatch) {
    return exactMatch;
  }
  return false;
};
export const searchSites = (sites: SiteDTO[], query: string): SiteDTO[] => {
  if (!query) {
    return sites;
  }

  const caseInsensitiveQuery = query.toLowerCase();

  if (query.length === 1) {
    // Search in external id ONLY with exact match
    return sites.filter((site) => (site.externalId || '') === query);
  } else if (query.length === 2) {
    const externalIdSite = externalIdSearch(sites, query);
    if (externalIdSite) {
      return [externalIdSite];
    }
    // Search in state and country for an exact match that is case-insensitive
    return sites.filter((site) => (site.address.state || '').toLowerCase() === caseInsensitiveQuery || (site.address.country || '').toLowerCase() === caseInsensitiveQuery);
  } else {
    const externalIdSite = externalIdSearch(sites, query);
    if (externalIdSite) {
      return [externalIdSite];
    }
    return sites.filter(
      (site) =>
        (site.name || '').toLowerCase().includes(caseInsensitiveQuery) ||
        (site.address?.addressLine1 || '').toLowerCase().includes(caseInsensitiveQuery) ||
        (site.address?.city || '').toLowerCase().includes(caseInsensitiveQuery) ||
        (site.address?.state || '').toLowerCase().includes(caseInsensitiveQuery) ||
        (site.address?.zip || '').toLowerCase().includes(caseInsensitiveQuery) ||
        (site.address?.country || '').toLowerCase().includes(caseInsensitiveQuery)
    );
  }
};

export const createSiteLocationForDevice = (countryService: CountryService, site: SiteDTO) => {
  const countryData = countryService.getCountryByCountryNameOrCode(site.address.country);
  return {
    latitude: site.geodeticCoordinates.latitude,
    longitude: site.geodeticCoordinates.longitude,
    location: {
      levelFamily: {
        id: 1,
        name: 'worldwide',
        level5: { levelLabel: 'LEVEL5', name: 'Street' },
        level4: { levelLabel: 'LEVEL4', name: 'City' },
        level3: { levelLabel: 'LEVEL3', name: 'State' },
        level2: { levelLabel: 'LEVEL2', name: 'Country' },
        level1: { levelLabel: 'LEVEL1', name: 'Continent' },
      },
      level5: site.address.addressLine1,
      level4: site.address.city,
      level3: site.address.state,
      level2: countryData?.name,
      level1: countryData?.continent,
      zip: site.address.zip,
    },
  };
};

/** Verify if timezone is set or not */
export const isTimezoneComplete = (site: SiteDTO): boolean => !!site.timezone;

/** Find all sites with missing info, e.g. incomplete address, geodetic coordinates, timezone etc */
export const findSitesWithIncompleteInfo = (sites: SiteDTO[]): IGeoFixerSite[] =>
  sites
    .map((site) => {
      let siteWithStatus: IGeoFixerSite;
      if (!isAddressComplete(site?.address)) {
        siteWithStatus = { ...site, validationStatus: 'INCOMPLETE_ADDRESS' };
      } else if (!areGeodeticCoordinatesComplete(site)) {
        siteWithStatus = { ...site, validationStatus: 'INCOMPLETE_GEODETIC_COORDINATES' };
      } else if (!isTimezoneComplete(site)) {
        siteWithStatus = { ...site, validationStatus: 'MISSING_TIMEZONE' };
      }
      return siteWithStatus;
    })
    .filter((site) => !!site);

/** Get the new path URL by changing only the site id and keeping the rest as is */
export const getNewPathUrl = (node: ActivatedRouteSnapshot, id: number): string[] => {
  // Change only the site ID in the URL
  const path = [];
  let isFound = false;

  while (node) {
    if (node.routeConfig?.path === ':siteId') {
      // Replace previous sideId by new one
      path.push(id);
      isFound = true;
    } else if (node.url[0]) {
      // Keep the same URL as before
      path.push(node.url[0].path);
    }
    node = node.children[0];
  }
  // If we didn't find the site id, then add it at the end of the path
  return isFound ? path : [...path, id];
};
