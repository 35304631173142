<h5>{{ 'tagOperation.TAG_OPERATION.APT.GENERAL.APT_TITLE_30' | transloco }}</h5>

<amp-board-selector (boardChange)="selectBoard($event)"></amp-board-selector>
@if (currentSelectedBoardId$ | async) {
  @if (tagValues$ | async | keyvalue; as tags) {
    @if (tags.length) {
      @if (tags | applyFunction: formatAptTags; as formattedTags) {
        <div class="board-apt-info-container">
          @for (tag of formattedTags.json; track tag.key) {
            <ng-container *ngTemplateOutlet="aptSectionTemplate; context: { value: tag.value, key: tag.key }"></ng-container>
          }

          <!-- If there is more "simple" apt tag to show -->
          @if (tags.length > formattedTags.json.length) {
            <ng-container
              *ngTemplateOutlet="aptSectionTemplate; context: { value: formattedTags.standard, key: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.OTHERS_20' | transloco }"
            ></ng-container>
          }
        </div>
      }
    } @else {
      <avn-empty-data-message class="empty-message" [icon]="'action:cloud_sync'" [text]="'tagOperation.TAG_OPERATION.APT.GENERAL.APT_NO_INFO_60' | transloco"></avn-empty-data-message>
    }
  }
}

<ng-template #aptSectionTemplate let-value="value" let-key="key">
  <h6>{{ key }}</h6>
  @if (value | applyFunction: flattenObject : [] : this | keyvalue; as formattedVal) {
    <div class="apt-info-section">
      @if (formattedVal.length) {
        @for (property of formattedVal; track property.key) {
          <div class="apt-info-row">
            <!-- Key value information -->
            <span class="apt-info-key"> {{ property.key }} </span>
            <span class="apt-info-value"> {{ property.value }} </span>
          </div>
        }
      } @else {
        {{ value }}
      }
    </div>
  }
</ng-template>
