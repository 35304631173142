import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom, switchMap, mapTo } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { siteManagementSelectors } from '../site-management.selectors';
import { BoardDTO, BoardsService, BoardTagsService, CreateAllDisplaysInBoardDTO, DisplayDTO, DisplaysInBoardService, SitesService } from '@activia/cm-api';
import { Store } from '@ngrx/store';
import { convertToCreateAllDisplaysInBoardDTO, generateDisplays } from '../../utils/display.utils';
import * as BoardActions from './board.actions';
import * as BoardSelectors from './board.selectors';
import * as DisplaySelectors from '../display/display.selectors';
import { changeBoardSize, convertToIBoard } from '../../utils/iboard.utils';
import { MessengerNotificationService } from '@amp/messenger';
import { IBoard } from '../../models/board-config.interface';
import { EXPERIENCE_TEMPLATE_TAG, getBoardOrgPathFromTags, getTagsStructureFromBoard, selectBoardOrgPathDefinition, selectBoardTagKeysSchema } from '@amp/tag-operation';
import { selectAllDevices } from '../device/device.selectors';

@Injectable()
export class BoardEffects {
  addBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardActions.AddBoard),
      withLatestFrom(
        this._store.select(siteManagementSelectors.currSiteData),
        this._store.select(BoardSelectors.selectBoardByOrgPath),
        this._store.select(selectBoardOrgPathDefinition),
        this._store.select(selectBoardTagKeysSchema),
      ),
      concatMap(([action, site, boardsPerOrgPath, boardOrgPathDef, boardTagKeysSchema]) => {
        const tagsStructure = getTagsStructureFromBoard(boardOrgPathDef, action.boardOrgPathTags, boardTagKeysSchema);
        const boardOrgPath = getBoardOrgPathFromTags(boardOrgPathDef, action.boardOrgPathTags);

        return this._siteService
          .addBoardToSite(site.id, {
            name: action.boardName,
            order: Math.max(...(boardsPerOrgPath[boardOrgPath]?.map((e) => e.order) || []), -1) + 1, // Place board at the last position
          })
          .pipe(
            // Create tags for org path
            switchMap((board) => {
              const tagValues = action.boardOrgPathTags;
              const tagKeys = Object.keys(action.boardOrgPathTags);

              const operations = tagKeys.map((tag) => ({ op: 'add', key: tag, newValues: [tagValues[tag].toString()] }));

              return this._boardTagsService.patchTagsForEntity(board.id, operations).pipe(map(() => board));
            }),
            switchMap((board) => {
              board.displays = generateDisplays(action.gridSize);
              const displays: CreateAllDisplaysInBoardDTO = convertToCreateAllDisplaysInBoardDTO(board.displays);

              return this._displaysInBoardService.createOrReplaceAllDisplaysInBoard(board.id, displays).pipe(map((displaysDTO) => [board, displaysDTO] as [BoardDTO, DisplayDTO[]]));
            }),
            map(([board, displays]) => {
              board.displays = displays; // Attach new display to board
              return BoardActions.AddBoardSuccess({ board: convertToIBoard(board, board.order, [], tagsStructure), displays });
            }),
            catchError((error) => {
              this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.CREATE_SITE_BOARD_FAIL_150', {
                boardOrgPath: boardOrgPath + '.' + action.boardName,
                errMsg: error?.message,
              });
              return of(BoardActions.AddBoardFail({ error }));
            }),
          );
      }),
    ),
  );

  deleteBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardActions.DeleteBoard),
      withLatestFrom(this._store.select(BoardSelectors.selectTemporaryDeletedBoards)),
      concatMap(([action, tempDeletedBoards]) => {
        const board = tempDeletedBoards[action.boardId];
        return this._boardsService.deleteBoard(action.boardId).pipe(
          map(() => BoardActions.DeleteBoardSuccess({ boardId: action.boardId })),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.DELETE_SITE_BOARD_FAIL_150', {
              boardOrgPath: board.organizationPath,
              errMsg: error?.message,
            });
            return of(BoardActions.DeleteBoardFail({ error, boardId: action.boardId }));
          }),
        );
      }),
    ),
  );

  updateBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardActions.UpdateBoard),
      withLatestFrom(
        this._store.select(siteManagementSelectors.currSiteData),
        this._store.select(DisplaySelectors.selectDisplayEntities),
        this._store.select(BoardSelectors.selectBoardEntities),
        this._store.select(selectBoardOrgPathDefinition),
        this._store.select(selectBoardTagKeysSchema),
        this._store.select(selectAllDevices),
      ),
      concatMap(([action, site, displayEntities, boardEntities, boardOrgPathDef, boardTagKeysSchema, devices]) => {
        const tagsStructure = getTagsStructureFromBoard(boardOrgPathDef, action.boardOrgPathTags, boardTagKeysSchema);
        const boardOrgPath = getBoardOrgPathFromTags(boardOrgPathDef, action.boardOrgPathTags);

        return this._siteService
          .addBoardToSite(site.id, {
            name: action.boardName,
            order: boardEntities[action.boardId].order,
          })
          .pipe(
            // Create tags for org path
            switchMap((board) => {
              const tagExpTemplate = boardEntities[action.boardId].experienceTemplateTag;
              const tagValues = {
                ...action.boardOrgPathTags,
                ...(tagExpTemplate && { [EXPERIENCE_TEMPLATE_TAG]: boardEntities[action.boardId].experienceTemplateTag }),
              };

              const operations = Object.keys(tagValues).map((tag) => ({ op: 'add', key: tag, newValues: [tagValues[tag]] }));

              return this._boardTagsService.patchTagsForEntity(board.id, operations).pipe(map(() => board));
            }),
            // Create display
            switchMap((board) => {
              const displays = boardEntities[action.boardId].displays.map((id) => displayEntities[id]);

              const newDisplays = convertToCreateAllDisplaysInBoardDTO(changeBoardSize(displays, action.gridSize));

              return this._displaysInBoardService.createOrReplaceAllDisplaysInBoard(board.id, newDisplays).pipe(map((displaysDTO) => [board, displaysDTO] as [BoardDTO, DisplayDTO[]]));
            }),
            switchMap(([board, displays]) => {
              board.displays = displays; // Attach new display to board
              return this._boardsService.deleteBoard(action.boardId).pipe(mapTo([board, displays] as [BoardDTO, DisplayDTO[]]));
            }),
            map(([board, displays]) =>
              BoardActions.UpdateBoardSuccess({
                board: convertToIBoard(board, board.order, devices, tagsStructure),
                boardId: action.boardId,
                displays,
              }),
            ),
            catchError((error) => {
              this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_BOARD_FAIL_150', {
                boardOrgPath,
                errMsg: error?.message,
              });
              return of(BoardActions.UpdateBoardFail({ error }));
            }),
          );
      }),
    ),
  );

  swapBoardOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardActions.SwapBoardOrder),
      withLatestFrom(this._store.select(BoardSelectors.selectBoardEntities), this._store.select(DisplaySelectors.selectDisplayEntities)),
      concatMap(([action, boardEntities, displayEntities]) => {
        const getOrgPath = (board: IBoard) => board.organizationPath.split('.').slice(0, -1).join('.'); // Remove board name from org path

        // Get board from same orgPath
        const orgPath = getOrgPath(boardEntities[action.boardId]);
        const boards = Object.values(boardEntities).filter((board) => getOrgPath(board) === orgPath);

        // Effect is executed AFTER reducer.
        // Because of optimistic update, order is already set in the state.
        return forkJoin(
          boards.map((board) =>
            this._boardsService.updateBoard(board.id, {
              order: boardEntities[board.id].order,
              id: boardEntities[board.id].id,
              name: boardEntities[board.id].name,
              displays: boardEntities[board.id].displays.map((id) => displayEntities[id]),
            }),
          ),
        ).pipe(
          map(() => BoardActions.SwapBoardOrderSuccess({ ...action })),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.SWAP_BOARD_ORDER_FAIL_150', {
              boardName: boardEntities[action.boardId].name,
              boardFrom: action.fromIndex,
              boardTo: action.toIndex,
            });
            return of(BoardActions.SwapBoardOrderFail({ error, ...action }));
          }),
        );
      }),
    ),
  );

  updateBoardSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardActions.UpdateBoardSize),
      withLatestFrom(this._store.select(DisplaySelectors.selectDisplayEntities), this._store.select(BoardSelectors.selectBoardEntities)),
      concatMap(([action, displayEntities, boardEntities]) => {
        const displays = boardEntities[action.boardId].displays.map((id) => displayEntities[id]);
        const boardOrgPath = boardEntities[action.boardId].organizationPath;
        const newDisplays = convertToCreateAllDisplaysInBoardDTO(changeBoardSize(displays, action.boardSize));

        return this._displaysInBoardService.createOrReplaceAllDisplaysInBoard(action.boardId, newDisplays).pipe(
          map((displaysResp: DisplayDTO[]) =>
            BoardActions.UpdateBoardSizeSuccess({
              board: {
                id: action.boardId,
                changes: {
                  displays: displaysResp.map((e) => e.id),
                  size: action.boardSize,
                },
              },
              displays: displaysResp,
            }),
          ),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_BOARD_FAIL_150', {
              boardOrgPath,
              errMsg: error?.message,
            });
            return of(BoardActions.UpdateBoardSizeFail({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private _store: Store,
    private _siteService: SitesService,
    private _boardsService: BoardsService,
    private _boardTagsService: BoardTagsService,
    private _displaysInBoardService: DisplaysInBoardService,
    private _messengerNotificationService: MessengerNotificationService,
  ) {}
}
