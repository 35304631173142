<div *ngIf="site$ | async as site" class="site-detail-main-wrapper">
  <amp-site-selector [isAssignableMode]="false"
                     [attr.data-e2e]="'site-selector'"
                     (siteSelectionChanged)="onSiteSelectionChange($event)"></amp-site-selector>
  <h4 class="site-detail-main-title"
      *ngIf="(selectedSites$ | async).length === 0"
      [attr.data-e2e]="'site-detail-main-title'">
      {{ site | siteTitle }}
  </h4>

  <div class="site-detail-site-info" [attr.data-e2e]="'site-detail-site-info'">
    <div>
      <div class="site-detail-store-number" [attr.data-e2e]="'site-detail-store-number'">
        <span>{{ siteMonitoringFacade.getColumnLabel(SiteProperties.ExternalId) | async }}</span>
        <span>{{ site.externalId }}</span>
      </div>

      <div class="site-detail-address" [attr.data-e2e]="'site-detail-address'">
        {{ site | applyFunction: formatAddress:[]:this }}
      </div>
    </div>

    <avn-button
      [avnTheme]="THEME_TYPES.PRIMARY"
      [icon]="(editable$ | async) ? 'action:edit' : 'action:visibility'"
      [attr.data-e2e]="'edit-site-btn'"
      [routerLink]="'../edit'"
      queryParamsHandling="preserve">
    </avn-button>
  </div>

  <div class="button-middle-section">
    <a [routerLink]="'../../../' + (site$ | async).id" target="_blank">
      <avn-button
        [avnTheme]="THEME_TYPES.PRIMARY"
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.OPEN_IN_NEW_50' | transloco"
        [attr.data-e2e]="'open-new-tab-btn'">
      </avn-button>
    </a>

    <avn-button [avnTheme]="THEME_TYPES.PRIMARY"
                [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.BOARD_EDITOR_40' | transloco"
                [attr.data-e2e]="'board-detail-btn'"
                (actioned)="goToBoardDetail()"></avn-button>

    <ng-container>
      <avn-button
        *ampHasAnyAuthority="[CmRole.ROLE_ADMIN, CmRole.ROLE_NETWORK_ADMIN]"
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_20' | transloco"
        [icon]="'hardware:browser_updated'"
        [avnTheme]="THEME_TYPES.PRIMARY"
        [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_TO_PLAYERS_DESC_100' | transloco"
        [attr.data-e2e]="'push-to-players-btn'"
        (actioned)="onPushToPlayers()"
      ></avn-button>
    </ng-container>
  </div>

  <div class="site-detail-tag" [attr.data-e2e]="'site-detail-tag'">
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.TAGS_20' | transloco }}</h5>

    <div>
      <div class="site-detail-tag-button"
           [attr.data-e2e]="'site-tag-btn'"
           [routerLink]="'../tag'"
           queryParamsHandling="preserve">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.SITES_20' | transloco }}
        <avn-icon icon="navigation:chevron_right"></avn-icon>
      </div>
      <div class="site-detail-tag-button"
           [attr.data-e2e]="'board-screens-tag-btn'"
           [routerLink]="'../board'"
           queryParamsHandling="preserve">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.BOARDS_AND_SCREENS_60' | transloco }}
        <avn-icon icon="navigation:chevron_right"></avn-icon>
      </div>
    </div>
  </div>
</div>
