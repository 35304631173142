import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoadingState, ThemeType } from '@activia/ngx-components';
import { BehaviorSubject, filter, map, Observable, ReplaySubject } from 'rxjs';
import { ISiteMonitoringKeyMetricViewerData } from '../../../model/site-monitoring-key-metric-viewer-data.interface';
import { IKeyMetricSiteEnclosureStatus, KeyMetricEnclosureCategories, KeyMetricEnclosureCategory } from '../../../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { AlarmEventLevel, AlarmLevelsInfo } from '@amp/devices';
import { share } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { CountCardValueSize } from '@activia/dataviz';

/** Enclosure status by category */
type KeyMetricEnclosureStatus = Record<KeyMetricEnclosureCategory, { count: number; level: AlarmEventLevel; description: string }>;

/** Site-level enclosure status */
interface KeyMetricEnclosureSiteStatus {
  valid?: boolean;
  enclosureCount: number;
  count?: KeyMetricEnclosureStatus;
  theme?: ThemeType;
  icon?: string;
}

@Component({
  selector: 'amp-key-metrics-enclosure-status',
  templateUrl: './key-metrics-enclosure-status.component.html',
  styleUrls: ['./key-metrics-enclosure-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsEnclosureStatusComponent implements OnInit, OnChanges {
  @Input() keyMetric: ISiteMonitoringKeyMetricViewerData;

  /** Indicates if the card should show the compact view */
  @Input() showCompactView = false;

  /** Site-level enclosure status */
  enclosureStatus$: Observable<KeyMetricEnclosureSiteStatus>;
  enclosureStatusDataState$: Observable<LoadingState>;

  i18nMessages: { [key: string]: { [key: string]: string } } = {
    FAN_FAILURES: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FAN_FAILURES.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FAN_FAILURES.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FAN_FAILURES.MESSAGE.PLURAL_30',
    },
    FILTERS_DIRTY: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERS_DIRTY.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERS_DIRTY.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERS_DIRTY.MESSAGE.PLURAL_30',
    },
    LATCHES_NOT_ENGAGED: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHES_NOT_ENGAGED.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHES_NOT_ENGAGED.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHES_NOT_ENGAGED.MESSAGE.PLURAL_30',
    },
    DOORS_OPENED: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOORS_OPENED.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOORS_OPENED.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOORS_OPENED.MESSAGE.PLURAL_30',
    },
    THERMAL_TRIPS_ENGAGED: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMAL_TRIPS_ENGAGED.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMAL_TRIPS_ENGAGED.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMAL_TRIPS_ENGAGED.MESSAGE.PLURAL_30',
    },
  };

  AlarmLevelsInfo = AlarmLevelsInfo;
  CountCardValueSize = CountCardValueSize;

  private _enclosureStatus: KeyMetricEnclosureSiteStatus;
  private _keyMetricSubject = new BehaviorSubject<IKeyMetricSiteEnclosureStatus>(null);
  private _keyMetric$: Observable<IKeyMetricSiteEnclosureStatus> = this._keyMetricSubject.asObservable();

  /** Set the card dimensions the height of the parent. Used to keep the card square **/
  get cardDimensions(): number {
    return this._elementRef.nativeElement.parentElement.clientHeight;
  }

  constructor(public _elementRef: ElementRef, private _translocoService: TranslocoService) {}

  ngOnChanges({ keyMetric }: SimpleChanges) {
    if (keyMetric && keyMetric.currentValue) {
      this._keyMetricSubject.next(keyMetric.currentValue.data);
    }
  }

  ngOnInit() {
    this.enclosureStatus$ = this._keyMetric$.pipe(
      filter((status) => !!status),
      map((keyMetric) => {
        if (keyMetric.enclosureCount) {
          const isStatusOk = keyMetric.level == null || (this.keyMetric.config.customConfig.optimisticViewEnabled && keyMetric.level === AlarmEventLevel.Info);

          const statuses: KeyMetricEnclosureStatus = Object.keys(keyMetric?.count || {}).reduce((res, curr) => {
            return {
              ...res,
              [curr]: {
                ...keyMetric.count[curr],
                description: [this._translocoService.translate(KeyMetricEnclosureCategories[curr].i18n.dmbMessage), this._translocoService.translate(KeyMetricEnclosureCategories[curr].i18n.dmbAction)]
                  .filter((message) => !!message)
                  .join('. '),
              },
            };
          }, {} as KeyMetricEnclosureStatus);

          this._enclosureStatus = {
            valid: isStatusOk,
            enclosureCount: keyMetric.enclosureCount,
            count: statuses,
            icon: isStatusOk ? 'action:check_circle' : AlarmLevelsInfo[keyMetric.level].icon,
            theme: isStatusOk ? ThemeType.SUCCESS : AlarmLevelsInfo[keyMetric.level].theme,
          };
        } else {
          this._enclosureStatus = {
            enclosureCount: keyMetric.enclosureCount,
          };
        }

        return this._enclosureStatus;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.enclosureStatusDataState$ = this.enclosureStatus$.pipe(map((status) => (status === null ? LoadingState.LOADING : LoadingState.LOADED)));
  }

  /** A function that indicates if the key metric data is empty **/
  emptyEnclosureStatusFn(): boolean {
    return !this._enclosureStatus?.enclosureCount;
  }
}
