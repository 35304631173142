import { CMRole } from './roles.enum';
import { IAuthority } from './authority.interface';
import { PERMISSIONS } from './permissions.constant';

export type SiteManagementRestrictedSection = 'site' | 'tag' | 'apt' | 'orgPath' | 'appSettings';

/** Roles required to perform create/update/delete actions on each type of entity */
export const SITE_MANAGEMENT_ROLES: Record<SiteManagementRestrictedSection, CMRole[]> = {
  site: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN],
  tag: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN],
  apt: [CMRole.ROLE_ADMIN],
  orgPath: [CMRole.ROLE_ADMIN],
  appSettings: [CMRole.ROLE_ADMIN],
};

export const SITE_MONITORING_AUTHORITIES_REQUIRED: IAuthority = {
  roles: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN, CMRole.ROLE_DATA_SCHEDULER, CMRole.ROLE_HOTLINE],
  permissions: [PERMISSIONS.GET_MONITORING_VIEW, PERMISSIONS.SEARCH_DEVICES],
};

export const DEVICE_MONITORING_AUTHORITIES_REQUIRED: IAuthority = {
  roles: [CMRole.ROLE_ADMIN, CMRole.ROLE_HOTLINE],
  permissions: [PERMISSIONS.GET_MONITORING_VIEW, PERMISSIONS.SEARCH_DEVICES],
};
