<div class="board-header" *ampHasAnyAuthority="rolesRequiredToEdit">
  <ng-container *ngIf="createNewBoardOptions$ | async as createNewBoardOptions">
    <avn-button
      [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ADD_NEW_100' | transloco"
      icon="action:add"
      iconPosition="left"
      [avnTheme]="'primary'"
      [attr.data-e2e]="'new-board-btn'"
      #overlayOrigin="cdkOverlayOrigin"
      cdkOverlayOrigin
      (click)="createNewModalOpened = true"
    ></avn-button>
    <avn-connected-element
      [style.display]="'contents'"
      *ngIf="createNewModalOpened"
      [overlayOrigin]="overlayOrigin"
      [opened]="!!createNewModalOpened"
      (toggled)="createNewModalOpened = $event"
      [hasVisibleBackdrop]="false"
      [hasOverlayBackdrop]="false"
    >
      <avn-option-list [virtualization]="false"
                       [attr.data-e2e]="'board-option-list'"
                       [options]="createNewBoardOptions"
                       [optionsTemplate]="optionTemplate"
                       (optionClicked)="addBoard($event.value, $event.data.experienceTemplate)">
        <ng-template let-option="option" #optionTemplate>
          <div class="create-new-option-item" [attr.data-e2e]="option.label | kebabCase">
            <avn-icon [icon]="option.data.icon"></avn-icon>
            <span>{{ option.label }} </span>
            <avn-badge badgeSize="small" avnTheme="primary" *ngIf="option.data.boardCount > 0" [label]="option.data.boardCount"></avn-badge>
          </div>
        </ng-template>
      </avn-option-list>
    </avn-connected-element>
  </ng-container>
</div>
<div class="be-content" avnScroll>
  <div *ngIf="(boardsByOrgPath$ | async)?.size > 0; else emptyBoardConfig">
    <ng-container *ngFor="let boardsByOrgPath of boardsByOrgPath$ | async | keyvalue; trackBy: trackByOrgPath; let orgPathIndex = index">
      <div class="board-container"  *ngIf="boardsByOrgPath.value.length > 0" [attr.data-e2e]="'board-container'">
        <div class="orgPath-key">
          <avn-section-breadcrumb [sections]="boardsByOrgPath.value[0].tagsStructure"
          [attr.data-e2e]="'section-breadcrumb'"></avn-section-breadcrumb>
        </div>
        <div class="accordion-container" cdkDropList (cdkDropListDropped)="dropBoard(boardsByOrgPath.value, $event)">
          <avn-accordion [multiExpansionEnabled]="true">
            <avn-accordion-panel
              #boardElement
              cdkDrag
              *ngFor="let board of boardsByOrgPath.value; trackBy: trackById; let boardIdx = index"
              class="board-accordion"
              [avnBackground]="{ cssProp: '--data-table-row-odd-background', shade: 800 }"
              [expand]="board.isAccordionOpen"
              [headerTemplate]="editorSectionHeaderTemplate"
              [headerTemplateContext]="{ siteManagementBoard: board, orgPath: boardsByOrgPath.key, boardIdx: boardIdx }"
              (toggled)="toggleAccordion(board.id)"
              [attr.data-e2e]="'accordion-panel'"
            >
              <div class="accordion-panel-content">
                <div class="be-container" [class.empty-display]="board.displays.length === 0" avnScroll [ngStyle]="{ gridTemplateColumns: gridTemplateColumns(board.size.column) }">
                  <ng-container *ngIf="board.displays.length > 0; else emptyDisplay">
                    <ng-container *ngFor="let displayId of board | applyFunction : getDisplayArray$ : [] : this | async; trackBy: trackById; let displayIdx = index">
                      <div>
                        <div class="becd-inner">
                          <amp-site-management-display
                            [boardId]="board.id"
                            [isBoardLocked]="board.isLocked"
                            [displayIndex]="displayIdx"
                            [displayId]="displayId"
                            [displayOrigin]="'orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx"
                            [editable]="editable"
                            [experienceTemplate]="board.id | applyFunction : getExperienceTemplate : [] : this | async"
                            (shakeLock)="shakeLock($event ? 'orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx : '')"
                            [attr.data-e2e]="boardsByOrgPath.key + '-' + boardIdx"
                          >
                          </amp-site-management-display>
                        </div>
                      </div>
                      <div class="display-gap"></div>
                    </ng-container>
                  </ng-container>

                  <ng-template #emptyDisplay>
                    <avn-empty-data-message
                      icon="communication:desktop_access_disabled"
                      [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.EMPTY_DISPLAY_TITLE_50' | transloco"
                      [subText]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.EMPTY_DISPLAY_MESSAGE_100' | transloco"
                    ></avn-empty-data-message>
                  </ng-template>
                </div>
              </div>
            </avn-accordion-panel>
          </avn-accordion>

          <ng-template #editorSectionHeaderTemplate let-siteManagementBoard="siteManagementBoard" let-orgPath="orgPath" let-boardIdx="boardIdx">
            <div
              class="breadcrumb-items"
              (click)="clickOnBreadcrumb($event, siteManagementBoard.isAccordionOpen)"
              [avnDragDropElementOrigin]="boardsByOrgPath.key"
              [avnDragDropElementOriginData]="siteManagementBoard.displays"
              [avnDragDropElementOriginIndex]="boardIdx"
              [avnDragDropElementOriginDisabled]="siteManagementBoard.isLocked"
              [attr.data-e2e]="boardsByOrgPath.key"
            >
              <div>
                <div class="bi-breadcrumb-container">
                  <div class="accordion-footer">
                    <amp-site-management-lock
                      [disabled]="(siteManagementBoard.id | applyFunction : getBoardComplete$ : [] : this | async) === false"
                      [isLocked]="siteManagementBoard.isLocked"
                      [shakeBaord]="shakeBoard === 'orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx"
                      [modalTitle]="boardsByOrgPath.key + '-' + siteManagementBoard.name"
                      (lockStateUpdated)="toggleLock(siteManagementBoard.id)"
                      [attr.data-e2e]="'site-management-lock'"
                    ></amp-site-management-lock>
                  </div>
                  <div
                    class="breadcrumb-orgPath"
                    [ngClass]="{ 'is-lock': !!siteManagementBoard.isLocked }"
                    (mouseenter)="shakeLock('orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx)"
                    (mouseleave)="shakeLock('')"
                    (dblclick)="!siteManagementBoard.isLocked ? editBoard(orgPath, siteManagementBoard) : null"
                  >
                    <span class="breadcrumb-content"
                    [attr.data-e2e]="'board-name'">{{
                      (nameCase$ | async) === 'uppercase'
                        ? (siteManagementBoard.name | uppercase)
                        : (nameCase$ | async) === 'capitalize'
                        ? (siteManagementBoard.name | titlecase)
                        : siteManagementBoard.name
                    }}</span>
                  </div>
                  <div class="breadcrumb-layout" [attr.data-e2e]="'board-size-selector'" (mouseenter)="shakeLock('orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx)" (mouseleave)="shakeLock('')">
                    <div class="">
                      <span class="breadcrumb-header">{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.LAYOUT_10' | transloco | uppercase }}</span>
                      <div
                        #overlayOrigin="cdkOverlayOrigin"
                        cdkOverlayOrigin
                        (click)="!siteManagementBoard.isLocked && editable && toggleConnectedElementOpened(siteManagementBoard.organizationPath + '-' + boardIdx)"
                        class="grid-size-selector"
                        [class.disabled]="siteManagementBoard.isLocked || !editable"
                      >
                        <avn-icon [fontSize]="14" [icon]="'image:grid_on'"></avn-icon>
                        <span>{{ siteManagementBoard.size.column }} x {{ siteManagementBoard.size.row }}</span>
                        <avn-icon
                          [icon]="'hardware:keyboard_arrow_down'"
                          [class]="{ active: openedGridSizeSelector === siteManagementBoard.organizationPath + '-' + boardIdx, disabled: siteManagementBoard.isLocked }"
                          [fontSize]="18"
                        ></avn-icon>
                      </div>

                      <avn-connected-element
                        [overlayOrigin]="overlayOrigin"
                        [minWidth]="'auto'"
                        [opened]="openedGridSizeSelector === siteManagementBoard.organizationPath + '-' + boardIdx"
                        (clickOutside)="!siteManagementBoard.isLocked && toggleConnectedElementOpened()"
                      >
                        <div class="connected-element">
                          <avn-grid-size-selector
                            [attr.data-e2e]="'board-size-selector'"
                            [avnTheme]="themeType.PRIMARY"
                            [minGridSize]="{ row: 3, column: 5 }"
                            [maxGridSize]="{ row: 5, column: 10 }"
                            [selectedGridSize]="siteManagementBoard.size"
                            [minSelectedGridSize]="siteManagementBoard.minSelectableSize"
                            (gridItemClicked)="onGridSizeSelected(siteManagementBoard.id, $event)"
                          ></avn-grid-size-selector>
                        </div>
                      </avn-connected-element>
                    </div>
                  </div>
                </div>
              </div>
              <div class="breadcrum-action">
                <avn-icon
                  class="context-menu"
                  [attr.data-e2e]="'context-menu'"
                  [icon]="'navigation:more_vert'"
                  [fontSize]="24"
                  avnContextMenu
                  [contextMenuDataSourceTemplate]="contextMenuTemplate"
                  [contextMenuPlacement]="'left'"
                  [contextMenuCloseOnItemSelected]="true"
                ></avn-icon>
              </div>

              <ng-template #contextMenuTemplate>
                <div class="site-device-actions-context-menu-actions">
                  <avn-button
                    *ngFor="let action of contextMenuDataSource$ | async"
                    [attr.data-e2e]="'board-' + action.action"
                    [icon]="action.icon"
                    [iconPosition]="'left'"
                    [label]="action.label"
                    [disabled]="siteManagementBoard.isLocked && action.action !== 'tags'"
                    (actioned)="onContextMenuAction(action.action, orgPath, siteManagementBoard)"
                    (mouseenter)="shakeLock('orgPathIdx-' + boardsByOrgPath.key + '-' + boardIdx)"
                    (mouseleave)="shakeLock('')"
                  >
                  </avn-button>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #emptyBoardConfig>
    <avn-empty-data-message
      icon="hardware:tv"
      [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_TITLE_50' | transloco"
      [subText]="
        editable
          ? ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_MESSAGE_100' | transloco)
          : ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_READONLY_MESSAGE_100' | transloco)
      "
      [attr.data-e2e]="'empty-data-message'"
    >
    </avn-empty-data-message>
  </ng-template>
</div>

<ng-template #saveErrors let-errors="errors">
  <div style="display: flex; justify-content: space-between; margin-bottom: var(--space-base)">
    <avn-icon style="margin-right: var(--space-md)" [icon]="'alert:warning'" [fontSize]="42" [avnTheme]="'warning'"> </avn-icon>
    <span>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.SAVE_FAIL.MESSAGE_100' | transloco }}</span>
  </div>
  <div style="margin-bottom: var(--space-base)" *ngFor="let actionErrors of errors | keyvalue">
    <div>
      <b>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.SAVE_FAIL.' + actionErrors.key.toUpperCase() + '.MESSAGE_100' | transloco }}</b>
    </div>

    <ng-container *ngFor="let actionError of actionErrors.value">
      <ng-container *ngIf="actionErrors.key !== 'screen'">
        <div>{{ 'Path: ' + actionError.datum.organizationPath + ' - Name: ' + actionError.datum.name + ' - Error: ' + actionError.error.error.message }}</div>
      </ng-container>
      <ng-container *ngIf="actionErrors.key === 'screen'">
        <div>{{ 'Board ID ' + actionError.datum.parentBoardId + ' - Error: ' + actionError.error.error.message }}</div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<avn-loader [isLoading]="!!(creatingTemplateBoardMessage$ | async)" [displayText]="creatingTemplateBoardMessage$ | async" [avnTheme]="'primary'"></avn-loader>
