import { Action, createReducer, on } from '@ngrx/store';
import {
  SiteSyncFixGeodeticCoordinates,
  SiteSyncFixSiteAddress,
  SiteSyncProcessCsvFile,
  SiteSyncUpdateSiteProgress,
  SiteSyncUpdateSiteRequestChainState,
  SiteSyncResumeCsvSiteProcessing,
  SiteSyncValidateCsvFile,
  SiteSyncValidateCsvFileError,
  SiteSyncValidateCsvFileSuccess,
  SiteSyncResetState,
  SiteSyncUpdateSiteInfo,
  SiteSyncFixMissingRequiredValue
} from './site-sync.actions';
import { ISiteSyncProgress, ISiteSyncState, SiteSyncGlobalStatus, SiteSyncStepId } from './site-sync.model';
import { hasAsyncDataError, LoadingState } from '@activia/ngx-components';
import { DeviceTypeDTO } from '@activia/cm-api';
import { geoAddressToApiAddress } from '@amp/site-monitoring-shared';

export interface ISiteManagementSyncState {
  status: SiteSyncGlobalStatus; // Global status for the whole csv file process
  messages: string[]; // List of feedback messages associated with the csv parsing process
  sitesSyncState: Record<string, ISiteSyncState>;
  inProgressCount: number; // indicates how many jobs are in progress in the global sync process (for performance, mainly to avoid recalculations)
  fixedMissingRequiredValueSites: string[]; // uids of sites for which the missing required value warnings were fixed
  fixedGeoSites: string[]; // uids of sites for which the geo warnings were fixed
  deviceTypes: DeviceTypeDTO[];
}

export const siteManagementSyncInitialState: ISiteManagementSyncState = {
  status: 'init',
  inProgressCount: 0,
  messages: null,
  sitesSyncState: {},
  fixedMissingRequiredValueSites: [],
  fixedGeoSites: [],
  deviceTypes: [],
};

const reducer = createReducer<ISiteManagementSyncState>(
  siteManagementSyncInitialState,

  /** Resets the state **/
  on(SiteSyncResetState, () => ({ ...siteManagementSyncInitialState })),

  /** Validate CSV file **/
  on(SiteSyncValidateCsvFile, (state, _) => ({
    ...state,
    status: 'validating',
    sitesSyncState: null,
  })),

  on(SiteSyncValidateCsvFileSuccess, (state, { sitesSyncState }) => ({
    ...state,
    status: 'valid',
    sitesSyncState,
  })),

  on(SiteSyncValidateCsvFileError, (state, { status, messages }) => ({
    ...state,
    status,
    messages,
  })),

  /** Process CSV file **/
  on(SiteSyncProcessCsvFile, (state, { deviceTypes }) => ({
    ...state,
    status: 'in-progress',
    deviceTypes,
    inProgressCount: Object.values(state.sitesSyncState).filter(({ validationStatus }) => ['add', 'update'].includes(validationStatus)).length,
  })),

  on(SiteSyncValidateCsvFileError, (state, { status, messages }) => ({
    ...state,
    status,
    messages,
  })),

  on(SiteSyncResumeCsvSiteProcessing, (state, { siteUid }) => {
    const currentSyncState = state.sitesSyncState[siteUid];
    const isAlreadyStarted = !!currentSyncState.syncProgress;
    return {
      ...state,
      sitesSyncState: {
        ...state.sitesSyncState,
        [siteUid]: {
          ...state.sitesSyncState[siteUid],
          syncProgress: !isAlreadyStarted
            ? {
                status: 'queued',
                progress: 0,
                loadingState: LoadingState.INIT,
                stepId: null,
              }
            : {
                ...currentSyncState.syncProgress,
                status: 'queued',
              },
        },
      },
    };
  }),

  on(SiteSyncUpdateSiteRequestChainState, (state, { siteUid, requestChainState }) => ({
    ...state,
    sitesSyncState: {
      ...state.sitesSyncState,
      [siteUid]: {
        ...state.sitesSyncState[siteUid],
        syncProgress: {
          ...state.sitesSyncState[siteUid].syncProgress,
          requestChainState,
        },
      },
    },
  })),

  on(SiteSyncUpdateSiteProgress, (state, { siteUid, requestChainStateChangeEvent }) => {
    const { progress, loadingState, requestId, errorInfo, requestChainState } = requestChainStateChangeEvent;
    // If we had a geo error, it could be a warning or an error, so check and update the site sync status accordingly
    const errorValidationResponse = errorInfo?.data?.dataValidationResponse?.response as 'warning' || errorInfo?.data?.geoResponse?.response as 'warning' | 'error';
    const updatedSyncStatus = errorValidationResponse ? errorValidationResponse : hasAsyncDataError(loadingState) ? 'error' : progress === 100 && loadingState === LoadingState.LOADED ? 'processed' : 'processing';
    // If there is site data to update in the store (currently only after success geo validation will return site with long + lat)
    const site = requestChainState.responses[requestId]?.result?.site;

    const currentSyncProgress = state.sitesSyncState[siteUid].syncProgress;
    const newSyncProgress: ISiteSyncProgress = {
      ...currentSyncProgress,
      status: updatedSyncStatus,
      progress,
      loadingState,
      errorInfo,
      stepId: requestId as SiteSyncStepId,
      requestChainState,
    };

    // update the number of sites being processed if its completed and mark the whole csv job process as completed if all sites are processed
    let inProgressCount = state.inProgressCount;
    let syncJobStatus = state.status;
    if (updatedSyncStatus === 'processed') {
      inProgressCount--;
      if (inProgressCount === 0) {
        syncJobStatus = 'finished';
      }
    }

    return {
      ...state,
      inProgressCount,
      status: syncJobStatus,
      sitesSyncState: {
        ...state.sitesSyncState,
        [siteUid]: {
          ...state.sitesSyncState[siteUid],
          ...(site ? { site: { ...state.sitesSyncState[siteUid].site, ...site } } : {}),
          syncProgress: newSyncProgress,
        },
      },
    };
  }),

  on(SiteSyncUpdateSiteInfo, (state, { siteUid, site }) => ({
    ...state,
    sitesSyncState: {
      ...state.sitesSyncState,
      [siteUid]: {
        ...state.sitesSyncState[siteUid],
        site,
      },
    },
  })),

  on(SiteSyncFixMissingRequiredValue, (state, { siteUid, site }) => ({
      ...state,
      sitesSyncState: {
        ...state.sitesSyncState,
        [siteUid]: {
          ...state.sitesSyncState[siteUid],
          ...{
            site: {
              ...state.sitesSyncState[siteUid].site,
              ...site,
            },
          },
        },
      },
      fixedMissingRequiredValueSites: [...state.fixedMissingRequiredValueSites, siteUid],
    })),

  on(SiteSyncFixGeodeticCoordinates, (state, { siteUid, geodeticCoordinates, keepCsvValue }) => ({
    ...state,
    sitesSyncState: {
      ...state.sitesSyncState,
      [siteUid]: {
        ...state.sitesSyncState[siteUid],
        ...(keepCsvValue ? {} : { site: { ...state.sitesSyncState[siteUid].site, geodeticCoordinates } }),
      },
    },
    fixedGeoSites: [...state.fixedGeoSites, siteUid],
  })),

  on(SiteSyncFixSiteAddress, (state, { siteUid, addressGeoResult, country, keepCsvValue }) => ({
    ...state,
    sitesSyncState: {
      ...state.sitesSyncState,
      [siteUid]: {
        ...state.sitesSyncState[siteUid],
        ...(keepCsvValue ?
          {} :
          {
            site: {
              ...state.sitesSyncState[siteUid].site,
              geodeticCoordinates: addressGeoResult.location,
              address: {
                ...geoAddressToApiAddress(addressGeoResult.address, country),
                addressLine2: state.sitesSyncState[siteUid].site.address.addressLine2,
              }
            }
          }),
      },
    },
    fixedGeoSites: [...state.fixedGeoSites, siteUid],
  })),
);

export const siteSyncReducer = (state: ISiteManagementSyncState | undefined, action: Action): ISiteManagementSyncState => reducer(state, action);
