<ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT'">
  <avn-page>
    <avn-ribbon [title]="t('DASHBOARD.TITLE_50')" [fabIcons]="(isAdmin$ | async) ? 'action:settings' : null" (fabActionClicked)="openSettingsDrawer()" [attr.data-e2e]="'siteManagementHeader'"> </avn-ribbon>

    <avn-main avnScroll>
      <div class="cards">
        <avn-menu-card avnTheme="primary"
                       [title]="t('DASHBOARD.CARDS.ALL_SITES.TITLE_50')"
                       [subTitle]="t('DASHBOARD.CARDS.ALL_SITES.SUBTITLE_50')"
                       [routerLink]="['sites']"
                       [attr.data-e2e]="'sites-card'">
          <span>{{ t('DASHBOARD.CARDS.ALL_SITES.DESCRIPTION_100') }}</span>
          <div class="button-list" *ampHasAnyAuthority="rolesRequiredToEdit">
            <avn-button [label]="t('DASHBOARD.CARDS.ALL_SITES.ACTION_BUTTONS.ADD_SITE_20')"
                        [icon]="'social:location_city'"
                        (actioned)="$event.stopPropagation(); openSiteCreationModal()"
                        [attr.data-e2e]="'sites-btn'">
            </avn-button>
          </div>
        </avn-menu-card>

        <ng-container *ampHasAnyAuthority="rolesRequiredToEdit">
          <avn-menu-card
            *ngIf="geoProblematicSitesCount$ | async"
            avnTheme="warning"
            [title]="t('DASHBOARD.CARDS.FIX_SITES.TITLE_50')"
            [subTitle]="t('DASHBOARD.CARDS.FIX_SITES.SUBTITLE_50')"
            [routerLink]="['sites-fix']"
            [attr.data-e2e]="'sites-fix-card'">
            <span> {{ t('DASHBOARD.CARDS.FIX_SITES.DESCRIPTION_100') }}</span>
          </avn-menu-card>
        </ng-container>

        <avn-menu-card *ampHasAnyAuthority="rolesRequiredToEdit"
                       [title]="t('DASHBOARD.CARDS.ADD_SITE.TITLE_50')"
                       [subTitle]="t('DASHBOARD.CARDS.ADD_SITE.SUBTITLE_50')"
                       [attr.data-e2e]="'add-sites-card'">
          <span>{{ t('DASHBOARD.CARDS.ADD_SITE.DESCRIPTION_100') }}</span>
          <div class="button-list">
            <avn-button
              [avnTheme]="'primary'"
              [label]="t('DASHBOARD.CARDS.ADD_SITE.TITLE_50')"
              [icon]="'social:location_city'"
              [attr.data-e2e]="'add-site-btn'"
              (actioned)="$event.stopPropagation(); openSiteCreationModal()"
            ></avn-button>
          </div>
        </avn-menu-card>

        <avn-menu-card *ampHasAnyAuthority="rolesRequiredToEdit"
                       [title]="t('DASHBOARD.CARDS.SYNC_SITES.TITLE_50')"
                       [subTitle]="t('DASHBOARD.CARDS.SYNC_SITES.SUBTITLE_50')"
                       [routerLink]="['sync']"
                       [attr.data-e2e]="'sync-sites-card'">
          <span>{{ t('DASHBOARD.CARDS.SYNC_SITES.DESCRIPTION_100') }}</span>
          <div class="button-list">
            <avn-button
              [avnTheme]="'primary'"
              [label]="t('SYNC_SITES.BUTTON_DOWNLOAD_CSV_FILE_30')"
              [icon]="'content:download'"
              [disabled]="hasSyncJobInProgress$ | async"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.SYNC_SITES_DISABLED_TOOLTIP_50')"
              [tooltipDisabled]="(hasSyncJobInProgress$ | async) === false"
              [attr.data-e2e]="'download-csv-file-btn'"
              (actioned)="$event.stopPropagation(); exportCsvSites()"
            ></avn-button>
            <avn-button
              [avnTheme]="'primary'"
              [label]="t('SYNC_SITES.BUTTON_UPLOAD_CSV_FILE_30')"
              [icon]="'content:upload'"
              [disabled]="hasSyncJobInProgress$ | async"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.SYNC_SITES_DISABLED_TOOLTIP_50')"
              [tooltipDisabled]="(hasSyncJobInProgress$ | async) === false"
              [attr.data-e2e]="'upload-csv-file-btn'"
              (actioned)="$event.stopPropagation(); importCsvSites()"
            ></avn-button>
          </div>
        </avn-menu-card>

        <avn-menu-card
          *ampHasFeature="'siteManagement.experiences'"
          [title]="'DASHBOARD.CARDS.EXPERIENCES.TITLE_50'"
          [subTitle]="'DASHBOARD.CARDS.EXPERIENCES.SUBTITLE_50'"
          [routerLink]="['experiences']"
          [attr.data-e2e]="'experiences-card'"
        >
          <span>{{ 'DASHBOARD.CARDS.EXPERIENCES.DESCRIPTION_100' }}</span>
        </avn-menu-card>

        <avn-menu-card
          *ampHasFeature="'siteManagement.orgPathDefinition'"
          [title]="t('DASHBOARD.CARDS.ORGANIZE_SITES.TITLE_50')"
          [subTitle]="t('DASHBOARD.CARDS.ORGANIZE_SITES.SUBTITLE_50')"
          [routerLink]="['site-org']"
          [attr.data-e2e]="'site-org-card'">
          <span>{{ t('DASHBOARD.CARDS.ORGANIZE_SITES.DESCRIPTION_100') }}</span>
          <div class="button-list">
            <avn-button [avnTheme]="'primary'"
                        [label]="t('DASHBOARD.CARDS.ACTION_BUTTONS.SITES_10')"
                        icon="content:web_stories"
                        [routerLink]="['site-org']"
                        [attr.data-e2e]="'site-org-btn'">
            </avn-button>
          </div>
        </avn-menu-card>

        <avn-menu-card
          *ampHasFeature="'siteManagement.orgPathDefinition'"
          [title]="t('DASHBOARD.CARDS.ORGANIZE_BOARDS.TITLE_50')"
          [subTitle]="t('DASHBOARD.CARDS.ORGANIZE_BOARDS.SUBTITLE_50')"
          [routerLink]="['board-org']"
          [attr.data-e2e]="'board-org-card'">
          <span>{{ t('DASHBOARD.CARDS.ORGANIZE_BOARDS.DESCRIPTION_100') }}</span>
          <div class="button-list">
            <avn-button [avnTheme]="'primary'"
                        [label]="t('DASHBOARD.CARDS.ACTION_BUTTONS.BOARDS_10')"
                        icon="content:web_stories"
                        [routerLink]="['board-org']"
                        [attr.data-e2e]="'board-org-btn'">
            </avn-button>
          </div>
        </avn-menu-card>
      </div>
    </avn-main>
  </avn-page>
</ng-container>
