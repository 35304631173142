<form [formGroup]="form">
  <avn-form-field [enableValidTheme]="false">
    <avn-checkbox-group
      formControlName="services"
      [items]="[
        { value: SiteMonitoredValue.ServicePlayer, label: getServiceI18nLabel(SiteMonitoredValue.ServicePlayer) | transloco },
        { value: SiteMonitoredValue.HttpService, label: getServiceI18nLabel(SiteMonitoredValue.HttpService) | transloco },
      ]"
      [direction]="'vertical'"
    ></avn-checkbox-group>
    <avn-form-field-error key="required" [message]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.SETTINGS.SERVICES.VALIDATION.REQUIRED_50' | transloco"></avn-form-field-error>
  </avn-form-field>
</form>
