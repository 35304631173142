import { IColumnDefinition } from '@amp/column-picker';
import { SiteProperties } from './site-properties.enum';
import { SiteMonitoredValue } from './site-monitored-value.enum';
import { ISiteMonitoringColumnPickerExtraConfig } from './site-monitoring-column-picker-extra-config.interface';
import { getSiteMonitoringColumnDefinition } from '../utils/site-monitoring-columns.utils';

/** Represents a category and its children **/
export interface ISiteMonitoringColumnsCategory {
  id: SiteMonitoringColumnsCategories;
  label: string;
  children: IColumnDefinition<ISiteMonitoringColumnPickerExtraConfig>[];
}

export enum SiteMonitoringColumnsCategories {
  SITE_PROPERTIES = 'site_properties',
  SUMMARY_INDICATORS = 'summary_indicators',
  CONTENT_ACCURACY = 'content_accuracy',
  MONITORING = 'monitoring',
  CONFIGURATION = 'configuration',
  CRITICAL_SYSTEM_COMPONENTS = 'critical_system_components',
}

/** Defines the categories for the monitoring module columns **/
export const SITE_MONITORING_COLUMNS_CATEGORIES: ISiteMonitoringColumnsCategory[] = [
  {
    id: SiteMonitoringColumnsCategories.SITE_PROPERTIES,
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.SITE_PROPERTIES.TITLE_35',
    children: [
      getSiteMonitoringColumnDefinition(SiteProperties.Id),
      getSiteMonitoringColumnDefinition(SiteProperties.ExternalId),
      getSiteMonitoringColumnDefinition(SiteProperties.Name),
      getSiteMonitoringColumnDefinition(SiteProperties.Street),
      getSiteMonitoringColumnDefinition(SiteProperties.City),
      getSiteMonitoringColumnDefinition(SiteProperties.State),
      getSiteMonitoringColumnDefinition(SiteProperties.Country),
      getSiteMonitoringColumnDefinition(SiteProperties.ZipPostalCode),
      getSiteMonitoringColumnDefinition(SiteProperties.DeviceCount),
    ],
  },
  {
    id: SiteMonitoringColumnsCategories.SUMMARY_INDICATORS,
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.SUMMARY_INDICATORS.TITLE_35',
    children: [
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.HealthStatus),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.Uptime),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.LastUpdate),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.LastAttempt),
    ],
  },
  {
    id: SiteMonitoringColumnsCategories.CONTENT_ACCURACY,
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.CONTENT_ACCURACY.TITLE_35',
    children: [
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.ContentStatus),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.LastContentUpdate),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.LastContentUpToDate),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.LastContentFailedUpdate),
      // Configuration
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.TagsLastModificationTime),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.DynamicTagsLastModificationTime),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.DevicePropertiesStatus),
    ],
  },
  {
    id: SiteMonitoringColumnsCategories.MONITORING,
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.MONITORING.TITLE_35',
    children: [
      // System metrics
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.CpuPercent),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.SystemDisk),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.SystemMemory),
      // Critical system
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.HttpService),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.ServicePlayer),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.PosState),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.PosLastUpdate),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.PosLastModified),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.CodState),
      getSiteMonitoringColumnDefinition(SiteMonitoredValue.CodLastUpdate),
    ],
  },
];

/**
 * Specifies the mandatory columns that can be removed
 * *
 */
export const SITE_MONITORING_COLUMNS_MANDATORY: string[] = [SiteProperties.ExternalId];
