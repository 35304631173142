import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { preloadProfile } from '../utils/site-monitoring-guards.utils';
import { SiteMonitoringFacade } from '../store/site-monitoring.facade';

@Injectable({ providedIn: 'root' })
export class PreloadProfileGuard  {
  constructor(private _siteMonitoringFacade: SiteMonitoringFacade) {}

  /**
   * Fetches the requested data for the route
   */
  canActivate(): Observable<boolean> {
    return preloadProfile(this._siteMonitoringFacade).pipe(
      mapTo(true)
    );
  }
}
