import { IDataTableColumnConfig } from '@activia/ngx-components';

import { DeviceProperty } from './device-properties.enum';
import { SiteProperty } from './site-properties.enum';

export const DEVICE_PROPERTIES_MAP = new Map<DeviceProperty, string>([
  [DeviceProperty.Id, 'id'],
  [DeviceProperty.Name, 'deviceInfo.deviceName'],
  [DeviceProperty.Hostname, 'deviceInfo.hostname'],
  [DeviceProperty.HostnameIp, 'deviceInfo.hostnameIp'],
  [DeviceProperty.IpAddress, 'deviceInfo.ipAddress'],
  [DeviceProperty.MacAddress, 'deviceInterfaces[].macAddress'],
  [DeviceProperty.OperationalState, 'deviceInfo.operationalState'],
  [DeviceProperty.ResolveHostname, 'deviceInfo.resolveHostname'],
  [DeviceProperty.Serial, 'deviceInfo.serial'],
  [DeviceProperty.Brand, 'deviceInfo.deviceType.brand'],
  [DeviceProperty.Model, 'deviceInfo.deviceType.model'],
  [DeviceProperty.Series, 'deviceInfo.deviceType.series'],
  [DeviceProperty.Version, 'systemModule.version'],
  [DeviceProperty.Type, 'deviceInfo.deviceType.type'],
  // Deprecated
  [DeviceProperty.Longitude, 'deviceInfo.deviceLocationInfo.longitude'],
  [DeviceProperty.Latitude, 'deviceInfo.deviceLocationInfo.latitude'],
  [DeviceProperty.Street, 'deviceInfo.deviceLocationInfo.location.level5'],
  [DeviceProperty.City, 'deviceInfo.deviceLocationInfo.location.level4'],
  [DeviceProperty.State, 'deviceInfo.deviceLocationInfo.location.level3'],
  [DeviceProperty.Country, 'deviceInfo.deviceLocationInfo.location.level2'],
  [DeviceProperty.Continent, 'deviceInfo.deviceLocationInfo.location.level1'],
  [DeviceProperty.PostalCode, 'deviceInfo.deviceLocationInfo.location.zip'],
]);

export const SITE_PROPERTIES_MAP = new Map<SiteProperty, string>([
  [SiteProperty.SiteExternalId, 'externalId'],
  [SiteProperty.SiteAddressLine1, 'address.addressLine1'],
  [SiteProperty.SiteAddressLine2, 'address.addressLine2'],
  [SiteProperty.SiteCity, 'address.city'],
  [SiteProperty.SiteCountry, 'address.country'],
  [SiteProperty.SiteState, 'address.state'],
  [SiteProperty.SiteZip, 'address.zip'],
]);

/**
 * Map of all sortable columns and the corresponding identifier to pass to api calls
 * missing props:
 *  - DeviceProperty.ResolveHostname
 *  - DeviceProperty.Name
 */
export const DEVICE_SORTABLE_FIELD_MAP = new Map<DeviceProperty, string>([
  [DeviceProperty.Id, 'device.id'],
  [DeviceProperty.HostnameIp, 'device.deviceInfo.hostname'],
  [DeviceProperty.IpAddress, 'device.deviceInfo.ipAddress'],
  [DeviceProperty.MacAddress, 'device.deviceInterfaces[].macAddress'],
  [DeviceProperty.OperationalState, 'device.deviceInfo.operationalState'],
  [DeviceProperty.Serial, 'device.deviceInfo.serial'],
  [DeviceProperty.Brand, 'device.deviceInfo.deviceType.brand'],
  [DeviceProperty.Model, 'device.deviceInfo.deviceType.model'],
  [DeviceProperty.Series, 'device.deviceInfo.deviceType.series'],
  [DeviceProperty.Type, 'device.deviceInfo.deviceType.type'],

  // Deprecated attribute
  [DeviceProperty.Longitude, 'device.deviceInfo.deviceLocationInfo.longitude'],
  [DeviceProperty.Latitude, 'device.deviceInfo.deviceLocationInfo.latitude'],
  [DeviceProperty.Street, 'device.deviceInfo.deviceLocationInfo.location.level5'],
  [DeviceProperty.City, 'device.deviceInfo.deviceLocationInfo.location.level4'],
  [DeviceProperty.State, 'device.deviceInfo.deviceLocationInfo.location.level3'],
  [DeviceProperty.Country, 'device.deviceInfo.deviceLocationInfo.location.level2'],
  [DeviceProperty.Continent, 'device.deviceInfo.deviceLocationInfo.location.level1'],
  [DeviceProperty.PostalCode, 'device.deviceInfo.deviceLocationInfo.location.zip'],
]);

/**
 * This is the identifier to use for api calls when sorting on a monitored value
 * Needs to be suffixed by the actual name of the monitored value
 * e.g. device.deviceInfo.sample.monitoredValues=SYSTEM_MEMORY
 */
export const DEVICE_SORTABLE_MONITORED_VALUE = 'device.deviceInfo.sample.monitoredValues=';

// Below are the fields from deviceInfo field in a device object
export const DEVICE_COLUMNS: IDataTableColumnConfig<any>[] = Array.from(DEVICE_PROPERTIES_MAP.keys()).map((key) => ({
  id: key,
  prop: DEVICE_PROPERTIES_MAP.get(key),
  name: `DEVICE.PROPERTY.${key}.NAME`,
  widthRatio: 1,
  sortable: !!DEVICE_SORTABLE_FIELD_MAP.get(key),
  draggable: true,
  resizable: true,
}));
