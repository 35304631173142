<ng-container *ngIf="value !== null && value !== undefined">
  <!-- Display according to the display type -->

  @switch (displayType) { @case (DisplayType.Raw) {
  {{ value }}
  } @case (DisplayType.Duration) {
  {{ value ? (value | duration) : '' }}
  } @case (DisplayType.DateTime) {
  {{ value ? (value | date: 'medium') : '' }}
  } @case (DisplayType.TimeAgo) {
  {{ value | timeAgo | async }}
  } @case (DisplayType.Percent) {
  {{ value ? percentValidation(value) + '%' : '' }}
  } @case (DisplayType.Boolean) {
  {{ value ? 'true' : 'false' }}
  } @case (DisplayType.Numeric) {
  {{ value || '0' }}
  } @case (DisplayType.TwoDecimals) {
  {{ value ? value.toFixed(2) : '0.00' }}
  } @case (DisplayType.Currency) {
  {{ (value ? value : 0) | currency: 'CAD':'symbol-narrow':'4.2-2' }}
  } @case (DisplayType.TwoLines) {
  <ng-container *ngTemplateOutlet="twoLines"></ng-container>
  } @case (DisplayType.HostnameIp) {
  <span class="hostname-ip" [attr.data-e2e]="'hostname-ip'">
    <a *ngIf="(url || '').length > 0; else twoLines" [routerLink]="[url]"> {{ value.line1 }}<br />{{ value.line2 }} </a>
  </span>
  } @case (DisplayType.RawWithTooltip) {
  <div class="raw-tooltip" [attr.data-e2e]="'raw-tooltip'" [avnTooltip]="value" [tooltipPlacement]="'top'">{{ value }}</div>
  } @case (DisplayType.HealthStatus) {
  <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
  } @case (DisplayType.HealthErrorIds) {
  <ng-container *ngTemplateOutlet="healthErrorsIndicator"></ng-container>
  } @case (DisplayType.Status) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (DisplayType.ServiceStatus) {
  <ng-container *ngTemplateOutlet="serviceStatusIndicator"></ng-container>
  } @case (DisplayType.OperationalState) {
  <ng-container *ngTemplateOutlet="operationalStateIndicator"></ng-container>
  } @case (DisplayType.PercentChart) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (DisplayType.PercentPieChart) {
  <ng-container *ngTemplateOutlet="percentagePieIndicator"></ng-container>
  } @case (DisplayType.PlayerVersion) {
  <ng-container *ngTemplateOutlet="playerVersionIndicator"></ng-container>
  } @case (DisplayType.ContentStatus) {
  <ng-container *ngTemplateOutlet="contentStatusIndicator"></ng-container>
  } @case (DisplayType.ContentStatusErrorIds) {
  <ng-container *ngTemplateOutlet="contentStatusErrorIdsIndicator"></ng-container>
  } @case (DisplayType.DevicePropertiesStatus) {
  <ng-container *ngTemplateOutlet="devicePropertiesStatusIndicator"></ng-container>
  } }

  <!-- Display according to the column id -->
  @if (!displayType) { @switch (field) { @case (MonitoredValue.Uptime) {
  {{ value ? (value | duration) : '' }}
  } @case (MonitoredValue.SystemDisk) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (MonitoredValue.CpuPercent) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (MonitoredValue.SystemMemory) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (MonitoredValue.HttpService) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (MonitoredValue.FtpStatus) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (MonitoredValue.FtpStatus) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (MonitoredValue.ServicePlayer) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (MonitoredValue.PlayerState) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @case (MonitoredValue.PlayerVersion) {
  <ng-container *ngTemplateOutlet="playerVersionIndicator"></ng-container>
  } @case (MonitoredValue.HealthStatus) {
  <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
  } @case (MonitoredValue.HealthErrorIds) {
  <ng-container *ngTemplateOutlet="healthErrorsIndicator"></ng-container>
  } @case (MonitoredValue.ProxyByteHitRatio) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (MonitoredValue.ProxyHitRatio) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (MonitoredValue.ProxyMemory) {
  <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
  } @case (DeviceProperty.OperationalState) {
  <ng-container *ngTemplateOutlet="operationalStateIndicator"></ng-container>
  } @default {
  <!-- Logical player state -->
  @if (isPlayerState(field)) {
  <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
  } @else {
  {{ value }}
  } } } }
</ng-container>

<ng-template #twoLines> {{ value.line1 }}<br />{{ value.line2 }} </ng-template>

<ng-template #percentageIndicator let-threshold="threshold">
  <div class="percentage-indicator" *ngIf="value !== undefined">
    <div class="gauge" [style.width.%]="percentValidation(value)" [avnThemify]="value < threshold ? 'success' : 'danger'"></div>
    <span>{{ value >= 0 ? value : 0 }}%</span>
  </div>
</ng-template>

<ng-template #percentagePieIndicator>
  <div class="percentage-pie-chart-indicator" *ngIf="value !== undefined">
    <avn-percent-donut
      [style.width.px]="'40'"
      [style.height.px]="'40'"
      [value]="percentValidation(value)"
      [valueSuffix]="'%'"
      [color]="!value || value <= 70 ? 'var(--success-base)' : value <= 85 ? 'var(--warning-base)' : 'var(--danger-base)'"
    >
    </avn-percent-donut>
  </div>
</ng-template>

<ng-template #statusIndicator>
  <avn-badge *ngIf="value !== undefined" [badgeSize]="badgeSize" [label]="!!value ? 'OK' : 'Fail'" [avnTheme]="!!value ? ThemeType.SUCCESS : ThemeType.DANGER"></avn-badge>
</ng-template>

<ng-template #serviceStatusIndicator>
  <avn-badge *ngIf="value !== undefined" [badgeSize]="badgeSize" [label]="!!value ? 'up' : 'down'" [avnTheme]="!!value ? ThemeType.SUCCESS : ThemeType.DANGER"></avn-badge>
</ng-template>

<ng-template #healthStatusIndicator>
  @switch (value) { @case (HealthStatusCode.OK) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.OK' | transloco" [avnTheme]="ThemeType.SUCCESS"></avn-badge>
  } @case (HealthStatusCode.WARNING) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.WARNING' | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
  } @case (HealthStatusCode.ERROR) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR' | transloco" [avnTheme]="ThemeType.DANGER"></avn-badge>
  } @case (HealthStatusCode.UNREACHABLE) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.UNREACHABLE' | transloco" [avnTheme]="ThemeType.SECONDARY"></avn-badge>
  } @case (HealthStatusCode.NOT_MONITORED) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.NOT_MONITORED' | transloco" [avnTheme]="ThemeType.INFO"></avn-badge>
  } }
</ng-template>

<ng-template #healthErrorsIndicator>
  <ng-container *ngFor="let error of !!value ? value.split(',') : [value]; let i = index">
    @switch (+error) { @case (HealthErrorIds.PLAYER_SERVICE_DOWN) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.PLAYER_SERVICE_DOWN' | transloco }}
    } @case (HealthErrorIds.HTTP_SERVICE_DOWN) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HTTP_SERVICE_DOWN' | transloco }}
    } @case (HealthErrorIds.FTP_SERVICE_DOWN) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FTP_SERVICE_DOWN' | transloco }}
    } @case (HealthErrorIds.MISSING_CONTENT) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.MISSING_CONTENT' | transloco }}
    } @case (HealthErrorIds.HD_ERROR) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HD_ERROR' | transloco }}
    } @case (HealthErrorIds.FAN_ERROR) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FAN_ERROR' | transloco }}
    } @case (HealthErrorIds.HIGH_PROCESSOR_TEMPERATURE) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_PROCESSOR_TEMPERATURE' | transloco }}
    } @case (HealthErrorIds.HIGH_CPU) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_CPU' | transloco }}
    } @case (HealthErrorIds.HIGH_MEMORY) {
    {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_MEMORY' | transloco }}
    } }
    <ng-container *ngIf="!!value && value.split(',').length > i + 1">, </ng-container>
  </ng-container>
</ng-template>

<ng-template #operationalStateIndicator>
  @switch (value) { @case (OperationalState.Running) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.SUCCESS"></avn-badge>
  } @case (OperationalState.HardwareMaintenance) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
  } @case (OperationalState.SoftwareMaintenance) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
  } @case (OperationalState.Provisioned) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.PRIMARY"></avn-badge>
  } @case (OperationalState.Decommissioned) {
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.DANGER"></avn-badge>
  } }
</ng-template>

<ng-template #playerVersionIndicator>
  <ng-container *ngIf="value !== undefined">
    <span *ngIf="!!value.description; else textOnly" [avnTooltip]="value.description" [tooltipPlacement]="'bottom'">{{ value.versionCode }}</span>
    <ng-template #textOnly>
      <span>{{ value.versionCode }}</span>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #contentStatusIndicator>
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.CONTENT_STATUS.' + ContentStatuses[value].label | transloco" [avnTheme]="ContentStatuses[value].theme"></avn-badge>
</ng-template>

<ng-template #contentStatusErrorIdsIndicator>
  <ng-container *ngFor="let error of !!value ? value.split(',') : [value]; let i = index">
    @switch (error) { @case (ContentStatusErrorIds.MISSING_CONTENT) {
    <span>{{ 'deviceFields.DEVICE.ENUM.CONTENT_STATUS_ERROR_IDS.MISSING_CONTENT' | transloco }}</span>
    } }
    <ng-container *ngIf="!!value && value.split(',').length > i + 1">, </ng-container>
  </ng-container>
</ng-template>

<ng-template #devicePropertiesStatusIndicator>
  <avn-badge
    [badgeSize]="badgeSize"
    [label]="'deviceFields.DEVICE.ENUM.DEVICE_PROPERTIES_STATUS_COMBINED.' + DevicePropertiesStatusTheme.get(value) | transloco"
    [avnTheme]="DevicePropertiesStatusTheme.get(value)"
  >
  </avn-badge>
</ng-template>
