import { ThemeType } from '@activia/ngx-components';
import { DeviceMonitoringData } from '../model/device-monitoring-data.type';
import { HealthStatusCode, HealthStatusThemes } from '../model/health-status.enum';
import { JSON_MONITORED_VALUES, LOGICAL_PLAYER_MONITORED_VALUES, MonitoredValue } from '../model/monitored-value.enum';
import { MonitoringDataDTO } from '@activia/cm-api';
import { HealthErrorIds } from '../model/health-error-ids.enum';
import { DateTime } from 'luxon';
import { IMonitoringListColumn } from '../model/monitoring-list-column.interface';

export enum MonitoredValueDataType {
  STRING = 1,
  NUMBER = 2,
  BOOLEAN = 3,
  TIMESTAMP = 4,
}

/** convert a device health_status_code into a object has according label and theme */
export const getDeviceStatusWithTheme = (healthStatusCode: number): { label: string; theme: ThemeType; code: number } => {
  let result: { label: string; theme: ThemeType; code: number };
  switch (healthStatusCode) {
    case HealthStatusCode.OK:
      result = { label: 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.OK', theme: HealthStatusThemes[healthStatusCode], code: healthStatusCode };
      break;
    case HealthStatusCode.WARNING:
      result = { label: 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.WARNING', theme: HealthStatusThemes[healthStatusCode], code: healthStatusCode };
      break;
    case HealthStatusCode.ERROR:
      result = { label: 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR', theme: HealthStatusThemes[healthStatusCode], code: healthStatusCode };
      break;
    case HealthStatusCode.UNREACHABLE:
      result = { label: 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.UNREACHABLE', theme: HealthStatusThemes[healthStatusCode], code: healthStatusCode };
      break;
    case HealthStatusCode.NOT_MONITORED:
      result = { label: 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.NOT_MONITORED', theme: HealthStatusThemes[healthStatusCode], code: healthStatusCode };
      break;
    default:
      result = { label: undefined, theme: undefined, code: healthStatusCode };
      break;
  }
  return result;
};

/** Converts the monitoring data DTO to a simplified key / value structure of monitoring data **/
export const toDeviceMonitoringData = (dto: MonitoringDataDTO): DeviceMonitoringData => {
  const hasMonitoringData = dto?.sample?.monitoredValues?.length > 0;
  if (!hasMonitoringData) {
    return {};
  }
  return dto.sample.monitoredValues.reduce((monitoringData, mv) => {
    monitoringData[mv.name] = getMonitoringValue(mv);
    return monitoringData;
  }, {} as DeviceMonitoringData);
};

/** TODO: verify the type field, cm-api is not clear about this field**/
export const getMonitoringValue = (dto: any): string | number | boolean | DateTime => {
  if (!dto) {
    return undefined;
  }

  if (dto.type === 'STRING' || +dto.type === MonitoredValueDataType.STRING) {
    return JSON_MONITORED_VALUES.includes(dto.name) ?
      JSON.parse(dto.stringValue.trim()).map((entry) => Object.values(entry).join(': ')).join('; ') :
      dto.stringValue.trim();
  } else if (dto.type === 'LONG' || +dto.type === MonitoredValueDataType.NUMBER) {
    return dto.longValue;
  } else if (dto.type === 'FLOAT' || +dto.type === MonitoredValueDataType.NUMBER) {
    return dto.floatValue;
  } else if (dto.type === 'BOOL' || +dto.type === MonitoredValueDataType.BOOLEAN) {
    return dto.booleanValue;
  } else if (dto.type === 'TIMESTAMP' || +dto.type === MonitoredValueDataType.TIMESTAMP ) {
    return DateTime.fromISO(dto.timestampValue);
  }

  return undefined;
};

export const parsePlayerVersion = (value): { raw: string; versionCode: string; description: string; isLegacyPlayer: boolean } => {
  const versionRegx = /^((\d+)\.(\d+)\.(\d+)\.\d+)(.*)/g;
  const VERSION_THRESHOLD = 4;
  const MAJOR_THRESHOLD = 2;
  const MINOR_THRESHOLD = 2;
  let isLegacyPlayer = true;
  const match = new RegExp(versionRegx).exec(value);

  if (!!match && match.length === 6) {
    const version = Number(match[2]);
    const major = Number(match[3]);
    const minor = Number(match[4]);

    if (version > VERSION_THRESHOLD) {
      isLegacyPlayer = false;
    } else if (version <= VERSION_THRESHOLD - 1) {
      isLegacyPlayer = true;
    } else {
      if (major > MAJOR_THRESHOLD) {
        isLegacyPlayer = false;
      } else if (major <= MAJOR_THRESHOLD - 1) {
        isLegacyPlayer = true;
      } else {
        if (minor >= MINOR_THRESHOLD) {
          isLegacyPlayer = false;
        } else {
          isLegacyPlayer = true;
        }
      }
    }
  }

  return {
    raw: value,
    versionCode: match?.[1],
    description: match?.[5],
    isLegacyPlayer,
  };
};

/**
 * Adds logical player monitored values if any of monitored value of the specified array is a
 * logical player monitored value *
 */
export const addLogicalPlayerMonitoredValues = (monitoredValues: string[]): string[] => {
  if (!monitoredValues) {
    return monitoredValues;
  }
  const res = [];
  for (const monitoredValue of monitoredValues) {
    res.push(monitoredValue);
    if (LOGICAL_PLAYER_MONITORED_VALUES.includes(monitoredValue as MonitoredValue)) {
      for (let i = 0; i < 4; i++) {
        res.push(`${monitoredValue}_${i}`);
      }
    }
  }
  return res.length > 0 ? res : undefined;
};

/**
 * Aggregate related monitoring variables, since they have same presentation in UI
 * e.g.  PLAYER_STATE_MESSAGE_0,1,2,3  -> PLAYER_STATE_MESSAGE in UI
 */
export const aggregateRelatedMonitoringValue = (monitoredValues: { [name: string]: string | number | boolean }) => {
  if (!monitoredValues || Object.keys(monitoredValues).length === 0) {
    return monitoredValues;
  }
  return Object.keys(monitoredValues).reduce((res, key) => {
    // PLAYER_STATE_MESSAGE
    if (key.match(new RegExp(MonitoredValue.PlayerStateMessage + '_\\d+$'))) {
      res[MonitoredValue.PlayerStateMessage] = res[MonitoredValue.PlayerStateMessage] ? res[MonitoredValue.PlayerStateMessage] + ', ' + monitoredValues[key].toString() : monitoredValues[key];
      return res;
    }
    // TODO: we need decide how to display following stats, since they can be 1,2,3,4,5...n for each device
    //
    // MonitoredValue.PlayerState
    // MonitoredValue.Playlist,
    // MonitoredValue.PlaylistStartTime,
    // MonitoredValue.PlaylistStopTime,
    // MonitoredValue.Subplaylists,
    res[key] = monitoredValues[key];
    return res;
  }, {});
};

/**
 * Get logical player monitored values of a device's monitored values list
 */
export const getLogicalPlayerMonitoredValues = (monitoringData: DeviceMonitoringData): { [key: number]: { ok: boolean; playlist: string; message: string } } => {
  const logicalPlayersData: { [key: number]: { ok: boolean; playlist: string; message: string } } = {};
  // logical player data
  Object.keys(monitoringData)
    .filter((k) => k.startsWith(MonitoredValue.PlayerState) && !k.startsWith(MonitoredValue.PlayerStateMessage))
    .forEach((key) => {
      const playerNumber = key === MonitoredValue.PlayerState ? 0 : Number(key.substring(`${MonitoredValue.PlayerState}_`.length));
      const playerOk = key === MonitoredValue.PlayerState ? monitoringData[MonitoredValue.PlayerState] : monitoringData[`${MonitoredValue.PlayerState}_${playerNumber}`];
      const playerPlaylist = key === MonitoredValue.Playlist ? monitoringData[MonitoredValue.Playlist] : monitoringData[`${MonitoredValue.Playlist}_${playerNumber}`];
      const playerMessage = key === MonitoredValue.PlayerStateMessage ? monitoringData[MonitoredValue.PlayerStateMessage] : monitoringData[`${MonitoredValue.PlayerStateMessage}_${playerNumber}`];
      logicalPlayersData[playerNumber] = { ok: playerOk === 1 ? true : false, playlist: playerPlaylist, message: playerMessage };
    });
  // if PLAYER_STATE is missing, still need add playlist if possible
  if (Object.keys(logicalPlayersData).length === 0) {
    Object.keys(monitoringData)
      .filter((k) => k.match(new RegExp(MonitoredValue.Playlist + '_\\d+$')))
      .forEach((key) => {
        const playerNumber = key === MonitoredValue.Playlist ? 0 : Number(key.substring(`${MonitoredValue.Playlist}_`.length));
        // since PLAYER_STATE is missing,it is not ok
        const playerOk = false;
        const playerPlaylist = key === MonitoredValue.Playlist ? monitoringData[MonitoredValue.Playlist] : monitoringData[`${MonitoredValue.Playlist}_${playerNumber}`];
        const playerMessage = key === MonitoredValue.PlayerStateMessage ? monitoringData[MonitoredValue.PlayerStateMessage] : monitoringData[`${MonitoredValue.PlayerStateMessage}_${playerNumber}`];
        logicalPlayersData[playerNumber] = { ok: playerOk, playlist: playerPlaylist, message: playerMessage };
      });
  }
  return logicalPlayersData;
};

/**
 * Returns extra error info per health error.
 * For example for health error = 100 (service player is down), there can be an more detail info about the error in PLAYER_STATUS_MESSAGE
 */
export const getHealthErrorsExtraInfo = (deviceMonitoringData: DeviceMonitoringData): Partial<Record<HealthErrorIds, string>> => {
  if (!deviceMonitoringData || !deviceMonitoringData.HEALTH_ERROR_IDS) {
    return {};
  }
  // Health status
  const healthErrorIds = deviceMonitoringData.HEALTH_ERROR_IDS.split(',').map((id) => +id);
  const res: Partial<Record<HealthErrorIds, string>> = {};

  // player service
  if (healthErrorIds.includes(HealthErrorIds.PLAYER_SERVICE_DOWN)) {
    // make to include the extra player error message only if the player state is not ok
    const isPlayerStateError = !deviceMonitoringData.SERVICE_PLAYER;
    if (isPlayerStateError && !!deviceMonitoringData.SERVICE_PLAYER_MESSAGE) {
      res[HealthErrorIds.PLAYER_SERVICE_DOWN] = deviceMonitoringData.SERVICE_PLAYER_MESSAGE;
    }
  }

  return res;
};

/**
 * Filter out columns of the deprecated monitored values so that these columns will not
 * appear in user/shared/system list in device monitoring
 * Currently deprecated monitored values: OMNICAST_VERSION, OMNICAST_STATUS
 */
export const filterValidDeviceMonitoredValuesColumns = (columns: IMonitoringListColumn[]): IMonitoringListColumn[] => {
  return (columns || []).filter((col) => !['OMNICAST_VERSION', 'OMNICAST_STATUS'].includes((col as IMonitoringListColumn).value));
};
