<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.SEARCH.TITLE_75' | transloco: { siteName: dialogConfig.data }"
    (closeClicked)="onCancel()"
  >
  </avn-dialog-header>

  <avn-dialog-content avnScroll>
    <amp-site-management-search-devices (selectedDevices)="onDevicesSelected($event)"></amp-site-management-search-devices>
  </avn-dialog-content>

  <avn-dialog-footer>
    <avn-button
      [attr.data-e2e]="'add-devices-btn'"
      [avnTheme]="'primary'"
      [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.ADD_PLAYERS_30' | transloco"
      [disabled]="selectedDevices.length === 0"
      (actioned)="addDevices()">
    </avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
