import { IModalConfig, IStandardDialogData, ModalDialogType, ModalService, ThemeType } from '@activia/ngx-components';
import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';

@Component({
  selector: 'amp-site-management-lock',
  templateUrl: './site-management-lock.component.html',
  styleUrls: ['./site-management-lock.component.scss'],
})
export class SiteManagementLockComponent {
  @ViewChild('unlockContent', { static: false }) unlockContentTemplate: TemplateRef<any>;

  @Input() isLocked: boolean;
  @Input() shakeBaord: string;
  @Input() modalTitle: string;
  @Input() disabled = false;

  @Output() lockStateUpdated = new EventEmitter<boolean>();

  confirmTextValue = 'UNLOCK';

  constructor(private _overlay: Overlay, private _modalService: ModalService, private _translocoService: TranslocoService) {}

  showLockModal() {
    if (this.isLocked) {
      const dialogData: IStandardDialogData = {
        type: ModalDialogType.ConfirmText,
        theme: ThemeType.DANGER,
        title: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.LOCK_MODAL.UNLOCK_10') + ` ${this.modalTitle}`,
        messageTemplate: this.unlockContentTemplate,
        closeActionLabel: this._translocoService.translate('button.cancel'),
        acceptActionLabel: this._translocoService.translate('button.unlock'),
        confirmTextLabel: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.LOCK_MODAL.CONFIRM_TEXT_LABEL_100') + this.confirmTextValue,
        confirmTextValue: this.confirmTextValue,
        confirmInputHelpTextError: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.LOCK_MODAL.CONFIRM_INPUT_HELP_TEXT_ERROR_100') + this.confirmTextValue,
      };

      const dialogConfig: IModalConfig<IStandardDialogData> = {
        showCloseIcon: true,
        closeOnBackdropClick: true,
        data: dialogData,
      };

      const overlayConfig = {
        hasBackdrop: true,
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      };

      const modalRef = this._modalService.openStandardDialog(dialogConfig, overlayConfig);

      modalRef.componentInstance.accepted.pipe(take(1)).subscribe(() => {
        this.lockStateUpdated.emit(!this.isLocked);
      });
    } else {
      this.lockStateUpdated.emit(this.isLocked);
    }
  }
}
