import {
  DEFAULT_COUNTRY_ADDRESS_DISPLAY_FORMAT,
  GeoAddressComponent,
  IGeoAddress,
  IGeoCountryData
} from '@activia/geo';
import { Address } from '@activia/cm-api';

/** Convert address format from Google Maps API to cm-api address format */
export const geoAddressToApiAddress = (geoAddress: IGeoAddress, country: IGeoCountryData): Address => {
  const displayFormat = country?.address?.displayFormat || DEFAULT_COUNTRY_ADDRESS_DISPLAY_FORMAT;
  const isStreetNumberFirstFormat: boolean = displayFormat.indexOf(`${GeoAddressComponent.STREET_NUMBER}`) < displayFormat.indexOf(`${GeoAddressComponent.STREET}`);

  const address = {
    addressLine1: isStreetNumberFirstFormat ? `${geoAddress?.streetNumber || ''} ${geoAddress?.street || ''}`.trim() : `${geoAddress?.street || ''} ${geoAddress?.streetNumber || ''}`.trim(),
    addressLine2: null,
    city: displayFormat.includes(GeoAddressComponent.CITY) ? geoAddress?.locality || geoAddress?.sublocalityLevel1 : null,
    state: displayFormat.includes(GeoAddressComponent.STATE) ? geoAddress?.administrativeArea1 : null,
    zip: displayFormat.includes(GeoAddressComponent.POSTAL_CODE) ? geoAddress?.postalCode : null,
    country: geoAddress?.country?.id,
  };
  return address;
};

/** Convert the api address to the display format defined according to the country */
export const getApiAddressDisplayFormat = (address: Address, country: IGeoCountryData, multiLine, includeAddressLine2: boolean): string => {
  const escapeRegExp = (stringVal: string) => stringVal.replace(/\[/g, '\\[').replace(/\]/g, '\\]').replace(/\s/g, ',*\\s');

  const addressLine1Format = `${GeoAddressComponent.STREET_NUMBER} ${GeoAddressComponent.STREET}|${GeoAddressComponent.STREET} ${GeoAddressComponent.STREET_NUMBER}`;
  const addressLine1FormatRegex = escapeRegExp(addressLine1Format);

  const displayFormat = country?.address?.displayFormat ?? DEFAULT_COUNTRY_ADDRESS_DISPLAY_FORMAT;

  const streetWithSuite = `${address.addressLine1}${includeAddressLine2 && address.addressLine2 ? ', ' + address.addressLine2 : '' }`;

  // Convert the address to the multiple line format
  const formattedAddress = displayFormat
    .replace(new RegExp(addressLine1FormatRegex), streetWithSuite)
    .replace(GeoAddressComponent.CITY, address.city || '')
    .replace(GeoAddressComponent.STATE, address.state || '')
    .replace(GeoAddressComponent.POSTAL_CODE, address.zip || '')
    .replace(GeoAddressComponent.COUNTRY, country?.name || address.country)
    // Remove extra space such as '  ' or ' ,  ' and replace with ' ' or ', ', also remove extra ',' such as ',,' or ', ,'
    .trim().replace(/\s{2,}/g, ' ').replace(/\s{1},/g, ',').replace(/,\s*,/g, ',');

  return multiLine ? formattedAddress.replace(/,\s{1}/g, '<br />') : formattedAddress;
};