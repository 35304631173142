<form [formGroup]="form">
  <div class="address-editor">
    <span *ngIf="isGeoAddressValid === false" class="parsing-error">
      {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS.ERROR.PARSING_ERROR' | transloco }}
    </span>

    <div>
      <avn-form-field>
        <avn-input
          formControlName="addressLine1"
          [attr.data-e2e]="'address-line-one'"
          [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.Street) | async"> </avn-input>
        <avn-form-field-error
          [attr.data-e2e]="'address-line-one-error'"
          key="required"
          [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.ADDRESS_LINE_1.VALIDATION.REQUIRED_150' | transloco"></avn-form-field-error>

        <div class="loading-icon" *ngIf="(addressLine1ControlStatus$ | async) === 'PENDING'">
          <avn-icon class="icon" [icon]="'action:autorenew'" [fontSize]="14" [rotate]="true"></avn-icon>
        </div>

        <span
          class="warning-message"
          [attr.data-e2e]="'warning-message'"
          [style.display]="(addressLine1ControlStatus$ | async) === 'WARNING' ? 'inherit' : 'none'">
          {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.ADDRESS_LINE_1.VALIDATION.DUPLICATE_100' | transloco }}
        </span>
      </avn-form-field>

      <avn-form-field>
        <avn-input
          formControlName="addressLine2"
          [attr.data-e2e]="'address-line-two'"
          [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.ADDRESS_LINE_2.TITLE_50' | transloco"> </avn-input>
      </avn-form-field>
    </div>

    <div>
      <avn-form-field>
        <avn-input
          formControlName="city"
          [attr.data-e2e]="'city'"
          [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.City) | async"> </avn-input>
      </avn-form-field>

      <avn-form-field>
        <avn-input
          formControlName="state"
          [attr.data-e2e]="'state'"
          [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.State) | async"> </avn-input>
      </avn-form-field>
    </div>

    <div class="equal-spacing">
      <avn-form-field>
        <avn-input
          formControlName="postalCode"
          [attr.data-e2e]="'postal-code'"
          [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.ZipPostalCode) | async"> </avn-input>
      </avn-form-field>

      <avn-form-field>
        <avn-autocomplete
          formControlName="country"
          [attr.data-e2e]="'country'"
          [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.Country) | async" [datasource]="countries$ | async"> </avn-autocomplete>
        <avn-form-field-error key="required" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.COUNTRY.VALIDATION.REQUIRED_150' | transloco"></avn-form-field-error>
      </avn-form-field>
    </div>
  </div>
</form>
