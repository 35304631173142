import { AggregationType, DisplayType, IColumnDefinition } from '@amp/column-picker';
import { SiteProperties } from './site-properties.enum';
import { SiteMonitoringColumnType } from './site-monitoring-column-type';
import { SiteMonitoredValue } from './site-monitored-value.enum';
import { ISiteMonitoringColumnPickerExtraConfig } from './site-monitoring-column-picker-extra-config.interface';
import { ContentStatus, ContentState, HealthStatusCode, DevicePropertiesStatus, ServiceStatusCode } from '@amp/devices';

/**  @ignore */
const getSitePropertiesInfo = (id: string) => ({
    id,
    name: `SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${id}.TITLE_35`,
    description: `SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${id}.DESCRIPTION_200`,
    type: SiteMonitoringColumnType.SiteProperty,
  });

/**  @ignore */
const getMonitoredValueInfo = (id: string) => ({
    id,
    name: `SITE_MONITORING.COLUMNS.MONITORED_VALUES.${id}`,
    description: `SITE_MONITORING.COLUMNS.MONITORED_VALUES.${id}`,
    type: SiteMonitoringColumnType.MonitoredValue,
  });

/**
 * This contains metadata about all the site properties columns:
 * - id
 * - i18n keys for name + description
 * - display types and default display types
 */
export const SITE_PROPERTY_COLUMNS: IColumnDefinition<ISiteMonitoringColumnPickerExtraConfig>[] = [
  { ...getSitePropertiesInfo(SiteProperties.Id), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '1000' },
  { ...getSitePropertiesInfo(SiteProperties.ExternalId), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: '1000' },
  { ...getSitePropertiesInfo(SiteProperties.Name), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: 'restaurant no 1' },
  { ...getSitePropertiesInfo(SiteProperties.Street), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: '14 elm street' },
  { ...getSitePropertiesInfo(SiteProperties.City), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: 'Dayton' },
  { ...getSitePropertiesInfo(SiteProperties.State), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: 'Ohio' },
  { ...getSitePropertiesInfo(SiteProperties.Country), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: 'USA' },
  { ...getSitePropertiesInfo(SiteProperties.ZipPostalCode), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: '90210' },
  { ...getSitePropertiesInfo(SiteProperties.DeviceCount), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], allowRename: true, sample: 42 },
];

/**
 * This contains metadata about all the site monitored values columns:
 * - id
 * - i18n keys for name + description
 * - display types and default display types
 */
export const SITE_MONITORED_VALUE_COLUMNS: IColumnDefinition<ISiteMonitoringColumnPickerExtraConfig>[] = [
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.HealthStatus),
    defaultDisplayType: DisplayType.CountPieChart,
    displayTypes: [DisplayType.HealthStatus, DisplayType.CountPieChart],
    defaultAggregationType: AggregationType.All,
    aggregationTypes: [AggregationType.All],
    extra: { optimisticViewEnabled: true },
    allowRename: true,
    sample: { [`${HealthStatusCode.OK}`]: 10, [`${HealthStatusCode.WARNING}`]: 5, [`${HealthStatusCode.ERROR}`]: 52, [`${HealthStatusCode.UNREACHABLE}`]: 2, [`${HealthStatusCode.NOT_MONITORED}`]: 4 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.Uptime),
    defaultDisplayType: DisplayType.Duration,
    displayTypes: [DisplayType.Raw, DisplayType.Duration],
    defaultAggregationType: AggregationType.Average,
    aggregationTypes: [AggregationType.Min, AggregationType.Max, AggregationType.Average],
    allowRename: true,
    sample: { min: 18000, max: 18000, avg: 18000 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.LastUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.LastAttempt),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.ContentState),
    defaultDisplayType: DisplayType.ContentState,
    displayTypes: [DisplayType.ContentState, DisplayType.CountPieChart],
    defaultAggregationType: AggregationType.All,
    aggregationTypes: [AggregationType.All],
    allowRename: true,
    sample: { [`${ContentState.OK}`]: 10, [`${ContentState.MissingContent}`]: 5 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.ContentStatus),
    defaultDisplayType: DisplayType.ContentStatus,
    displayTypes: [DisplayType.ContentStatus, DisplayType.CountPieChart],
    defaultAggregationType: AggregationType.All,
    aggregationTypes: [AggregationType.All],
    extra: { optimisticViewEnabled: true },
    allowRename: true,
    sample: { [`${ContentStatus.OK}`]: 10, [`${ContentStatus.WARNING}`]: 5, [`${ContentStatus.ERROR}`]: 1 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.LastContentUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.LastContentUpToDate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.LastContentFailedUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.CpuPercent),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    defaultAggregationType: AggregationType.Average,
    aggregationTypes: [AggregationType.Min, AggregationType.Max, AggregationType.Average],
    allowRename: true,
    sample: { min: 10, max: 90, avg: 45 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.SystemDisk),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    defaultAggregationType: AggregationType.Average,
    aggregationTypes: [AggregationType.Min, AggregationType.Max, AggregationType.Average],
    allowRename: true,
    sample: { min: 10, max: 90, avg: 45 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.SystemMemory),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    defaultAggregationType: AggregationType.Average,
    aggregationTypes: [AggregationType.Min, AggregationType.Max, AggregationType.Average],
    allowRename: true,
    sample: { min: 10, max: 90, avg: 45 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.TagsLastModificationTime),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.DynamicTagsLastModificationTime),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.DevicePropertiesStatus),
    defaultDisplayType: DisplayType.DevicePropertiesStatus,
    displayTypes: [DisplayType.DevicePropertiesStatus, DisplayType.CountPieChart],
    defaultAggregationType: AggregationType.All,
    aggregationTypes: [AggregationType.All],
    extra: { optimisticViewEnabled: true },
    allowRename: true,
    sample: {
      [`${DevicePropertiesStatus.OK}`]: 10,
      [`${DevicePropertiesStatus.NoticeDYN}`]: 4,
      [`${DevicePropertiesStatus.NoticeALL}`]: 5,
      [`${DevicePropertiesStatus.WarningDYN}`]: 2,
      [`${DevicePropertiesStatus.WarningALL}`]: 3,
      [`${DevicePropertiesStatus.ErrorDYN}`]: 1,
      [`${DevicePropertiesStatus.ErrorALL}`]: 3,
    },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.HttpService),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.ServiceStatus, DisplayType.CountPieChart],
    allowRename: true,
    sample: { [`${ServiceStatusCode.OK}`]: 10, [`${ServiceStatusCode.ERROR}`]: 5 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.ServicePlayer),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.ServiceStatus, DisplayType.CountPieChart],
    allowRename: true,
    sample: { [`${ServiceStatusCode.OK}`]: 10, [`${ServiceStatusCode.ERROR}`]: 5 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.PosState),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.ServiceStatus, DisplayType.CountPieChart],
    allowRename: true,
    sample: { [`${ServiceStatusCode.OK}`]: 10, [`${ServiceStatusCode.ERROR}`]: 5 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.PosLastUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.PosLastModified),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.CodState),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.ServiceStatus],
    allowRename: true,
    sample: { [`${ServiceStatusCode.OK}`]: 10, [`${ServiceStatusCode.ERROR}`]: 5 },
  },
  {
    ...getMonitoredValueInfo(SiteMonitoredValue.CodLastUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    defaultAggregationType: AggregationType.Max,
    aggregationTypes: [AggregationType.Min, AggregationType.Max],
    allowRename: true,
    sample: { min: new Date().toISOString(), max: new Date().toISOString(), avg: new Date().toISOString() },
  },
];

export const SITE_COLUMNS = [...SITE_PROPERTY_COLUMNS, ...SITE_MONITORED_VALUE_COLUMNS];
