/**
 * Enum that represents all the properties of a site related with a device
 */
export enum SiteProperty {
  SiteExternalId = 'SITE_EXTERNAL_ID',
  SiteCity = 'SITE_CITY',
  SiteAddressLine1 = 'SITE_ADDRESS_LINE_1',
  SiteAddressLine2 = 'SITE_ADDRESS_LINE_2',
  SiteState = 'SITE_STATE',
  SiteZip = 'SITE_ZIP',
  SiteCountry = 'SITE_COUNTRY',
}
