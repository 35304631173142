/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

/**
 * ALl possible command codes
 * Reference: https://confluence.stratacache.com/display/CM/Notifications
 * Endpoint: /amp/v1/commands/codes
 * Default is UPLOAD_LOGS_COMMAND when no command code
 */


export enum CommandCodeEnum {
  UploadConfiguration = 0,
  BackupConfiguration = 1,
  RestoreConfiguration = 2,
  Cancel = 3,
  WebDistribution = 5,
  UploadLogs = 6,
  GenerateLogsReport = 7,
  Purge = 10,
  ChannelUpdate = 11,
  ServiceAction = 23,
  SystemAction = 28,
  UpdateSoftware = 40,
  PlayerAction = 42,
  Script = 42,
  SetDeviceProperties = 52,
  CancelRecovery = 53,
}
