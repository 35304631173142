/**
 * Enum that represents all the monitored values of a device
 */
export enum MonitoredValue {
  /** ====== For 5.1 ====== */

  /** AgentVersion - The ActiVia agent version **/
  AgentVersion = 'AGENT_VERSION',

  /** CcpRespTime **/
  CcpRespTime = 'CCP_RESP_TIME',

  /** ContentStateMessage - Error message when content is missing on the player **/
  ContentStateMessage = 'CONTENT_STATE_MESSAGE',

  /** CpuPercent **/
  CpuPercent = 'CPU_PERCENT',

  /** Firmware - Image firmware (only TNET/FW40/Amino) **/
  Firmware = 'FIRMWARE',

  /** FtpStatus **/
  FtpStatus = 'FTP_STATUS',

  /** HealthStatus **/
  HealthStatus = 'HEALTH_STATUS',

  /** HealthErrorIds **/
  HealthErrorIds = 'HEALTH_ERROR_IDS',

  /** HttpService - To know if application can perform HTTP requests **/
  HttpService = 'HTTP_SERVICE',

  /** LastAttempt - Todo */
  LastAttempt = 'LAST_ATTEMPT',

  /** LastUpdate - Todo */
  LastUpdate = 'LAST_UPDATE',

  /** PlayerState - State of the player **/
  PlayerState = 'PLAYER_STATE',

  /**
     PlayerStateMessage - Error message when the Player State is KO (for 3.1.1 players and above
     only) *
   */
  PlayerStateMessage = 'PLAYER_STATE_MESSAGE',

  /** PlayerVersion - The player version (Spectra/Enseo Pack installer version) **/
  PlayerVersion = 'PLAYER_VERSION',

  /** Playlist - Name of the current playlist, with its ID **/
  Playlist = 'PLAYLIST',

  /** PlaylistStartTime **/
  PlaylistStartTime = 'PLAYLIST_START_TIME',

  /** PlaylistStopTime **/
  PlaylistStopTime = 'PLAYLIST_STOP_TIME',

  /** ProxyByteHitRatio - The HTTP byte hit ratio **/
  ProxyByteHitRatio = 'PROXY_BYTE_HIT_RATIO',

  /** ProxyClients - The total number of clients accepted by application **/
  ProxyClients = 'PROXY_CLIENTS',

  /** ProxyCpu - Amount of CPU centiseconds consumed by application **/
  ProxyCpu = 'PROXY_CPU',

  /** ProxyHitRatio - The HTTP hit ratio **/
  ProxyHitRatio = 'PROXY_HIT_RATIO',

  /** ProxyHitResponse - Cache hit response time in the last minute **/
  ProxyHitResponse = 'PROXY_HIT_RESPONSE',

  /** ProxyHttpKbClientOut - The number of KBs delivered to clients by application **/
  ProxyHttpKbClientOut = 'PROXY_HTTP_KB_CLIENT_OUT',

  /** ProxyHttpKbServerIn - The number of KBs received by the application from remote servers **/
  ProxyHttpKbServerIn = 'PROXY_HTTP_KB_SERVER_IN',

  /** ProxyMemory **/
  ProxyMemory = 'PROXY_MEMORY',

  /** ProxyMissResponse - Cache miss response time in the last minute **/
  ProxyMissResponse = 'PROXY_MISS_RESPONSE',

  /** ProxyTransactions - The total number of HTTP requests received from clients by application **/
  ProxyTransactions = 'PROXY_TRANSACTIONS',

  /** ProxyUptime - Uptime of the application in timeticks **/
  ProxyUptime = 'PROXY_UPTIME',

  /** ServicePlayer - 1: working. 0: problem **/
  ServicePlayer = 'SERVICE_PLAYER',

  /** ServicePlayerMessage - Error message when the SERVICE_PLAYER is KO **/
  ServicePlayerMessage = 'SERVICE_PLAYER_MESSAGE',

  /**
     Subplaylists - Playlists playing permanently (starting at main current playlist start time and
     ending at main current playlist end time without interruption) within the main PLAYLIST *
   */
  Subplaylists = 'SUBPLAYLISTS',

  /** SystemDisk - % of disk used (partition used by Stratacache) **/
  SystemDisk = 'SYSTEM_DISK',

  /** SystemMemory - % RAM used (partition used by Stratacache) **/
  SystemMemory = 'SYSTEM_MEMORY',

  /** Uptime **/
  Uptime = 'UPTIME',

  /** ====== For 5.2 ====== */

  /** Pos pricing version */
  ConfPosVersion = 'CONF_POS_VERSION',

  /** Pos common libs version */
  ConfPosLibsVersion = 'CONF_POS_LIBS_VERSION',

  /** Pos state */
  PosState = 'POS_STATE',

  /** Timestamp which last time a pos update was attempted */
  PosLastUpdate = 'POS_LASTUPDATE',

  /** Timestamp which last time a pos update occurred */
  PosLastModified = 'POS_LASTMODIFIED',

  /**
   * Information about products/promotions
   * When in error state, the idea is to show the last 'X' number of alarms in the UI somewhere
   */
  PosMessage = 'POS_MESSAGE',

  /** Cod version, e.g. cod-clientname_1.0.0.4-g870a012 */
  ConfCodVersion = 'CONF_COD_VERSION',

  /** Cod state */
  CodState = 'COD_STATE',

  /** Cod state */
  Cod = 'COD',

  /** Timestamp which last cod data was received from the pos */
  CodLastUpdate = 'COD_LASTUPDATE',

  /** Will only be populated if COD_STATE=0 */
  CodMessage = 'COD_MESSAGE',

  /** Device content status	*/
  ContentStatus = 'CONTENT_STATUS',

  /** Error Ids for device content status	*/
  ContentStatusErrorIds = 'CONTENT_STATUS_ERROR_IDS',

  /** Timestamp when device was last up-to-date (device had up-to-date contents in all channels) */
  LastContentUpToDate = 'LAST_CONTENT_UPTODATE',

  /** Timestamp of last content change for the device (in any of the device channels) */
  LastContentUpdate = 'LAST_CONTENT_UPDATE',

  /** Timestamp when device had a failed update (last update failed in any channel) */
  LastContentFailedUpdate = 'LAST_CONTENT_FAILED_UPDATE',

  /** Last time tags were updated through the distribution (all tags updated) */
  TagsLastModificationTime = 'PROPERTIES_MTIME',

  /** Last time tags were updated through the properties update command (only dynamic tags are updated). Irrelevant if older than PROPERTIES_MTIME */
  DynamicTagsLastModificationTime = 'PROPERTIES_MTIMEDYN',

  /** Properties status */
  DevicePropertiesStatus = 'PROPERTIES_STATUS',

  /** Number of fans */
  ConfEnclosureFans = 'CONF_ENCLOSURE_FANS',

  /** Type of fans */
  ConfEnclosureFanstype = 'CONF_ENCLOSURE_FANSTYPE',

  /** Type of enclosure */
  ConfEnclosureType = 'CONF_ENCLOSURE_TYPE',

  /** Firmware versions */
  ConfEnclosureVersion = 'CONF_ENCLOSURE_VERSION',

  /** Fan running time*/
  EnclosureFantime = 'ENCLOSURE_FANTIME',

  /** In humidity*/
  EnclosureHumid0 = 'ENCLOSURE_HUMID0',

  /** Out humidity */
  EnclosureHumid1 = 'ENCLOSURE_HUMID1',

  /** Enclosure operating mode */
  EnclosureMode = 'ENCLOSURE_MODE',

  /** Enclosure fan power */
  EnclosurePower0 = 'ENCLOSURE_POWER0',

  /** Enclosure heat power */
  EnclosurePower1 = 'ENCLOSURE_POWER1',

  /** Enclosure In/Out pressure differential */
  EnclosurePressure = 'ENCLOSURE_PRESSURE',

  /** Current fan speed pwm */
  EnclosurePwm0 = 'ENCLOSURE_PWM0',

  /** Target fan speed pwm */
  EnclosurePwm1 = 'ENCLOSURE_PWM1',

  /** Min temperature for all sensors */
  TemperatureEnclosureMin = 'TEMPERATURE_ENCLOSURE_MIN',

  /** Max temperature for all sensors */
  TemperatureEnclosureMax = 'TEMPERATURE_ENCLOSURE_MAX',

  /** Aim board temperature */
  TemperatureEnclosureAim = 'TEMPERATURE_ENCLOSURE_AIM',

  /** Exhaust tempereature */
  TemperatureEnclosureEx = 'TEMPERATURE_ENCLOSURE_EX',

  /** Intake temperature */
  TemperatureEnclosureIn = 'TEMPERATURE_ENCLOSURE_IN',

  /** Internal network IP Address(es) */
  ConfSystemNetwork = 'CONF_SYSTEM_NETWORK',

  /** Timezone where the device locates */
  ConfSystemTimezone = 'CONF_SYSTEM_TIMEZONE',

  /** List of displays with model and serial. Since pack 5.0.1 */
  ConfDisplays = 'CONF_DISPLAYS',
}

/**
 * Monitored values that contain JSON string
 * TODO: CM-8261: temp solution until backend provides the JSON type
 */
export const JSON_MONITORED_VALUES = [MonitoredValue.ConfSystemNetwork, MonitoredValue.ConfDisplays];

/**
 * Array that represent all the monitored values that belong to the logical players
 * those value will be suffixed b _X where X is the logical player id
 * for me infor, see https://confluence.stratacache.com/display/CCPA/Monitoring+Values
 */
export const LOGICAL_PLAYER_MONITORED_VALUES = [
  MonitoredValue.PlayerState,
  MonitoredValue.PlayerStateMessage,
  MonitoredValue.Playlist,
  MonitoredValue.PlaylistStartTime,
  MonitoredValue.PlaylistStopTime,
  MonitoredValue.Subplaylists,
];

/**
 * All monitored values that apply only to a WEB_CACHE
 * https://confluence.stratacache.com/pages/viewpage.action?spaceKey=CM&title=AMP+Supported+Devices
 */
export const WEB_CACHE_MONITORED_VALUES = [
  MonitoredValue.HttpService,
  MonitoredValue.ProxyByteHitRatio,
  MonitoredValue.ProxyClients,
  MonitoredValue.ProxyCpu,
  MonitoredValue.ProxyHitRatio,
  MonitoredValue.ProxyHitResponse,
  MonitoredValue.ProxyHttpKbClientOut,
  MonitoredValue.ProxyHttpKbServerIn,
  MonitoredValue.ProxyMemory,
  MonitoredValue.ProxyMissResponse,
  MonitoredValue.ProxyTransactions,
  MonitoredValue.ProxyUptime,
];

/**
 * All monitored values that apply only to a DISTRIBUTION SENDER
 */
export const DISTRIBUTION_SENDER_MONITORED_VALUES = [MonitoredValue.FtpStatus];
