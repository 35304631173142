<div [style.min-height.px]="listItemHeightInPx" class="component-label">{{ i18nLabels?.listSelectedColumnsTitle }}</div>
<div *ngIf="(dataSource$ | async)?.length > 0; else noColumnSelected" cdkDropList [avnScroll] class="column-picker-list" [style.height.px]="componentHeightInPx" (cdkDropListDropped)="drop($event)">
  <div
    *ngFor="let item of dataSource$ | async; trackBy: trackByFn"
    class="column-picker-item"
    [style.min-height.px]="listItemHeightInPx"
    cdkDrag
    [cdkDragDisabled]="renamedColumns.includes(item.columnDefinition.id)"
    [cdkDragData]="item"
  >
    <div class="drop-icon">
      <avn-icon [fontSize]="21" icon="navigation:more_vert"></avn-icon>
    </div>
    <div class="main-content">
      <div class="column-info" [class.full-width]="!item.selectedAggregationType && !item.selectedDisplayType">
        <span class="type">{{ item.categoryName }}</span>
        <div class="name-container" *ngIf="item.columnDefinition.allowRename; else nameDisplay">
          <ng-container *ngIf="renamedColumns.includes(item.columnDefinition.id); else nameNotRenamed">
            <avn-input #input [id]="item.columnDefinition.id" [value]="item.columnDefinition.name" [placeholder]="i18nLabels?.columnNameInputPlaceholder"></avn-input>
            <avn-button avnTheme="primary" [label]="i18nLabels?.renameColumnSaveButtonLabel" [disabled]="input.value.trim().length === 0" (actioned)="onSaveColumnName(item, input.value)"></avn-button>
          </ng-container>
          <ng-template #nameNotRenamed>
            <ng-container *ngTemplateOutlet="nameDisplay"></ng-container>
            <avn-icon *ngIf="allowRenameColumn" icon="content:edit" [fontSize]="20" [avnTooltip]="i18nLabels?.renameColumnTooltip" tooltipPlacement="right" (click)="onEditColumnName(item)"> </avn-icon>
          </ng-template>
        </div>
        <ng-template #nameDisplay><span class="name">{{ item.columnDefinition.name }}</span></ng-template>
        <span class="description">{{ item.columnDefinition.description | transloco }}<ng-container *ngIf="item.columnDefinition.extra?.infoTooltip"> {{ item.columnDefinition.extra?.infoTooltip | transloco }}</ng-container></span>
        <div class="extra-config" *ngIf="extraColumnConfigTemplate && item.columnDefinition.extra">
          <ng-container *ngTemplateOutlet="extraColumnConfigTemplate; context: { item: item }"></ng-container>
        </div>
      </div>
      <amp-column-picker-options-selector
        *ngIf="item.selectedAggregationType || item.selectedDisplayType"
        [item]="item"
        [displayTypeTemplate]="displayTypeTemplate"
        (updated)="onItemOptionChanged($event)"
        (edited)="onItemOptionsEdited($event)"
      ></amp-column-picker-options-selector>
      <avn-icon
        [fontSize]="14"
        icon="content:clear"
        class="icon-clear"
        (click)="!item.mandatory && !isEditedItem(item) && removeItem(item)"
        [style.visibility]="item.mandatory || isEditedItem(item) ? 'hidden' : 'visible'"
      >
      </avn-icon>
    </div>
  </div>
</div>

<ng-template #noColumnSelected>
  <div [style.min-height.px]="listItemHeightInPx" class="empty-list-section">
    {{ i18nLabels?.listNoColumnsSelectedMessage }}
  </div>
</ng-template>
