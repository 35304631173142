<div class="site-management-display" 
[attr.data-e2e]="'site-management-display'">
  <div #displayComponent class="smd-container" *ngIf="display$ | async as display; else addDisplay">
    <div class="smd-header">
      <amp-screen-orientation
        [disabled]="isBoardLocked || !editable"
        [screenOrientation]="display.geometry.orientation"
        (screenOrientationChanged)="onScreenOrientationChange($event, display.id)"
        (mouseenter)="onShakeLock($event)"
        (mouseleave)="onShakeLock($event)"
      ></amp-screen-orientation>
      <div class="display-header">
        <avn-icon
          *ngIf="editable"
          icon="action:delete_forever"
          [fontSize]="16"
          [disabled]="isBoardLocked || !canRemoveDisplay(display)"
          (click)="removeDisplay(display.id)"
          (mouseenter)="onShakeLock($event)"
          (mouseleave)="onShakeLock($event)"
        ></avn-icon>
      </div>
    </div>
    <div class="smd-content">
      <div class="smdc-display">
        <div class="smdc-title">
          <avn-form-field>
            <avn-input
              [debounceTime]="1000"
              [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DISPLAY_10' | transloco"
              [value]="display.name"
              [disabled]="isBoardLocked || !editable"
              (changed)="onChangeName($event, display.id)"
            ></avn-input>
          </avn-form-field>
        </div>

        <!-- Player connections -->
        <!-- Primary -->
        <div class="smdc-player-loader">
          <avn-linear-loader [show]="primaryDeviceLoader$ | async"></avn-linear-loader>

          <amp-display-input-editor
            [attr.data-e2e]="'display-input'"
            [editable]="editable && !isBoardLocked"
            [boardId]="boardId"
            [device]="primaryInput$ | async"
            [displayInputType]="DisplayInputType.PRIMARY"
            [displayInput]="display.inputs[DisplayInputType.PRIMARY]"
            [avnDragDropElementTarget]="dragDropTarget$ | async"
            [avnDragDropElementTargetShowOnDragOnly]="false"
            [avnDragDropElementTargetShowOnHoverOnly]="false"
            [avnDragDropElementTargetOpacity]="0.5"
            [avnDragDropElementTargetBorder]="'dashed'"
            (elementDropped)="dropDevice($event, DisplayInputType.PRIMARY)"
            (addInput)="openDeviceSelector(DisplayInputType.PRIMARY)"
            (shakeLock)="onShakeLock($event)"
            (deleteInput)="deleteInput(DisplayInputType.PRIMARY)"
            (deleteDevice)="deleteDevice(DisplayInputType.PRIMARY)"
          ></amp-display-input-editor>
        </div>

        <!-- Backup -->
        <div class="smdc-player-loader" *ngIf="experienceTemplate?.redundancyStrategy !== 'NONE'">
          <avn-linear-loader [show]="backupDeviceLoader$ | async"></avn-linear-loader>

          <amp-display-input-editor
            [attr.data-e2e]="'display-input'"
            [editable]="editable && !isBoardLocked"
            [boardId]="boardId"
            [device]="backupInput$ | async"
            [displayInputType]="DisplayInputType.BACKUP"
            [displayInput]="display.inputs[DisplayInputType.BACKUP]"
            [avnDragDropElementTarget]="dragDropTarget$ | async"
            [avnDragDropElementTargetShowOnDragOnly]="false"
            [avnDragDropElementTargetShowOnHoverOnly]="false"
            [avnDragDropElementTargetOpacity]="0.5"
            [avnDragDropElementTargetBorder]="'dashed'"
            (elementDropped)="dropDevice($event, DisplayInputType.BACKUP)"
            (addInput)="openDeviceSelector(DisplayInputType.BACKUP)"
            (shakeLock)="onShakeLock($event)"
            (deleteInput)="deleteInput(DisplayInputType.BACKUP)"
            (deleteDevice)="deleteDevice(DisplayInputType.BACKUP)"
          ></amp-display-input-editor>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addDisplay>
  <div class="smd-container add-display" (click)="addNewDisplay()">
    <avn-icon icon="content:add_circle_outline"></avn-icon>
    <span>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_DISPLAY_10' | transloco }}</span>
  </div>
</ng-template>
