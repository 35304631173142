<form class="edit-board-main" [formGroup]="form" *ngIf="boardTemplate; else empty">
  <div class="edit-board-form-field-wrapper">
    <avn-form-field>
      <avn-input formControlName="label" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.LABEL_40' | transloco"></avn-input>
    </avn-form-field>
  </div>

  <div class="edit-board-form-field-wrapper">
    <avn-form-field class="edit-board-form-field">
      <avn-select formControlName="redundancyStrategy" [options]="options" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.STRATEGY_40' | transloco"></avn-select>
    </avn-form-field>
    <avn-icon class="edit-board-form-field-icon" icon="action:help"></avn-icon>
  </div>

  <div class="edit-board-form-field-wrapper">
    <avn-form-field class="edit-board-form-field">
      <avn-input formControlName="namePattern" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.NAME_PATTERN_60' | transloco"></avn-input>
    </avn-form-field>
    <avn-icon class="edit-board-form-field-icon" icon="action:help"></avn-icon>
  </div>

  <div class="edit-board-form-field-wrapper">
    <avn-form-field class="edit-board-form-field">
      <avn-input formControlName="ipAdress" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.IP_ADRESS_40' | transloco" placeholder="1.1.1.1"></avn-input>
    </avn-form-field>
    <avn-icon class="edit-board-form-field-icon" icon="action:help"></avn-icon>
  </div>

  <div class="edit-board-form-field-wrapper">
    <avn-radio-group name="items" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.MODEL_40' | transloco" avnTheme="primary">
      <avn-radio type="text" label="ng2" value="ng2"></avn-radio>
      <avn-radio type="text" label="ng4" value="ng4"></avn-radio>
    </avn-radio-group>
  </div>

  <ng-container *ngIf="!form.get('displays').value?.length; else displays">
    <div>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.GEOMETRY_40' | transloco }}</div>
    <avn-grid-size-selector
      [attr.data-e2e]="'board-size-selector'"
      [avnTheme]="themeType.PRIMARY"
      [minGridSize]="{ row: 3, column: 3 }"
      [maxGridSize]="{ row: 10, column: 10 }"
      (gridItemClicked)="getGeometry($event)"
    ></avn-grid-size-selector>
  </ng-container>

  <ng-template #displays>
    <div class="edit-board-displays">
      <div>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.DISPLAY_40' | transloco }}</div>
      <avn-icon avnContextMenu icon="navigation:more_vert"></avn-icon>
    </div>
    <div>
      <div class="displays-list">
        <ng-container *ngFor="let display of form.get('displays').value">
          <ng-container *ngIf="display.geometry | applyFunction: getGeometryRowAndColumn as geometry">
            <div class="display" [ngStyle]="{ gridRow: geometry.row, gridColumn: geometry.column }">
              <div class="smd-header">
                <amp-screen-orientation [screenOrientation]="display.orientation"></amp-screen-orientation>
                <div class="display-header">
                  <avn-icon icon="action:delete_forever" [fontSize]="14"></avn-icon>
                </div>
              </div>
              <div class="smdc-title">
                <avn-form-field>
                  <avn-input [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.DISPLAY_40' | transloco" [value]="display.name"></avn-input>
                </avn-form-field>
              </div>
              <avn-skeleton [animate]="false" [skeletonType]="'SCREEN_SHOT'" class="screenshot-skeleton"> </avn-skeleton>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</form>

<ng-template #empty>
  <avn-empty-data-message
    icon="communication:desktop_access_disabled"
    [text]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.BOARD_EMPTY_MESSAGE.TEXT_100' | transloco"
    [subText]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.BOARD_EMPTY_MESSAGE.SUBTEXT_100' | transloco"
  ></avn-empty-data-message>
</ng-template>
