import {
  getSiteMonitoringKeyMetricEnclosureAlarmEvents,
  IKeyMetricSiteEnclosureStatus
} from '../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import {BoardDTO, MonitoringAlarmEventDTO} from '@activia/cm-api';
import {AlarmEventNamespace} from '@amp/devices';
import {getBoardDeviceIds} from './site-boards.utils';
import {ICombinedDeviceInfo} from '../components/site-monitoring-detail/store/site-monitoring-detail.model';
import {ALARM_EVENT_OPTIMISTIC_COMBINATIONS} from '../model/optimistic-view-status-combinations';
import {ISiteMonitoringKeyMetricConfig} from '../model/site-monitoring-key-metric-config.interface';
import {ISiteMonitoringKeyMetricViewerData} from '../model/site-monitoring-key-metric-viewer-data.interface';

export const getEnclosureKeyMetricViewerData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  data: IKeyMetricSiteEnclosureStatus,
) => {
  return {
    id: keyMetric.id,
    config: keyMetric,
    data,
    date: (new Date()).toISOString(),
  } as ISiteMonitoringKeyMetricViewerData;
};

export const getHighestAlarmLevel = (aLevel, bLevel) => {
  if (aLevel != null && bLevel != null) {
    return aLevel < bLevel ? aLevel : bLevel;
  }
  return aLevel != null ? aLevel : bLevel;
};

/** Filter out device alarms events required for enclosure key metrics only */
export const filterEnclosureAlarms = (alarms: MonitoringAlarmEventDTO[]): MonitoringAlarmEventDTO[] => {
  // Find the types of alarms events required for enclosure key metrics
  const enclosureEvents = getSiteMonitoringKeyMetricEnclosureAlarmEvents();
  // Collect event names used in the backend
  const alarmEventNames = enclosureEvents.flatMap((event) => event.name);
  // Filter out only the alarms for enclosure key metrics
  const alarmEvents: MonitoringAlarmEventDTO[] = (alarms || []).filter((alarm) =>
    alarm.namespace.toLowerCase() === AlarmEventNamespace.Enclosure.toLowerCase() &&
    alarmEventNames.includes(alarm.name as any));
  return alarmEvents;
};

/** Filter out enclosure type board from a list of boards */
export const filterEnclosures = (boards: BoardDTO[], devices: Partial<ICombinedDeviceInfo>[]): BoardDTO[] => {
  const enclosures = (boards || []).filter((board) => {
    const devIds = getBoardDeviceIds(board);
    return (devices || []).filter((device) => devIds.includes(device.device.deviceId))
      .some((device) => device.monitoringData.CONF_ENCLOSURE_TYPE);
  });
  return enclosures;
};

/** Group alarm events by each device and apply optimistic view setting */
export const groupAlarmsByDevicesIds = (
  deviceIds: number[],
  devices: ICombinedDeviceInfo[],
  alarmEvents: MonitoringAlarmEventDTO[],
  optimisticViewEnabled: boolean,
): Array<ICombinedDeviceInfo> => {
  const deviceAlarms: Array<ICombinedDeviceInfo> = (deviceIds || []).map((deviceId) => {
    const alarmsParsed = (alarmEvents || [])
      .filter((alarm) => alarm.deviceId === deviceId)
      // Apply optimisticViewEnabled if set to true
      .map((alarm) => {
        if (optimisticViewEnabled) {
          const optimizedLevel = ALARM_EVENT_OPTIMISTIC_COMBINATIONS.get(alarm.level);
          return optimizedLevel ? { ...alarm, level: optimizedLevel } : alarm;
        } else {
          return alarm;
        }
      })
      .sort((a, b) => a.level - b.level);

    const device = devices.find((d) => d.device.deviceId === deviceId);

    return { ...device, alarmEvents: alarmsParsed };
  });

  return deviceAlarms;
};

/** Get alarm events in an enclosure board */
export const getEnclosureAlarms = (enclosure: BoardDTO, deviceAlarms: ICombinedDeviceInfo[]): MonitoringAlarmEventDTO[] => {
  // Get IDs of the devices in this enclosure
  const devIds = getBoardDeviceIds(enclosure).sort((a, b) => a - b);

  // Get alarms found in all the devices in this enclosure and group by alarm event type
  const enclosureAlarms = deviceAlarms
    .filter((da) => devIds.includes(da.device.deviceId))
    .flatMap((da) => da.alarmEvents);

  return enclosureAlarms;
};
