<avn-treeview
  [dataSource]="dataSource || []"
  [selectedNodeIds]="(selectedColumns$ | async | mapTo: 'columnDefinition.id') || []"
  [multipleSelection]="true"
  [showSearch]="true"
  [searchPlaceholder]="i18nLabels?.treeviewSearchPlaceholder"
  [emptyListLabel]="i18nLabels?.treeviewEmptyListMessage"
  [showSelected]="false"
  [componentHeightInPx]="440"
  [showSelectionCount]="true"
  [maxLevel]="3"
  [firstSelectableLevel]="2"
  (selectionChanged)="onTreeviewSelectionChange($event)"
  [style.visibility]="columnsLoading ? 'hidden' : 'visible'"
  [customTemplate]="itemTemplate"
  [listItemHeightInPx]="40"
  [searchInputHeightInPx]="40"
  expandIconPosition="left"
>
</avn-treeview>
<amp-column-picker-list
  [componentHeightInPx]="400"
  [listItemHeightInPx]="40"
  [allowRenameColumn]="allowRenameColumn"
  [displayTypeTemplate]="displayTypeTemplate"
  [extraColumnConfigTemplate]="extraColumnConfigTemplate"
  [i18nLabels]="i18nLabels"
  (columnDataEdited)="dataEdited.emit($event)"
  (columnRenamed)="onColumnNameChange($event)"
>
</amp-column-picker-list>

<ng-template #itemTemplate let-item>
  <ng-container *ngIf="item.children?.length > 0 || !(item.data?.columnDefinition.description | transloco); else tooltipTemplate">
    <span>{{ item.label }}</span>
  </ng-container>

  <ng-template #tooltipTemplate>
    <div class="item-container">
      <div class="title" [avnTooltip]="item.data?.columnDefinition.description | transloco" [tooltipMouseLeaveCloseDelay]="0" [tooltipCloseOnMouseLeave]="true" [tooltipPlacement]="'right'">{{ item.label }}</div>
      <avn-icon *ngIf="item.data?.columnDefinition.extra?.deprecated" icon="action:warning" [avnTheme]="ThemeType.WARNING" [avnTooltip]="item.data?.columnDefinition.extra?.deprecated | transloco"></avn-icon>
      <avn-icon
        *ngIf="item.data?.columnDefinition.extra?.infoTooltip"
        icon="action:info"
        [avnTheme]="ThemeType.PRIMARY"
        [avnTooltip]="item.data?.columnDefinition.extra?.infoTooltip | transloco"
      ></avn-icon>
    </div>
  </ng-template>
</ng-template>
