/**
 * Enum that represents all the monitored values for sites
 * https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values
 * https://confluence.stratacache.com/display/CM/PoS+Monitoring+Values
 */
export enum SiteMonitoredValue {
  ContentState = 'CONTENT_STATE',
  CpuPercent = 'CPU_PERCENT',
  HealthStatus = 'HEALTH_STATUS',
  LastAttempt = 'LAST_ATTEMPT',
  LastUpdate = 'LAST_UPDATE',
  SystemDisk = 'SYSTEM_DISK',
  SystemMemory = 'SYSTEM_MEMORY',
  Uptime = 'UPTIME',
  /** HttpService - To know if application can perform HTTP requests **/
  HttpService = 'HTTP_SERVICE',
  /** ServicePlayer - 1: working. 0: problem **/
  ServicePlayer = 'SERVICE_PLAYER',

  /** ====== For 5.2 ====== */

  ContentStatus = 'CONTENT_STATUS',

  /** date/time when device was last up-to-date (device had up-to-date contents in all channels)     */
  LastContentUpToDate = 'LAST_CONTENT_UPTODATE',

  /** date/time of last content change for the device (in any of the device channels)   */
  LastContentUpdate = 'LAST_CONTENT_UPDATE',

  /** date/time when device had a failed update (last update failed in any channel) */
  LastContentFailedUpdate = 'LAST_CONTENT_FAILED_UPDATE',

  /** Last time tags were updated through the distribution (all tags updated).*/
  TagsLastModificationTime = 'PROPERTIES_MTIME',

  /** Last time tags were updated through the properties update command (only dynamic tags are updated)  */
  DynamicTagsLastModificationTime = 'PROPERTIES_MTIMEDYN',

  /** Indicates if a device is up to date properties wise */
  DevicePropertiesStatus = 'PROPERTIES_STATUS',

  /** Pos state */
  PosState = 'POS_STATE',

  /** Timestamp which last time a pos update was attempted */
  PosLastUpdate = 'POS_LASTUPDATE',

  /** Timestamp which last time a pos update occurred */
  PosLastModified = 'POS_LASTMODIFIED',

  /** Cod state */
  CodState = 'COD_STATE',

  /** Timestamp which last cod data was received from the pos */
  CodLastUpdate = 'COD_LASTUPDATE',
}
