import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {ITaskPanelItemComponent, ModalService, ModalType} from '@activia/ngx-components';
import {
  BehaviorSubject,
  concatMap,
  map,
  Observable,
  of,
  pairwise,
  ReplaySubject,
  Subject, switchMap, take,
  takeUntil,
} from 'rxjs';
import {DevicesAddDownloadLogsTaskFail, DevicesAddDownloadLogsTaskSuccess, DevicesClearDownloadLogsTask} from '../../../store/devices.actions';
import {DevicesFacade} from '../../../store/devices.facade';
import {DownloadLogsTaskIcons, DownloadLogsTaskStatus, DownloadLogsTaskThemes, IDownloadLogsTask} from '../../../model/download-logs-task.interface';
import {Store} from '@ngrx/store';
import {Overlay} from '@angular/cdk/overlay';
import {catchError, startWith, tap} from 'rxjs/operators';
import {differenceBy} from 'lodash/array';
import {DownloadLogsTasksSummaryModalComponent} from '../download-logs-tasks-summary-modal/download-logs-tasks-summary-modal.component';
import {DOWNLOAD_LOGS} from '../../../model/download-logs.constant';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {PIP_PATH} from '@amp/environment';
import {AuthFacade} from '@amp/auth';

@Component({
  selector: 'amp-download-logs-task-panel-item',
  templateUrl: './download-logs-task-panel-item.component.html',
  styleUrls: ['./download-logs-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLogsTaskPanelItemComponent implements ITaskPanelItemComponent, OnDestroy {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);

  MAX_CONCURRENT_TASKS = 50;

  DownloadLogsTaskIcons = DownloadLogsTaskIcons;
  DownloadLogsTaskThemes = DownloadLogsTaskThemes;

  private _taskSubject = new ReplaySubject<IDownloadLogsTask>();

  private _modelSubject = new BehaviorSubject({
    errorCount: 0,
    successCount: 0,
    totalCount: 0,
    completePercentage: 0,
    taskCompleted: true,
  });

  get model$(): Observable<{
    errorCount: number;
    successCount: number;
    totalCount: number;
    completePercentage: number;
    taskCompleted: boolean;
  }> {
    return this._modelSubject.asObservable();
  }

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(PIP_PATH) private pipPath: string,
    private _devicesFacade: DevicesFacade,
    private _httpClient: HttpClient,
    private _authFacade: AuthFacade,
    private _store: Store,
    private _modalService: ModalService,
    private _overlay: Overlay,
  ) {
    this._devicesFacade.downloadLogsTasks$.pipe(
      startWith([]),
      pairwise(),
      map(([prev, curr]) => {
        const newTasks = differenceBy(curr, prev, 'id');
        newTasks.forEach((task) => {
          const totalCount = this._modelSubject.value.totalCount + 1;
          const model = {
            ...this._modelSubject.value,
            totalCount,
            completePercentage: ((this._modelSubject.value.successCount + this._modelSubject.value.errorCount) / totalCount) * 100,
            taskCompleted: false
          };
          this._modelSubject.next(model);
          this._taskSubject.next(task);
        });
      }),
      takeUntil(this._componentDestroyed$)
    ).subscribe();

    this._taskSubject.asObservable().pipe(
      concatMap((task) =>
        this._downloadLogs(task.deviceId).pipe(
          map((logsData) => {
            this._store.dispatch(DevicesAddDownloadLogsTaskSuccess({ task: { ...task, status: 'SUCCESS' as DownloadLogsTaskStatus, logsData } }));
            return { success: true };
          }),
          catchError((err: HttpErrorResponse) => {
            this._store.dispatch(DevicesAddDownloadLogsTaskFail({ task: { ...task, status: 'FAIL' as DownloadLogsTaskStatus, errMsg: err.message } }));
            return of({ success: false });
          }),
        )
      ),
      tap(({ success }) => {
        const model = { ...this._modelSubject.value };
        if (success) {
          model.successCount++;
        } else {
          model.errorCount++;
        }
        model.completePercentage = ((model.successCount + model.errorCount) / model.totalCount) * 100;
        model.taskCompleted = model.completePercentage === 100;
        this._modelSubject.next(model);
        this.completed$.next(model.taskCompleted);
      }),
      takeUntil(this._componentDestroyed$)
    ).subscribe();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._store.dispatch(DevicesClearDownloadLogsTask());
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  showSummary() {
    this._modalService.open<DownloadLogsTasksSummaryModalComponent, void>(
      DownloadLogsTasksSummaryModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        height: '50%',
        width: '1000px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('100px'),
      },
      ModalType.Dialog
    );
  }

  private _downloadLogs(deviceId: number): Observable<ArrayBuffer> {
    return this._authFacade.userApiToken$.pipe(
      take(1),
      switchMap((sessionId) => this._httpClient.get(`${this.pipPath}/device/${deviceId}/${sessionId}/CM/${DOWNLOAD_LOGS.relativePipUrl}`, { responseType: 'arraybuffer' })),
    );
  }
}

