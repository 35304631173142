import { Injectable } from '@angular/core';
import { Observable, iif } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeviceFilterApiExpressionService } from '../nlp/device-filter/device-filter-api-expression.service';
import { DeviceService, DeviceCountDTO } from '@activia/cm-api';
import { DeviceType } from '../model/device-type.enum';
import { ApiRequestState, AsyncDataService, getAsyncDataError, LoadingState } from '@activia/ngx-components';
import { select, Store } from '@ngrx/store';
import { IDevicesState } from './devices.reducer';
import { AppPreferenceKeys, UserPreferenceKeys } from '@amp/global';
import { devicesSelectors } from './devices.selectors';
import * as DeviceAction from './devices.actions';
import { IMonitoringListDeviceCount, IMonitoringSharedListDTO } from '../model/monitoring-list.interface';
import { IExportDevicesTask } from '../model/export-devices-task.interface';
import { IDownloadLogsTask } from '../model/download-logs-task.interface';

/**
 * Since facades only instantiate itself in it's own module (without providedIn annotation),
 * Some common functions can be put here and used across all lazy loaded modules.
 */
@Injectable({ providedIn: 'root' })
export class DevicesFacade {
  deviceFilterCountRequestState: ApiRequestState = new ApiRequestState();

  /** Shared Lists */
  sharedListsCounts$: Observable<IMonitoringListDeviceCount[]> = this._store.pipe(select(devicesSelectors.sharedListsCounts));
  sharedLists$: Observable<IMonitoringSharedListDTO[]> = this._store.pipe(select(devicesSelectors.sharedLists));
  sharedListsState$ = this._store.pipe(select(devicesSelectors.sharedListsState));
  sharedListsLoading$: Observable<boolean> = this._store.pipe(select(devicesSelectors.sharedListsLoading));
  sharedListsLoaded$: Observable<boolean> = this._store.pipe(select(devicesSelectors.sharedListsLoaded));
  sharedListsError$: Observable<string> = this._store.pipe(select(devicesSelectors.sharedListsError));
  newShared$: Observable<Array<string>> = this._store.pipe(select(devicesSelectors.newShared));
  exportTasks$: Observable<Array<IExportDevicesTask>> = this._store.pipe(select(devicesSelectors.exportTasks));
  downloadLogsTasks$: Observable<Array<IDownloadLogsTask>> = this._store.pipe(select(devicesSelectors.downloadLogsTasks));

  constructor(
    private _store: Store<IDevicesState>,
    private _deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
    private _deviceService: DeviceService,
    private _asyncDataService: AsyncDataService
  ) {}

  /**
   * Get names of all shared lists
   * @param exceptionListId ID of the list to exclude from the returned values
   */
  getNamesSharedLists(exceptionListId?: string): Observable<string[]> {
    return this.sharedLists$.pipe(
      map((sharedLists) => {
        return sharedLists
          .map((sharedList) => {
            return sharedList.id === exceptionListId ? null : sharedList.name;
          })
          .filter((sharedList) => !!sharedList);
      })
    );
  }

  /** Returns the count of devices for the specified device group and filter **/
  getDevicesCount$(deviceGroupId: number, filterExpression: string, deviceTypes: DeviceType[]): Observable<number> {
    return this._deviceFilterApiExpressionService.getAvailableDeviceFilter(filterExpression, deviceTypes, true).pipe(
      switchMap((filter) => {
        // if no filter is provided, we need to use a different endpoint
        const serverRequest$: Observable<number> = iif(
          () => (filter || '').trim().length === 0,
          this._deviceService.getDeviceCountForFilter(deviceGroupId),
          this._deviceService.getDeviceCountForFilter(deviceGroupId, filter)
        ).pipe(map((deviceCountDTO: DeviceCountDTO) => deviceCountDTO.count));
        return this._asyncDataService.doRequestWithState$(serverRequest$, this.deviceFilterCountRequestState, null, null);
      })
    );
  }

  /** Fetch the shared lists */
  fetchSharedLists() {
    this._store.dispatch(DeviceAction.DevicesFetchSharedLists({ userSharedListsKey: UserPreferenceKeys.MONITORING_SHARED_LISTS, appSharedListsKey: AppPreferenceKeys.MONITORING_SHARED_LISTS }));
  }

  /** Updates the shared list devices count */
  updateSharedListDeviceCount(sharedListId: string, managerId = null, resetCountHistory = false) {
    this._store.dispatch(DeviceAction.DevicesUpdateSharedListCount({ sharedListId, managerId, resetCountHistory }));
  }

  getSharedListDeviceCountInfo$(listId: string): Observable<{ listId: string; loaded: boolean; error: string; count: number; previousCount: number | null; countDifference: number | null }> {
    return this.sharedListsCounts$.pipe(
      map((counts) => counts.find((c) => c.listId === listId)),
      map((count) =>
        !count
          ? null
          : {
              listId,
              loaded: count.state === LoadingState.LOADED,
              error: getAsyncDataError(count.state),
              count: count.count,
              previousCount: count.previousCount,
              countDifference: count.previousCount === null || count.count === null ? null : count.count - count.previousCount,
            }
      )
    );
  }
}
