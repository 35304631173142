<ng-container *ngIf="syncStatus === 'error'">
  <avn-icon
    cdkOverlayOrigin
    [fontSize]="20"
    [icon]="'navigation:refresh'"
    [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.ACTIONS.RETRY_50' | transloco"
    tooltipPlacement="top"
    (click)="resume(siteImportState.uid)"
  >
  </avn-icon
></ng-container>

<ng-container *ngIf="syncStatus === 'warning' && missingRequiredValue">
  <avn-data-table-actions [dataRow]="siteImportState">
    <avn-icon
      [fontSize]="20"
      [icon]="'social:construction'"
      [avnTheme]="ThemeType.WARNING"
      [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.ACTIONS.FIX_THIS_WARNING_TITLE_50' | transloco"
      tooltipPlacement="top"
      (click)="openSiteEditor(siteImportState)"
    >
    </avn-icon>
  </avn-data-table-actions>
</ng-container>

<ng-container *ngIf="syncStatus === 'warning' && isGeoIssue">
  <avn-data-table-actions [dataRow]="siteImportState">
    <avn-icon
      #overlayOrigin="cdkOverlayOrigin"
      cdkOverlayOrigin
      [fontSize]="20"
      [icon]="'social:construction'"
      [avnTheme]="ThemeType.WARNING"
      [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.ACTIONS.FIX_THIS_WARNING_TITLE_50' | transloco"
      tooltipPlacement="top"
      (click)="overlayOpened = true"
    >
    </avn-icon>

    <avn-connected-element [overlayOrigin]="overlayOrigin" [minWidth]="'auto'" [opened]="overlayOpened" (clickOutside)="overlayOpened = false" (toggled)="overlayOpened = $event">
      <div avnScroll style="background: var(--modal-background); padding: var(--space-md)">
        <ng-container [ngSwitch]="errorCode">
          <ng-container *ngSwitchCase="'MULTIPLE_ADDRESSES_FOUND'">
            <amp-import-address-selector
              [geoResultOptions]="errorData.geoResponse.result | applyFunction: toGeoResultOptions:['address']:this"
              [siteLocation]="siteImportState.site | applyFunction: toFormattedSiteAddress"
              (locationSelected)="fixMultipleAddressesFound($event, siteImportState)"
            >
            </amp-import-address-selector>
          </ng-container>

          <ng-container *ngSwitchCase="'MISMATCH_ADDRESS'">
            <amp-import-address-selector
              [geoResultOptions]="[errorData.geoResponse.result] | applyFunction: toGeoResultOptions:['address']:this"
              [siteLocation]="siteImportState.site | applyFunction: toFormattedSiteAddress"
              (locationSelected)="fixMismatchAddress($event, siteImportState)"
            >
            </amp-import-address-selector>
          </ng-container>

          <ng-container *ngSwitchCase="'MISMATCH_GEODETIC_COORDINATE'">
            <amp-import-address-selector
              [geoResultOptions]="[errorData.geoResponse.result.geoResult] | applyFunction: toGeoResultOptions:['geoCoordinates']:this"
              [siteLocation]="siteImportState.site.geodeticCoordinates | applyFunction: toFormattedGeoCoordinates:[]:this"
              (locationSelected)="fixSiteGeoCoordinates($event, siteImportState)"
            >
            </amp-import-address-selector>
          </ng-container>
        </ng-container>
      </div>
    </avn-connected-element>
  </avn-data-table-actions>
</ng-container>
