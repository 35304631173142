import { IColumnDefinition } from '@amp/column-picker';
import { ISiteMonitoringColumnPickerExtraConfig } from '../model/site-monitoring-column-picker-extra-config.interface';
import { SITE_COLUMNS } from '../model/site-monitoring-columns';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';

/**
 * Retrieves the column definition from a column id
 * **/
export const getSiteMonitoringColumnDefinition = (id: string): IColumnDefinition<ISiteMonitoringColumnPickerExtraConfig> => SITE_COLUMNS.find((column) => id === column.id);

/**
 * Filter out columns of the deprecated monitored values so that these columns will not
 * appear in the site list
 * Currently deprecated monitored values: OMNICAST_STATUS
 */
export const filterValidSiteMonitoredValuesColumns = (columns: ISiteMonitoringListColumnConfig[]): ISiteMonitoringListColumnConfig[] => {
  return columns.filter((col) => col.id !== 'OMNICAST_STATUS');
};
