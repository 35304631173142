import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that transforms a string into camel case format.
 * If the input string contains spaces, it capitalizes the first letter of each word (excluding the first word).
 *
 * Usage Example:
 * ```
 * <div>
 *   <!-- Assuming 'exampleString' is a property in your component -->
 *   <p>Original String: {{ exampleString }}</p>
 *   <p>Camel Case: {{ exampleString | camelCase }}</p> output
 * </div>
 *  shows:
 * <div>
 *   <p>Original String: this is an example string</p>
 *   <p>Camel Case: thisIsAnExampleString</p>
 * </div>
 * ```
 */
@Pipe({ name: 'camelCase', standalone: true })
export class CamelCasePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return '';
    }
    const camelCaseStr = value.trim().toLowerCase();

    if (camelCaseStr.includes(' ')) {
      const words = camelCaseStr.split(/\s+/);
      const capitalizedWords = words.map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()));
      return capitalizedWords.join('');
    }

    return camelCaseStr;
  }
}
