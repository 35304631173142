<avn-loader [isLoading]="preferencesLoading$ | async" [avnTheme]="themeType.PRIMARY"></avn-loader>
<avn-drawer-layout>
  <avn-drawer-header [title]="'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.TITLE_50' | transloco"
                     [attr.data-e2e]="'site-settings-header'"
                     (closeClicked)="onClose()">
  </avn-drawer-header>
  <avn-drawer-content avnScroll>
    <ng-container *ngIf="preferencesFormGroup">
      <form [formGroup]="preferencesFormGroup">
        <section>
          <h4 [attr.data-e2e]="'site-settings-title'">{{ 'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.CATEGORIES.GLOBAL.TITLE_60' | transloco }}</h4>

          <div class="config-row">
            <label>{{ 'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.ENABLE_DEVICES_ACROSS_BOARDS_40' | transloco }}</label>
            <avn-form-field>
              <avn-switch
                [formControlName]="'allowDevicesAcrossMultipleBoards'"
                [avnTheme]="themeType.PRIMARY"
                [attr.data-e2e]="'allow-devices-across-multiple-boards'"
                (statusChanged)="updateSwitch('allowDevicesAcrossMultipleBoards', $event.status)"
              ></avn-switch>
            </avn-form-field>
          </div>

          <div class="config-row">
            <label>{{ 'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_COUNT_PER_PLAYER_30' | transloco }}</label>
            <avn-form-field>
              <avn-input [formControlName]="'defaultOutputCountPerPlayer'"
                         [avnTheme]="themeType.PRIMARY"
                         [attr.data-e2e]="'default-output-count-per-player'"
                         [type]="'number'"></avn-input>
              <avn-form-field-error
                key="min"
                [attr.data-e2e]="'min-error'"
                [message]="'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_COUNT_PER_PLAYER_MIN_ERROR' | transloco: { minimum: preferencesConfig?.minDefaultOutputCountPerPlayer }"
              ></avn-form-field-error>
              <avn-form-field-error
                key="max"
                [attr.data-e2e]="'max-error'"
                [message]="'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_COUNT_PER_PLAYER_MAX_ERROR' | transloco: { maximum: preferencesConfig?.maxDefaultOutputCountPerPlayer }"
              ></avn-form-field-error>
            </avn-form-field>
          </div>

          <div class="config-row">
            <label>{{ 'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_NUMBER_OF_PLAYBACK_UNIT_30' | transloco }}</label>
            <avn-form-field>
              <avn-input [formControlName]="'defaultPlayerCountPerDevice'"
                         [avnTheme]="themeType.PRIMARY"
                         [attr.data-e2e]="'default-player-count-per-device'"
                         [type]="'number'"></avn-input>
              <avn-form-field-error
                key="min"
                [attr.data-e2e]="'min-error'"
                [message]="
                  'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_NUMBER_OF_PLAYBACK_UNIT_MIN_ERROR' | transloco: { minimum: preferencesConfig?.minDefaultPlayerCountPerDevice }
                "
              ></avn-form-field-error>
              <avn-form-field-error
                key="max"
                [attr.data-e2e]="'max-error'"
                [message]="
                  'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.DEFAULT_NUMBER_OF_PLAYBACK_UNIT_MAX_ERROR' | transloco: { maximum: preferencesConfig?.maxDefaultPlayerCountPerDevice }
                "
              ></avn-form-field-error>
            </avn-form-field>
          </div>

          <div class="config-row">
            <label>{{ 'siteManagementScope.SITE_MANAGEMENT.APPLICATION_SETTINGS.ORG_PATH_BOARD_CASE_50' | transloco }}</label>
            <avn-form-field>
              <avn-select formControlName="orgPathBoardNameCase"
                          [attr.data-e2e]="'org-path-board-name-case'"
                          [options]="caseOptions | toSelectOption"></avn-select>
            </avn-form-field>
          </div>
        </section>
      </form>
    </ng-container>
  </avn-drawer-content>

  <avn-drawer-footer>
    <avn-button id="close"
                [label]="'button.close' | transloco"
                [attr.data-e2e]="'close-btn'"
                (actioned)="onClose()"></avn-button>
    <avn-button [attr.data-e2e]="'reset-btn'" [label]="'button.reset' | transloco" [disabled]="preferencesFormGroup?.pristine" (actioned)="onResetUserPreferences()"></avn-button>
    <avn-button
      [attr.data-e2e]="'save'"
      [avnTheme]="themeType.PRIMARY"
      [label]="'button.save' | transloco"
      [disabled]="preferencesFormGroup?.pristine || preferencesFormGroup?.invalid"
      (actioned)="onSaveUserPreferences()"
    ></avn-button>
  </avn-drawer-footer>
</avn-drawer-layout>
