import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SiteManagementAction from './site-management.actions';
import * as SiteActions from './site-management.actions';
import { CreateTemplateBoardsUpdateStatus } from './site-management.actions';
import { Inject, Injectable, Optional } from '@angular/core';
import { catchError, defaultIfEmpty, filter, map, switchMap, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, zip } from 'rxjs';
import { MessengerNotificationService } from '@amp/messenger';
import {
  ApplicationPreferencesService,
  BoardDTO,
  BoardsService,
  BoardTagsService,
  DeviceCommandService,
  DeviceDTO,
  DeviceService,
  DisplayDTO,
  ResponseDevicesSiteDTO,
  SiteDTO,
  SitesService,
  SiteTagsService,
} from '@activia/cm-api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DEFAULT_SITE_MANAGEMENT_CONFIG_SCHEMA, ISiteManagementConfig, ISiteManagementConfigSchema, ISiteManagementConfigurationSchema, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from './site-management.reducer';
import { siteManagementEntities } from './site-management.selectors';
import { getNewPathUrl, updateSite$ } from '../utils/site.utils';
import { CountryService, GeoTimezoneService } from '@activia/geo';
import { getRequestChainResponseArray } from './site-sync/request-chain';
import { convertToIBoard } from '../utils/iboard.utils';
import { BoardState } from './board/board.reducer';
import * as BoardSelectors from './board/board.selectors';
import * as DeviceSelectors from './device/device.selectors';
import { dataOnceReady, TaskPanelService } from '@activia/ngx-components';
import { SiteDeleteTaskPanelItemComponent } from '../components/site-delete-task-panel-item/site-delete-task-panel-item.component';
import { ExperienceTemplateService, ICreateExperienceTemplateInfo } from '../services/experience-template.service';
import { getApplicationPreferencesByKey, updateApplicationPreferencesByKey } from '@amp/utils/common';
import {
  EXPERIENCE_TEMPLATE_TAG,
  getBoardTagsDefinitions,
  getTagsStructureFromBoard,
  IOrgPathDefRoot,
  selectBoardOrgPathDefinition,
  selectBoardOrgPathDefinitionState,
  selectBoardTagKeysSchema,
} from '@amp/tag-operation';
import { IBoard } from '../models/board-config.interface';
import { DeviceState } from './device/device.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { toCreateTemplateBoardsSuccessEvent } from '../components/experience-template/experience-template.utils';

@Injectable()
export class SiteManagementEffects {
  private readonly SITE_MANAGEMENT_PREFERENCES_KEY = 'siteManagement.preferences';

  /**
   * Fetch the site config / app preference.
   * Values overrided in the app preference have priorities. Missing values will be filled by the default values
   * from site config.
   */
  fetchSiteConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.FetchSiteConfig),
      switchMap(() => {
        const siteConfig$ = this._httpClient.get(this._siteManagementConfig.configFilePath).pipe(catchError(() => of({ ...DEFAULT_SITE_MANAGEMENT_CONFIG_SCHEMA })));
        const appPreference$ = getApplicationPreferencesByKey<ISiteManagementConfigSchema>(this._applicationPreferencesService, `${this.SITE_MANAGEMENT_PREFERENCES_KEY}`, null);

        return combineLatest([appPreference$, siteConfig$]).pipe(
          map(([appPreference, siteConfig]) => {
            const appPref = {
              ...siteConfig,
              ...appPreference,
            };
            return appPref;
          }),
        );
      }),
      map((siteConfig: ISiteManagementConfigurationSchema) => SiteManagementAction.FetchSiteConfigSuccess({ siteConfig })),
      catchError(() => of(SiteManagementAction.FetchSiteConfigSuccess({ siteConfig: { ...DEFAULT_SITE_MANAGEMENT_CONFIG_SCHEMA } }))),
    ),
  );

  /**
   * Update Selected Sites
   */
  updateSelectedSites$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SiteManagementAction.UpdateSelectedSites),
        withLatestFrom(this._store.pipe(siteManagementEntities.selectedCurrentSite$)),
        tap(([{ selectedSiteIds }, currentSite]) => {
          if (!currentSite && selectedSiteIds?.length) {
            // If there is no current active site, then select the first one in the list of selected sites
            const siteId = +selectedSiteIds[0];
            const path = getNewPathUrl(this.route.snapshot, siteId);
            this._router.navigate(path, { queryParamsHandling: 'preserve' });
          } else if (currentSite && !selectedSiteIds?.length) {
            // When user deselected all sites, we need to remove the activated site
            this._router.navigate([...this._siteManagementConfig.moduleBasePath, 'sites'], { queryParamsHandling: 'preserve' });
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Fetch all sites in the system
   */
  fetchAllSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.FetchAllSites),
      switchMap(() => {
        // Performance issue when request counts on all sites: https://jira.activia-networks.com/browse/CM-6380
        const params = ['board-count', 'device-count', 'display-count'];

        return this._siteService.findSitesByKeyword(null, null, params, null, null, null).pipe(
          map((res: Array<SiteDTO>) => {
            const sites = (res || []).map((site) => ({
              ...site,
              boardCount: site.boardCount || 0,
              deviceCount: site.deviceCount || 0,
              displayCount: site.displayCount || 0,
            }));
            return SiteManagementAction.FetchAllSitesSuccess({ sites });
          }),
          catchError((err: HttpErrorResponse) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FETCH_SITES_FAIL_150');
            return of(SiteManagementAction.FetchAllSitesFail({ error: err.error }));
          }),
        );
      }),
    ),
  );

  /** Fetch the current site */
  fetchSiteDetail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.FetchSiteDetail),
      switchMap((action) => {
        const { site$, boards$, devices$, boardOrgPathDef$ } = this.getSiteInfo(action.siteId);

        return this.mapSiteInfo$(site$, boards$, devices$, boardOrgPathDef$).pipe(
          map(([site, boards, devices, displays]) =>
            SiteManagementAction.FetchSiteDetailSuccess({
              site,
              boards,
              devices,
              displays,
            }),
          ),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FETCH_SITE_FAIL_150');
        return of(SiteManagementAction.FetchSiteDetailFail({ error: err.error }));
      }),
    ),
  );

  private mapSiteInfo$(site$: Observable<SiteDTO>, boards$: Observable<BoardDTO[]>, devices$: Observable<DeviceDTO[]>, boardOrgPathDef$: Observable<IOrgPathDefRoot>) {
    return zip(site$, boards$, devices$, boardOrgPathDef$).pipe(
      switchMap(([site, boards, devices, boardOrgPathDef]) => {
        const displays = boards
          .map((e) => e.displays)
          .flat()
          .filter((e) => !!e); // It's possible some display are not defined in the board

        return forkJoin(
          boards.map((board, boardIdx) =>
            this._boardTagsService.findTagsForEntity(board.id).pipe(
              withLatestFrom(this._store.select(selectBoardTagKeysSchema)),
              map(([tags, boardTagKeysSchema]) => {
                // Sanitize tags to be only have 1 value
                const tagValues = Object.keys(tags).reduce((acc, curr) => ({ ...acc, [curr]: tags[curr][0] }), {});
                const expTemplateTag = tagValues[EXPERIENCE_TEMPLATE_TAG];
                const tagsStructure = getTagsStructureFromBoard(boardOrgPathDef, tagValues, boardTagKeysSchema);

                return convertToIBoard(board, boardIdx, devices, tagsStructure, expTemplateTag);
              }),
            ),
          ),
        ).pipe(
          defaultIfEmpty([] as IBoard[]),
          map((newBoards) => [site, newBoards, devices, displays] as [SiteDTO, IBoard[], DeviceDTO[], DisplayDTO[]]),
        );
      }),
    );
  }

  private getSiteInfo(siteId: number) {
    const params = ['board-count', 'device-count', 'display-count', 'managers'];

    const site$ = this._siteService.findSitesById(siteId, params);

    // Pagination is required but there is no need to use it here...A site shouldn't have more than 50 boards,
    // setting limit to 500 should be enough
    const boards$: Observable<BoardDTO[]> = this._boardService.findBoardsByIds(null, ['screens'], siteId, 500, 0);

    // Don't really need pagination...It's probably rare if a site has more than 50 devices, setting to 500 should be enough
    const devices$: Observable<DeviceDTO[]> = this._deviceService
      .getDevices(500, 0, null, null, `site.id=${siteId}`, null, null, ['*'])
      .pipe(map((devices) => (devices ?? []).map((device) => ({ ...device }))));

    const boardOrgPathDef$ = dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState));
    return { site$, boards$, devices$, boardOrgPathDef$ };
  }

  createSiteSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.CreateSiteSuccess),
      filter((action) => action.experiencesSelected?.experiences.length > 0),
      switchMap((action) =>
        this._deviceService.getDeviceTypes().pipe(
          map((deviceTypes) => ({
            siteSource: action.site,
            experiences: action.experiencesSelected.experiences,
            deviceAction: action.experiencesSelected.deviceAction,
            existingSiteBoardOrgPaths: [],
            deviceTypes,
          })),
        ),
      ),
      withLatestFrom(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardTagKeysSchema)),
      switchMap(([createExperiencesInfo, boardOrgPathDef, boardTagKeysSchema]) =>
        this._experienceTemplateService.createExperienceTemplates(createExperiencesInfo).pipe(
          map((resp) => {
            const event = toCreateTemplateBoardsSuccessEvent(boardOrgPathDef, createExperiencesInfo.siteSource as SiteDTO, resp.boards, resp.tags, boardTagKeysSchema, resp.devices);
            return SiteActions.CreateTemplateBoardsSuccess(event);
          }),
        ),
      ),
      catchError((_) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.CREATE_SITE_BOARD_CREATION_FAIL_150');
        return of(null);
      }),
    ),
  );

  /** Update a site */
  updateSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.UpdateSite),
      switchMap((action) => {
        return updateSite$(this._geoTimezoneService, this._countryService, this._siteService, action.site, true, action.tags, this._siteTagsService).pipe(
          map((resp) => {
            this._messengerNotificationService.showSuccessMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_SUCCESS_100');
            return SiteManagementAction.UpdateSiteSuccess({ site: resp.siteResponse.site });
          }),
          catchError((_) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_FAIL_100');
            return of(null);
          }),
        );
      }),
    ),
  );

  /** Create boards from template in the specified site */
  createTemplateBoards$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.CreateTemplateBoards),
      // Get tags defintion for board org path
      switchMap(({ site, boards, expTemplate }) => {
        const tags = Object.keys(expTemplate.tags);

        return getBoardTagsDefinitions(this._boardTagsService, tags).pipe(
          map((tagsDefinition) => ({ site, boards, expTemplate, tagsDefinition })), // Add tag defintions to be used in orgpath definition
        );
      }),
      withLatestFrom(
        this._boardStore.select(BoardSelectors.selectAllBoards),
        this._store.pipe(siteManagementEntities.siteConfigData$),
        this._store.select(selectBoardOrgPathDefinition),
        this._store.select(selectBoardTagKeysSchema),
      ),
      switchMap(([{ site, boards, expTemplate, tagsDefinition }, boardsInCurrSite, { defaultPlayerCountPerDevice }, boardOrgPathDef, boardTagKeysSchema]) => {
        // if devices for each boards are provided, that means provisionning was done
        const shouldProvisionDevices = !!boards;

        const createExperienceTemplateInfo: ICreateExperienceTemplateInfo = {
          siteSource: site,
          experiences: [
            {
              experience: expTemplate,
              count: 1,
              devicesPerBoard: shouldProvisionDevices
                ? boards.reduce(
                    (res, board, boardIndex) => ({
                      ...res,
                      [boardIndex]: board.devices,
                    }),
                    {},
                  )
                : null,
            },
          ],
          deviceAction: shouldProvisionDevices ? 'provision' : 'connect-existing',
          existingSiteBoardOrgPaths: boardsInCurrSite.map((e) => e.organizationPath),
        };

        const experienceTemplateRequestChain = this._experienceTemplateService.createExperienceTemplatesRequestChain(
          createExperienceTemplateInfo,
          defaultPlayerCountPerDevice,
          boardOrgPathDef,
          tagsDefinition,
        );

        return experienceTemplateRequestChain.resume$().pipe(
          tap(({ requestId, loadingState }) =>
            this._store.dispatch(
              CreateTemplateBoardsUpdateStatus({
                stepId: requestId,
                loadingState,
              }),
            ),
          ),
          toArray(),
          map((events) => {
            const [lastResponse] = events.slice(-1);

            if (!lastResponse) {
              // All boards in the site already exist
              this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.BOARDS_ALREADY_EXIST_100');
              return SiteManagementAction.CreateTemplateBoardsFail({
                error: 'All boards already exist in the site',
                siteId: site.id,
              });
            }

            if (lastResponse.errorInfo) {
              this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.CREATE_EXPERIENCE_TEMPLATE_FAIL_150', {
                errorMsg: lastResponse.errorInfo.message,
              });
              return SiteManagementAction.CreateTemplateBoardsFail({
                error: lastResponse.errorInfo.message,
                siteId: site.id,
              });
            }

            let createdBoardDTOs = getRequestChainResponseArray<BoardDTO>(lastResponse.requestChainState.responses, 'create-board');
            const createdDeviceDtos = getRequestChainResponseArray<DeviceDTO>(lastResponse.requestChainState.responses, 'create-device');
            const createdDisplayDtos = getRequestChainResponseArray<DisplayDTO>(lastResponse.requestChainState.responses, 'create-displays');
            const createdTags = getRequestChainResponseArray<Record<string, unknown>>(lastResponse.requestChainState.responses, 'create-tags');

            const successfullyAttachedDeviceIds = getRequestChainResponseArray<ResponseDevicesSiteDTO>(lastResponse.requestChainState.responses, 'attach-devices-to-site')
              .map(({ create }) => {
                const addedDevicesIds = Array.from(create)
                  .filter((device: any) => device.code === 200)
                  .map((device: any) => device.id);
                return addedDevicesIds;
              })
              .reduce((addedDeviceIds, deviceIds) => [...addedDeviceIds, ...deviceIds], []);

            createdBoardDTOs = createdBoardDTOs.map((board) => ({
              ...board,
              displays: createdDisplayDtos
                .flat()
                .filter((display) => board.id === display.parentBoardId)
                .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx),
            }));

            const addedDevicesSuccessfullyAttachedToSite = createdDeviceDtos.filter(({ id }) => successfullyAttachedDeviceIds.includes(id));
            const event = toCreateTemplateBoardsSuccessEvent(boardOrgPathDef, site, createdBoardDTOs, createdTags, boardTagKeysSchema, addedDevicesSuccessfullyAttachedToSite);
            return SiteManagementAction.CreateTemplateBoardsSuccess(event);
          }),
        );
      }),
    ),
  );

  /** Delete sites */
  deleteSites$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SiteManagementAction.DeleteSites),
        // adds the task panel component that will queue sites for deletion
        tap(() => this._taskPanelService.addTaskComponent(SiteDeleteTaskPanelItemComponent)),
      ),
    { dispatch: false },
  );

  /**
   * Update SiteManagement App Preferences
   */
  updatePreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.UpdatePreferences),
      switchMap((action) =>
        updateApplicationPreferencesByKey(this._applicationPreferencesService, `${this.SITE_MANAGEMENT_PREFERENCES_KEY}`, action.preferences).pipe(
          map((_) => SiteManagementAction.UpdatePreferencesSuccess({ preferences: action.preferences })),
          catchError((err) => of(SiteManagementAction.UpdatePreferencesFail({ error: err }))),
        ),
      ),
    ),
  );

  /** Push changes to players of devices */
  pushChangesToPlayer$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SiteManagementAction.PushChangesToPlayers),
        withLatestFrom(this._deviceStore.select(DeviceSelectors.selectDeviceIds)),
        switchMap(([{ currentSiteOnly }, ids]) => {
          let requestIds = [];
          if (currentSiteOnly) {
            requestIds = [...(ids as number[])];
          }
          return this._deviceCommandService.updateDeviceProperties1(requestIds).pipe(
            map(() => this._messengerNotificationService.showSuccessMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.SUCCESS.PUSH_TO_PLAYERS_SUCCESS_100')),
            catchError(() => this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.PUSH_TO_PLAYERS_FAIL_100')),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _applicationPreferencesService: ApplicationPreferencesService,
    private _boardService: BoardsService,
    private _boardTagsService: BoardTagsService,
    private _boardStore: Store<BoardState>,
    private _deviceStore: Store<DeviceState>,
    private _deviceService: DeviceService,
    private _experienceTemplateService: ExperienceTemplateService,
    private _geoTimezoneService: GeoTimezoneService,
    private _countryService: CountryService,
    private _httpClient: HttpClient,
    private _messengerNotificationService: MessengerNotificationService,
    private _siteService: SitesService,
    private _store: Store<ISiteManagementState>,
    private _taskPanelService: TaskPanelService,
    private _deviceCommandService: DeviceCommandService,
    private _router: Router,
    private route: ActivatedRoute,
    private _siteTagsService: SiteTagsService,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) @Optional() private _siteManagementConfig: ISiteManagementConfig,
  ) {}
}
