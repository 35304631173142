import { ServiceStatusCode } from '@amp/devices';
import { ISiteMonitoringData } from '../model/site-monitoring-data.interface';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { AggregationType } from '@amp/column-picker';

/** Some operations need to be done on monitored values when they are received.
 * E.g convert services statuses from 't' / 'f' to our internal status (1 / 0)
 * */
export const toInternalSiteMonitoredValues = (monitoredValues: ISiteMonitoringData): ISiteMonitoringData => {
  const services = [SiteMonitoredValue.HttpService, SiteMonitoredValue.ServicePlayer, SiteMonitoredValue.PosState, SiteMonitoredValue.CodState];

  return Object.entries(monitoredValues || {}).reduce((res, [id, monitoringValueData]) => {
    monitoringValueData = monitoringValueData || {};
    if (services.includes(id as SiteMonitoredValue)) {
      if (Object.prototype.hasOwnProperty.call(monitoringValueData, 'f')) {
        monitoringValueData[ServiceStatusCode.ERROR] = monitoringValueData['f'];
        delete monitoringValueData['f'];
      }
      if (Object.prototype.hasOwnProperty.call(monitoringValueData, 't')) {
        monitoringValueData[ServiceStatusCode.OK] = monitoringValueData['t'];
        delete monitoringValueData['t'];
      }
    }
    return { ...res, [id]: monitoringValueData };
  }, {});
};

/** Mappings of the aggregation type to the actual data property in site monitoring */
export const SiteMonitoringAggregationTypeMappings = {
  [AggregationType.Min]: 'min',
  [AggregationType.Max]: 'max',
  [AggregationType.Average]: 'avg',
};
