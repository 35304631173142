<ng-container *transloco="let t">

  <avn-dialog-layout>
    <avn-dialog-header
      [showCloseIcon]="dialogConfig.showCloseIcon"
      [title]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.TITLE_50')"
      (closeClicked)="onCancel()"
    ></avn-dialog-header>

    <avn-dialog-content avnScroll>
      <avn-stepper [linear]="true" [showHeader]="false">
        <avn-step [stepControl]="form" [headerType]="'number'">
          <form [formGroup]="form">
            <avn-form-field>
              <avn-textarea
                [attr.data-e2e]="'selection-textarea'"
                formControlName="externalIds"
                [label]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.LABEL_50', { externalIdLabel: siteMonitoringFacade.getColumnLabel(SiteProperties.ExternalId) | async | lowercase })"
                [placeholder]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.PLACEHOLDER_50')"
                [rows]="10"
                [columns]="20"
                (changed)="onSitesSelected($event)">
              </avn-textarea>

              <avn-form-field-error
                key="required"
                [message]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.REQUIRED_MESSAGE_100')">
              </avn-form-field-error>
            </avn-form-field>

            <div class="button-container">
              <avn-button [attr.data-e2e]="'cancel-btn'" [label]="t('button.cancel')" (actioned)="onCancel()"></avn-button>
              <div class="button-list">
                <avn-button [attr.data-e2e]="'next-btn'" [avnTheme]="'primary'" [disabled]="form.pristine || form.invalid" [label]="t('button.next')" (actioned)="goNext()"></avn-button>
              </div>
            </div>
          </form>
        </avn-step>

        <avn-step>
          <div class="summary-title">{{ t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.SUMMARY.MESSAGE_100') }}</div>
          <avn-accordion>
            <avn-accordion-panel
              *ngFor="let status of summary$ | async | keyvalue"
              class="disabled-expansion"
              [disableExpansion]="true"
              [headerTemplate]="statusCount"
              [headerTemplateContext]="{
                count: status.value.length,
                status: status.key
              }"
            >
            </avn-accordion-panel>
          </avn-accordion>

          <ng-template #statusCount let-count="count" let-status="status">
            <div class="status-count">
              <div class="status-count-card">
                <span class="count" [class.active]="count > 0" [avnThemify]="count > 0 ? status === 'valid' ? 'success' : 'danger' : null">{{ count }}</span>
                <span>{{ t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.SUMMARY.' + (status | uppercase) + '_50') }}</span>
              </div>
              <div>
                <avn-icon
                  *ngIf="status === 'invalid'"
                  [icon]="'fa:copy'"
                  [fontSize]="20"
                  [avnTooltip]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.COPY_15')"
                  [tooltipPlacement]="'top'"
                  (click)="copyInvalidSelections()">
                </avn-icon>
              </div>
            </div>
          </ng-template>

          <div class="button-container">
            <avn-button [attr.data-e2e]="'cancel-btn'" [label]="t('button.cancel')" (actioned)="onCancel()"></avn-button>
            <div class="button-list">
              <avn-button [icon]="'navigation:chevron_left'" [label]="t('button.back')" avnStepperPrevious class="btn-prev" [attr.data-e2e]="'btn-prev'"></avn-button>
              <avn-button [attr.data-e2e]="'select-btn'"
                avnStepperComplete
                avnTheme="primary"
                [label]="t('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.SELECT_SITES_50')"
                [disabled]="!(summary$ | async)?.valid.length"
                (actioned)="selectSites()">
              </avn-button>
            </div>
          </div>
        </avn-step>

      </avn-stepper>
    </avn-dialog-content>
  </avn-dialog-layout>

</ng-container>
