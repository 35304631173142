import { DeviceDTO, DeviceService } from '@activia/cm-api';
import { IDataTableColumnConfig, IDataTableDataFetchEvent, IDataTableDataSource } from '@activia/ngx-components';
import { extractTotalRecordsFromHttpHeaders } from '@amp/utils/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  map,
  Observable,
  of,
  ReplaySubject,
  scan,
  share,
  startWith,
  Subject,
  takeUntil
} from 'rxjs';
import { DEFAULT_DRAG_DROP_ROW_ID } from '../../models/player-unit.enum';
import { concatMap, skip, switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { PlayerColumnService } from '../../players/services/player-column.service';
import { PlayerColumn, UNLINKED_PLAYER_COLS } from '../../players/models/player-column';
import { SiteManagementService } from '../../services/site-management.service';
import { Actions, ofType } from '@ngrx/effects';
import * as DeviceActions from '../../store/device/device.actions';
import * as DisplayActions from '../../store/display/display.actions';

@Component({
  selector: 'amp-site-management-provision-list',
  templateUrl: './site-management-provision-list.component.html',
  styleUrls: ['./site-management-provision-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementProvisionListComponent implements OnInit, OnDestroy {

  /** The cell template for drag indicator */
  @ViewChild('dragIndicatorTemplate', { static: true }) dragIndicatorTemplate: TemplateRef<any>;

  @Input() siteId: number;

  /** List of all devices not binded with a site */
  devicesDataSource$: Observable<IDataTableDataSource<DeviceDTO>>;

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  /** Event everytime grid fetch devices */
  fetchDevices$ = new Subject<IDataTableDataFetchEvent>();

  editable$: Observable<boolean>;

  /** Specify whether the search should return unlinked devices only */
  unlinkedDevicesOnly$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  defaultFilter = this.setDefaultFilter();

  /** Filter devices by hostname, serial number or mac address */
  filter$: BehaviorSubject<string> = new BehaviorSubject(this.defaultFilter);

  private _searchQuery: string;

  /** @ignore Pattern used to close all subscriptions*/
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _deviceService: DeviceService,
    private _playerColumnService: PlayerColumnService,
    private _actions$: Actions,
    private _siteManagementService: SiteManagementService
  ) {
    this.editable$ = this._siteManagementService.hasAuthority$('site');
  }

  public ngOnInit(): void {
    this.setColumns();

    // Re-fetch data source when filter change, or when a device is linked/unlinked to the site
    const fetchDataSource$: Observable<string> = combineLatest([
      this.filter$,
      this._actions$.pipe(ofType(DeviceActions.AddDevicesSuccess, DeviceActions.DeleteDevicesSuccess, DisplayActions.SetDeviceToDisplaySuccess), startWith(null)),
    ]).pipe(
      map(([filter, _]) => filter),
    );

    const devices$: Observable<{ devices: DeviceDTO[]; count: number }> = fetchDataSource$.pipe(
      switchMap((filter: string) => concat(of({ devices: [], count: 1 }), this.getDevices$(filter))),
      skip(1), // Skip the first event {devices: [], count: 1} because the data-table will do it when initialized
      takeUntil(this.componentDestroyed$)
    );

    this.devicesDataSource$ = devices$.pipe(
      map((devices) => {
        return {
          rows: devices.devices.map((d) => {
            if (!d.siteId || d.siteId === -1) {
              d.siteId = undefined;
              return d;
            } else {
              return { ...d, datatableRowOptions: { disabled: true } };
            }
          }),
          totalRowCount: devices.count,
        };
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );
  }

  /** @ignore */
  public ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  setColumns(): void {
    const columns$ = this._playerColumnService.getFormattedPlayerColumns$(UNLINKED_PLAYER_COLS).pipe(
      map((columns) => [
        {
          id: 'dragIndicator',
          name: null,
          prop: 'dragIndicator',
          dataCellTemplate: this.dragIndicatorTemplate,
          sortable: false,
          resizable: false,
          draggable: false,
          widthPx: 20,
        },
        ...columns,
      ])
    );
    this.columns$ = combineLatest([columns$, this.unlinkedDevicesOnly$]).pipe(
      map(([columns, unlinkedDevicesOnly]) => unlinkedDevicesOnly ? columns.filter((col) => col.id !== PlayerColumn.LINKED_SITE_ID) : columns),
    );
  }

  searchQueryChanged(value: string) {
    this._searchQuery = value.trim();
    this.searchDevices();
  }

  toggleUnlinkedDevicesOnly() {
    this.unlinkedDevicesOnly$.next(!this.unlinkedDevicesOnly$.value);
    this.setDefaultFilter();
    this.searchDevices();
  }

  public fetchDevices(fetchEvent: IDataTableDataFetchEvent): void {
    this.fetchDevices$.next(fetchEvent);
  }

  /** Search the specific device by hostname, serial number or mac address */
  private searchDevices() {
    if (!this._searchQuery?.length || this._searchQuery.length > 2) {
      const filter =
        !this._searchQuery?.length
          ? this.defaultFilter
          : `( serial ~ "${this._searchQuery}" | interface.hostname ~ "${this._searchQuery}" | interface.ip ~ "${this._searchQuery}" | interface.mac ~ "${this._searchQuery}" ) & ${this.defaultFilter}`;
      this.filter$.next(filter);
    }
  }

  private mapDevices(response: HttpResponse<Array<DeviceDTO>>): { devices: DeviceDTO[]; count: number } {
    const totalDevices = extractTotalRecordsFromHttpHeaders<DeviceDTO>(response);
    const devices = response.body.map((device) => ({
      ...device,
      datatableRowOptions: {
        dragOriginId: DEFAULT_DRAG_DROP_ROW_ID,
      },
    }));

    return { count: totalDevices, devices };
  }

  private getDevices$(filter: string): Observable<{ devices: DeviceDTO[]; count: number }> {
    // Lazy loading devices with virtual scroll
    return this.fetchDevices$.pipe(
      concatMap((fetchEvent: IDataTableDataFetchEvent) => this._deviceService.getDevices(fetchEvent.amount, fetchEvent.offset, null, null, filter, null, null, ['*'], null, null,'response')),
      map((response: HttpResponse<Array<DeviceDTO>>) => this.mapDevices(response)),
      scan(
        (all, { devices, count }) => ({
          devices: [...all.devices, ...devices],
          count,
        }),
        { devices: [], count: 0 }
      )
    );
  }

  private setDefaultFilter(): string {
    this.defaultFilter = this.unlinkedDevicesOnly$.value ?
      `( site.id.defined="false" ) & ( type.type = "Digital Signage Network Player" )` :
      `( site.id != ${this.siteId} ) & ( type.type = "Digital Signage Network Player" )`;
    return this.defaultFilter;
  }
}
