/* eslint-disable no-useless-escape */
import { createToken, Lexer, TokenType } from 'chevrotain';

const _escapeRegExp = (str): string => str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // $& means the whole matched string
const REGEX_DATETIME = /"\d{4}\-\d{2}\-\d{2}(?:\s\d{2}:\d{2}(?::\d{2})?)?"/;

// error if tokenSuggestions not in same file
export const Value = createToken({ name: 'Value', pattern: Lexer.NA });
export const StringValue = createToken({ name: 'StringValue', pattern: /"[a-zA-ZÀ-ÿ0-9_$&+,:;=?@#|'<>.^*()%!\s\\-]*"/, label: '', categories: Value });
export const WhiteSpace = createToken({ name: 'WhiteSpace', pattern: /[ \t\n\r\s]+/, group: Lexer.SKIPPED });
export const NumericValue = createToken({ name: 'NumericValue', pattern: /(?:\d*\.\d{1,2}|\d+)/, label: '', categories: Value, longer_alt: StringValue });
export const DateValue = createToken({ name: 'DateValue', pattern: REGEX_DATETIME, label: '', categories: Value, longer_alt: StringValue });
export const LParenthesis = createToken({ name: 'LParenthesis', pattern: '(', label: 'NLP.SYMBOL.LEFT_PARENTHESIS' });
export const RParenthesis = createToken({ name: 'RParenthesis', pattern: ')', label: 'NLP.SYMBOL.RIGHT_PARENTHESIS' });
export const Field = createToken({ name: 'Field', pattern: Lexer.NA });
export const NumericField = createToken({ name: 'NumericField', pattern: Lexer.NA, categories: Field });
export const EqualityNumericField = createToken({ name: 'EqualityNumericField', pattern: Lexer.NA, categories: Field });
export const EqualityStringField = createToken({ name: 'EqualityStringField', pattern: Lexer.NA, categories: Field });
/** String fields that cannot be used with IsDefined and IsNotDefined operators */
export const DefinedStringField = createToken({ name: 'DefinedStringField', pattern: Lexer.NA, categories: Field });
export const StringField = createToken({ name: 'StringField', pattern: Lexer.NA, categories: Field });
export const DateField = createToken({ name: 'DateField', pattern: Lexer.NA, categories: Field });
export const DurationField = createToken({ name: 'DurationField', pattern: Lexer.NA, categories: Field });
export const TimeUnit = createToken({ name: 'TimeUnit', pattern: Lexer.NA });
export const PercentageField = createToken({ name: 'PercentageField', pattern: Lexer.NA });
// Devices Properties
export const Hostname = createToken({ name: 'Hostname', pattern: 'interface.hostname', label: 'DEVICE.PROPERTY.HOSTNAME.NAME', categories: StringField });
export const IpAddress = createToken({ name: 'IpAddress', pattern: 'interface.ip', label: 'DEVICE.PROPERTY.IP_ADDRESS.NAME', categories: StringField });
export const NlpOperationalState = createToken({ name: 'OperationalState', pattern: 'operational.state', label: 'DEVICE.PROPERTY.OPERATIONAL_STATE.NAME', categories: [EqualityStringField] });
export const QuickFilter = createToken({ name: 'QuickFilter', pattern: 'quick.filter', label: 'DEVICE.PROPERTY.QUICK_FILTER.NAME', categories: StringField });
export const Serial = createToken({ name: 'Serial', pattern: 'serial', label: 'DEVICE.PROPERTY.SERIAL.NAME', categories: StringField });
export const Name = createToken({ name: 'Name', pattern: 'name', label: 'DEVICE.PROPERTY.DEVICE_NAME.NAME', categories: StringField });
export const Version = createToken({ name: 'Version', pattern: 'system.module.version', label: 'DEVICE.PROPERTY.VERSION.NAME', categories: StringField });
// @deprecated use site.address instead
export const LocationZip = createToken({ name: 'LocationZip', pattern: 'location.zip', label: 'DEVICE.PROPERTY.POSTAL_CODE.NAME', categories: StringField });
export const LocationLevel1 = createToken({ name: 'LocationLevel1', label: 'DEVICE.PROPERTY.CONTINENT.NAME', pattern: 'location.level1', categories: [EqualityStringField] }); // Continent
export const LocationLevel2 = createToken({ name: 'LocationLevel2', label: 'DEVICE.PROPERTY.COUNTRY.NAME', pattern: 'location.level2', categories: [EqualityStringField] }); // Country
export const LocationLevel3 = createToken({ name: 'LocationLevel3', label: 'DEVICE.PROPERTY.STATE.NAME', pattern: 'location.level3', categories: StringField }); // State
export const LocationLevel4 = createToken({ name: 'LocationLevel4', label: 'DEVICE.PROPERTY.CITY.NAME', pattern: 'location.level4', categories: StringField }); // City
export const LocationLevel5 = createToken({ name: 'LocationLevel5', label: 'DEVICE.PROPERTY.STREET.NAME', pattern: 'location.level5', categories: StringField }); // Street

export const SiteAddressCity = createToken({ name: 'SiteAddressCity', label: 'DEVICE.PROPERTY.CITY.NAME', pattern: 'site.address.city', categories: DefinedStringField });
export const SiteAddressState = createToken({ name: 'SiteAddressState', label: 'DEVICE.PROPERTY.STATE.NAME', pattern: 'site.address.state', categories: DefinedStringField });
export const SiteAddressZip = createToken({ name: 'SiteAddressZip', label: 'DEVICE.PROPERTY.POSTAL_CODE.NAME', pattern: 'site.address.zip', categories: DefinedStringField });
export const SiteAddressCountry = createToken({ name: 'SiteAddressCountry', label: 'DEVICE.PROPERTY.COUNTRY.NAME', pattern: 'site.address.country', categories: [EqualityStringField] });
export const SiteExternalId = createToken({ name: 'SiteExternalId', label: 'DEVICE.PROPERTY.SITE_EXTERNAL_ID.NAME', pattern: 'site.externalId', categories: DefinedStringField });

export const TypeBrand = createToken({ name: 'TypeBrand', pattern: 'type.brand', label: 'DEVICE.PROPERTY.DEVICE_BRAND.NAME', categories: [StringField] });
export const TypeModel = createToken({ name: 'TypeModel', pattern: 'type.model', label: 'DEVICE.PROPERTY.DEVICE_MODEL.NAME', categories: [StringField] });
export const TypeSeries = createToken({ name: 'TypeSeries', pattern: 'type.series', label: 'DEVICE.PROPERTY.DEVICE_SERIES.NAME', categories: [StringField] });
export const InterfaceMac = createToken({ name: 'InterfaceMac', pattern: 'interface.mac', label: 'DEVICE.PROPERTY.MAC_ADDRESS.NAME', categories: StringField });
// Monitoring Properties
export const MvPlayerVersion = createToken({ name: 'MvPlayerVersion', pattern: 'mv.player.version', label: 'DEVICE.MONITORED_VALUE.PLAYER_VERSION.NAME', categories: StringField });
export const MvAgentVersion = createToken({ name: 'MvAgentVersion', pattern: 'mv.agent.version', label: 'DEVICE.MONITORED_VALUE.AGENT_VERSION.NAME', categories: StringField });
export const MvPl = createToken({ name: 'MvPl', pattern: 'mv.pl', label: 'DEVICE.MONITORED_VALUE.PLAYLIST.NAME', categories: StringField });
export const MvSpls = createToken({ name: 'MvSpls', pattern: 'mv.spls', label: 'DEVICE.MONITORED_VALUE.SUBPLAYLISTS.NAME', categories: StringField });
export const MvPlayerStMsg = createToken({ name: 'MvPlayerStMsg', pattern: 'mv.player.st.msg', label: 'DEVICE.MONITORED_VALUE.PLAYER_STATE_MESSAGE.NAME', categories: StringField });
export const MvPlayerCsMsg = createToken({ name: 'MvPlayerCsMsg', pattern: 'mv.player.cs.msg', label: 'DEVICE.MONITORED_VALUE.CONTENT_STATE_MESSAGE.NAME', categories: StringField });
export const MvPlayerSvcMsg = createToken({ name: 'MvPlayerSvcMsg', pattern: 'mv.player.svc.msg', label: 'DEVICE.MONITORED_VALUE.SERVICE_PLAYER_MESSAGE.NAME', categories: StringField });
export const MvSysCpu = createToken({ name: 'MvSysCpu', pattern: 'mv.sys.cpu', label: 'DEVICE.MONITORED_VALUE.SYSTEM_CPU.NAME', categories: [NumericField, PercentageField] });
export const MvSysMem = createToken({ name: 'MvSysMem', pattern: 'mv.sys.mem', label: 'DEVICE.MONITORED_VALUE.SYSTEM_MEMORY.NAME', categories: [NumericField, PercentageField] });
export const MvSysDisk = createToken({ name: 'MvSysDisk', pattern: 'mv.sys.disk', label: 'DEVICE.MONITORED_VALUE.SYSTEM_DISK.NAME', categories: [NumericField, PercentageField] });
export const MvHttpSvc = createToken({ name: 'MvHttpSvc', pattern: 'mv.http.svc', label: 'DEVICE.MONITORED_VALUE.HTTP_SERVICE.NAME', categories: EqualityNumericField });
export const MvFtpSvc = createToken({ name: 'MvFtpSvc', pattern: 'mv.ftp.svc', label: 'DEVICE.MONITORED_VALUE.FTP_STATUS.NAME', categories: EqualityNumericField });
export const MvPlayerSvc = createToken({ name: 'MvPlayerSvc', pattern: 'mv.player.svc', label: 'DEVICE.MONITORED_VALUE.SERVICE_PLAYER.NAME', categories: EqualityNumericField });

// @deprecated use MvPlayerContentStatus instead
export const MvPlayerCs = createToken({ name: 'MvPlayerCs', pattern: 'mv.player.cs', label: 'DEVICE.MONITORED_VALUE.CONTENT_STATE.NAME', categories: EqualityNumericField });

export const MvPlayerContentStatus = createToken({
  name: 'MvPlayerContentStatus',
  pattern: 'mv.content.status',
  label: 'DEVICE.MONITORED_VALUE.CONTENT_STATUS.NAME',
  categories: EqualityNumericField,
});

export const MvLastContentUpdate = createToken({ name: 'Content Last Update', pattern: 'mv.content.last_update', label: 'DEVICE.MONITORED_VALUE.LAST_CONTENT_UPDATE.NAME', categories: DateField });
export const MvLastContentUpTodate = createToken({
  name: 'Content Last Up-To-Date',
  pattern: 'mv.content.last_uptodate',
  label: 'DEVICE.MONITORED_VALUE.LAST_CONTENT_UPTODATE.NAME',
  categories: DateField,
});
export const MvLastContentFailedUpdate = createToken({
  name: 'Last Failed Content Update',
  pattern: 'mv.content.last_failed_update',
  label: 'DEVICE.MONITORED_VALUE.LAST_CONTENT_FAILED_UPDATE.NAME',
  categories: DateField,
});

export const MvPlayerSt = createToken({ name: 'MvPlayerSt', pattern: 'mv.player.st', label: 'DEVICE.MONITORED_VALUE.PLAYER_STATE.NAME', categories: EqualityNumericField });
export const MvStatus = createToken({ name: 'MvStatus', pattern: 'mv.status', label: 'DEVICE.MONITORED_VALUE.MONITORING_STATUS.NAME', categories: EqualityNumericField });
export const MvHealthStatus = createToken({ name: 'MvHealthStatus', pattern: 'mv.health.status', label: 'DEVICE.MONITORED_VALUE.HEALTH_STATUS.NAME', categories: EqualityNumericField });
export const MvLastAttempt = createToken({ name: 'LastAttempt', pattern: 'mv.atp', label: 'DEVICE.MONITORED_VALUE.LAST_ATTEMPT.NAME', categories: DateField });
export const MvLastUpdate = createToken({ name: 'LastUpdate', pattern: 'mv.upd', label: 'DEVICE.MONITORED_VALUE.LAST_UPDATE.NAME', categories: DateField });
// COD
export const CodLastUpdate = createToken({ name: 'COD LastUpdate', pattern: 'mv.cod.last_update', label: 'DEVICE.MONITORED_VALUE.COD_LASTUPDATE.NAME', categories: DateField });
export const CodState = createToken({ name: 'COD State', pattern: 'mv.cod.state', label: 'DEVICE.MONITORED_VALUE.COD_STATE.NAME', categories: EqualityNumericField });
// Point Of Sale
export const PosLastUpdate = createToken({ name: 'POS LastUpdate', pattern: 'mv.pos.last_update', label: 'DEVICE.MONITORED_VALUE.POS_LASTUPDATE.NAME', categories: DateField });
export const PosLastModified = createToken({ name: 'POS LastModified', pattern: 'mv.pos.last_modified', label: 'DEVICE.MONITORED_VALUE.POS_LASTMODIFIED.NAME', categories: DateField });
export const PosState = createToken({ name: 'POS State', pattern: 'mv.pos.state', label: 'DEVICE.MONITORED_VALUE.POS_STATE.NAME', categories: EqualityNumericField });
// Enclosure
export const EncFanCount = createToken({ name: 'EncFanCount', pattern: 'mv.enclosure.fan.count', label: 'DEVICE.MONITORED_VALUE.CONF_ENCLOSURE_FANS.NAME', categories: EqualityNumericField });
export const EncFanType = createToken({ name: 'EncFanType', pattern: 'mv.enclosure.fan.type', label: 'DEVICE.MONITORED_VALUE.CONF_ENCLOSURE_FANSTYPE.NAME', categories: StringField });
export const EncType = createToken({ name: 'EncType', pattern: 'mv.enclosure.type', label: 'DEVICE.MONITORED_VALUE.CONF_ENCLOSURE_TYPE.NAME', categories: EqualityStringField });
export const EncVersion = createToken({ name: 'EncVersion', pattern: 'mv.enclosure.version', label: 'DEVICE.MONITORED_VALUE.CONF_ENCLOSURE_VERSION.NAME', categories: StringField });
export const EncFantime = createToken({ name: 'EncFantime', pattern: 'mv.enclosure.fantime', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_FANTIME.NAME', categories: DurationField });
export const EncHumid0 = createToken({ name: 'EncHumid0', pattern: 'mv.enclosure.humidity_0', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_HUMID0.NAME', categories: [NumericField, PercentageField] });
export const EncHumid1 = createToken({ name: 'EncHumid1', pattern: 'mv.enclosure.humidity_1', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_HUMID1.NAME', categories: [NumericField, PercentageField] });
export const EncMode = createToken({ name: 'EncMode', pattern: 'mv.enclosure.mode', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_MODE.NAME', categories: EqualityStringField });
export const EncPower0 = createToken({ name: 'EncPower0', pattern: 'mv.enclosure.power_0', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_POWER0.NAME', categories: NumericField });
export const EncPower1 = createToken({ name: 'EncPower1', pattern: 'mv.enclosure.power_1', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_POWER1.NAME', categories: NumericField });
export const EncPressure = createToken({ name: 'EncPressure', pattern: 'mv.enclosure.pressure', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_PRESSURE.NAME', categories: NumericField });
export const EncPwm0 = createToken({ name: 'EncPwm0', pattern: 'mv.enclosure.pwm_0', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_PWM0.NAME', categories: NumericField });
export const EncPwm1 = createToken({ name: 'EncPwm1', pattern: 'mv.enclosure.pwm_1', label: 'DEVICE.MONITORED_VALUE.ENCLOSURE_PWM1.NAME', categories: NumericField });
export const EncTemperatureMin = createToken({
  name: 'EncTemperatureMin',
  pattern: 'mv.enclosure.temperature.min',
  label: 'DEVICE.MONITORED_VALUE.TEMPERATURE_ENCLOSURE_MIN.NAME',
  categories: NumericField,
});
export const EncTemperatureMax = createToken({
  name: 'EncTemperatureMax',
  pattern: 'mv.enclosure.temperature.max',
  label: 'DEVICE.MONITORED_VALUE.TEMPERATURE_ENCLOSURE_MAX.NAME',
  categories: NumericField,
});
export const EncTemperatureAim = createToken({
  name: 'EncTemperatureAim',
  pattern: 'mv.enclosure.temperature.aim',
  label: 'DEVICE.MONITORED_VALUE.TEMPERATURE_ENCLOSURE_AIM.NAME',
  categories: NumericField,
});
export const EncTemperatureEx = createToken({
  name: 'EncTemperatureEx',
  pattern: 'mv.enclosure.temperature.exhaust',
  label: 'DEVICE.MONITORED_VALUE.TEMPERATURE_ENCLOSURE_EX.NAME',
  categories: NumericField,
});
export const EncTemperatureIn = createToken({
  name: 'EncTemperatureIn',
  pattern: 'mv.enclosure.temperature.intake',
  label: 'DEVICE.MONITORED_VALUE.TEMPERATURE_ENCLOSURE_IN.NAME',
  categories: NumericField,
});

// other properties
export const SiteManager = createToken({ name: 'SiteManager', pattern: 'site.manager', label: 'DEVICE.ORGANIZATION_INFO.SITE_MANAGER', categories: EqualityNumericField });
const siteTagRegex = new RegExp(`(site.)[a-zA-Z0-9\.\_\-]*`);
export const SiteTagField = createToken({ name: 'SiteTagField', label: '', pattern: siteTagRegex, categories: Field });
const boardTagRegex = new RegExp(`(board.)[a-zA-Z0-9\.\_\-]*`);
export const BoardTagField = createToken({ name: 'BoardTagField', label: '', pattern: boardTagRegex, categories: Field });


//#region - operators
export const Operator = createToken({ name: 'Operator', pattern: Lexer.NA });
export const SelfEndOperator = createToken({ name: 'SelfEndOperator', pattern: Lexer.NA, categories: Operator });
export const StringOperator = createToken({ name: 'StringOperator', pattern: Lexer.NA, categories: Operator });
export const RelationalOperator = createToken({ name: 'RelationalOperator', pattern: Lexer.NA, categories: Operator });
export const DateOperator = createToken({ name: 'DateOperator', pattern: Lexer.NA, categories: Operator });

export const Equals = createToken({ name: 'Equals', pattern: '=', label: 'NLP.OPERATOR.EQUALS', categories: [StringOperator, RelationalOperator] });
export const NotEquals = createToken({ name: 'NotEquals', pattern: '!=', label: 'NLP.OPERATOR.NOT_EQUALS', categories: [StringOperator, RelationalOperator] });

export const Contains = createToken({ name: 'Contains', pattern: '~', label: 'NLP.OPERATOR.CONTAINS', categories: StringOperator });
export const NotContains = createToken({ name: 'NotContains', pattern: '!~', label: 'NLP.OPERATOR.NOT_CONTAINS', categories: StringOperator });

export const StartsWith = createToken({ name: 'StartsWith', pattern: '*~', label: 'NLP.OPERATOR.STARTS_WITH', categories: StringOperator });
export const NotStartsWith = createToken({ name: 'NotStartsWith', pattern: '!*~', label: 'NLP.OPERATOR.NOT_STARTS_WITH', categories: StringOperator });

export const EndsWith = createToken({ name: 'EndsWith', pattern: '~*', label: 'NLP.OPERATOR.ENDS_WITH', categories: StringOperator });
export const NotEndsWith = createToken({ name: 'NotEndsWith', pattern: '!~*', label: 'NLP.OPERATOR.NOT_ENDS_WITH', categories: StringOperator });

export const In = createToken({ name: 'In', pattern: '%', label: 'NLP.OPERATOR.IN', categories: [StringOperator] });
export const NotIn = createToken({ name: 'NotIn', pattern: '!%', label: 'NLP.OPERATOR.NOT_IN', categories: [StringOperator] });

export const IsDefined = createToken({ name: 'IsDefined', pattern: 'notnull', label: 'NLP.OPERATOR.IS_DEFINED', categories: [SelfEndOperator] });
export const IsNotDefined = createToken({ name: 'IsNotDefined', pattern: 'isnull', label: 'NLP.OPERATOR.IS_NOT_DEFINED', categories: [SelfEndOperator] });

export const GreaterThan = createToken({ name: 'GreaterThan', pattern: '>', label: 'NLP.OPERATOR.GREATER_THAN', categories: [RelationalOperator, DateOperator] });
export const LessThan = createToken({ name: 'LessThan', pattern: '<', label: 'NLP.OPERATOR.LESS_THAN', categories: [RelationalOperator, DateOperator] });
export const GreaterThanEquals = createToken({ name: 'GreaterThanEquals', pattern: '>=', label: 'NLP.OPERATOR.GREATER_THAN_EQUALS', categories: [RelationalOperator, DateOperator] });
export const LessThanEquals = createToken({ name: 'LessThanEquals', pattern: '<=', label: 'NLP.OPERATOR.LESS_THAN_EQUALS', categories: [RelationalOperator, DateOperator] });
//#endregion

// logical operators
export const LogicalOperator = createToken({ name: 'LogicalOperator', pattern: Lexer.NA });
export const Or = createToken({ name: 'Or', pattern: '|', label: 'NLP.LOGICAL_OPERATOR.OR', categories: LogicalOperator });
export const And = createToken({ name: 'And', pattern: '&', label: 'NLP.LOGICAL_OPERATOR.AND', categories: LogicalOperator });
// Time Units
export const Second = createToken({ name: 'Second', pattern: 'second', label: 'NLP.TIME_UNIT.SECOND', categories: TimeUnit });
export const Minute = createToken({ name: 'Minute', pattern: 'minute', label: 'NLP.TIME_UNIT.MINUTE', categories: TimeUnit });
export const Hour = createToken({ name: 'Hour', pattern: 'hour', label: 'NLP.TIME_UNIT.HOUR', categories: TimeUnit });
export const Day = createToken({ name: 'Day', pattern: 'day', label: 'NLP.TIME_UNIT.DAY', categories: TimeUnit });
export const Week = createToken({ name: 'Week', pattern: 'week', label: 'NLP.TIME_UNIT.WEEK', categories: TimeUnit });
export const Month = createToken({ name: 'Month', pattern: 'month', label: 'NLP.TIME_UNIT.MONTH', categories: TimeUnit });
export const Year = createToken({ name: 'Year', pattern: 'year', label: 'NLP.TIME_UNIT.YEAR', categories: TimeUnit });

// create a regex that match any string expect fields above
const allPatterns = [
  Hostname,
  IpAddress,
  Serial,
  Name,
  LocationZip,
  LocationLevel1,
  LocationLevel2,
  LocationLevel3,
  LocationLevel4,
  LocationLevel5,
  TypeBrand,
  TypeModel,
  TypeSeries,
  InterfaceMac,
  NlpOperationalState,
  MvPlayerVersion,
  MvAgentVersion,
  MvSpls,
  MvPlayerStMsg,
  MvPlayerCsMsg,
  MvPlayerSvcMsg,
  MvSysCpu,
  MvSysMem,
  MvSysDisk,
  MvHttpSvc,
  MvFtpSvc,
  MvPlayerSvc,
  MvPlayerCs,
  MvPlayerContentStatus,
  MvPlayerSt,
  MvPl,
  MvHealthStatus,
  MvLastAttempt,
  MvLastUpdate,
  EncFanCount,
  EncFanType,
  EncVersion,
  EncFantime,
  EncHumid0,
  EncHumid1,
  EncMode,
  EncPower0,
  EncPower1,
  EncPressure,
  EncPwm0,
  EncPwm1,
  EncTemperatureMin,
  EncTemperatureMax,
  EncTemperatureAim,
  EncTemperatureEx,
  EncTemperatureIn,
  SiteManager,
  Second,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Year,
]
  .map((token) => _escapeRegExp(token.PATTERN))
  .join('|');

const tagFieldRegex = new RegExp(`((tag\.)?((?!${allPatterns})[a-zA-Z0-9\.\_\-])+)`);
export const TagField = createToken({ name: 'TagField', label: '', pattern: tagFieldRegex, categories: Field });

export const DEVICE_TOKEN_GROUPS = new Map<string, TokenType[]>([
  ['NLP.CATEGORY.DEVICE_PROPERTIES', [Hostname, IpAddress, InterfaceMac, Serial, TypeBrand, TypeModel, TypeSeries, NlpOperationalState, Version]],
  ['NLP.CATEGORY.COD', [CodLastUpdate, CodState]],
  ['NLP.CATEGORY.POS', [PosLastUpdate, PosLastModified, PosState]],
  ['NLP.CATEGORY.DEVICE_LOCATION', [SiteAddressCity, SiteAddressState, SiteAddressZip, SiteAddressCountry, SiteExternalId]],
  [
    'NLP.CATEGORY.MONITORED_VALUES',
    [
      MvPlayerVersion,
      MvAgentVersion,
      MvSpls,
      MvPlayerStMsg,
      MvPlayerCsMsg,
      MvPlayerSvcMsg,
      MvSysCpu,
      MvSysMem,
      MvSysDisk,
      MvHttpSvc,
      MvFtpSvc,
      MvPlayerSvc,
      MvPlayerCs,
      MvPlayerContentStatus,
      MvPlayerSt,
      MvPl,
      MvStatus,
      MvHealthStatus,
      MvLastUpdate,
      MvLastAttempt,
      MvLastContentUpdate,
      MvLastContentFailedUpdate,
      MvLastContentUpTodate,
    ],
  ],
  [
    'NLP.CATEGORY.ENCLOSURE',
    [
      EncFanCount,
      EncFanType,
      EncType,
      EncVersion,
      EncFantime,
      EncHumid0,
      EncHumid1,
      EncMode,
      EncPower0,
      EncPower1,
      EncPressure,
      EncPwm0,
      EncPwm1,
      EncTemperatureMin,
      EncTemperatureMax,
      EncTemperatureAim,
      EncTemperatureEx,
      EncTemperatureIn,
    ],
  ],
  ['NLP.CATEGORY.ORGANIZATION_INFO', [SiteManager]],
  ['NLP.CATEGORY.TIME_UNIT', [Second, Minute, Hour, Day, Week, Month, Year]],
  [
    'NLP.CATEGORY.OPERATORS',
    [Equals, NotEquals, Contains, NotContains, StartsWith, IsDefined, IsNotDefined, NotStartsWith, EndsWith, NotEndsWith, GreaterThan, LessThan, GreaterThanEquals, LessThanEquals],
  ],
  ['NLP.CATEGORY.LOGICAL_OPERATORS', [And, Or]],
  ['NLP.CATEGORY.GROUPING_OPERATORS', [LParenthesis, RParenthesis]],
]);

/**
 * If any token can be enabled/disabled by feature flag, record it in this map.
 * Key: token name; value: path of the feature flag
 */
export const DEVICE_TOKEN_FEATURE_PERMISSION = new Map<string, string>([[SiteManager.name, 'nlp.siteManager']]);
