import { trimWrapperChar } from '@activia/ngx-components';
import { IToken, TokenType } from 'chevrotain';
import { DateTime, Duration, DurationObjectUnits, DurationUnit } from 'luxon';

import {
  BoardTagField,
  DateField,
  DateOperator,
  DateValue,
  DefinedStringField,
  DurationField,
  EncFantime,
  EqualityNumericField,
  EqualityStringField,
  Equals,
  GreaterThan,
  GreaterThanEquals,
  IsDefined,
  IsNotDefined,
  LessThan,
  LessThanEquals,
  LogicalOperator,
  LParenthesis,
  NotEquals,
  NumericField,
  NumericValue,
  RelationalOperator,
  RParenthesis,
  Second,
  SiteTagField,
  StringField,
  StringOperator,
  StringValue,
  TagField,
  TimeUnit,
} from './device-filter.tokens';
import { LUXON_DURATION_MAP, NLP_API_DATE_FORMAT, NLP_INTERNAL_DATE_FORMAT, NLP_INTERNAL_DATE_TIME_FORMAT } from './device-filter.utils';
import { TagPrefix } from '../../utils/device.utils';

/**
 * This visitor converts the device filter native epxression into the api backend expression
 * - Relative times (e.g 1 Minute) are converted into actual dates
 * - Tags are prefixed with 'custom.field'
 */
export class DeviceFilterApiExpressionVisitor {
  visitor: any;

  constructor(BaseCstVisitorWithDefaults: any) {
    class DeviceFilterApiExpressionVisitorClass extends BaseCstVisitorWithDefaults {
      constructor() {
        super();
        this.validateVisitor();
      }

      filter(ctx): string[] {
        return this.visit(ctx.filterExpression);
      }

      filterExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(...this.visit(ctx.lhs));
        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
          ctx.rhs.forEach((rhsFieldExpression) => {
            tokenList.push(getTokenValue(ctx, LogicalOperator));
            // there will be one operator for each rhs operand
            tokenList.push(...this.visit(rhsFieldExpression));
          });
        }
        return tokenList;
      }

      fieldExpression(ctx): string[] {
        if (ctx.stringExpression) {
          return this.visit(ctx.stringExpression);
        } else if (ctx.siteTagFieldExpression) {
          return this.visit(ctx.siteTagFieldExpression);
        } else if (ctx.boardTagFieldExpression) {
          return this.visit(ctx.boardTagFieldExpression);
        } else if (ctx.tagFieldExpression) {
          return this.visit(ctx.tagFieldExpression);
        } else if (ctx.numericExpression) {
          return this.visit(ctx.numericExpression);
        } else if (ctx.dateExpression) {
          return this.visit(ctx.dateExpression);
        } else if (ctx.durationExpression) {
          return this.visit(ctx.durationExpression);
        } else if (ctx.parenthesisExpression) {
          return this.visit(ctx.parenthesisExpression);
        } else if (ctx.selfEndExpression) {
          return this.visit(ctx.selfEndExpression);
        } else {
          return [];
        }
      }

      parenthesisExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, LParenthesis));
        tokenList.push(...this.visit(ctx.filterExpression));
        tokenList.push(getTokenValue(ctx, RParenthesis));
        return tokenList;
      }

      selfEndExpression(ctx): string {
        if (ctx.isDefinedOrUndefinedExpression) {
          return this.visit(ctx.isDefinedOrUndefinedExpression);
        } else if (ctx.dateIsDefinedOrUndefinedExpression) {
          return this.visit(ctx.dateIsDefinedOrUndefinedExpression);
        } else {
          return null;
        }
      }

      isDefinedOrUndefinedExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, StringField));
        tokenList.push(getTokenValue(ctx, IsDefined, IsNotDefined));
        return tokenList;
      }

      stringExpression(ctx): string {
        if (ctx.regularStringExpression) {
          return this.visit(ctx.regularStringExpression);
        } else if (ctx.equalityStringExpression) {
          return this.visit(ctx.equalityStringExpression);
        } else if (ctx.definedStringExpression) {
          return this.visit(ctx.definedStringExpression);
        } else {
          return null;
        }
      }

      regularStringExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, StringField));
        tokenList.push(getTokenValue(ctx, StringOperator));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      equalityStringExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, EqualityStringField));
        tokenList.push(getTokenValue(ctx, Equals, NotEquals));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      definedStringExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, DefinedStringField));
        tokenList.push(getTokenValue(ctx, StringOperator));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      dateExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, DateField));
        // tokenList.push(getTokenValue(ctx, RelationalOperator));
        // const operator = getToken(ctx, RelationalOperator);
        const operator = getToken(ctx, DateOperator);
        const durationValues = [getTokenValue(ctx, NumericValue), getTokenValue(ctx, TimeUnit)].filter((v) => !!v);
        const dateValue = [getTokenValue(ctx, DateValue)].filter((v) => !!v);

        // if its a date
        if (dateValue.length > 0) {
          // the operator stays the same
          tokenList.push(operator.image, convertDate(dateValue[0]));
          return tokenList;
        }

        // if its a duration
        if (durationValues.length > 0) {
          // the operator may differ and will be recalculated
          tokenList.push(convertDuration(operator, durationValues[0], durationValues[1]));
          return tokenList;
        }

        return tokenList;
      }

      dateIsDefinedOrUndefinedExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, DateField));
        tokenList.push(getTokenValue(ctx, IsDefined, IsNotDefined));
        return tokenList;
      }

      durationExpression(ctx): string[] {
        const tokenList: string[] = [];
        const fieldName = getTokenValue(ctx, DurationField);
        tokenList.push(fieldName);
        tokenList.push(getTokenValue(ctx, RelationalOperator));
        const durationValues = [getTokenValue(ctx, NumericValue), getTokenValue(ctx, TimeUnit)].filter((v) => !!v);
        if (durationValues.length > 1) {
          tokenList.push(convertDurationByExpressionField(fieldName, durationValues[0], durationValues[1]));
          return tokenList;
        }
        return tokenList;
      }

      tagFieldExpression(ctx): string[] {
        const tokenList: string[] = [];
        const tagTokenValue = getTokenValue(ctx, TagField);
        tokenList.push('custom.field:' + wrapWith(removeTagPrefix(tagTokenValue, TagField), '"'));
        tokenList.push(getTokenValue(ctx, StringOperator));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      siteTagFieldExpression(ctx): string[] {
        const tokenList: string[] = [];
        const siteTagTokenValue = getTokenValue(ctx, SiteTagField);
        tokenList.push('site.tag:' + wrapWith(removeTagPrefix(siteTagTokenValue, SiteTagField), '"'));
        tokenList.push(getTokenValue(ctx, StringOperator));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      boardTagFieldExpression(ctx): string[] {
        const tokenList: string[] = [];
        const boardTagTokenValue = getTokenValue(ctx, BoardTagField);
        tokenList.push('board.tag:' + wrapWith(removeTagPrefix(boardTagTokenValue, BoardTagField), '"'));
        tokenList.push(getTokenValue(ctx, StringOperator));
        tokenList.push(getTokenValue(ctx, StringValue));
        return tokenList;
      }

      numericExpression(ctx): string {
        if (ctx.regularNumericExpression) {
          return this.visit(ctx.regularNumericExpression);
        } else if (ctx.equalityNumericExpression) {
          return this.visit(ctx.equalityNumericExpression);
        } else {
          return null;
        }
      }

      regularNumericExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, NumericField));
        tokenList.push(getTokenValue(ctx, RelationalOperator));
        tokenList.push(getTokenValue(ctx, NumericValue));
        return tokenList;
      }

      equalityNumericExpression(ctx): string[] {
        const tokenList: string[] = [];
        tokenList.push(getTokenValue(ctx, EqualityNumericField));
        tokenList.push(getTokenValue(ctx, Equals, NotEquals));
        tokenList.push(getTokenValue(ctx, NumericValue));
        return tokenList;
      }
    }

    // create an instance of the visitor
    this.visitor = new DeviceFilterApiExpressionVisitorClass();
  }

  /**
   * Visits the CST Tree to return the list of field expressions
   */
  getApiExpression(cstResult: any): string {
    return this.visitor.visit(cstResult).join(' ');
  }
}

/** helper function to retrieve data */
const getTokenValue = (ctx: any, ...tokenTypes: TokenType[]): string => {
  for (const tokenType of tokenTypes) {
    const token = getToken(ctx, tokenType);
    if (token) {
      return token.image;
    }
  }
  return null;
};

const getToken = (ctx: any, tokenType: TokenType): IToken => {
  if (ctx[tokenType.name]) {
    return ctx[tokenType.name][0];
  }
  return null;
};

const convertDate = (dateValue: string): string => {
  dateValue = trimWrapperChar(dateValue, '"');
  const internalDateFormat = dateValue.length === NLP_INTERNAL_DATE_TIME_FORMAT.length ? NLP_INTERNAL_DATE_TIME_FORMAT : NLP_INTERNAL_DATE_FORMAT;
  return wrapWith(DateTime.fromFormat(dateValue, internalDateFormat).toFormat(NLP_API_DATE_FORMAT), '"');
};

const convertDuration = (operator: IToken, amount: string, timeUnit: string): string => {
  let newOperator: string;
  if ([Equals, NotEquals].includes(operator.tokenType)) {
    newOperator = operator.image;
  } else if (GreaterThan === operator.tokenType) {
    newOperator = `${LessThan.PATTERN}`;
  } else if (GreaterThanEquals === operator.tokenType) {
    newOperator = `${LessThanEquals.PATTERN}`;
  } else if (LessThan === operator.tokenType) {
    newOperator = `${GreaterThan.PATTERN}`;
  } else if (LessThanEquals === operator.tokenType) {
    newOperator = `${GreaterThanEquals.PATTERN}`;
  }

  // dates are stored UTC in the backend
  const date = DateTime.utc().minus({ [timeUnit.toLowerCase()]: amount });
  const apiFormattedDate = wrapWith(date.toFormat(NLP_API_DATE_FORMAT), '"');
  return `${newOperator} ${apiFormattedDate}`;
};

/** Convert duration for duration expression */
export const convertDurationByExpressionField = (fieldName: string, amount: string, timeUnit: string): string => {
  let targetUnit = Second;
  // different field could have different target unit
  switch (fieldName) {
    case EncFantime.PATTERN:
      targetUnit = Second;
      break;
    default:
      break;
  }
  const key = LUXON_DURATION_MAP[timeUnit];
  const duration = Duration.fromObject({ [key]: Number(amount) } as DurationObjectUnits)
    .shiftTo(LUXON_DURATION_MAP[targetUnit.PATTERN.toString()] as DurationUnit)
    .toObject();

  return duration[LUXON_DURATION_MAP[targetUnit.PATTERN.toString()]];
};

const wrapWith = (str: string, char: string): string => [char, str, char].join('');

const removeTagPrefix = (value: string, tagType: TokenType) => {
  let prefix;
  switch (tagType) {
    case TagField:
      prefix = TagPrefix.Device;
      break;
    case SiteTagField:
      prefix = TagPrefix.Site;
      break;
    case BoardTagField:
      prefix = TagPrefix.Board;
      break;
  }
  if (value.startsWith(prefix)) {
    return value.substring(prefix.length);
  }
  return value;
};
