<ng-container *ngIf="model$ | async as model">
  <avn-task-panel-item
    [icon]="'maps:edit_location_alt'"
    [iconText]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.FIND_WARNINGS_10' | transloco"
  >
    <div class="progress" avnTaskPanelItemFeedbackSlot>
      <avn-progressbar
        *ngIf="model.totalCount > 0"
        [showValue]="true"
        [showIcon]="false"
        [max]="model.totalCount"
        [finished]="model.completePercentage === 100"
      >
        <avn-progress-segment [value]="model.successCount" avnTheme="info"></avn-progress-segment>
      </avn-progressbar>

      <div class="status-info-wrapper">
        <span>{{ 'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.AUTO_FIX_SITES_TASK_PANEL.COMPLETED_TEXT_50' | transloco: { completed: model.successCount, total: model.totalCount } }}</span>

        <div class="count-icons">
          <ng-container *ngIf="model.errorCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['error'], count: model.errorCount, theme: SiteSyncStatusThemes['error'] }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.successCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['processed'], count: model.successCount, theme: SiteSyncStatusThemes['processed'] }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <avn-button
      [attr.data-e2e]="'view-details-btn'"
      avnTaskPanelItemActionSlot
      [label]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.AUTO_FIX_SITES_TASK_PANEL.VIEW_DETAIL_15' | transloco"
      [size]="'small'"
      [avnTheme]="'primary'"
      (actioned)="showSummary()">
    </avn-button>
  </avn-task-panel-item>
</ng-container>

<ng-template #iconCount let-theme="theme" let-count="count" let-icon="icon">
  <div class="icon-count-wrapper">
    <avn-icon [fontSize]="15" [icon]="icon" [avnTheme]="theme"></avn-icon>
    <span>{{ count }}</span>
  </div>
</ng-template>
