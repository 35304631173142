import { MonitoringAlarmEventDTO, SiteDTO } from '@activia/cm-api';
import { BoardDeviceAlarm, IBoardDeviceAlarmInfo, ICombinedDeviceInfo, IDeviceToDisplayConnection, ISiteBoard, ISiteSection, ISiteStructure } from './site-monitoring-detail.model';
import { LiveDeviceInfoDTO, LogicalPlayerDTO } from '@activia/device-screenshot-api';
import {
  AlarmEventLevel,
  ContentStatus,
  convertPlayerAlarm,
  DeviceMonitoringData,
  extractPlayerIdFromAlarm,
  filterNonStatefulAlarms,
  getHealthErrorsExtraInfo,
  getLogicalPlayerMonitoredValues,
  HealthErrorIds,
  HealthStatusCode,
} from '@amp/devices';
import { AsyncDataState, hasAsyncDataError, LoadingState } from '@activia/ngx-components';
import { CONTENT_STATUS_OPTIMISTIC_COMBINATIONS, HEALTH_STATUS_OPTIMISTIC_COMBINATIONS } from '../../../model/optimistic-view-status-combinations';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';

/**
 * Converts the site and its boards into a structure that is used in the UI.
 */
export const getSiteDisplayStructure = (site: SiteDTO, siteBoards: IBoardWithOrgPath[]): ISiteStructure => {
  if (!site || !siteBoards || siteBoards.length === 0) {
    return { sections: [], boards: {} };
  }

  // sort by org path
  siteBoards = siteBoards.sort((a, b) => {
    const orgPath1 = a.organizationPath.split('.');
    const orgPathA = orgPath1.slice(0, orgPath1.length - 1).join('.'); // Remove name

    const orgPath2 = b.organizationPath.split('.');
    const orgPathB = orgPath2.slice(0, orgPath2.length - 1).join('.'); // Remove name

    // Sort by orgpath first and if equality then sort by order
    return orgPathA < orgPathB ? -1 : orgPathA > orgPathB ? 1 : a.order - b.order;
  });

  // build the structure
  let currentSection: ISiteSection = null;
  return siteBoards.reduce(
    (structure, board) => {
      const orgPathTemp = board.organizationPath.split('.');

      const section = orgPathTemp.slice(0, orgPathTemp.length - 1).join('.'); // Remove name

      const siteBoard: ISiteBoard = {
        id: board.id,
        order: board.order,
        name: board.name,
        fullName: [site.name, ...orgPathTemp].filter(Boolean),
      };

      const isNewSection = section !== currentSection?.name || section === ''; // if section-name is empty then we always assume new section
      if (isNewSection) {
        currentSection = { name: section, boards: [], tagsStructure: board.tagValues };
        structure.sections.push(currentSection);
      }

      currentSection.boards = [...currentSection.boards, siteBoard].sort((a, b) => a.order - b.order);
      structure.boards[board.id] = siteBoard;
      return structure;
    },
    {
      sections: [],
      boards: {},
    } as ISiteStructure
  );
};

/**
 * Combines data returned by CM and from the device directly (via CGI) to determine the most up to date device/players state
 * Also adds error and warning detected.
 *
 * @param deviceInfo info about the device returned by cm
 * @param liveDeviceInfo  data from the last SUCCESSFUL fetch from the player via CGI endpoint (/!\ not necessarily from the last attempt)
 * @param liveDeviceInfoDataState the current loading state of the direct connection to the device
 */
export const mergeDeviceAndPlayerInfo = (
  deviceInfo: ICombinedDeviceInfo,
  liveDeviceInfo: LiveDeviceInfoDTO,
  liveDeviceInfoDataState: AsyncDataState,
  lastPlayerAttemptFailed: boolean
): ICombinedDeviceInfo => {
  const logicalPlayers = liveDeviceInfo?.logicalPlayers;
  // check if the device is reachable (from what CM reports + by contacting the player directly - Note: CM may be out of sync so reaching the player is our source of thruth)
  const isDeviceHealthStatusUnreachable = deviceInfo.monitoringData.HEALTH_STATUS === HealthStatusCode.UNREACHABLE;
  const isDeviceUnreachable = hasAsyncDataError(liveDeviceInfoDataState) || lastPlayerAttemptFailed;
  const isDeviceReachable = liveDeviceInfoDataState === LoadingState.LOADED;
  const isDeviceLoading = liveDeviceInfoDataState === LoadingState.LOADING;

  // init response
  const result: ICombinedDeviceInfo = {
    ...deviceInfo,
    deviceLoading: isDeviceLoading,
    alarms: [],
    logicalPlayers: [],
    time: deviceInfo.lastUpdate, // Use the last update timestamp first, if there is data from player, set the one from player
  };

  let usePlayerInfoFromCm = false;

  // The device could not be reached on the CGI endpoint
  if (isDeviceUnreachable) {
    result.alarms.push(createBoardDeviceAlarmInfo('unreachable', AlarmEventLevel.Emergency));
  }

  // CM monitoring data reports the device as unreachable but a direct connection to the device could be established
  if (isDeviceHealthStatusUnreachable && isDeviceReachable) {
    result.alarms.push(createBoardDeviceAlarmInfo('device-reachable-mismatch', AlarmEventLevel.Warning));
  }

  // CM monitoring data reports the device as reachable but a direct connection to the device could not be established.
  if (!isDeviceHealthStatusUnreachable && isDeviceUnreachable) {
    result.alarms.push(createBoardDeviceAlarmInfo('device-unreachable-mismatch', AlarmEventLevel.Warning));
  }

  // check if the device has health errors
  if (deviceInfo.monitoringData?.HEALTH_ERROR_IDS) {
    const healthErrorIds = deviceInfo.monitoringData?.HEALTH_ERROR_IDS.split(',').sort((a, b) => (+a < +b ? -1 : 1)) || [];
    const healthErrorsExtraInfo = getHealthErrorsExtraInfo(deviceInfo.monitoringData);
    healthErrorIds.forEach((healthErrorId) => {
      result.alarms.push(
        createBoardDeviceAlarmInfo('device-health-error', AlarmEventLevel.Error, {
          healthErrorId,
          healthErrorExtraInfo: healthErrorsExtraInfo[healthErrorId],
        })
      );
    });
  }

  // if the device is a legacy one or its monitoring data is empty, it wont have monitoring data so lets just do nothing in that case and use CM monitoring data
  const hasPlayerVersion = !!deviceInfo.monitoringData.PLAYER_VERSION;
  const isPlayerWithoutMonitoringDataAndFailoverInfo = !isPlayerWithMonitoringDataAndFailoverInfo(deviceInfo.monitoringData.PLAYER_VERSION);
  // eslint-disable-next-line no-prototype-builtins
  if (hasPlayerVersion && isPlayerWithoutMonitoringDataAndFailoverInfo) {
    result.alarms.push(createBoardDeviceAlarmInfo('player-version-outdated', AlarmEventLevel.Warning));
  }
  usePlayerInfoFromCm = isDeviceUnreachable;

  // a single player can output to one to several ports, so lets create a map of connections per logical player
  const connectionsPerLogicalPlayer: Record<number, IDeviceToDisplayConnection[]> = deviceInfo.connectivity.reduce(
    (res, connection) => ({
      ...res,
      [connection.devicePlayerId]: [...(res[connection.devicePlayerId] || []), connection],
    }),
    {}
  );

  // add the device level player events (not link to a particular logical player), either from cm or from the player
  // if we could get live info from the player, extract the alarms from it (its more up to date than CM's alarm events)
  // when getting alarms from the player directly, we also need to filter non stateful alarms (this is baked-in on CM's alarms endpoint)
  let deviceAlarmEvents: MonitoringAlarmEventDTO[];
  if (usePlayerInfoFromCm) {
    deviceAlarmEvents = deviceInfo.alarmEvents?.filter((alarm) => (extractPlayerIdFromAlarm(alarm) ?? null) === null);
  } else {
    deviceAlarmEvents = filterNonStatefulAlarms(liveDeviceInfo?.events?.map((event) => convertPlayerAlarm(event, deviceInfo.device.deviceId)));
  }
  deviceAlarmEvents.forEach((alarm) =>
    result.alarms.push({
      alarm: 'event',
      data: alarm,
    })
  );

  // process each logical player of the board setup
  Object.entries(connectionsPerLogicalPlayer).forEach(([logicalPlayerId, connections]) => {
    if (usePlayerInfoFromCm) {
      // extract alarms from cm data
      const logicalPlayerAlarmEvents = deviceInfo.alarmEvents?.filter((alarm) => extractPlayerIdFromAlarm(alarm) === +logicalPlayerId);
      const logicalPlayerBoardAlarms = sortBoardAlarmsBySeverity(
        logicalPlayerAlarmEvents.map((alarm) => ({
          alarm: 'event',
          data: alarm,
        }))
      );
      // find the player in CM's monitoring data and extract its information
      const deviceMonitoringDataPlayerStatuses = getLogicalPlayerMonitoredValues(deviceInfo.monitoringData);
      const logicalPlayerStatus = deviceMonitoringDataPlayerStatuses[logicalPlayerId];
      if (!logicalPlayerStatus) {
        logicalPlayerBoardAlarms.push(createBoardDeviceAlarmInfo('misconfigured-cm', AlarmEventLevel.Warning));
      }

      result.logicalPlayers.push({
        id: +logicalPlayerId,
        status: logicalPlayerStatus?.ok,
        unreachable: isDeviceUnreachable && isDeviceHealthStatusUnreachable,
        playlist: logicalPlayerStatus?.playlist,
        player: null, // we could not contact the player...
        connections, // connections to displays for this player
        alarms: logicalPlayerBoardAlarms,
      });
    } else {
      // extract and convert alarms from the player events
      const logicalPlayer = logicalPlayers?.find((lp) => lp.id === +logicalPlayerId);
      const logicalPlayerAlarmEvents = filterNonStatefulAlarms(logicalPlayer?.monitoring?.events?.map((event) => convertPlayerAlarm(event, deviceInfo.device.deviceId, +logicalPlayerId)));
      const logicalPlayerBoardAlarms = sortBoardAlarmsBySeverity(
        logicalPlayerAlarmEvents.map((alarm) => ({
          alarm: 'event',
          data: alarm,
        }))
      );

      // use the monitoring data returned by the player screenshot api
      const logicalPlayerStatus = logicalPlayer ? getLogicalPlayerStatus(logicalPlayer) : null;
      if (!logicalPlayer) {
        logicalPlayerBoardAlarms.push(createBoardDeviceAlarmInfo('misconfigured-player', AlarmEventLevel.Warning));
      } else if (!logicalPlayerStatus.ok) {
        if (logicalPlayerStatus.ok === null) {
          logicalPlayerBoardAlarms.push(createBoardDeviceAlarmInfo('unknown-state', AlarmEventLevel.Info));
        }
      }

      result.logicalPlayers.push({
        id: +logicalPlayerId,
        status: logicalPlayerStatus?.ok,
        unreachable: isDeviceUnreachable && isDeviceHealthStatusUnreachable,
        playlist: logicalPlayerStatus?.playlist,
        player: logicalPlayer,
        alarms: logicalPlayerBoardAlarms,
        connections, // connections to displays for this player
      });
      result.time = liveDeviceInfo?.sampleTime || new Date().toISOString();
    }
  });

  // misconfiguration errors are also reported at the device level, but in an more generic way
  if (result.logicalPlayers.some((player) => player.alarms.some((error) => error.alarm === 'misconfigured-cm' || error.alarm === 'misconfigured-player'))) {
    result.alarms.push(createBoardDeviceAlarmInfo('misconfigured', AlarmEventLevel.Warning));
  }

  // sort the alarms by severity
  result.alarms = sortBoardAlarmsBySeverity(result.alarms);
  return result;
};

/**
 * Creates a custom board alarm (i.e. not an error from the player itself)
 * */
const createBoardDeviceAlarmInfo = (alarm: BoardDeviceAlarm, level: AlarmEventLevel, customData = null): IBoardDeviceAlarmInfo => ({
  alarm,
  data: {
    level,
    jsonData: customData,
  },
});

/**
 * Extracts the logical players status information for the dto
 * */
export const getLogicalPlayerStatus = (
  logicalPlayer: LogicalPlayerDTO
): {
  /** player status. null when unknown */
  ok: boolean | null;
  /** Name of the playlist currently played */
  playlist: string;
} => {
  const state = logicalPlayer.monitoring.state === undefined || logicalPlayer.monitoring.state === null ? null : logicalPlayer.monitoring.state === 1;
  return { ok: state, playlist: logicalPlayer.monitoring.playlistTitle };
};

/**
 * Indicates if the player version supports monitoring data and failover info
 */
export const isPlayerWithMonitoringDataAndFailoverInfo = (playerVersion: string): boolean => {
  // players with versions < 4.3.1.11 do not return monitoring data and failover info
  // eg. of versions seen in monitoring data:
  // 4.3.1.11 ng4-14.04.6
  // 4.2.4.7
  playerVersion = (playerVersion || '').trim();
  if (!playerVersion) {
    return null;
  }
  playerVersion = playerVersion.split(' ')[0];
  return playerVersion.localeCompare('4.3.1.11', undefined, { numeric: true, sensitivity: 'base' }) >= 0;
};

/**
 * Converts to the device monitoring data to optimistic data
 */
export const toOptimisticMonitoringData = (deviceMonitoringData: DeviceMonitoringData): DeviceMonitoringData => {
  // Health status
  if (HEALTH_STATUS_OPTIMISTIC_COMBINATIONS.has(deviceMonitoringData.HEALTH_STATUS)) {
    deviceMonitoringData.HEALTH_STATUS = HEALTH_STATUS_OPTIMISTIC_COMBINATIONS.get(deviceMonitoringData.HEALTH_STATUS);
    // remove any warnings if we are now in a OK state
    if (!!deviceMonitoringData.HEALTH_ERROR_IDS && deviceMonitoringData.HEALTH_STATUS === HealthStatusCode.OK) {
      deviceMonitoringData.HEALTH_ERROR_IDS = '';
    }
  }
  // Content status
  if (CONTENT_STATUS_OPTIMISTIC_COMBINATIONS.has(deviceMonitoringData.CONTENT_STATUS)) {
    deviceMonitoringData.CONTENT_STATUS = CONTENT_STATUS_OPTIMISTIC_COMBINATIONS.get(deviceMonitoringData.CONTENT_STATUS);
    // remove at least the MISSING_CONTENT health error if we mark the content status as ok
    if (!!deviceMonitoringData.HEALTH_ERROR_IDS && deviceMonitoringData.CONTENT_STATUS === ContentStatus.OK) {
      deviceMonitoringData.HEALTH_ERROR_IDS = deviceMonitoringData.HEALTH_ERROR_IDS.split(',')
        .filter((errorId) => +errorId !== HealthErrorIds.MISSING_CONTENT)
        .join(',');
    }
  }
  return deviceMonitoringData;
};

/**
 * Sorts the alarms by severity level (high to low)
 *
 * @param alarms
 */
export const sortBoardAlarmsBySeverity = (alarms: IBoardDeviceAlarmInfo[]): IBoardDeviceAlarmInfo[] =>
  // get the highest severity
  alarms.sort((a, b) => (a.data.level - b.data.level < 0 ? -1 : 1));
