/**
 * Enum that represents all the properties of a device
 */
export enum DeviceProperty {
  Id = 'DEVICE_ID',
  Name = 'DEVICE_NAME',
  Hostname = 'HOSTNAME',
  HostnameIp = 'HOSTNAME_IP',
  IpAddress = 'IP_ADDRESS',
  MacAddress = 'MAC_ADDRESS',
  OperationalState = 'OPERATIONAL_STATE',
  ResolveHostname = 'RESOLVE_HOSTNAME',
  Serial = 'SERIAL',
  Version = 'VERSION',
  Brand = 'DEVICE_BRAND',
  Model = 'DEVICE_MODEL',
  Series = 'DEVICE_SERIES',
  Type = 'DEVICE_TYPE',
  Longitude = 'LONGITUDE',
  Latitude = 'LATITUDE',
  Street = 'STREET',
  City = 'CITY',
  State = 'STATE',
  Country = 'COUNTRY',
  Continent = 'CONTINENT',
  PostalCode = 'POSTAL_CODE',
}
