import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { getNodeById, ITreeNodeItem, ITreeNodeSelectionEvent, ThemeType, TreeviewComponent } from '@activia/ngx-components';
import { IColumnPickerItem } from './column-picker-item.interface';
import { ColumnPickerListComponent } from './column-picker-list/column-picker-list.component';
import { ColumnPickerService } from './column-picker.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'amp-column-picker',
  templateUrl: './column-picker.component.html',
  styleUrls: ['./column-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnPickerService],
})
export class ColumnPickerComponent<T = any> implements OnInit, OnChanges, OnDestroy {
  /** The treeview component **/
  @ViewChild(TreeviewComponent, { static: true }) treeview: TreeviewComponent<IColumnPickerItem<T>>;

  /** The list component **/
  @ViewChild(ColumnPickerListComponent, { static: true }) list: ColumnPickerListComponent;

  /** The treeview component **/
  @Input() dataSource: ITreeNodeItem<IColumnPickerItem<T>>[] = [];

  /** Ids of the selected items **/
  @Input() selectedColumns: IColumnPickerItem<T>[] = [];

  /** Indicates if the columns for the treeview are loading **/
  @Input() columnsLoading: boolean;

  /** Indicates if the columns are allowed to be renamed */
  @Input() allowRenameColumn: boolean;

  /** A template to render the display type content **/
  @Input() displayTypeTemplate: TemplateRef<any>;

  /** A template to render extra column configuration **/
  @Input() extraColumnConfigTemplate: TemplateRef<any>;

  /** i18n labels **/
  @Input() i18nLabels: {
    treeviewSearchPlaceholder: string;
    treeviewEmptyListMessage: string;
    listSelectedColumnsTitle: string;
    listNoColumnsSelectedMessage: string;
    renameColumnTooltip?: string;
    columnNameInputPlaceholder?: string;
    renameColumnSaveButtonLabel?: string;
  };

  /** Emits when the selection / order or columns has changed **/
  @Output() valueChanged = new EventEmitter<IColumnPickerItem<T>[]>();

  /** Emits when a column name has changed **/
  @Output() columnNameChanged = new EventEmitter<{ item: IColumnPickerItem<T>; name: string }>();

  /** Emits when data is being edited (column name, display type, aggregation type...) **/
  @Output() dataEdited = new EventEmitter<boolean>();

  /** Selected columns in the column picker */
  selectedColumns$: Observable<IColumnPickerItem[]>;

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  ThemeType = ThemeType;

  constructor(private _columnPickerService: ColumnPickerService) {}

  ngOnInit(): void {
    this._columnPickerService.valueChanged$.pipe(takeUntil(this._componentDestroyed$)).subscribe((selectedColumns) => {
      this.valueChanged.emit(selectedColumns);
    });

    this.selectedColumns$ = this._columnPickerService.selectedColumns$;
  }

  ngOnChanges({ selectedColumns }: SimpleChanges): void {
    if (selectedColumns) {
      this._columnPickerService.setSelectedColumns(selectedColumns.currentValue, false);
    }
  }

  /** Called when the treeview selection changes */
  onTreeviewSelectionChange(event: ITreeNodeSelectionEvent<IColumnPickerItem<T>>): void {
    // update our selected columns (keep the current order)
    const selectedColumns = [
      // all selected columns still present in the new selection
      ...this.getSelectedColumns().filter((col) => event.selectedNodes.map((node) => node.id).includes(col.columnDefinition.id)),
      // all new columns not present in the new selection (at the end)
      ...event.selectedNodes
        .filter(
          (node) =>
            !this.getSelectedColumns()
              .map((col) => col.columnDefinition.id)
              .includes(node.id)
        )
        .map((node) => node.data),
    ];
    this._columnPickerService.setSelectedColumns(selectedColumns, true);
  }

  /** Called when a column is renamed **/
  onColumnNameChange(event: { item: IColumnPickerItem<T>; name: string }) {
    // update the column name in the treeview
    // directly modify the treeview datasource to avoid repassing all treeview items (that would deselect all selected items)
    const treeviewNode = getNodeById(this.treeview._internalDatasource, event.item.columnDefinition.id);

    // if the name did not change, do nothing
    if (event.name === treeviewNode.label) {
      return;
    }
    treeviewNode.label = event.name;

    // propagate the event
    this.columnNameChanged.emit(event);
  }

  /** Returns the currently selected columns **/
  getSelectedColumns(): IColumnPickerItem[] {
    return this._columnPickerService.selectedColumns;
  }

  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
